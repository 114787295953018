<template>
  <div>
    <div v-if="multifyLoginUrl && isShowMyAccount" class="full-page-iframe">
      <div
        v-click-outside="closeAllOutside"
        class="marketplace-popup__container"
      >
        <button
          type="button"
          aria-label="Close"
          class="close auth-close"
          @click="closeAll"
        >
          <img :src="require(`@/assets/icons/icon-closed.svg`)" alt="">
        </button>

        <iframe
          ref="accountIframe"
          width="100%"
          height="100%"
          frameborder="0"
          class="marketplace-popup__frame"
          :src="multifyLoginUrl"
        />
      </div>
    </div>

    <div
      v-if="
        (isShowLogin ||
          isShowRegister ||
          isShowMessage ||
          isShowConcurrency ||
          isShowNewPassword ||
          isShowForgot) &&
          (!centerOn || (centerOn && leftOffset && topOffset))
      "
      class="auth-container full-page-iframe"
    >
      <div
        v-click-outside="closeAllOutside"
        :class="`auth-block ${isShowNewPassword ? 'auth-block-set-password' : ''} ${(centerOn
            ? 'centered-on-ref'
            : isShowConcurrency
              ? 'auth-block--concurrency'
              : '') +
            '' +
            (isActiveFeature('webview_feature') ? 'auth-block--webview' : ' ')}`"
        :style="
          centerOn
            ? {
              right: 'auto',
              left: leftOffset,
              top: topOffset,
              transform: 'translate(-50%, -50%)',
            }
            : {}
        "
      >
        <div class="heder-wrap-auth">
          <img
            v-if="!isShowNewPassword"
            width="85"
            :style="`border-radius: ${logo ? '5px' : 0}`"
            :src="logo || require('src/assets/logo/logo-shoprz-dark.svg')"
            alt=""
          >

          <button
            v-show="!outsideClose || forceCloseBtn"
            type="button"
            aria-label="Close"
            class="close auth-close"
            @click="closeAll"
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.77976 1.28241C10.0734 0.988753 10.0734 0.513902 9.77976 0.223368C9.4861 -0.0671666 9.01125 -0.0702906 8.72071 0.223368L5.00312 3.94096L1.28241 0.220244C0.988753 -0.0734145 0.513902 -0.0734145 0.223368 0.220244C-0.0671666 0.513902 -0.0702906 0.988753 0.223368 1.27929L3.94096 4.99688L0.220244 8.71759C-0.0734145 9.01125 -0.0734145 9.4861 0.220244 9.77663C0.513902 10.0672 0.988753 10.0703 1.27929 9.77663L4.99688 6.05904L8.71759 9.77976C9.01125 10.0734 9.4861 10.0734 9.77663 9.77976C10.0672 9.4861 10.0703 9.01125 9.77663 8.72071L6.05904 5.00312L9.77976 1.28241Z" fill="#343434"/>
            </svg>
          </button>
        </div>
        <concurrency-popup
          v-if="isShowConcurrency"
          class="concurrency-popup"
          :is-active.sync="isShowConcurrency"
          :message="messages"
          :actions="actions"
        />

        <message-popup
          v-if="isShowMessage"
          class="message-popup"
          :is-active.sync="isShowMessage"
          :default-messages="messages"
          :actions="actions"
        />

        <div
          v-show="isShowLogin || isShowRegister || isShowForgot || isShowNewPassword"
          :class="{
            'auth-popup': true,
            'auth-popup-set-password': isShowNewPassword,
          }"
        >
          <button
            v-if="isShowForgot"
            class="auth__return-button"
            @click="showAuth"
          >
            <i class="fas fa-chevron-left"/>
          </button>
          <div
            v-if="isShowNavBar || isActiveFeature('webview_feature')"
            class="auth-nav"
            :class="{
              'justify-content-center': isActiveFeature('webview_feature'),
            }"
          />

          <login-popup
            v-show="isShowLogin"
            :default-messages="messages"
            @update:isShowForgot="isShowForgot = $event"
            @update:isShowLogin="isShowLogin = $event"
            @changeMultifyLoginUrl="changeMultifyLoginUrl($event)"
          />
          <div v-if="isShowLogin" class="form-header">
            <div class="form-header__wrap-description">
              <span class="form-text-title">New to Shoprz? </span>
              <span class="form-link-title" @click="showRegister()">Create an account</span>
            </div>
          </div>
          <register-popup
            v-if="isShowRegister"
            :is-active.sync="isShowRegister"
          />
          <template>
            <div v-if="isShowRegister" class="form-header">
              <!-- <div class="form-title">
                Create account
              </div> -->
              <div class="form-header__wrap-description">
                <span class="form-text-title">Already have an account? </span>
                <span class="form-link-title" @click="showAuth()">Sign in</span>
              </div>
            </div>
            <div
              v-if="isShowForgot && !messObj"
              class="form-header form-header--forgot"
            >
              <div class="form-title">
                Forgot your password?
              </div>
              <div class="form-text-title">
                Enter the email address that is connected to your account, so we can send you a link to reset your password.
              </div>
            </div>
            <div
              v-if="(isShowForgot || isShowNewPassword) && messObj"
              class="form-header form-header--forgot mt-2"
            >
              <div class="form-title">
                {{ isShowNewPassword ? 'Set your password' : 'Create new password' }}
              </div>
              <div>
                <span class="form-text-title">
                  {{ isShowNewPassword
                    ? 'To gain access to your account.'
                    : 'Your new password must be different from previous used passwords'
                  }}

                </span>
              </div>
            </div>
          </template>

          <new-password-popup
            v-if="isShowNewPassword"
            @submit:form="onNewPassword"
          />
          <forgot-popup
            v-if="isShowForgot"
            :is-active.sync="isShowForgot"
            :mess-obj="messObj"
            :access-data="accessData"
            @toggleEmailPopup="isShowEmailPopup = true"
            @togglePasswordPopup="isShowPasswordPopup = true"
          />
        </div>
      </div>
    </div>
    <email-sent-popup
      v-if="isShowEmailPopup"
      @closeEmailPopup="isShowEmailPopup = false"
    />
    <password-changed-popup
      v-if="isShowPasswordPopup"
      @closePasswordPopup="isShowPasswordPopup = false"
      @openSignInPopup="showAuth"
    />
    <success-register-popup
      v-if="isShowSuccessRegisterPopup"
      @closeSuccessRegisterPopup="isShowSuccessRegisterPopup = false"
    />
  </div>
</template>

<script>
  import ResponsiveImage from 'src/components/reponsive-image';
  import EmailSentPopup from './email-sent-popup/email-sent-popup.vue';
  import PasswordChangedPopup from './password-changed-popup/password-changed-popup.vue';
  import SuccessRegisterPopup from './success-register-popup/success-register-popup.vue'
  import LoginPopup from 'src/components/auth-block/login/login';
  import RegisterPopup from 'src/components/auth-block/register/register.vue';
  import ForgotPopup from 'src/components/auth-block/forgot-pass/forgot';
  import MessagePopup from 'src/components/auth-block/message/message';
  import ConcurrencyPopup from 'src/components/auth-block/concurrency-message/concurrency-message';
  import vClickOutside from 'v-click-outside';
  import { mapMutations, mapState } from 'vuex';
  import { activateFeature, isActiveFeature } from '@/plugins/feature-toggle';
  import Vue from 'vue';
  import { amsClient } from '@/service/ams';
  import Config from '@/service/config';
  import NewPasswordPopup from '@/components/auth-block/new-password-popup/new-password-popup.vue';

  export default {
    name: 'AuthBlock',
    components: {
      NewPasswordPopup,
      ResponsiveImage,
      LoginPopup,
      RegisterPopup,
      ForgotPopup,
      MessagePopup,
      ConcurrencyPopup,
      EmailSentPopup,
      PasswordChangedPopup,
      SuccessRegisterPopup,
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    data() {
      return {
        isShowLogin: false,
        isShowRegister: false,
        isShowForgot: false,
        isShowMessage: false,
        isShowConcurrency: false,
        isShowMyAccount: false,
        isShowNewPassword: false,
        isShowNavBar: true,
        centerOn: null,
        centerOnRect: null,

        forceCloseBtn: false,
        outsideClose: true,

        messages: '',
        actions: {},
        idTimeout: 0,
        /* for centering*/
        leftOffset: 'auto',
        topOffset: 'auto',
        /*---------------*/
        /*for forgot component*/
        messObj: null,
        accessData: null,
        customer: process.env.VUE_APP_CUSTOMER,
        multifyLoginUrl: '',
        /*-------------------*/

        //modals
        isShowEmailPopup: false,
        isShowPasswordPopup: false,
        isShowSuccessRegisterPopup: false,
        logo: '',
      };
    },
    computed: {
      isShown() {
        return (
          this.isShowLogin ||
          this.isShowRegister ||
          this.isShowMessage ||
          this.isShowConcurrency ||
          this.isShowForgot
        );
      },
      windowSize() {
        return window.innerWidth;
      },
    },
    watch: {
      isShown(newVal) {
        this.setAuthState(newVal);
        if (newVal && this.$store.state.popup.isPurchaseShown) {
          this.closeAll();
        }
      },
      isShowForgot(newVal) {
        if (newVal === false) {
          this.accessData = null;
          this.messObj = null;
          this.isShowNavBar = true;
        }
      },
      isShowMyAccount(val) {
        this.$nextTick(() => {
          Vue.prototype.$accountIframeRef = val ? this.$refs.accountIframe : null;
        });
      },
    },
    beforeCreate() {
      this.$router.beforeEach((to, from, next) => {
        if (from.name !== to.name) {
          this.closeAll();
        }

        next();
      });
    },
    mounted() {
      this.$bus.$on('toggleRegisterVisibility', ({hide}) => {
        this.isShowRegister = !hide;
      });
      this.$bus.$on(
        'showAfterloginAccountPopup',
        this.showAfterloginAccountPopup,
      );
      this.$bus.$on('toggleAuthPopup', this.toggleAuthPopup);
      this.$bus.$on('resizeWindow', this.calculateHeight);
      this.$bus.$on('closeOtherPopups', this.closeAll);

      Config.loaded(() => {
        this.logo = amsClient.get('conf.store.logo');
        // this.logo = 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.40751.cust.original.jpg'
      });
    },
    destroyed() {
      // this.$body.closeModal();
      this.$bus.$off('toggleRegisterVisibility');
      this.$bus.$off('toggleAuthPopup', this.toggleAuthPopup);
      this.$bus.$off('resizeWindow', this.calculateHeight);
      this.$bus.$off(
        'showAfterloginAccountPopup',
        this.showAfterloginAccountPopup,
      );

      this.setAuthState(false);
    },
    methods: {
      ...mapMutations('popup', ['setAuthState']),
      onNewPassword(form) {
        amsClient.account
          .verifyToken(
            this.accessData.pk,
            this.accessData.token,
            true,
            form.password,
            form.confirmPassword,
          )
          .then((res) => {
            // this.$bus.$emit('toggleAuthPopup', {
            //   component: 'login',
            //   force: true,
            //   message: this.translateVal('FORGOT_PASS.SUCCESS_CHANGED_PASS'),
            // });
            this.isShowNewPassword = false;
            this.$body.closeModal();
            this.$emit('togglePasswordPopup');
          })
          .catch((error) => {
            console.log('error', error);
          });
      },
      showAfterloginAccountPopup(url) {
        this.isShowLogin = false;
        this.isShowMyAccount = true;
        this.multifyLoginUrl = url;
      },
      calculateHeight() {
        if (this.centerOn) {
          this.centerOnRect = this.centerOn.getBoundingClientRect();
          this.leftOffset =
            window.innerWidth > 1024
              ? this.centerOnRect
                ? this.centerOnRect.width / 2 + this.centerOnRect.left + 'px'
                : 'auto'
              : '50%';
          this.topOffset = this.centerOnRect
            ? this.centerOnRect.height / 2 + this.centerOnRect.top + 'px'
            : null;
        }
      },
      closeAllOutside() {
        if (this.outsideClose) {
          this.closeAll();
        }
      },
      closeAll() {
        this.isShowLogin = false;
        this.isShowRegister = false;
        this.isShowForgot = false;
        this.isShowMessage = false;
        this.isShowConcurrency = false;
        this.isShowMyAccount = false;
        this.isShowNewPassword = false;
        this.centerOn = null;
        this.accessData = null;
        this.messObj = null;
        this.$body.closeModal();
        // this.$body.closeModal();
      },
      toggleAuthPopup(event = {}) {
        if (this.$root.inTransition && event.centerOn) {
          setTimeout(() => {
            this.toggleAuthPopup(event);
          }, 100);
          return;
        }
        let forceTrigger = event.force !== undefined ? event.force : null;

        let centerOn =
          event.centerOn && document.querySelector(event.centerOn)
            ? document.querySelector(event.centerOn)
            : null;
        if (centerOn && centerOn.getBoundingClientRect()) {
          this.centerOn = centerOn;
          this.calculateHeight();
        } else {
          this.centerOn = null;
        }
        if (event.message) {
          this.messages = event.message;
        } else {
          this.messages = '';
        }
        this.outsideClose =
          event.outsideClose === undefined ? true : !!event.outsideClose;
        this.forceCloseBtn = !!event.forceCloseBtn;

        if (event.actions) {
          this.actions = event.actions;
        } else {
          this.actions = {};
        }

        this.isShowLogin = false;
        this.isShowRegister = false;
        this.isShowForgot = false;
        this.isShowMessage = false;
        this.isShowConcurrency = false;

        switch (event.component) {
          case 'login':
            this.isShowLogin =
              forceTrigger !== null ? forceTrigger : !this.isShowLogin;
            break;
          case 'register':
            this.isShowRegister =
              forceTrigger !== null ? forceTrigger : !this.isShowRegister;
            break;
          case 'forgot':
            this.isShowForgot =
              forceTrigger !== null ? forceTrigger : !this.isShowForgot;
            this.messObj = event.messObj ? event.messObj : null;
            this.accessData = event.accessData ? event.accessData : null;
            this.isShowNavBar = false;
            break;
          case 'set_password':
            this.isShowNewPassword = true;
            this.messObj = event.messObj ? event.messObj : null;
            this.accessData = event.accessData ? event.accessData : null;
            this.isShowNavBar = false;
            break;
          case 'message':
            this.isShowMessage =
              forceTrigger !== null ? forceTrigger : !this.isShowMessage;
            break;
          case 'concurrency':
            this.isShowConcurrency =
              forceTrigger !== null ? forceTrigger : !this.isShowConcurrency;
          case 'success-register':
            this.isShowSuccessRegisterPopup = true;
        }

        if (
          this.isShowLogin ||
          this.isShowForgot ||
          this.isShowRegister ||
          this.isShowMessage ||
          this.isShowConcurrency ||
          this.isShowNewPassword
        ) {
          this.$body.openModal();
        } else {
          this.$body.closeModal();
        }
      },
      showRegister() {
        this.isShowRegister = true;
        this.isShowLogin = false;
        this.isShowForgot = false;
        this.messObj = null;
        this.accessData = null;
      },
      showAuth() {
        this.isShowLogin = true;
        this.isShowRegister = false;
        this.isShowForgot = false;
        this.messObj = null;
        this.accessData = null;
      },
    },
  };
</script>

<style lang="scss">
@import './auth-block';

.full-page-iframe {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  left: 0;
  z-index: 1488;
  padding-top: 0;
  background: #000000a8;


  &.auth-container {
    z-index: 14890!important;
  }

  iframe {
    border: 0;
  }
}

.marketplace-popup {
  position: relative;

  &__container {
    border-radius: 5px;
    background-color: $popup-background-color;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 88%;
    max-width: 1220px;
    width: calc(100% - 20px);
    @media screen and (max-width: 460px) {
      width: 100%;
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 20px;
    top: 85px;
  }

  &__frame {
    border-radius: 5px;
  }
}

.form-header {
  font-style: normal;
  font-weight: normal;
  color: black !important;

  &__wrap-description {
    text-align: center;
    margin-top: 17px;
  }

  &--forgot {
    @media screen and (max-width: 576px) {
      margin-top: 45px;
    }
  }

  .form-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
  }
  .form-text-title {
    font-size: 15px;
    color: #96A0B6;
  }

  .form-link-title {
    cursor: pointer;
    color: $main-color;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      color: $auth-hover-color;
      border-bottom: 1px solid $main-color;
    }
  }
}
</style>
