import makeRequest from 'src/core/http/storefront.client';

import createCardMutation from './mutations/create-card.mutation';
import addToCartMutation from './mutations/add-to-cart.mutation';
import createAuthCartMutation from './mutations/create-auth-cart.mutation';

import getCartQuery from './queries/get-cart.query';

const createCart = async () => {
  const response = await makeRequest(createCardMutation);

  return response;
};

/**
 * @param {String} token:
 * @param {String} email:
 */
const createAuthCart = async (token, email) => {
  const response = await makeRequest(createAuthCartMutation, {
    input: {
      buyerIdentity: {
        customerAccessToken: token,
        email,
      },
    },
  });

  return response;
};

/**
 * @param {String} cartId:
 * @param {Number} count: //optional
 */
const getCartById = async (cartId, count = 100) => {
  const response = await makeRequest(getCartQuery, {
    id: cartId,
    count,
  });

  return response;
};

/**
 * @param {Object} payload
 * @param {String} payload.cartId
 * @param {String} payload.itemId
 * @param {String} payload.store
 */
const addItemToCart = async (payload) => {
  const response = await makeRequest(addToCartMutation, {
    cartId: payload.cartId,
    lines: [
      {
        merchandiseId: payload.itemId,
        quantity: payload.quantity,
        attributes: [
          ...(payload.store ? [{ key: '__store', value: payload.store }] : []),
          ...(payload.referal ? [{ key: '__ref', value: payload.referal }] : []),
          ...(payload.customPrice ? [{ key: '__customPrice', value: payload.customPrice.toString() }] : []),
        ],
      },
    ],
  });

  return response;
};

/**
 * @param {Object} payload
 * @param {String} payload.cartId
 * @param {Array} payload.items
 */
const addALotOfItemsToCart = async (payload) => {
  const response = await makeRequest(addToCartMutation, {
    cartId: payload.cartId,
    lines: payload.items,
  });

  return response;
};

export default {
  createCart,
  createAuthCart,
  getCartById,
  addItemToCart,
  addALotOfItemsToCart,
};
