<template>
  <form class="search" :style="`max-width: ${extended ? 'auto' : '350px'}`" @submit.prevent="clickBySearch">
    <b-spinner
      v-if="loading"
      :variant="isSearchOnFocus ? 'dark' : 'light'"
      small
    />

    <input
      ref="searchInput"
      type="text"
      class="form-control search-input"
      maxlength="255"
      :class="{'rtl': isActiveFeature('rtl'), 'loading': loading}"
      :placeholder="placeHolderSearchTranslate"
      :value="query"
      @input="dynamicSearch"
      @focus="onFocusHandler"
      @blur="isSearchOnFocus = false"
      @keyup.enter.stop="clickBySearch"
      @click="preventClosing"
    >

    <div v-if="isVisible && isShowItems" class="modal-rounded search-dropdown">
      <!-- SEARCH IN STORE LINE -->
      <div
        v-if="isStoreSearchShow"
        class="search-category__list"
      >
        <h2 class="search-category__title">
          Search in store
        </h2>
        <router-link
          to=""
          class="search-dropdown__first-line"
          @click.native.prevent="searchInCurrentStore()"
        >
          <span>Search {{ query }} in</span>

          <img :src="currentShopData.poster" alt="store_img">

          <span>{{ currentShopData ? currentShopData.origin_title : 'Title' }}</span>
        </router-link>
      </div>
      <!--ITEMS-->
      <div v-for="(group, key) in items" :key="key" class="search-category">
        <div v-if="group.length">
          <h2 v-if="key === 'products'" class="search-category__title">
            Products
          </h2>
          <h2 v-if="key === 'store'" class="search-category__title">
            Store
          </h2>
          <h2 v-if="key === 'categories'" class="search-category__title">
            Move to category
          </h2>
          <h2 v-if="key === 'searchCategories'" class="search-category__title">
            Search in category
          </h2>
          <h2 v-if="key === 'lives'" class="search-category__title">
            Live events
          </h2>
          <h2 v-if="key === 'videos'" class="search-category__title">
            Videos
          </h2>
          <div class="search-category__list">
            <router-link
              v-for="(item, index) in group"
              :key="index"
              :to="item | linkNormalisation"
              event=""
              @click.native.prevent="onClick(item, key, getRealPosition(index, group, items))"
            >
              {{ item | contentLanguage('title') }}
              <i :class="key === 'searchCategories' ? 'far fa-search' : 'fas fa-chevron-right'" />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="isVisible && isShowSearchError && query"
      class="no-search-error"
    >
      <p>
        Sorry, «{{ query }}» did not match any result.
      </p>
    </div>

    <button
      ref="afterClick"
      type="button"
      class="btn"
      @click.stop="clickBySearch()"
    >
      <i
        class="fas fa-search search-icon icon"
        aria-hidden="true"
      />
    </button>
  </form>
</template>

<script>
  import Config from 'src/service/config';
  import { debounce } from 'lodash';
  import { CancelToken, isCancel } from 'axios';
  import { amsClient } from 'src/service/ams';
  import { mapGetters, mapActions } from 'vuex';

  const DEFAULT_ITEMS_CONFIG = {
    products: [],
    store: [],
    categories: [],
    searchCategories: [],
    lives: [],
    videos: [],
  };

  export default {
    name: 'SearchBlock',
    props: {
      extended: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isSearchOnFocus: false,
        loading: false,
        isVisible: false,
        query: '',
        items: Object.assign({}, DEFAULT_ITEMS_CONFIG),
        cancel: null,
        timeout: 1 * 1000,
        minLen: 2,
        currentShopData: {},
        isStoreSearch: false,
        isShowSearchError: false,
      };
    },
    computed: {
      placeHolderSearchTranslate() {
        return this.$t('HEADER.SEARCH') + '...';
      },
      isShowItems() {
        const { products, store, categories, searchCategories, lives, videos } = this.items;
        return products.length
          || store.length
          || categories.length
          || searchCategories.length
          || lives.length
          || videos.length;
      },
      isStoreSearchShow() {
        return this.$route.name === 'ChannelPage';
      },
    },
    created() {
      this.dynamicSearchDebounce = debounce(this.dynamicSearchRequests, this.timeout);
    },
    mounted() {
      this.$bus.$on('currentShopData', ({ title, img, id }) => {

        this.currentShopData = {
          origin_title: title,
          poster: img,
          provider_id: id,
        };
      });
    },
    beforeDestroy() {
      this.$bus.$off('currentShopData');
    },
    methods: {
      ...mapActions({
        setCurrentSearchLink: 'searchInStore/setCurrentSearchLink',
      }),
      searchInCurrentStore() {
        this.isStoreSearch = true;

        this.setCurrentSearchLink('');

        sessionStorage.setItem('currentShopData', JSON.stringify(this.currentShopData));

        this.clickBySearch();

        this.isStoreSearch = false;
      },
      onFocusHandler() {
        this.isVisible = true;
        this.isSearchOnFocus = true;
      },
      dynamicSearch(event) {
        this.query = event.target && event.target.value ? event.target.value : null;

        if (!event.target.value.length) {
          this.isShowSearchError = false;
        }
        ;

        this.dynamicSearchDebounce();
      },
      preventClosing(event) {
        event.stopPropagation();
      },
      close() {
        this.isVisible = false;
      },
      toggleVisibility() {
        this.isVisible = !this.isVisible;
        this.query = null;
        this.resetSearchItems();
      },
      dynamicSearchRequests() {
        this.isShowSearchError = false;
        this.isSearchOnFocus = true;
        let queryBase = this.query ? this.query.trim().substr(0, 256) : null;
        this.resetSearchItems();

        if (queryBase && queryBase.length > this.minLen) {
          const query = encodeURIComponent(
            queryBase
              .replace(/[\@]/g, '&#x40;')
              .replace(/[\|]/g, '&#x7c;'),
          ); // encodeURI(
          const searchUrl = `/dynamic_search/?term=${query}`;

          this.loading = true;

          if (this.cancel) {
            this.cancel('Operation canceled by the user.');
            this.cancel = null;
          }
          /** todo rewrite to fetch signal */
          amsClient.callAms(searchUrl, {
            cache: false,
            config: {
              cancelToken: new CancelToken(c => {
                // An executor function receives a cancel function as a parameter
                this.cancel = c;
              }),
            },
          })
            .then(data => {
              this.formatItems(data.items);
              this.loading = false;
              const timeout = setTimeout(() => {
                this.$refs.searchInput.focus();
                clearTimeout(timeout);
              }, 0);

              this.isShowSearchError = !this.isShowItems;
            })
            .catch(err => {
              if (isCancel(err)) {
                console.error(err);
                return;
              }
              this.resetSearchItems();
            });
        }
      },
      formatItems(items) {
        const entries = Object.entries(items);

        entries.forEach(entry => {
          switch (entry[0]) {
            case 'accountadmin':
              this.items.store = [...entry[1]];
              break;
            case 'cat':
              this.items.categories = [...entry[1]];
              break;
            case 'shopmovie':
              this.items.videos = [...entry[1]];
              break;
            case 'studioproduct':
              this.items.products = [...entry[1]];
              break;
            case 'shopevent':
              this.items.lives = [...entry[1]];
              break;
            default:
              if (entry[1].every(item => item.link.includes('?term'))) {
                this.items.searchCategories = [...entry[1]];
              }
          }
        });
      },
      resetSearchItems() {
        this.items = Object.assign({}, DEFAULT_ITEMS_CONFIG);
        this.loading = false;
      },
      search() {
        this.query = this.query.trim().substr(0, 256);
        this.$router.push({
          path: '/search',
          query: {
            term: this.query,
            providerId: this.isStoreSearch ? this.currentShopData?.provider_id : '',
          },
        });

        this.toggleVisibility();
      },
      async onClick(item, key, position) {


        this.$stats.send('search_chosen', { search_term: this.query, position }, { item });

        if (key === 'videos') {
          const vodWithChat = await amsClient.callAms(item.link, { cache: false });
          const eventData = {
            chat_url: vodWithChat?.head?.event?.chat_url,
            chat_id: vodWithChat?.head?.event?.chat_id,
          };

          delete vodWithChat.head.event;


          // this.$bus.$emit('togglePopupVOD', {
          //   item: { ...vodWithChat.head, ...eventData },
          //   source: 'search',
          // });
          this.toggleVisibility();
          this.$router.push(`/s/${vodWithChat.head.storefront?.slug}/v/${vodWithChat.head.slug || vodWithChat.head.id}`);
          // this.$router.push(`/v/${vodWithChat.head.slug || vodWithChat.head.id}`);

          return;
        }

        if (key === 'searchCategories') {
          const query = item?.link?.split('?term=')?.pop();

          this.$router.push({
            path: '/search',
            query: { term: query, initialState: item.title },
          });
          this.toggleVisibility();

          return;
        }

        if (key === 'products') {
          this.$bus.$emit('toggleProductPopup', { item });
          this.toggleVisibility();
          return;
        }

        if (key === 'lives' && !item?.on_air) {
          const { info } = await amsClient.callAms(item?.link, { cache: false });
          this.$bus.$emit('ToggleScheduleEventDialog', info);
          this.toggleVisibility();
          return;
        }

        Config.set('temp.context', 'search');
        // this.$stats.send('search', {
        //   search_keyword: this.query,
        //   result_of_click: 'dynamic_search',
        // });
        this.toggleVisibility();
        this.$router.push(this.$options.filters.linkNormalisation(item));
      },
      setFocus(el) {
        if (el) {
          el.focus();
        }
      },
      clearFocus() {
        this.$refs.afterClick.blur();
      },
      clickBySearch() {
        this.$emit('icon-click');
        if (this.isVisible && this.query && this.query.trim().length > 0) {
          this.$emit('closeSearchProductPopup');
          this.search();
        } else {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            force: false,
            formId: 'fromPlayer',
          });
          this.toggleVisibility();
        }
      },
      getRealPosition(index, group, items) {
        let position = index + 1;
        for (const workGroup in items) {
          if (items[workGroup] === group) {
            break;
          } else {
            position += items[workGroup].length;
          }
        }
        return position;
      },
    },
  };

</script>

<style lang="scss" scoped="true">
  @import './search';
</style>

