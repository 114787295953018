import { doFetch } from 'vimmi-web-utils/esm/doFetch';
import { amsClient } from '@/service/ams';
import { normDomain } from 'vimmi-web-utils/cjs/normDomain';
import Config from '@/service/config';

export default {
  async getVODRecommendations(options = {}) {
    const page = options.page || 1;
    const pageSize = options.pageSize || 20;
    const providerId = options.provider_id;
    const targetId = options.target_id;
    const query = {
      page,
      page_size: pageSize,
    };
    if (providerId) {
      query.provider_id = providerId;
    }
    if (targetId) {
      query.target_id = targetId;
    }
    const response = await doFetch(`https://${normDomain(Config.get('recommendation'))}/recommendation/vod`, {
      query,
      headers: { uid: amsClient.get('user.id') },
    });

    return response.result.map((item) => {
      item.id = item.event_id;
      item.recommendation_ = true;
      item.slug = item.movie_slug;
      return item;
    });
  },
  async getLIVERecommendations(options = {}) {
    const page = options.page || 1;
    const pageSize = options.pageSize || 20;
    const providerId = options.provider_id;
    const query = {
      page,
      page_size: pageSize,
    };
    if (providerId) {
      query.provider_id = providerId;
    }
    const response = await doFetch(`https://${normDomain(Config.get('recommendation'))}/recommendation/live`, {
      query,
      headers: { uid: amsClient.get('user.id') },
    });

    return response.result.map((item) => {
      item.id = item.event_id;
      item.slug = item.event_slug;
      item.is_live = true;
      item.recommendation_ = true;
      return item;
    });
  },
  async getItem(itemId) {
    const response = await amsClient.callAms(`/shoprzapi/event_info/${itemId}/`, { cache: false });
    // const response = await amsClient.callAms(`/get_item/${itemId}/`, { cache: false });
    return response.head;
  },
  async getItemBySlug(slug) {
    const response = await amsClient.callAms(`/shoprzapi/event_info/${slug}/?by=slug`, { cache: false });
    return response.head;
  },
};
