<template>
  <router-link :to="`/product/${product.slug}`" class="product-item">
    <div class="product-item__image-container">
      <img :src="product.poster" alt="" class="product-item__image">

      <div class="product-item__like" @click.prevent="removeProductFromWishlist">
        <img :src="require('@/assets/icons/added-to-wishlist-icon.svg')" alt="" class="product-item__like-image">
      </div>
    </div>

    <div class="product-item__title" :title="product.title">
      {{ product.title }}
    </div>

    <div class="product-item__price">
      ${{ product.price }}
    </div>
  </router-link>
</template>

<script>
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'ProductItemComponent',
    props: {
      product: {
        type: Object,
        default: null,
      },
    },
    methods: {
      async removeProductFromWishlist() {
        await WishlistService.removeFromWishlist(this.product.id);

        this.$stats.send('remove_wishlist', {}, { item: this.product });
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-item {
    color: #343434;

    &__image-container {
      margin-bottom: 10px;
      height: 220px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__like {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 20px;
      background: #fff;
      cursor: pointer;
    }

    &__title {
      margin-bottom: 10px;
      display: -webkit-box;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #343434;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        color: #343434;
      }
    }

    &__price {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }

    @media(max-width: 767px) {
      &__image-container {
        height: 160px;
      }
    }
  }
</style>