<template>
  <div class="sp-summary" :class="{ 'sp-desktop': !isMobile }">
    <order-details-component :cost="cost" class="sp-summary--details" />

    <div class="sp-summary--discount">
      <span v-if="!hideDiscount" class="sp-separator" />

      <div v-if="!hideDiscount" class="sp-summary--discount-wrapper">
        <form-input-component
          v-model="discount"
          placeholder="Gift card or Discount code"
          class="sp-summary--discount-input"
        />

        <button class="sp-summary--discount-submit">Apply</button>
      </div>

      <span class="sp-separator" />
    </div>

    <product-list-component
      :products="products"
      :hide-edit-cart="hideEditCart"
      :is-popup-checkout="isPopupCheckout"
      class="sp-summary--products"
      @edit:cart="handleEditCart"
    />
  </div>
</template>

<script>
  import OrderDetailsComponent from './order-details.component';
  import ProductListComponent from './product-list.component';
  import FormInputComponent from 'src/shared/components/form-components/form-input.component';

  export default {
    name: 'OrderSummary',

    components: {
      ProductListComponent,
      OrderDetailsComponent,
      FormInputComponent,
    },

    props: {
      isMobile: {
        type: Boolean,
        default: false,
      },

      cost: {
        type: Object,
        default: () => ({}),
      },

      products: {
        type: Array,
        default: () => [],
        require: true,
      },

      hideEditCart: {
        type: Boolean,
        default: false,
      },

      hideDiscount: {
        type: Boolean,
        default: false,
      },

      isPopupCheckout: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        discount: '',
      };
    },

    methods: {
      handleEditCart() {
        this.$emit('edit:cart');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-summary {
    display: flex;
    flex-direction: column;

    &--discount {
      margin: 24px 0px;

      &-wrapper {
        display: flex;
        align-items: center;
        margin: 16px 0px;
      }

      &-input {
        flex: 1;
        margin-right: 8px;
      }

      &-submit {
        width: 96px;
        height: 44px;
        outline: none;
        border: 1px solid #f0f2f7;
        border-radius: 5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #343434;
      }
    }

    .sp-separator {
      height: 1px;
      background-color: #f0f2f7;
      width: 100%;
      display: block;
    }

    &.sp-desktop {
      border: 1px solid #f0f2f7;
      border-radius: 10px;
      padding: 32px 24px 24px;

      .sp-summary--details {
        order: 2;
      }

      .sp-summary--products {
        order: 0;
      }

      .sp-summary--discount {
        order: 1;
      }
    }
  }
</style>
