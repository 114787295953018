import Vue from 'vue';
import TrimText from './trimText';
import BgImageJs from './backgroundImageJS';
import HoverJS from './hoverJS';
import OutsideClick from './OutsideClick';
import DragScrollFunction from './drag-scroll-fn';
import ResizeFunction from './resize';

export const hoverJs = Vue.directive('hoverJs', HoverJS);
export const trimmText = Vue.directive('ellipsisText', TrimText);
export const bgImageJs = Vue.directive('bgImageJs', BgImageJs);
export const outsideClick = Vue.directive('outsideClick', OutsideClick);
export const dragScrollFn = Vue.directive('dragScrollFn', DragScrollFunction);
export const resizeFn = Vue.directive('resizeElement', ResizeFunction);

export default {
  hoverJs,
  ellipsisText: trimmText,
  bgImageJs,
  resizeElement: resizeFn,
  outsideClick: OutsideClick,
  dragScrollFn: DragScrollFunction,
};
