<template>
  <div
    ref="liveBlock"
    class="channel-event-video channel-event-video--wrapper"
    :class="{ 'position-fixed float-event': isFloatingLive }"
  >
    <media-player
      v-if="actualEvent"
      ref="liveContainer"
      :area="actualEvent"
      :item="area"
      :aspect-ratio="isMobile ? '9x16' : '16x9'"
      :shop-layouts="area.creator"
      :is-one="true"
      :is-live="livePlayer"
      :first-event-component="firstEventComponent"
      class="position-relative"
      :product="product"
      :product-id="productID"
      :product-old-id="productOldID"
      @closeProductPopup="onCloseProductPopup"
    />
  </div>
</template>

<script>
  import { PM, PMLive, SHOP_EVENTS } from 'vimmi-web-utils/cjs/postMessage';
  import { OPEN_PRODUCT_POPUP__LIVE } from '../player/video/events.const';
  import normalizeImg from '@/filters/normalizeImg';
  import MediaPlayer from '../player/media/media-player.vue';
  import device from '@/service/device-service';
  import ResponsiveImage from '../reponsive-image';
  import SharedProductAPI from '@/shared/api/product/product.api';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'EventStreem',
    components: {
      MediaPlayer,
      ResponsiveImage,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      firstEventComponent: {
        type: Boolean,
        default: false,
      },
      livePlayer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        product: null,
        productID: null,
        productOldID: null,
        elementCoords: null,
        currentCoords: null,
        liveBlock: null,
        eventTrigger: false,
      };
    },
    computed: {
      miniPlayerFromProduct() {
        return this.$store.getters['popup/miniPlayerFromProduct'];
      },
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isLiveFloating() {
        return this.$store.getters['popup/isLiveFloating'];
      },
      isVodFloating() {
        return this.$store.getters['popup/isVodFloating'];
      },
      isFirstEventComponentFloating() {
        return this.$store.getters['player/isFirstEventComponentFloating'];
      },

      isSecondEventComponentFloating() {
        return this.$store.getters['player/isSecondEventComponentFloating'];
      },

      isFloatingLive() {
        if (this.firstEventComponent) {
          return (
            this.isMiniatureIframe &&
            this.isLiveFloating &&
            this.isFirstEventComponentFloating
          );
        }
        return (
          this.isMiniatureIframe &&
          this.isLiveFloating &&
          this.isSecondEventComponentFloating
        );
      },
      actualEvent() {
        return this.area?.actualEvent;
      },
      image() {
        if (!this.actualEvent) {
          return null;
        }

        const imgPath = this.isMobile
          ? this.actualEvent.poster
          : this.actualEvent.thumbnail;

        return this.normalizeImg(imgPath, 'thumb', 'HD', 'SD');
      },
      isMobile() {
        return device.isAnyMobile();
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      currenSecondLive() {
        return this.$store.getters['dataAreasLive/currenSecondLive'];
      },
      playerCoords() {
        return this.$store.getters['player/playerCoords'];
      },
      pageForCurrentLive() {
        return this.$store.getters['player/pageForCurrentLive'];
      },
      pageForSecondLive() {
        return this.$store.getters['player/pageForSecondLive'];
      },
      isRestore() {
        return this.$store.getters['player/isRestore']
      },
    },
    watch: {
      eventTrigger(value) {
        if (value) {
          PMLive.inst().on(SHOP_EVENTS.CLOSE_FLOATING, this.handleCloseFloating);
          this.eventTrigger = false
        }
      },
    },
    created() {
      this.$bus.$off('VideoPlayerPlayAction', this.eventPlayerMethods);
      this.destroyPMEvents();
    },
    mounted() {
      this.$bus.$on('VideoPlayerPlay', (currentItem) => {
        if (currentItem.id !== this.actualEvent.id) {
          this.$bus.$emit('VideoPlayerPlayAction', 'MUTE_OFF');
        }
      });
      this.liveBlock = this.$refs.liveBlock;
      if (this.playerCoords) {
        this.currentCoords = { ...this.playerCoords };
      }
      this.initPMEvents();
    },
    // beforeDestroy() {
    //   this.destroyPMEvents();
    // },
    methods: {
      normalizeImg,
      initPMEvents() {
        PMLive.inst().on('CLOSE_PLAYER', this.closeLivePlayer);
        PMLive.inst().on('START_DRAG', this.handleStartDrag);
        PMLive.inst().on('END_DRAG', this.handleEndDrag);
        PMLive.inst().on('SHARE', this.handleShare);
        PMLive.inst().on(SHOP_EVENTS.CLOSE_FLOATING, this.handleCloseFloating);
        PM.inst().on(OPEN_PRODUCT_POPUP__LIVE, this.handleOpenProductPopup);
        PM.inst().on('PRODUCT_CLICK', this.handleProductClick);
      },
      destroyPMEvents() {
        PMLive.inst().off('CLOSE_PLAYER', this.closeLivePlayer);
        PMLive.inst().off('START_DRAG', this.handleStartDrag);
        PMLive.inst().off('END_DRAG', this.handleEndDrag);
        PMLive.inst().off('SHARE', this.handleShare);
        PMLive.inst().off(SHOP_EVENTS.CLOSE_FLOATING, this.handleCloseFloating);
        PM.inst().off(OPEN_PRODUCT_POPUP__LIVE, this.handleOpenProductPopup);
        PM.inst().off('PRODUCT_CLICK', this.handleProductClick);
        // PM.inst().off(SHOP_EVENTS.OPEN_PRODUCT_IFRAME);
      },
      async handleProductClick(product) {
        const { head } = await amsClient.callAms(`/shoprzapi/product/${product.id}/?by=id`);

        this.productOldID = product.id;

        await this.getProductData(head.remote_id);
      },
      async getProductData(id) {
        const response = await SharedProductAPI.getProductById(id);

        this.productID = id;
        this.product = response.product;
      },
      onCloseProductPopup() {
        this.product = null;
      },
      async closeLivePlayer() {
        if (this.pageForCurrentLive) {
          this.$store.commit('dataAreasLive/setCurrenSecondLive', null);
          this.handleCloseFloatPlayer();
          return;
        } else if (this.pageForSecondLive) {
          this.$store.commit('dataAreasLive/setCurrentLive', null);
          this.handleCloseFloatPlayer();
          return;
        }
        this.$bus.$emit('clearLiveFloating');
        await this.$bus.$emit('setCurrentPage', null);
        this.$store.commit('popup/setScreenType', 'full');
        this.$store.commit('popup/setFloatingMode', null);
        await this.$bus.$emit('setSound', 'SET_SOUND');
      },
      onMoveElement({ x, y }) {
        const posX = x - this.currentCoords.x + this.elementCoords.x;
        const posY = y - this.currentCoords.y + this.elementCoords.y;
        if (!this.liveBlock.classList.contains('float-event')) return;
        this.liveBlock.setAttribute(
          'style',
          `top: ${posY}px !important; left: ${posX}px !important`,
        );
        this.$store.commit('player/SET_PLAYER_COORDS', { x: posX, y: posY });
      },
      setProductPopup(data) {
        data.item.link = data.link;
        data.item.ref = data.ref;
        this.$bus.$emit('toggleProductPopup', { item: data.item });
      },
      handleCloseFloatPlayer() {
        this.$bus.$emit('closeProductPopup');
        const timeout = setTimeout(() => {
          this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
          this.$store.commit('popup/setScreenType', 'full');
          this.$store.commit('popup/setFloatingMode', null);
          clearTimeout(timeout);
        }, 0);
      },
      handleStartDrag(data) {
        if (!this.isMiniatureIframe) return;
        if (!this.currentCoords) {
          this.currentCoords = data.position;
          this.elementCoords = {
            x: this.liveBlock.getBoundingClientRect().x,
            y: this.liveBlock.getBoundingClientRect().y,
          };
        }
        this.onMoveElement(data.position, data.event);
      },
      handleEndDrag() {
        if (!this.isMiniatureIframe) return;
        this.elementCoords = null;
        this.currentCoords = null;
      },
      handleShare(data) {
        const link = data.link ? data.link : `/s/${data.creatorSlug}`;
        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${link}`,
          currentStoreName: data.creator,
          item: data,
        });
      },
      handleOpenProductPopup(data = {}) {
        data.item && this.setProductPopup(data);
      },
      handleCloseFloating(data) {
        if (!data.ref.isStream) return;
        PMLive.inst().off(SHOP_EVENTS.CLOSE_FLOATING, this.handleCloseFloating);
        const firstEvent =
          !this.pageForCurrentLive &&
          data?.creator?.slug &&
          this.currentLive?.creator?.id === data.creator.id;
        const secondEvent =
          !this.pageForSecondLive &&
          data?.creator?.slug &&
          this.currenSecondLive?.creator?.id === data.creator.id;

        if (firstEvent) {
          this.$store.commit('player/SET_RESTORE', true);
          this.$router.push(`/s/${data.creator.slug}`);

        }
        if (secondEvent) {
          this.$store.commit('player/SET_RESTORE', true);
          this.$router.push(`/s/${data.creator.slug}`);
        }
        if (!this.isRestore) {
          this.onCloseFloating(data);
        }
        this.eventTrigger = true;
      },
      onCloseFloating(data) {
        this.$emit('closePopupVOD');
        this.$store.commit('player/SET_ROUTER_TO_LIVE', true);
        this.$store.commit('dataAreasLive/setPlayingLive', true);
        if (this.miniPlayerFromProduct) {
          this.$bus.$emit('closeProductPopup');
        }
        this.$bus.$emit('handleCloseFloatPlayer', data.itemConfig.item_id);
        this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
      },
    },
  };
</script>
