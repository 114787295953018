<template>
  <button
    :class="{
      'sp-button': true,
      [`sp-button__${size}`]: true,
      'sp-button__disabled': disabled,
    }"
    :disabled="disabled"
    :style="{ height, 'max-width': width }"
    v-on="$listeners"
  >
    {{ label }}
  </button>
</template>

<script>
  import Loader from '@/components/loader/loader.vue';

  export default {
    name: 'ButtonComponent',
    components: { Loader },

    props: {
      label: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      height: {
        type: String,
        default: 'auto',
      },
      width: {
        type: String,
        default: 'auto',
      },
      size: {
        type: String,
        default: 'default',
        validator(val) {
          return ['default', 'large'].includes(val);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-button {
    background-color: #21a66e;
    border-radius: 5px;
    color: white;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    outline: none;
    // TODO: it is random padding actually
    padding: 5px 0;

    &__large {
      padding: 17px 0;
    }

    &__disabled {
      background-color: rgba(33, 166, 110, 0.5);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
</style>
