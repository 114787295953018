<template>
  <div class="cart">
    <!-- <button class="btn cart-btn" @click="openCart">
      <span>Cart</span>
      <img :src="require('src/assets/icons/new-cart.svg')" alt="Cart">
      <div v-if="cartItemsCount" class="btn-pin">
        {{ cartItemsCount }}
      </div>
    </button> -->

    <router-link class="btn cart-btn" :to="{ name: 'cart' }">
      <!--      <span>Cart</span>-->
      <img :src="require(`src/assets/icons/new-mobile-cart${isDark ? '-dark' : ''}.svg`)" alt="Cart">
      <div v-if="cartItemsCount" class="btn-pin">
        {{ cartItemsCount }}
      </div>
    </router-link>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import CheckoutService from '@/store/checkout';

  export default {
    name: 'Cart',
    props: {
      isDark: {
        type: Boolean,
        default: true,
      },
      isTablet: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      cartItemsCount() {
        return this.$store.getters['checkout/getCartItemsCount']
      },
    },
    methods: {
      async openCart() {
        const cartUrl = amsClient.get('conf.portal.marketplace.shopify.cart');

        this.$bus.$emit('toggleShopCart', {
          item: {
            link: `${cartUrl}?c_id=${CheckoutService.getAvailableCheckoutId()}`,
            title: 'My Cart',
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './Cart';
</style>
