<template>
  <div class="sp-address-from">
    <slot name="prepend" />

    <slot>
      <div class="sp-address-from--field sp-country">
        <custom-select-with-search
          :options="countries"
          :selected-value="address.country"
          placeholder="Country"
          height="50px"
          @handleSelectOption="handleSelectCountry"
        />
      </div>

      <div class="sp-address-from--field sp-street">
        <form-auto-complete-component
          id="address"
          v-model="address.address1"
          :data="addressSuggestions"
          placeholder="Address"
          serialized-field="formatted"
          @typing="handleFetchAddresses"
          @select="handleSelectAddress"
        >
          <template #default="{ option }">
            <div>
              <span class="sp-size-5 mr-1">
                {{ option.address_line1 }}
              </span>
              <span class="create-edit-address-form__autocomplete__address2">
                {{ option.address_line2 }}
              </span>
            </div>
          </template>
        </form-auto-complete-component>
      </div>

      <div class="sp-address-from--field-group">
        <div class="sp-address-from--field sp-city">
          <form-auto-complete-component
            id="city"
            v-model="address.city"
            :data="citySuggestions"
            placeholder="City"
            @typing="handleFetchCities"
            @input="onFieldChange('city')"
            @select="handleSelectCity"
          />
        </div>

        <div class="sp-address-from--field sp-zip">
          <form-input-component
            :value="address.zip"
            type="text"
            placeholder="Zip"
            maxlength="100"
            height="50px"
            :mask="'#####'"
            @input="handleInputZipCode"
          />
        </div>
      </div>

      <div class="sp-address-from--field sp-state">
        <form-input-component
          v-model="address.state"
          type="text"
          placeholder="State"
          maxlength="100"
          height="50px"
        />
      </div>
    </slot>

    <slot name="append" />
  </div>
</template>

<script>
  import GeoapifyApi from '@/shared/api/geoapify.api';

  import FormInputComponent from '@/shared/components/form-components/form-input.component';
  import CustomSelectWithSearch from '@/shared/components/form-components/custom-select-with-search';
  import FormAutoCompleteComponent from '@/shared/components/form-components/form-autocomplete.component';

  import Loader from 'src/components/loader/loader';

  export default {
    name: 'AddressAutocompleteForm',

    components: {
      FormInputComponent,
      CustomSelectWithSearch,
      FormAutoCompleteComponent,

      Loader,
    },

    props: {
      defaultAddress: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        address: {
          country: 'United States',
          countryCode: 'us',
          city: '',
          zip: '',
          address1: '',
          state: '',
        },

        countries: [
          { label: 'United States', value: 'United States', code: 'us' },
        ],

        citySuggestions: [],
        addressSuggestions: [],

        isSelectedAddress: false,
      };
    },

    methods: {
      async fetchCities(form) {
        if (!form.city) {
          return;
        }

        try {
          const { results } = await GeoapifyApi.searchByCity(
            form.city,
            form.countryCode,
          );

          this.citySuggestions = [...new Set(results.map((a) => a.city))];
        } catch (err) {
          console.log('err', err);
          // TODO: show error
        }
      },
      async fetchAddresses(form) {
        if (!form.address1) {
          return;
        }

        try {
          const { results } = await GeoapifyApi.searchAddress({
            text: form.address1,
            countryCode: form.countryCode,
          });

          this.addressSuggestions = results;
        } catch (err) {
          console.log('err', err);
          // TODO: show error
        }
      },

      handleFetchCities() {
        return this.fetchCities(this.address);
      },

      handleFetchAddresses() {
        this.isSelectedAddress = false;

        this.$emit('change:selected-status', this.isSelectedAddress);

        return this.fetchAddresses(this.address);
      },

      async handleInputZipCode(value) {
        this.address.zip = value;

        this.isSelectedAddress = false;

        this.$emit('change:selected-status', this.isSelectedAddress);

        if (value.length < 5) {
          return;
        }

        try {
          const { results } = await GeoapifyApi.searchByPostCode(
            value,
            this.address.countryCode,
          );

          const address = results[0];

          this.address.city = address?.city || '';

          this.address.address1 =
            address?.formatted ||
            address?.address_line2 ||
            address?.address_line1 ||
            '';

          this.address.state = address?.state || '';

          this.isSelectedAddress = true;

          this.$emit('input', this.address, this.isSelectedAddress);

          this.onFieldChange('zip');
        } catch (error) {
          console.log('err', error);
        }
      },

      handleSelectCountry(country, item) {
        this.address.country = country;
        this.address.countryCode = item.code;

        this.$emit('input', this.address, this.isSelectedAddress);
      },

      handleSelectCity(city) {
        this.address.city = city;

        this.$emit('input', this.address, this.isSelectedAddress);
      },

      handleSelectAddress(address) {
        this.isSelectedAddress = true;

        this.address = {
          ...this.address,
          zip: address.postcode,
          city: address.city,
          state: address.state,
        };

        this.$emit('input', this.address, this.isSelectedAddress);
      },

      onFieldChange(field) {
        this.$emit('fieldChanged', { field });
      },
    },
  };
</script>

<style lang="scss">
  .sp-address-from {
    .form-autocomplete {
      input {
        height: 50px !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .sp-address-from {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    position: relative;

    &--field-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
    }
  }
</style>
