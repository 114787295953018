export default {
  poc: [
    {
      name: '3D Printers',
      path: '/s/poc-3d-printers',
    },

    {
      name: 'Materials',
      path: '/s/poc-materials',
    },

    {
      name: 'Spare Parts',
      path: '/s/poc-spare-parts',
    },

    {
      name: 'Software',
      path: '/s/software-1',
    },

    {
      name: 'Courses',
      path: '/s/courses-1',
    },
  ],

  Imtech: [
    {
      name: '3D Printers',
      path: '/s/poc-3d-printers',
    },

    {
      name: 'Materials',
      path: '/s/poc-materials',
    },

    {
      name: 'Spare Parts',
      path: '/s/poc-spare-parts',
    },

    {
      name: 'Software',
      path: '/s/software-1',
    },

    {
      name: 'Courses',
      path: '/s/courses-1',
    },
  ],

  ZYMOX: [
    {
      name: 'Cat Supplies',
      path: '/s/zymox-makeup',
    },

    {
      name: 'Dog Supplies',
      path: '/s/zymox-skincare',
    },

    {
      name: 'Other Animals',
      path: '/s/zymox-to-be-deleted-3',
    },

    {
      name: 'Equine Care',
      path: '/s/zymox-beauty-gadgets-accessories',
    },

    {
      name: 'Education Center',
      path: '/s/education-center-1',
    },
  ],
};
