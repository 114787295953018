<template>
  <div v-if="activeItem"
       ref="floatPlayer"
       class="float-player-container float-player"
       :class="{
         'float-player--vertical': isVertical,
         'float-player--horizontal': !isVertical,
         'float-player--drags': isDrag,
         'float-player--mobile': isAnyMobile
       }"
       :style="{ 'top': pagePosition.y + 'px', 'left': pagePosition.x + 'px' }"
       @mousedown="onMouseDown"
       @touchstart="onMouseDown"
  >
    <player-component
      class="w-100 h-100"
      ref="player"
      :autoplay="!playbackState.paused"
      :focused="true"
      :force-autoplay-muted="false"
      :controls="false"
      :muted="initialMuted"
      :item="activeItem"
      :live="activeItem.is_live"
      :loop="!activeItem.is_live"
      :start-position="activeItem.is_live ? null : startPosition"
      :vertical="activeItem.media_is_vertical || activeItem.orientation === 'vertical'"
      :vjs-options="vjsOptions"
      :get-playback-url="getPlaybackUrl"
      @player:play="onPlay($event)"
      @player:pause="onPause($event)"
      @player:duration-change="onDurationChange($event)"
      @player:volume-change="onVolumeChange($event)"
      @player:time-update="onTimeUpdate($event)"
      @player:stats="playerStats"
      @player:bitrate-changed="onChangedBitrate"
      @player:loaded-data="onLoadedData($event)"
      @player:loaded-metadata="onLoadedMetaData($event)"
    >
      <template #layouts="{ methods, state }">
        <div
          class="float-player--wrapper"
        >
          <div class="float-player--controls header-bar ">
            <div class="left-controls">
              <div class="d-inline-block">
                <button
                  class="header-bar--exit-btn header-bar--btn"
                  type="button"
                  @click="back()"
                >
                  <img :src="require('@/assets/icons/float-player/icon-pip.svg')" alt="">
                </button>
              </div>
              <div class="d-inline-block" style="margin-right: auto;">
                <button
                  class="header-bar--volume-btn header-bar--btn"
                  type="button"
                  @click="toggleMute()"
                >
                  <img
                    v-if="playbackState.muted"
                    :src="require('@/assets/icons/float-player/icon-mute.svg')"
                    alt=""
                  >
                  <img
                    v-else
                    :src="require('@/assets/icons/float-player/icon-unmute.svg')" alt=""
                  >
                </button>
              </div>
            </div>
            <div class="right-controls">
              <div class="d-inline-block">
                <button
                  class="header-bar--exit-btn header-bar--btn"
                  type="button"
                  @click="closeFloatPlayer()"
                >
                  <img :src="require('@/assets/icons/float-player/close.svg')" alt="">
                </button>
              </div>
            </div>
          </div>
          <play-button
            v-show="state.paused"
            @btn-click="togglePlayback"
          />
          <div class="float-player--click-area" @click="wrapperClick($event)" />
        </div>
      </template>
    </player-component>
  </div>
</template>

<script>
  import { PlayerComponent, PlayButton, PLAYER_EVENTS } from 'shop-player-v2/dist/shop-player-v2';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import device from '@/service/device-service';
  import { disablePageScroll, enablePageScroll, isPageScrollDisabled } from '@/shared/helpers/pageScrollToggler';
  import ShopPlayerService from '@/shared/services/shop-player.service';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'FloatPlayerContainer',
    components: { PlayButton, PlayerComponent },

    data() {
      return {
        activeItem: null,
        startPosition: 0,
        isAnyMobile: device.isAnyMobile(),
        disabledScroll: false,
        isDrag: false,
        initialMuted: false,
        isMouseDownOnPlayer: false,
        dragPosition: this.$store.state.floatPlayer.pagePosition,
        dragOffset: { x: 0, y: 0 },
        verticalByStream: false,
        vjsOptions: {
          html5: {
            vhs: {
              enableLowInitialPlaylist: true,
              limitRenditionByPlayerDimensions: true,
              useDevicePixelRatio: true,
              useNetworkInformationApi: true,
              fastQualityChange: true,
            },
          },
        },
      };
    },
    computed: {
      ...mapState('floatPlayer', {
        item: 'floatItem',
        playbackState: 'playbackState',
        options: 'options',
      }),
      ...mapGetters({
        getPlayTemplate: 'chachedPreviewMedia/getPlayTemplate',
      }),
      pagePosition() {
        return this.isDrag ? this.dragPosition : this.$store.state.floatPlayer.pagePosition;
      },
      isVertical() {
        return this.verticalByStream || this.item.media_is_vertical || this.item.orientation === 'vertical';
      },
    },
    watch: {
      item: function(newItem) {
        if (newItem) {
          this.verticalByStream = false;
          this.startPosition = this.playbackState?.position;
          this.initialMuted = this.playbackState?.muted;
          const fullItem = { ...newItem };

          /** todo: possible will need to wait play templates loading end before */
          if (!fullItem.playback_url) {
            const playbackUrl = ShopPlayerService.generatePlaybackUrl(fullItem, this.getPlayTemplate);
            if (playbackUrl) {
              fullItem.playback_url = playbackUrl;
            }
          }
          this.activeItem = fullItem;
        } else {
          this.activeItem = null;
        }
      },
    },
    created() {
      window.addEventListener('orientationchange', this.onOrientationChange, true);
      document.addEventListener('mouseup', this.onDocumentMouseUp, true);
      document.addEventListener('touchend', this.onDocumentMouseUp, true);
      document.addEventListener('mousemove', this.onDocumentMouseMove, true);
      document.addEventListener('touchmove', this.onDocumentMouseMove, true);
    },
    mounted() {

    },
    beforeDestroy() {
      document.removeEventListener('orientationchange', this.onOrientationChange, true);
      document.removeEventListener('mouseup', this.onDocumentMouseUp, true);
      document.removeEventListener('touchend', this.onDocumentMouseUp, true);
      document.removeEventListener('mousemove', this.onDocumentMouseMove, true);
      document.removeEventListener('touchmove', this.onDocumentMouseMove, true);
    },
    methods: {
      ...mapActions({
        setItem: 'floatPlayer/setFloatItem',
        setPlaybackState: 'floatPlayer/setPlaybackState',
        setOptions: 'floatPlayer/setFloatOptions',
        setPosition: 'floatPlayer/setFloatPagePosition',
      }),
      onMouseDown($event) {
        this.isMouseDownOnPlayer = true;
        let x;
        let y;
        if ($event.touches && $event.touches.length) {
          x = $event.touches[0].clientX;
          y = $event.touches[0].clientY;
        } else {
          x = $event.clientX;
          y = $event.clientY;
        }
        this.dragOffset = {
          x: x - this.$refs.floatPlayer.offsetLeft,
          y: y - this.$refs.floatPlayer.offsetTop,
        };
      },
      onOrientationChange($event) {
        if (!this.pagePosition.y) {
          return;
        }
        setTimeout(() => {
          if (this.pagePosition.y > window.innerHeight) {
            this.setPosition({
              x: this.pagePosition.x || 20,
              y: window.innerHeight - this.$refs.floatPlayer.clientHeight - 20,
            });
          } else if (this.pagePosition.x > window.innerWidth) {
            this.setPosition({
              y: this.pagePosition.y || window.innerHeight - this.$refs.floatPlayer.clientHeight - 20,
              x: window.innerWidth - this.$refs.floatPlayer.clientWidth - 20,
            });
          }
        }, 600);
      },
      onDocumentMouseUp($event) {
        if (this.disabledScroll) {
          this.disabledScroll = false;
          enablePageScroll('float');
        }
        this.isMouseDownOnPlayer = false;
        this.setPosition(this.dragPosition);
        setTimeout(() => {
          this.isDrag = false;
        }, 50);
      },
      onDocumentMouseMove($event) {
        $event.preventDefault();
        if (!this.isMouseDownOnPlayer) {
          return;
        }
        if (!isPageScrollDisabled()) {
          this.disabledScroll = true;
          disablePageScroll('float');
        }
        this.isDrag = true;
        let x;
        let y;
        if ($event.touches && $event.touches.length) {
          x = $event.touches[0].clientX;
          y = $event.touches[0].clientY;
        } else {
          x = $event.clientX;
          y = $event.clientY;
        }

        this.pagePosition.x = x - this.dragOffset.x;
        this.pagePosition.y = y - this.dragOffset.y;

        this.$refs.floatPlayer.style.left = this.pagePosition.x + 'px';
        this.$refs.floatPlayer.style.top = this.pagePosition.y + 'px';
      },
      onPlay(playbackState) {
        this.setPlaybackState(playbackState);
      },
      onPause(playbackState) {
        this.setPlaybackState(playbackState);
      },
      onDurationChange(playbackState) {
        this.setPlaybackState(playbackState);
      },
      onVolumeChange(playbackState) {
        this.setPlaybackState(playbackState);
      },
      onTimeUpdate(playbackState) {
        this.setPlaybackState(playbackState);
      },
      wrapperClick($event) {
        if (this.isDrag) {
          return;
        }
        this.togglePlayback();
      },
      togglePlayback() {
        if (!this.playbackState.paused) {
          this.$refs.player.pause(true);
        } else {
          if (this.item.is_live) {
            this.$refs.player.seekTo(100000000);
          }
          this.$refs.player.play(true);
        }
      },
      toggleMute() {
        if (this.playbackState.muted) {
          this.$refs.player.unmute(true);
        } else {
          this.$refs.player.mute(true);
        }
      },
      closeFloatPlayer() {
        const itemId = this.item.id;
        const item = { ...this.item };
        const isLive = this.item.is_live;
        const options = { ...this.options };
        this.activeItem = null;
        this.setItem(null);
        this.setOptions({ ...options, closed: true, itemId, isLive, item, ignoreScroll: false });
        this.$emit('close', {
          item: item,
          playbackState: { ...this.playbackState },
          options: options,
        });
      },
      back() {
        const itemId = this.item.id;
        const item = { ...this.item };
        const isLive = this.item.is_live;
        const options = { ...this.options };
        this.activeItem = null;
        this.setItem(null);
        this.setOptions({ ...options, returned: true, itemId, isLive, item, ignoreScroll: false });
        this.$emit('back', {
          item: item,
          playbackState: { ...this.playbackState },
          options: options,
        });
      },
      onMoveElement({ x, y }) {
        const posX = x - this.pagePosition.x + this.elementCoords.x;
        const posY = y - this.pagePosition.y + this.elementCoords.y;
        // this.floatingPosition = { x: posX, y: posY };
        this.$store.commit('player/SET_PLAYER_COORDS', { x: posX, y: posY });
      },
      handleStartDrag(data) {
        this.onMoveElement(data.position, data.event);
      },
      handleEndDrag() {
        if (!this.isMiniatureIframe) return;

        this.elementCoords = null;
        this.currentCoords = null;
      },

      onChangedBitrate({ prev, active }) {
        if (active) {
          this.resizeByAspectRatio(active.width / active.height);
        }
      },

      onLoadedData() {
      },
      onLoadedMetaData() {
      },

      resizeByAspectRatio(aspectRatio) {
        this.verticalByStream = aspectRatio < 1;
        this.$nextTick(() => {
          if (aspectRatio < 1) {
            this.$refs.floatPlayer.style.width = `${this.$refs.floatPlayer.getBoundingClientRect().height * aspectRatio}px`;
          } else {
            this.$refs.floatPlayer.style.height = `${this.$refs.floatPlayer.getBoundingClientRect().width / aspectRatio}px`;
          }
        });
      },
      async getPlaybackUrl(item) {
        const { media } = await amsClient.callAms(`/play/${item.id}`, { cache: true });
        return media[0].link;
      },

      playerStats({ event, item, data }) {
        const playerEvent = event.replace('player:', '');

        switch (event) {
          case 'player:user-play':
            // this.$stats.collect(item.id, 'watch', playerEvent, data.state, 'float');
            break;
          case 'player:user-pause':
            // this.$stats.collect(item.id, 'watch', playerEvent, data.state, 'float');
            break;
          case 'player:user-resume':
            // this.$stats.collect(item.id, 'watch', playerEvent, data.state, 'float');
            break;
          case PLAYER_EVENTS.TIME_UPDATE:
            const heartbeatData = this.$stats.collect(item.id, 'heartbeat', playerEvent, data.state, 'float');
            if (heartbeatData) {
              this.$stats.send('heartbeat', { ...heartbeatData }, { item });
            }
            this.$stats.collect(item.id, 'watch', playerEvent, data.state, 'float');
            break;
          case PLAYER_EVENTS.SEEKED:
            this.$stats.collect(item.id, 'watch', playerEvent, data.state, 'float');
            break;
          case PLAYER_EVENTS.ENDED:
            if (data.state.watchTime >= 1) {
              const heartbeatData = this.$stats.collect(item.id, 'heartbeat', playerEvent, data.state, 'float');
              if (heartbeatData) {
                this.$stats.send('heartbeat', { ...heartbeatData }, { item });
              }
            }
          case 'player:destroyed':
            if (this.options?.returned) { // do nothing because video continue to play in the main player or popup player
              return;
            }
            if (this.options?.closed) {
              // opened from popup player
              // opened from main player but on another page, so after closing need to send stats
              if (this.options.type === 'vod-popup' || this.options.openerPage !== this.$route.fullPath) {
                this.$stats.send('watch', {}, { item });
                const heartbeatData = this.$stats.collect(item.id, 'heartbeat', 'ended', data.state, 'float');
                if (heartbeatData) {
                  this.$stats.send('heartbeat', { ...heartbeatData }, { item });
                }
                this.$stats.clearCollectedData(item.id, 'heartbeat');
              } else { // opened from main player, after closing the float player, the video still available in main player
                // do nothing
              }
            }
            break;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .float-player {
    position: fixed;
    right: 40px;
    bottom: 60px;
    height: 254px;
    width: 450px;
    // z-index: 10000;
    z-index: 1030;
    border-radius: 5px;

    ::v-deep .video-js {
      border-radius: 5px;

      video {
        border-radius: 5px;
      }
    }


    &--vertical {
      width: 254px;
      height: 450px;
    }

    &--drags {
      cursor: move;
    }

    &--wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .header-bar {
      position: relative;
      z-index: 51;

      .right-controls {
        position: absolute;
        right: 0;
        top: 0;
      }

      .left-controls {
        position: absolute;
        left: 0;
        top: 0;
      }

      &--btn {
        outline: none;
        border: none;
        z-index: 60;
        margin: 4px;
        padding: 0;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        background-color: rgba(51, 57, 83, .2);

        &:focus, &:active, &:hover {
          outline: none;
          border: none;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &--click-area {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
    }

    &--mobile {
      right: 10px;
      height: 84px;
      width: 150px;

      .header-bar {
        &--btn {
          width: 20px;
          height: 20px;
          line-height: 14px;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }

      &.float-player--vertical {
        height: 150px;
        width: 84px;
      }
    }
  }
</style>