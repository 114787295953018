<template>
  <div class="responsive-main" :style="responsiveMainStyle">
    <div
      class="responsive-block"
      :style="aspectRatioStyle"
    >
      <div
        class="responsive-content"
        :class="contentClass"
        :style="contentStyle"
      >
        <slot/>
      </div>
    </div>
    <slot name="additionally"/>
  </div>
</template>

<script>
  import { isString } from 'lodash';
  export default {
    name: 'ResponsiveComponent',
    props: {
      aspectRatio: {
        type: [String],
        default: '16x9',
        validator: type => {
          // The value should be '16x9', '9x16', ...etc;
          return isString(type) && type.toLowerCase().indexOf('x') > -1;
        },
      },

      contentClass: {
        type: String,
        default: null,
      },
      contentStyle: {
        type: Object,
        default: () => ({}),
      },
      responsiveMainStyle: {
        type: String,
        default: '',
      },
    },

    computed: {
      aspectRatioStyle() {
        if (!this.aspectRatio) {
          return { paddingBottom: (16 / 9) * 100 + '%' };
        }
        let values = this.aspectRatio.toLowerCase().split('x');
        if (values.length < 2) {
          return { paddingBottom: '100%' };
        }
        let divider = values[1] / values[0];
        return { paddingBottom: divider * 100 + '%' };
      },
    },
  };
</script>


<style lang="scss">
	.responsive {
		&-main {
			// Note: not `flex`, while not correct calculated height of FF to 65 version and Chrome.
			display: block;
			flex-grow: 0;
			flex-shrink: 0;
			overflow: hidden;
			position: relative;
		}

		&-block {
			position: relative;
			width: 100%;
		}

		&-content {
			display: flex;
			height: 100%;
			position: absolute;
			width: 100%;
		}

		// &-content-wrapper {
		//   // flex: 1 0 0;
		//   width: 100%;
		//   position: relative;
		// }
	}
</style>
