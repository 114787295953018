<template>
  <div
    class="product-details-popup"
    :class="{ 'product-details-popup--open': isProductPopupOpen }"
    @click.stop
  >
    <div
      v-touch:swipe.bottom="bottomSwipe"
      v-touch:swipe.top="topSwipe"
      class="product-details-popup__swiper"
    >
      <div class="product-details-popup__swiper-line" />
    </div>

    <div class="product-details-popup__header">
      <i class="icon-close-button" @click="closeProductPopup" />
      <!--      <img-->
      <!--        class="product-details-popup__close"-->
      <!--        :src="require('@/assets/shop-player/close-button.svg')"-->
      <!--        @click="closeProductPopup"-->
      <!--      >-->
    </div>

    <div class="product-details-popup__content">
      <shared-cart-container
        v-if="isCart"
        is-popup
        @open:checkout="handleOpenCheckout"
      />

      <shared-checkout-container
        v-if="isCheckout"
        is-popup
        :buy-now-product="isSelectedProduct"
        @edit:cart="handleOpenCart"
      />
      <product-details-component
        v-if="!isCart && !isCheckout"
        :id="product.amsProduct.remote_id"
        is-popup
        :old-id="product.amsProduct.id"
        :ams-product="product.amsProduct"
        :product="product"
        :is-mobile="isMobile"
        :open-from-video="true"
        :media-source="mediaSource"
        @open:cart="handleOpenCart"
        @buy:now="handleBuyNow"
      />
    </div>
  </div>
</template>

<script>
  import ProductItem from '../product-item.component';
  import ProductDetailsComponent from './product-details.component';

  import SharedCheckoutContainer from 'src/shared/features/checkout/checkout.container';
  import SharedCartContainer from 'src/shared/features/cart/cart.container';

  export default {
    name: 'ProductDetailsPopupComponent',
    components: {
      ProductDetailsComponent,
      ProductItem,
      SharedCheckoutContainer,
      SharedCartContainer,
    },
    props: {
      product: {
        type: Object,
        default: null,
      },
      isMobile: {
        type: Boolean,
        default: false,
      },
      mediaSource: {
        type: String,
        default: 'vod',
      },
    },

    data() {
      return {
        isProductPopupOpen: false,
        isCheckout: false,
        isCart: false,
        isSelectedProduct: null,
      };
    },
    methods: {
      closeProductPopup() {
        this.$emit('close');
      },

      handleOpenCheckout() {
        this.isCart = false;
        this.isCheckout = true;
      },

      handleOpenCart() {
        this.isCart = true;
        this.isCheckout = false;
        this.isSelectedProduct = null;
      },

      handleBuyNow(data) {
        this.isCheckout = true;
        this.isCart = false;
        this.isSelectedProduct = data;
      },
      topSwipe() {
        this.isProductPopupOpen = true;
      },
      bottomSwipe() {
        if (this.isProductPopupOpen) {
          this.isProductPopupOpen = false;
        } else {
          this.closeProductPopup();
        }
      },
    },
  };
</script>

<style lang="scss">
  .product-details-popup {
    a {
      color: revert;
    }
  }
</style>

<style lang="scss" scoped>
  .product-details-popup {
    position: absolute;
    top: 50px;
    bottom: 50px;
    right: 20px;
    width: 380px;
    background: #fff;
    border-radius: 10px;
    z-index: 119;

    &__swiper {
      display: none;
    }

    &__header {
      display: flex;
      padding: 15px;

      .icon-close-button {
        font-size: 13px;
        cursor: pointer;
      }
    }

    &__content {
      padding: 15px;
      height: calc(100% - 65px);
      overflow-y: auto;
    }
  }

  .with-mobile-menu {
    .product-details-popup {
      position: fixed;
      top: 70%;
      right: 10px;
      left: 10px;
      width: auto;
      height: calc(30% - 60px);
      transition: 0.3s ease all;
      border-radius: 10px 10px 0 0;
      border: 1px #d1d1d1 solid;

      &--open {
        top: 90px;
        height: calc(100% - 150px);
        border-radius: 10px;
      }

      &__swiper {
        display: block;
        width: 180px;
        height: 50px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

      &__swiper-line {
        width: 52px;
        height: 4px;
        position: absolute;
        left: 50%;
        bottom: 27px;
        background: #fff;
        border-radius: 10px;
        transform: translateX(-50%);
      }

      &__header {
        display: none;
      }

      &__content {
        padding: 15px;
        height: 100%;
      }
    }
  }
</style>