export default `
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      lastIncompleteCheckout {
        id
      }
    }
  }
`;
