<template>
  <div>
    <loader v-if="!products.length" :spinner-style="{ color: '#343434' }" />

    <checkout-layout v-else :is-mobile="isMobile" :is-popup="isPopup">
      <template #actions>
        <shipping-component
          v-show="activeStep === 1"
          :locations="locations"
          :is-mobile="isMobile"
          :is-order-exists="isOrderExists"
          :loading="loading"
          :is-rates-calculated="isRatesCalculated"
          @step:forward="changeStep(activeStep + 1)"
          @order:create="handleCreateDraftOrder"
          @changed:shipping-address="handleCalculateShippingAndTax"
        />

        <payment-component
          v-show="activeStep === 2"
          :is-mobile="isMobile"
          :shipping-info="shippingInfo"
          :loading="loading"
          @step:back="changeStep(activeStep - 1)"
          @order:complete="completeOrder"
          @payment-form:create="createPaymentForm"
        >
          <template #paymentForm>
            <div id="paymentForm" />
          </template>
        </payment-component>

        <order-component
          v-show="activeStep === 3"
          :locations="locations"
          :is-mobile="isMobile"
          :customer-email="customerEmail"
          :order-info="orderInfo"
          :order-num="orderNumber"
          :order-placed-at="orderPlacedAt"
          :loading="loading"
          :hide-go-to-store-button="isPopup"
          @order:finish="finishOrder"
        />
      </template>

      <template #details>
        <order-summary-component
          :products="products"
          :cost="cost"
          :hide-edit-cart="isOrderPlaceStep || isBuyNow"
          :hide-discount="isOrderPlaceStep"
          :is-mobile="isMobile"
          :is-popup-checkout="isPopup"
          @edit:cart="handleEditCart"
        />
      </template>
    </checkout-layout>

    <b-alert
      v-model="visibleErrorMessage"
      variant="danger"
      fade
      dismissible
      class="sp-danger--alert"
    >
      {{ errorMessage }}
    </b-alert>
  </div>
</template>

<script>
  //layout
  import CheckoutLayout from './layout/checkout.layout';

  //stepper component
  import ShippingComponent from './shipping/shipping.component';
  import PaymentComponent from './payment/payment.component';
  import OrderComponent from './order/order.component';

  //easy components
  import OrderSummaryComponent from './components/order-summary.component';
  import Loader from 'src/components/loader/loader';

  import { amsClient } from '@/service/ams';
  import OrderApi from '@/shared/api/order';
  import CheckoutService from './checkout.service';
  import LocationService from '@/shared/services/locations.service';
  import Auth from '@/service/authService';
  import { debounce } from 'lodash';

  const SharedLocationsService = new LocationService();

  export default {
    components: {
      CheckoutLayout,
      OrderSummaryComponent,
      ShippingComponent,
      PaymentComponent,
      OrderComponent,

      Loader,
    },
    props: {
      buyNowProduct: {
        type: Object,
        default: null,
      },
      isPopup: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loading: false,
        locations: null,
        cashOnDeliveryTermId: '',
        activeStep: 1,
        completePaidOrder: null,
        errorMessage: '',
        visibleErrorMessage: false,
        isRatesCalculated: false,
        taxCalculation: null,
      };
    },

    computed: {
      productWithoutCart() {
        if (this.buyNowProduct) {
          return [this.buyNowProduct];
        }

        const buyNowProduct = this.$store.getters['checkout/getBuyNowProduct'];

        if (buyNowProduct) {
          return [buyNowProduct];
        }

        return null;
      },
      products() {
        if (this.productWithoutCart) {
          return this.productWithoutCart;
        }

        if (this.completePaidOrder && this.completePaidOrder.products) {
          return this.completePaidOrder.products;
        }

        const products = this.$store.getters['checkout/getCartItems'];

        // TODO: back after POC
        return products.map((product) => {
          const customPriceAttribute = product.node.attributes.find(
            (a) => a.key === '__customPrice',
          );

          const productDiscount = customPriceAttribute
            ? product.node.merchandise.price.amount - customPriceAttribute.value
            : 0;

          const productPrice = productDiscount > 0
            ? customPriceAttribute.value
            : product.node.merchandise.price.amount;

          return {
            ...product.node,
            discount: productDiscount,
            merchandise: {
              ...product.node.merchandise,
              price: {
                ...product.node.merchandise.price,
                amount: productPrice,
              },
            },
          }
        });
      },

      cost() {
        if (this.buyNowProduct) {
          return this.buyNowProduct.cost;
        }

        const buyNowProduct = this.$store.getters['checkout/getBuyNowProduct'];

        if (buyNowProduct) {
          return buyNowProduct.cost;
        }

        if (this.completePaidOrder && this.completePaidOrder.cost) {
          return this.completePaidOrder.cost;
        }

        return this.$store.getters['checkout/getCostDetails'];
      },

      isBuyNow() {
        const buyNowProduct = this.$store.getters['checkout/getBuyNowProduct'];

        return !!this.buyNowProduct || !!buyNowProduct;
      },

      isOrderPlaceStep() {
        return this.activeStep === 3;
      },

      isUser() {
        return this.$store.getters['authenticate/isUser'];
      },

      isCartId() {
        if (!this.isUser.guest) {
          return Auth.get('user.cart');
        }

        return localStorage.getItem('cartId');
      },

      store() {
        return amsClient.get('conf.store');
      },

      isOrderExists() {
        return !!CheckoutService.instance.isOrderExists;
      },

      shippingInfo() {
        return CheckoutService.instance.shippingAddressInfo;
      },

      orderNumber() {
        return CheckoutService.instance.orderNum;
      },

      orderPaymentMethod() {
        return CheckoutService.instance.orderPaymentMethod;
      },

      orderShippingMethod() {
        return CheckoutService.instance.orderShippingMethod;
      },

      orderPlacedAt() {
        return CheckoutService.instance.orderPlacedAt;
      },

      customerEmail() {
        const info = CheckoutService.instance.customerInfo;
        return info?.email;
      },

      orderInfo() {
        let info = [];

        if (this.orderShippingMethod === CheckoutService.SHIPPING_METHOD_PICKUP) {
          const infoLines = this.shippingInfo.slice(1); // remove name info

          info.push({
            icon: 'location',
            title: 'Pick up Address',
            lines: infoLines,
          });
        } else {
          info = [
            {
              icon: 'location',
              title: 'Shipping address',
              lines: this.shippingInfo,
            },
            {
              icon: 'truck-fast',
              title: 'Shipping method',
              lines: [this.orderShippingMethod],
            },
          ];
        }

        info = [
          ...info,
          {
            icon: 'credit-card',
            title: 'Payment method',
            lines: [this.orderPaymentMethod],
          },
        ];

        if (this.orderPaymentMethod === CheckoutService.PAYMENT_METHOD_NOW) {
          info.push({
            icon: 'receipt',
            title: 'Billing address',
            lines: CheckoutService.instance.billingAddressInfo,
          });
        }

        return info;
      },
      isMobile() {
        return this.isPopup || window.innerWidth <= 990;
      },
    },
    watch: {
      activeStep(stepId) {
        if (!this.isPopup) {
          switch (stepId) {
            case 1:
              document.title = 'Checkout Shipping';

              break;
            case 2:
              document.title = 'Checkout Payment';

              break;
            case 3:
              document.title = `Checkout | Order ${
                this.completePaidOrder?.order?.name || 1
              }`;
              break;

            default:
              break;
          }
        }

        if (stepId === 2) {
          this.createPaymentForm();
          this.fetchPaymentTerms();
        }
      },

      products(products) {
        if (!products.length && this.activeStep !== 3) {
          this.$router.push('/');
        }
      },
    },

    created() {
      this.debouncedCalculateShippingAndTax = debounce(
        (payload) => this.calculateShippingAndTax(payload),
        500,
      );
    },

    mounted() {
      if (!this.isPopup) {
        document.title = 'Checkout Shipping';
      }

      this.fetchLocations();
    },
    beforeDestroy() {
      this.$store.commit('checkout/setBuyNowProduct', null);
    },

    methods: {
      async fetchPaymentTerms() {
        const terms = await CheckoutService.instance.getPaymentTerms();

        const cashOnDeliveryTerm = terms.find(
          (term) => term.paymentTermsType === 'RECEIPT',
        );

        this.cashOnDeliveryTermId = cashOnDeliveryTerm.id;
      },
      async fetchLocations() {
        try {
          const locations = await SharedLocationsService.getUserLocations(
            this.store?.provider_id || 'mw6gwag74u8sftzl',
          );

          this.locations = locations.warehouse;
        } catch (err) {
          this.error = normalizeError(err);
        }
      },

      async handleCreateDraftOrder(form) {
        this.loading = true;

        const customerInfo = {
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phone,
        };

        const address = form.address;

        const items = this.products.map((product) => {
          return {
            variantId: product.merchandise.id,
            quantity: product.quantity,
            customAttributes: product.attributes.filter((a) => a.key !== '__customPrice'),
            ...(product.discount > 0 && {
              appliedDiscount: {
                value: product.discount,
                valueType: 'FIXED_AMOUNT',
              },
            }),
          }
        });

        try {
          await Promise.all([
            CheckoutService.instance.createDraftOrder(
              customerInfo,
              address,
              items,
              this.cost.totalShippingAmount.amount,
              this.taxCalculation.tax.taxAmountExclusive / 100,
            ),

            CheckoutService.instance.softRegistration({
              email: form.email,
              first_name: form.firstName,
              last_name: form.lastName,
              allow_soft_registration: form.allowSoftRegistration,
              cart: this.isCartId,
            }),
          ]);

          this.changeStep(2)
        } catch (error) {
          this.errorMessage = error;
          console.error(error);
          // ToDo and clear form
        } finally {
          this.loading = false;
        }
      },

      changeStep(step) {
        this.activeStep = step;
      },

      async createPaymentForm() {
        this.loading = true;

        try {
          const form = await CheckoutService.instance.getPaymentForm();

          form.mount('#paymentForm');
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      },

      async finishOrder() {
        this.$router.push('/');
      },

      async completeOrder({ isPayNow, billingAddress }) {
        this.loading = true;

        try {
          let response = null;

          if (isPayNow) {
            response = await CheckoutService.instance.completeOrder(
              billingAddress,
              this.taxCalculation.id,
            );
          } else {
            response = await CheckoutService.instance.termOrder(
              this.cashOnDeliveryTermId,
              this.taxCalculation.id,
            );
          }

          await this.fetchOrderById(response.order.id);

          this.changeStep(3);

          if (!this.productWithoutCart) {
            this.handleClearCart(true);
          }
        } catch (error) {
          this.showErrorMessage(error);
        } finally {
          this.loading = false;
        }
      },

      async fetchOrderById(shopifyId) {
        this.loading = true;

        try {
          const pagination = {
            count: 20,
            page: '',
          };

          const options = {
            count: pagination.count,
            ...(pagination.page ? { page: pagination.page } : {}),
          };

          const response = await OrderApi.getOrder(shopifyId, options, true);
          const orderNode = response.node;

          console.log('orderNode', orderNode);

          const customTaxAttribute = orderNode.customAttributes.find(a => a.key === 'tax');
          const taxAmount = customTaxAttribute?.value || 0;

          const products = orderNode.lineItems.edges.map((node) => {
            const product = node.node;

            return {
              next: node.cursor,
              merchandise: {
                id: product.id,
                image: product.image,
                price: product.discountedUnitPriceSet.shopMoney,
                product: product.product,
                title: product.variantTitle,
              },
              quantity: product.quantity,
            };
          });

          const subtotalShopMoney = orderNode.subtotalPriceSet.shopMoney;
          const totalAmount = +orderNode.totalPriceSet.shopMoney.amount + +taxAmount;

          this.completePaidOrder = {
            products,
            cost: {
              subtotalAmount: subtotalShopMoney,
              totalAmount: {
                amount: totalAmount,
              },
              totalDutyAmount: orderNode.totalDiscountsSet.shopMoney,
              totalTaxAmount: {
                amount: taxAmount,
              },
              totalShippingAmount: orderNode.totalShippingPriceSet.shopMoney,
            },
            order: { ...orderNode },
          };
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },

      handleClearCart(trueUnpaid) {
        localStorage.removeItem('cartId');

        Auth.set('user.cart', null);

        this.$store.commit('checkout/setCompleteOrder', trueUnpaid);
      },

      handleEditCart() {
        this.$emit('edit:cart');
      },

      showErrorMessage(text) {
        this.errorMessage = text;
        this.visibleErrorMessage = true;

        const timeout = setTimeout(() => {
          this.visibleErrorMessage = false;
          this.errorMessage = '';

          clearTimeout(timeout);
        }, 3000);
      },
      handleCalculateShippingAndTax(payload) {
        this.debouncedCalculateShippingAndTax(payload);
      },
      async calculateShippingAndTax({ type, address }) {
        console.log('address', type, address);

        this.$store.commit('checkout/setShippingTotal', 0);
        this.$store.commit('checkout/setShippingTotal', 0);

        this.isRatesCalculated = false;

        const [warehouse] = this.locations;

        const defaultShippingPackage = CheckoutService.instance.defaultShippingPackage;

        try {
          let shippingRate = 0;

          if (type === 'ship') {
            shippingRate = await this.calculateShippingRate(warehouse.address.zip, address.zip, defaultShippingPackage);
          }

          const taxRate = await this.calculateTaxRate(address, this.products, shippingRate);

          this.taxCalculation = taxRate;

          this.isRatesCalculated = true;
        } catch {
          this.isRatesCalculated = false;

          this.showErrorMessage('Shipping address is not valid');
        }
      },
      async calculateShippingRate(fromZip, toZip, deliveryPackage) {
        const shippingRate = await CheckoutService.instance.calculateShippingRate({
          from: fromZip,
          to: toZip,
          weight: deliveryPackage.dimensions.weight,
          weightUnit: 'POUNDS',
          height: deliveryPackage.dimensions.height,
          width: deliveryPackage.dimensions.width,
          length: deliveryPackage.dimensions.length,
          sizeUnit: 'in',
          container: deliveryPackage.id,
        });

        const totalShippingRate = shippingRate.rate * this.products.length;
        this.$store.commit('checkout/setShippingTotal', totalShippingRate);

        return totalShippingRate;
      },
      async calculateTaxRate(address, products, shippingRate) {
        console.log('products', products);

        const productLineItems = products.map(p => {
          const customPriceAttribute = p.attributes.find(
            (a) => a.key === '__customPrice',
          );

          const productPrice = customPriceAttribute
            ? customPriceAttribute.value
            : p.merchandise.price.amount;

          return {
            reference: `${p.merchandise.product.title} - ${p.merchandise.title}`,
            amount: Math.floor(productPrice * 100),
            quantity: p.quantity,
          };
        });

        const taxCalculation = await CheckoutService.instance.calculateTax({
          address: {
            line1: address.address1,
            postal_code: address.zip,
            country: address.countryCode || 'US',
            state: address.state,
            city: address.city,
          },
          line_items: productLineItems,
          shipping_cost: {
            amount: Math.floor(shippingRate * 100),
          },
        });

        console.log('taxCalculation', taxCalculation);

        this.$store.commit('checkout/setTaxTotal', taxCalculation.tax.taxAmountExclusive / 100);

        return taxCalculation;
      },
    },
  };
</script>

<style lang="scss">
  button {
    outline: none !important;
  }
  .sp-active-breadcrump {
    cursor: pointer;
    color: #21a66e;
  }
</style>

<style lang="scss" scoped>
  .sp-danger--alert {
    position: fixed;
    bottom: 94px;
    right: 20px;
    border-radius: 5px;
    border: none;
    z-index: 10;
  }
  #paymentForm {
    margin-bottom: 1.5rem;
  }
</style>
