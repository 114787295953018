export default `
  query {
    paymentTermsTemplates {
      id
      name
      paymentTermsType
      dueInDays
      description
      translatedName
    }
  }
`;
