import ResizeObserver from 'resize-observer-polyfill';

// import debounce from 'lodash/debounce';
export default {
  bind: (el, bindings, vnode) => {
    const callback = bindings.value; // debounce(bindings.value, );
    el._obs = new ResizeObserver((entries, observer) => {
      callback();
    });

    el._obs.observe(el);
    // let callback = bindings.value; // debounce(bindings.value, );
    // el._obs = OnResize(el, callback);
    // console.log(el._obs);
  },
  // inserted(el, bindings) {
  // console.log('inserted');
  // },

  // updated() {

  // },

  unbind: (el) => {
    // console.log(el._obs);
    if (el._obs) {
      el._obs.disconnect();
    }
  },
};
