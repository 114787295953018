export default [
  {
    name: 'WatchItem',
    path: '/watch/:slug',
    component: () => import('./watch.view'),
  },
  {
    name: 'Watch',
    path: '/watch',
    component: () => import('./watch.view'),
  },
];
