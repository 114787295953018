export default `

query getOrder($id: ID!) {
    node(id: $id) {
      id
      ... on Order {
          id
          name
          tags
          email
          displayFinancialStatus
          displayFulfillmentStatus
          createdAt
          processedAt
          paymentGatewayNames
          customAttributes {
            key
            value
          }
          billingAddress {
            formatted
          }
          shippingAddress {
            formatted
          }
          shippingLine {
            title
          }
          customer {
            id
            email
            lastName
            firstName
          }
          subtotalPriceSet {
            shopMoney {
              currencyCode
              amount
            }
          }
          totalShippingPriceSet {
            shopMoney {
              currencyCode
              amount
            }
          }
          totalDiscountsSet {
            shopMoney {
              currencyCode
              amount
            }
          }
          totalPriceSet {
            shopMoney {
              currencyCode
              amount
            }
          }
          totalTaxSet {
            shopMoney {
              currencyCode
              amount
            }
          }
          returnStatus
          lineItems(first: 50) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              endCursor
              startCursor
            }
            edges {
              cursor
              node {
                id
                name
                variantTitle
                quantity
                refundableQuantity
                unfulfilledQuantity
                customAttributes {
                  key
                  value
                }
                originalUnitPriceSet {
                  shopMoney {
                    currencyCode
                    amount
                  }
                }
                discountedUnitPriceSet {
                  shopMoney {
                      currencyCode
                      amount
                  }
                }
                originalTotalSet {
                  shopMoney {
                    currencyCode
                    amount
                  }
                }
                image {
                  url
                }
                product {
                  id
                  title
                }
              }
            }
          }
      }
    }
  }
`;
