import moment from 'moment';

const formatDate = (date, format = 'MM/DD/YYYY') => {
  if (!date) {
    return null;
  }

  return moment(date).format(format);
};

export default formatDate;
