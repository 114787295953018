<template>
  <div class="footer">
    <div class="navigateWrap">
      <nav>
        <div class="logo" @click="redirectToHome">
          <img :style="`height: 36px; border-radius: ${logo ? '5px' : 0}`" :src="logoSrc">
        </div>

        <a v-if="!$isStandaloneStore" @click="$router.push('/screen/live/')">Live</a>
        <a v-if="!$isStandaloneStore" @click="$router.push('/stores')">Stores</a>

        <!-- <a id="myLauncher" @click="openWidget_AboutUs">About Us</a>
        <a id="myLauncher" @click="openWidget_FAQ">FAQ</a>
        <a :href="isLinkMarketLive">Returns</a> -->
      </nav>

      <!-- <div class="sellersWrap">
        <span>Join our sellers community today</span>
        <router-link to="/seller-splash">
          Apply to be a Seller
          <i class="fas fa-chevron-right"/>
        </router-link>
      </div> -->
    </div>

    <div class="policy">
      <p>
        <a id="myLauncher" @click="openWidget_Privacy">Policy</a>
        <span class="bullet"/>
        <a class="termLink" @click="openWidget_Terms">Term of Use</a>
        <span class="bullet"/>
        <a href="javascript:Optanon.ToggleInfoDisplay()">Do
          Not Sell My Personal Information</a>
      </p>

      <div class="verbLinkWrap">
        <span>© All rights reserved, Vimmi Communications Ltd</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Config from '@/service/config';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'FooterMain',
    props: {
      logo: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isLinkMarketLive: '',
        sellerLink: '',
        currentYear: new Date().getFullYear(),
      };
    },
    computed: {
      logoSrc() {
        return this.logo || require('src/assets/logo/logo-shoprz-dark.svg')
      },
    },
    created() {
      Config.loaded(() => {
        this.sellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
        this.isLinkMarketLive = amsClient.get('conf.portal.marketplace.shopify.returns_and_exchanges_url');
      });
    },
    methods: {
      openWidget_FAQ() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'FAQs1' });
      },

      openWidget_AboutUs() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'AboutUs1' });
      },

      openWidget_Privacy() {
        this.$router.push({ name: 'privacy-policy' });
      },
      openWidget_Terms() {
        this.$router.push({ name: 'terms-and-conditions' });
      },

      redirectToHome() {
        if (this.$route.fullPath === '/') {
          window.scroll(0, 0);
        } else {
          this.$router.push('/');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .footer {
    z-index: 0;
    margin-top: auto;
    padding: 32px 40px 19px;
    background: #f5f5fa;
    color: $main-color-items;
    font-size: 15px;
    line-height: 25px;

    a {
      color: $main-color-items;
      cursor: pointer;
    }

    @media (max-width: 800px) {
      padding: 30px 20px 22px;
    }
  }

  .navigateWrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e9e9ec;

    nav {
      display: flex;
      align-items: center;

      .logo {
        margin-right: 40px;
        cursor: pointer;
      }

      a {
        margin-right: 20px;
        font-size: 15px;
        line-height: 18px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: 800px) {
        display: block;
        padding-bottom: 25px;
        border-bottom: 1px solid #e9e9ec;

        .logo {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .sellersWrap {
      display: flex;
      flex-direction: column;
      flex-basis: 250px;

      span {
        margin-bottom: 14px;
      }

      a, .open-popup-button {
        color: $main-color;
        cursor: pointer;

        i {
          margin-left: 10px;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    @media (max-width: 800px) {
      display: block;

      &.standalone-store {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .sellersWrap {
        margin-top: 26px;
      }
    }
  }

  .policy {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;

    .verbLinkWrap {
      display: flex;
      margin-right: 47px;
      flex-direction: column;

      span {
        color: #9c9fad;
      }

      a {
        margin-top: 5px;
      }
    }

    .bullet {
      display: inline-block;
      border-radius: 50%;
      vertical-align: super;
      margin: 0 10px;
      width: 4px;
      height: 4px;
      background-color: #d8d8e2;
    }

    @media (max-width: 800px) {
      display: block;

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }

      span {
        margin-right: 0;
      }

      .bullet {
        max-width: 4px;
      }
    }

    @media (max-width: 460px) {
      a:last-of-type {
        margin-top: 5px;
      }

      span:last-of-type {
        visibility: hidden;
      }
    }
  }
</style>