<template src="./carousel-item.html" />

<script>
  import { mapState, mapGetters } from 'vuex';
  import { debounce } from 'lodash';
  import Config from '@/service/config';
  import rtm from '@/service/realTimeMessages';
  import { amsClient } from '@/service/ams';
  import HlsVideoService from '@/service/hls-video';
  import device from '@/service/device-service.js';
  import { getFormattedCurrency } from 'vimmi-web-utils/cjs/formatCurrency';
  import { FollowMixin } from '@/plugins/mixin';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import normalizeImg from 'src/filters/normalizeImg';
  import normalizeShopifyImg from '@/shared/helpers/normalize-shopify-img';
  import LinkWrapper from '@/components/link-wrapper';
  import WishlistService from '@/store/wishlist';
  import followButton from 'src/components/followButton/followButton';
  import ReferalService from '@/shared/services/referal.service';
  import { formatDateTime } from '@/shared/helpers/date';
  import responsiveImage from '@/components/reponsive-image.vue';
  import LockIconComponent from '@/shared/components/lock-icon.vue';

  const addedHeartImg = require('/src/assets/icons/added-to-wishlist-icon.svg');
  const addHeartImg = require('/src/assets/icons/add-to-wishlist-icon.svg');

  export default {
    name: 'CarouselItem',
    components: {
      LockIconComponent, responsiveImage,
      LinkWrapper,
      followButton,
    },
    mixins: [FollowMixin, AuthVariableMixin],
    props: {
      area: {
        type: Object,
        default: () => ({}),
      },
      item: {
        type: Object,
        required: true,
      },
      position: {
        type: Number,
      },
      itemType: {
        type: String,
        default: '',
      },
      isVodPreview: {
        type: Boolean,
        default: () => false,
      },
      swiper: {
        type: Boolean,
        default: () => false,
      },
      size: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isBlocked: false,
        isHovered: false,
        areProductsVisible: false,
        areProductsLoading: false,
        chachedPreviewMedia: Object.create(null),
        previewDelayTimer: null,
        featuredProducts: [],
        showSpinner: true,
        sendingToWishlist: false,
        isVertical: false,
        toggleStyle: false,
        titleMain: '',
      };
    },
    computed: {
      ...mapState('vodPreview', {
        isPreviewOpen: 'visible',
      }),
      ...mapGetters({
        getList: 'wishlist/getList',
        getVideosList: 'wishlist/getVideosList',
        screenType: 'popup/screenType',
        getPlayTemplate: 'chachedPreviewMedia/getPlayTemplate',
      }),
      isMiniature() {
        return this.screenType === 'miniature';
      },
      isInWishList() {
        return Object.keys(this.getList).includes(this.item.id);
      },
      isInWishVideosList() {
        return Object.keys(this.getVideosList).includes(this.item.id);
      },
      heartImg() {
        return this.isInWishList || this.isInWishVideosList
          ? addedHeartImg
          : addHeartImg;
      },
      isProductItemType() {
        return this.item.itype === 'shop_product';
      },
      isVod() {
        return this.item?.itype?.includes('vod');
      },
      isEvent() {
        return this.item?.itype === 'item_epg_shop_event';
      },
      isProduct() {
        return this.item?.itype === 'shop_product';
      },
      isSize() {
        if (this.size) {
          return this.size;
        }

        if (this.area?.size) {
          return this.area.size;
        }

        return null;
      },
      isFollowedStores() {
        if (this.area?.model === 'store_channels') {
          return this.item.storefront.storefront_type === 'channel';
        }

        return (
          ['screen_shoprz', 'event_host_admin'].includes(this.item?.itype) &&
          (this.item?.creator || this.item?.id)
        );
      },
      isLive() {
        return this.item?.is_live && this.item?.on_air;
      },
      image() {
        if (this.area?.dtype === 'promoted') {
          return this.item?.banner;
        }

        if (this.item.itype === 'screen_shoprz_page' && this.item?.storefront) {
          return this.item?.storefront?.picture || '';
        }

        return (
          this.item?.picture ||
          this.item?.backdrop ||
          this.item?.thumbnail ||
          this.item?.poster ||
          this.item?.cover ||
          this.item?.creator?.picture
        );
      },
      title() {
        if (this.area?.dtype === 'promoted') {
          return this.item?.title;
        }

        if (this.item.itype === 'screen_shoprz_page' && this.item?.storefront) {
          return this.item?.storefront?.name || '';
        }

        return this.item?.itype === 'screen_shoprz'
          ? this.brand
          : this.item?.title || '';
      },
      brand() {
        return this.item?.creator?.name || '';
      },
      broadcastStatus() {
        if (this.isBannerAsSegment) {
          return null;
        }

        return this.isEvent
          ? this.isLive
            ? 'LIVE'
            : formatDateTime(
              new Date(this.item?.start?.replace(/ /g, 'T') + 'Z'),
            )
          : null;
      },
      isVisibleDuration() {
        if (this.isBannerAsSegment) {
          return null;
        }

        return this.isVideo && !(this.item?.is_active && this.item?.is_live);
      },
      isVideo() {
        return /mov/i.test(this.item?.itype);
      },
      liveHasPromo() {
        return !!this.item?.promo_vod;
      },
      channelLogo() {
        if (this.isBannerAsSegment) {
          return null;
        }

        return this.item.itype === 'item_epg_shop_event'
          ? this.item.creator.picture
          : null;
      },
      creatorID() {
        return this.item?.itype === 'event_host_admin'
          ? this.item.id
          : this.item.creator.id;
      },
      storeId() {
        return this.item?.store?.id || this.item?.storefront?.id;
      },
      followers() {
        return this.titleMain === 'Stores' ||
        this.titleMain === 'Followed stores' ||
        this.titleMain === 'Storefronts' ||
        this.$route.query.title === 'Followed stores' ||
        this.$route.query.title === 'Storefronts'
          ? this.item?.creator?.followers
          : this.item?.followers;
      },
      isPreviewMode() {
        return !this.isBannerAsSegment && (this.isVod || this.isEvent) && this.canPlay();
      },
      isBannerAsSegment() {
        return this.area?.dtype === 'promoted';
      },
      hasBannerVideo() {
        return this.isBannerAsSegment && this.item.media_url;
      },
      isMobile() {
        return device.isAnyMobile() && device.isMobileSize();
      },
      uniqStyleWrap() {
        return this.item?.itype === 'screen_shoprz' ||
        this.item?.itype === 'event_host_admin'
          ? 'carouselWrap'
          : '';
      },
      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      playingLive() {
        return this.$store.getters['dataAreasLive/playingLive'];
      },
      pageForCurrentLive() {
        return this.$store.getters['player/pageForCurrentLive'];
      },
      pageForSecondLive() {
        return this.$store.getters['player/pageForSecondLive'];
      },
      isLiveData() {
        return this.$store.getters['player/isLiveData'];
      },
      playTemplate() {
        return this.getPlayTemplate;
      },
    },
    inject: ['toggleReadyLive'],
    async created() {
      this.$bus.$on('closeProduct', (id, areaId) =>
        this.closeProductsById(id, areaId),
      );
      this.hlsVideoService = new HlsVideoService();

      const productsState = this.item.productsState;
      if (productsState) {
        this.areProductsVisible = true;
        this.featuredProducts = productsState.featuredProducts;
      }
      this.onHover = debounce((event) => {
        if (
          this.isPreviewMode &&
          this.isHovered &&
          !this.isPreviewOpen &&
          !this.isMobile
        ) {
          if (event?.target) {
            const rect = event.target.getBoundingClientRect();

            const item = { ...this.item, size: this.isSize };
            if (this.areProductsVisible) {
              item.productsState = {
                areProductsVisible: true,
                featuredProducts: this.featuredProducts,
              };
            }
            this.$store.dispatch('vodPreview/show', {
              visible: this.isHovered,
              rect,
              item,
            });
          }
        }
      }, 1000);

      // if (this.isPreviewMode && this.isMobile) {
      //   this.getFeaturedProducts();
      // }
    },
    mounted() {
      this.$bus.$on('isTitle', (title) => {
        this.titleMain = title;
      });

      this.isMaxAtt();
    },
    destroyed() {
      this.isHovered = false;
      this.onHover();
      this.$bus.$off('closeProduct', (id, areaId) =>
        this.closeProductsById(id, areaId),
      );
      window.removeEventListener('scroll', () => this.hideProducts());
    },
    methods: {
      imgIsPng(src) {
        return !!src.includes('.png');
      },
      normalizeImg,
      normalizeShopifyImg,
      getFormattedCurrency,
      normalizedProductImage(image) {
        let size;
        if (this.area?.size === 'large' || this.size === 'large') {
          size = [460, 460];
        } else if (this.area?.size === 'medium' || this.size === 'medium') {
          size = [340, 340];
        } else {
          size = [220, 220];
        }
        return this.normalizeShopifyImg(image, ...size);
      },
      normalizedVideoImage(image) {
        let size;
        if (this.area?.size === 'large' || this.size === 'large') {
          return image;
        } else if (this.area?.size === 'medium' || this.size === 'medium') {
          size = 'HD';
        } else {
          size = 'SD';
        }
        return normalizeImg(image, 'thumb', size, size);
      },
      onClickOutside() {
        if (this.areProductsVisible) {
          this.areProductsVisible = false;
        }
      },
      closeProductsById(id, areaId) {
        if (this.item.id === id && this.area?.id === areaId) {
          return;
        }

        this.areProductsVisible = false;
      },
      async clickOnHeart() {
        this.sendingToWishlist = true;

        if (this.isVod) {
          if (this.isInWishVideosList) {
            await WishlistService.removeFromVideosWishlist(this.item.id);

            this.$stats.send('remove_wishlist', {}, { item: this.item });

            this.sendingToWishlist = false;
            this.$bus.$emit('toggle:heart', this.item.id);

            return;
          }

          await WishlistService.addToVideoWishlist(this.item.id);

          this.$stats.send('add_wishlist', {}, { item: this.item });

          this.sendingToWishlist = false;

          return;
        }

        if (this.isInWishList) {
          await WishlistService.removeFromWishlist(this.item.id);

          this.$stats.send('remove_wishlist', {}, { item: this.item });

          this.sendingToWishlist = false;

          return;
        }

        this.$stats.send('add_wishlist', {}, { item: this.item });

        await WishlistService.addToWishlist(this.item.id);

        this.$stats.send('add_wishlist', {}, { item: this.item });

        this.sendingToWishlist = false;
      },
      async onShowProducts() {
        this.areProductsVisible = true;
        if (device.isMobileSize()) {
          this.$bus.$emit('closeProduct', this.item.id, this.area?.id);
          window.addEventListener('scroll', this.hideProducts.bind(this));
        }
        try {
          this.areProductsLoading = true;
          if (this.item.itype === 'item_mov_vod_shoprz') {
            const data = await amsClient.callAms(`/get_item/${this.item.id}/`);

            this.featuredProducts = data?.head?.featured_products || [];
          }
        } catch (e) {
          console.log('err', e);
          this.areProductsVisible = false;
        } finally {
          this.areProductsLoading = false;
        }
      },
      hideProducts() {
        this.areProductsVisible = false;
        window.removeEventListener('scroll', () =>
          this.hideProducts.bind(this),
        );
      },
      hoverHandler() {
        if (device.isMobileSize()) {
          return;
        }

        if (
          this.item.itype === 'event_host_admin' ||
          this.item.itype === 'screen_shoprz' ||
          this.titleMain === 'Stores' ||
          this.titleMain === 'Followed stores' ||
          this.$route.query.title === 'Followed stores'
        ) {
          this.toggleStyle = true;
        }
      },
      isMaxAtt() {
        if (
          this.isEvent &&
          this.item.is_active &&
          this.item.is_live &&
          this.item.on_air
        ) {
          let att = 0;
          let limit = 0;

          rtm.on('stats', async (data) => {
            att = await data.msg[this.item.id];
          });

          rtm.on('license_package', async (data) => {
            if (data.room === this.item.id) {
              limit = await data.msg.attendees_limit;
            }

            if (att > limit) {
              this.isBlocked = true;
            }
          });
        }
      },
      async onClickProduct(product) {
        this.$emit('slide-click', product);
      },
      async onClick() {
        this.$emit('slide-click');

        this.$store.dispatch('vodPreview/hide');

        Config.set(
          'temp.context',
          location.href.indexOf('search') === -1 ? 'user_click' : 'search',
        );

        this.$stats.send(
          'object_click',
          { position: this.position },
          { item: this.item },
        );

        if (this.area?.dtype === 'promoted') {
          if (this.item.itype === 'item_mov_vod_shoprz') {
            const { head } = await amsClient.callAms(`/get_item/${this.item.id}/`);
            this.$router.push(head.page);
            return;
          }

          if (this.item.itype === 'item_epg_shop_event') {
            const { head: storefront } = await amsClient.callAms(`/shoprzapi/storefront/${this.item.storefront}`);
            const link = `/s/${storefront?.slug}${this.item.link}`;

            this.$router.push(link);
            return;
          }

          this.$router.push(`/${this.item.link}`);
          return;
        }

        if (this.item.itype === 'shop_product') {
          if (this.item.slug) {
            ReferalService.instance().setSource('carousel');
            this.$router.push(`/product/${this.item.slug}`);
          }
        }

        if (this.isEvent && this.item.is_live) {
          const { head: storefront } = await amsClient.callAms(`/shoprzapi/storefront/${this.item.storefront.id}`);
          //const link = `/s/${storefront?.slug}/l/${this.item.slug}`;
          const link = `/s/${storefront?.slug}`;
          this.$router.push(link);
          // this.$bus.$emit('ToggleScheduleEventDialog', this.item);
        }

        if (this.isEvent && !this.item.is_live) {
          this.$bus.$emit('ToggleScheduleEventDialog', this.item);
        }

        if (this.isBlocked) {
          this.$bus.$emit('toggleMessagePopup', { item: this.item });
        }
      },
      runPreview() {
        this.stopPreview();

        if (this.previewDelayTimer) {
          clearTimeout(this.previewDelayTimer);
        }

        this.previewDelayTimer = setTimeout(async () => {
          this.isHovered = true;
          const video = this.$refs.videoPreview;

          if (!video) {
            return;
          }

          try {
            if (!this.chachedPreviewMedia[this.item.id]) {
              if (
                this.item.itype === 'item_epg_shop_event' &&
                this.liveHasPromo &&
                !this.item.is_live
              ) {
                const promoVideo = await amsClient.callAms(
                  `/play/${this.item.promo_vod}`,
                  { cache: true },
                );

                this.chachedPreviewMedia[this.item.id] = {
                  link: this.getVideoLink('promo', promoVideo.head.mid),
                  isVertical: this.itemType !== 'horizontal',
                };
              } else if (this.item.itype === 'item_mov_vod_shoprz') {
                if (this.item.recorded) {
                  const recording = await amsClient.callAms(this.item.media);

                  this.chachedPreviewMedia[this.item.id] = {
                    link: recording.media[0].link,
                    isVertical: true,
                  };
                } else {
                  this.chachedPreviewMedia[this.item.id] = {
                    link: this.getVideoLink('vod', this.item.mid),
                    isVertical: this.item.media_is_vertical,
                  };
                }
              }
            }

            if (!this.isHovered) {
              return;
            }

            const chachedMedia = this.chachedPreviewMedia[this.item.id];

            if (chachedMedia) {
              this.hlsVideoService.bindVideo(
                video,
                chachedMedia.hasOwnProperty('link')
                  ? chachedMedia.link
                  : chachedMedia,
              );
            }
            this.isVertical = chachedMedia?.hasOwnProperty('isVertical')
              ? chachedMedia.isVertical
              : false;
            if (this.hlsVideoService.canPlay()) {
              await this.hlsVideoService.play(this.isMobile ? 1 : undefined);
            }
          } catch (e) {
            console.error('preview', e);
          }
        }, 500);
      },
      stopPreview() {
        this.isVertical = false;

        if (this.previewDelayTimer) {
          clearTimeout(this.previewDelayTimer);
        }

        this.hlsVideoService.clear();
      },
      getVideoLink(type, mid) {
        return this.playTemplate?.info[type].replaceAll('{MID}', mid);
      },
      async getFeaturedProducts() {
        try {
          if (this.item.itype === 'item_mov_vod_shoprz') {
            const data = await amsClient.callAms(`/get_item/${this.item.id}/`);

            this.featuredProducts = data?.head?.featured_products || [];
          }
        } catch (e) {
        }
      },
      imageLoaded() {
        this.showSpinner = false;
      },
      isShowLockIcon() {
        return this.item.itype === 'item_mov_vod_shoprz' && !amsClient.canPlay(this.item);
      },
      canPlay() {
        // console.log(11111, this.item.itype, amsClient.canPlay(this.item), this.item);
        return this.item.itype === 'item_mov_vod_shoprz' && amsClient.canPlay(this.item);
      },
    },
  };
</script>
<style lang="scss">
  .featured-products__item-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
</style>
<style lang="scss" scoped>
  .carousel-item-relative-wrapper {
    //min-height: 235px;
    min-width: 175px;
  }

  .carousel-item-wrapper {
    position: relative;
    text-align: left;

    .heartBtn {
      position: absolute;
      display: flex;
      top: 7px;
      right: 10px;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: $main-bg-color;
      background-image: none !important;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      z-index: 10;

      img {
        width: 20px;
        height: 18px;
        background-image: none !important;
      }

      &.vod-heartBtn {
        top: 75%;
      }
    }
  }

  .item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(47, 59, 69, 0),
        rgba(47, 59, 69, 1)
    );
    opacity: 0.5;
  }

  .item {
    position: relative;
    display: block;
    margin: 0 auto;
    min-width: 135px;

    &:hover {
      cursor: pointer;

      .item-image {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(#000, 0.1);
        }
      }

      .item-title {
        color: $main-color;
      }
    }

    &.vertical {
      &.large {
        max-width: 440px;

        .item-image {
          height: 440px;
          @media (max-width: 540px) {
            height: 420px;
          }
          @media (max-width: 470px) {
            height: 350px;
          }
          @media (max-width: 400px) {
            height: 330px;
          }
        }
      }

      &.medium {
        max-width: 330px;

        .item-image {
          height: 330px;
        }
      }

      &.small {
        max-width: 220px;

        .item-image {
          height: 220px;
        }
      }

      max-width: 159px;

      .item-image {
        height: 159px;
      }
    }

    &.circle {
      max-width: 135px;

      .item-image {
        width: 135px;
        height: 135px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #d4dbe0;
      }
    }

    &.horizontal {
      //max-width: 280px;

      @media (max-width: 480px) {
        max-width: 100%;
      }

      .item-image {
        height: 146px;

        @media (max-width: 480px) {
          height: 184px;
        }
      }

      &.large {
        max-width: 440px;

        .item-image {
          height: 260px;
          @media (max-width: 540px) {
            height: 220px;
          }
          @media (max-width: 470px) {
            height: 200px;
          }
        }
      }

      &.medium {
        max-width: 340px;

        .item-image {
          height: 195px;
        }
      }

      &.small {
        max-width: 230px;

        .item-image {
          height: 130px;
        }
      }
    }

    &-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 12px;
      font-size: 14px;
      overflow: hidden;
      border-radius: 10px;

      img.swiper-lazy:not(.sp-img-is-png) {
        background-image: url('../../../assets/logo/logo-shoprz.svg');
        background-position: 50% 50%;
        background-size: contain !important;
        background-color: rgba(#000, 0.1);
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;

        &.is-vertical {
          object-fit: contain;
          background-image: none !important;
          background-color: #000;
        }
      }
    }

    &-title {
      margin-bottom: 10px;
      line-height: 18px;
      font-weight: 600;
      font-size: 15px;
      color: $main-text-color;

      &.mobile-vod {
        position: relative;
        margin-top: -40px;
        padding: 0 20px;
        color: #fff;
      }

      &.product {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        white-space: normal;
      }
    }

    &-subtitle {
      margin-bottom: 10px;
      font-size: 13px;
      color: rgba(#343434, 0.3);
    }
  }

  .item-product-icon {
    position: absolute;
    bottom: 38px;
    cursor: pointer;
    right: 8px;
    background-color: white;
    padding: 0 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
    max-width: 43px;
    height: 26px;

    > svg {
      width: 100%;
    }

    &-count {
      font-size: 14px;
      margin-left: 5px;
      font-weight: 400;
    }

    &-close {
      background-color: #e2e6ee;
    }
  }

  .item-product-list-wrapper {
    position: absolute;
    width: calc(100% - 20px);
    bottom: 70px;
    left: 10px;
    height: 100px;
    display: flex;
    align-items: flex-end;
    z-index: 2;

    &-large {
      height: 150px;
    }

    @media (max-width: 480px) {
      height: 135px;
    }
  }

  .item-product-list {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;

    &__item {
      display: flex;
      align-items: center;
      padding: 5px 0;
      margin: 0 8px;
      border-bottom: 1px solid #f0f2f6;

      &:hover {
        color: $main-color;
      }

      &-text {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }

      &-poster {
        min-width: 24px;
        max-width: min-content;
        height: 24px;
        border-radius: 5px;
        margin-right: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .item-label {
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    padding: 0 8px;
    background-color: rgba($main-text-color, 0.5);
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    border-radius: 10px;

    &.logo {
      left: auto;
      right: 10px;
      width: 30px;
      height: 30px;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.warn {
      background-color: $product-discount;
    }
  }

  .item-price {
    font-size: 16px;
    line-height: 16px;
    color: $main-text-color;
  }

  .item-price-value {
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    &.original {
      margin-left: 4px;
      overflow: hidden;
      line-height: 13px;
      font-size: 13px;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to top right,
            transparent calc(50% - 2px),
            #e73e3f,
            transparent calc(50% + 1px)
        );
      }
    }
  }

  .follow-btn {
    display: block;
    width: 86px;
    margin: 0 auto;
    padding: 5px;
    border: none;
    background-color: $main-color;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #fff;

    &.active {
      background-color: #7f8095;
    }
  }

  .featured-products {
    padding: 10px 0;
    border-top: 1px solid $segment-border-color;

    &.mobile-vod {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 8px;
      box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.25);

      .featured-products__item {
        flex: 1;
        max-width: calc(33.333% - 8px);
        margin: 0 4px;
        padding-left: 0;

        &-poster {
          position: relative;
          width: 100%;
          height: 73px;
          margin-bottom: 6px;

          @media (max-width: 479px) {
            height: 115px;
          }

          @media (max-width: 375px) {
            height: 95px;
          }
        }

        h4 {
          line-height: normal;
          overflow: visible;
          white-space: normal;
        }

        .limited-text {
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .featured-products__item {
    position: relative;
    min-height: 36px;
    margin-bottom: 5px;
    padding-left: 46px;

    h4 {
      margin-bottom: 0;
      font-size: 13px;
      line-height: 36px;
      font-weight: 600;
      color: $main-text-color;
    }
  }

  .btn-follow {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 12px;
      color: #cacdd8;
    }
  }

  .carouselWrap {
    min-width: 100%;
    padding: 16px 20px 22px;
    transition: 0.3s transform;

    .card {
      border-radius: 10px;
    }

    &.shadowItem {
      background-color: #fff;
      position: absolute;
      box-shadow: 0px 0px 20px 5px rgba(151, 174, 202, 0.2);
      z-index: 10;
      transform: translateY(-3%);
      border-radius: 10px;
    }
  }

  .live-event-price {
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 10px;
    background: #e73e3f;
  }

  .live-event-price__icon {
    margin-right: 5px;
  }

  .live-event-price__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
  }

  .title-by-left {
    text-align: left !important;
  }
</style>