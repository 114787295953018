<template>
  <div
    v-if="isActive"
    class=""
  >
    <div class="row justify-content-center">
      <div>
        <h5
          v-for="(mess, index) in messages"
          :key="index"
          class="animated fadeIn  text-center mt-4 mb-4"
          style="color:#000;"
          :class="'message-' + mess.status"
          v-html="mess.text"
        />
        <div
          v-if="actions"
          class="text-center"
        >
          <template v-for="(text, method, index) in actions">
            <button
              v-if="_self[method]"
              :key="index"
              class="btn-danger btn"
              :class="'msg-btn-'+method"
              type="button"
              @click="_self[method]"
            >
              {{ text }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'MessageBlock',

    props: {
      isActive: {
        type: [Boolean, String],
        default: false,
      },
      defaultMessages: {
        type: String,
      },
      actions: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        timeToShowMessage: 4 * 1000,

        fix18n: {},
        messages: [],
      };
    },
    watch: {
      defaultMessages: {
        immediate: true,
        handler: function (newVal) {
          if (this.defaultMessages) {
            this.messages.splice(0, 1, {
              status: 'info',
              text: this.defaultMessages,
            });
          } else {
            this.messages = [];
          }
        },
      },
    },
    mounted() {
      this.fix18n = this.$i18n;
    },
    created() {
      // console.log(this.actions, this)
    },
    methods: {
      translateVal(val) {
        return this.fix18n.t(val);
      },

      close() {
        if (this.isActive) {
          this.$emit('update:isActive', false);
          // this.$body.closeModal();
        }
      },

      showMessage(messageData, time) {
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          this.messages.splice(pos, 1);
        }, time || this.timeToShowMessage);
      },
    },
  };
</script>