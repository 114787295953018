<template>
  <div
    class="sp-image-preview"
    @click="handleClose"
  >
    <div class="sp-image-preview-picture" @click.stop>
      <span @click.stop="handleClose">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.77976 1.28241C10.0734 0.988753 10.0734 0.513902 9.77976 0.223368C9.4861 -0.0671666 9.01125 -0.0702906 8.72071 0.223368L5.00312 3.94096L1.28241 0.220244C0.988753 -0.0734145 0.513902 -0.0734145 0.223368 0.220244C-0.0671666 0.513902 -0.0702906 0.988753 0.223368 1.27929L3.94096 4.99688L0.220244 8.71759C-0.0734145 9.01125 -0.0734145 9.4861 0.220244 9.77663C0.513902 10.0672 0.988753 10.0703 1.27929 9.77663L4.99688 6.05904L8.71759 9.77976C9.01125 10.0734 9.4861 10.0734 9.77663 9.77976C10.0672 9.4861 10.0703 9.01125 9.77663 8.72071L6.05904 5.00312L9.77976 1.28241Z"
            fill="#ffffff"
          />
        </svg>
      </span>
      <img :src="src" :alt="alt"/>
    </div>
  </div>
</template>

<script>
  export default  {
    name: 'ImagePreviewComponent',

    props: {
      src: {
        type: String,
        default: '',
      },
      alt: {
        type: String,
        default: '',
      },
    },

    methods: {
      handleClose() {
        this.$emit('close');
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sp-image-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    z-index: 1030;
    overflow: auto;

    &-picture {
      max-width: 820px;
      margin: auto;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      position: relative;

      @include lg {
        border-radius: 20px;
      }

      img {
        width: 100%;
      }

      span {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

</style>