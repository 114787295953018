<template>
  <b-modal
    v-model="showModal"
    centered
    scrollable
    :size="isMobile ? 'sm' : 'lg'"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="sp-live-finished-popup modal-rounded">
      <button class="close-btn" @click="simpleCloseModal">
        <img :src="require(`@/assets/icons/icon-close.svg`)" alt="">
      </button>
      <div class="content-wrap">
        <span class="sp-live-finished-popup-title">That live is finished</span>
      </div>
      <div class="btn-wrap">
        <button
          class="btn-wrap__button-accept"
          @click="acceptHandler"
        >
          Close
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Auth from '@/service/authService';
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'LiveFinishedPopup',
    data() {
      return {
        showModal: false,
        callFromFooter: false,
        isMobile: false,
      };
    },
    computed: {
    },
    watch: {
      showModal(val) {
        // this.$emit('change:modal', 'termOfUseModal', val);
      },
    },
    mounted() {
      this.checkWidth();
      this.$bus.$on('resizeWindow', this.checkWidth);
      this.$bus.$on('openLiveFinishedPopup', () => {
        // this.showAcceptButton = false;
        // this.callFromFooter = false;
        //
        // if (!isAcceptHidden){
        //   this.showAcceptButton = true;
        // };
        //
        // if (callFromFooter){
        //   this.callFromFooter = true;
        // };

        this.showModal = true;
      });
    },
    destroyed() {
      this.$bus.$off('openLiveFinishedPopup');
      this.$bus.$off('resizeWindow');
    },
    methods: {
      acceptHandler() {
        this.showModal = false;
        this.$router.go(-2);
      },
      checkWidth() {
        this.isMobile = window.innerWidth < 576;
      },
      simpleCloseModal() {
        this.showModal = false;
        this.$router.go(-2);
      },
      closeModal() {
        this.showModal = false;
      },
    },
  }
</script>

<style lang='scss' scoped>
  ::v-deep {
    .modal-body {
      //width: 400px;
      //height: calc(100vh - 80px);
    }

    .modal-dialog {
      overflow: hidden;
      margin-top: 0;
      width: 500px;
    }

    .modal-content {
      //width: 400px;
    }
  }

  .sp-live-finished-popup {
    position: relative;
    max-width: 450px;
    height: 165px;
    margin: auto;
    padding: 2.5rem 1.25rem;
    //padding: 50px 40px 40px;
    background-color: $button-white-txt-color;
    z-index: 1051;

    a {
      color: $modal-link-color;
      text-decoration: underline;
    }

    .close-btn {
      right: 3px;
      top: 1px;
      //width: 10px;
      //height: 10px;
      position: absolute;
      border: none;
      outline: none;

      img {
        width: 12px;
      }
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }

    .content-wrap {
      text-align: center;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      &__button-accept {
        font-weight: 500;
        font-size: 15px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 10px 50px;
        background-color: #21A66E;
        outline: none;
        border: none;
        color: $button-white-txt-color;

        &:disabled {
          filter: opacity(0.5);
        }
      }
    }
  }

  //@media (max-width: 550px) {
  //  ::v-deep {
  //    .modal-body {
  //      //height: calc(100vh - 40px);
  //    }
  //
  //    .modal-dialog {
  //      //margin: 0px 15px;
  //      //margin-top: 20px;
  //    }
  //  }
  //
  //  .term-of-use-popup {
  //    padding-right: 20px;
  //    padding-left: 20px;
  //    padding-top: 25px;
  //
  //    &__section {
  //      padding-right: 0px;
  //      margin-bottom: 0px;
  //      margin-top: 20px;
  //
  //      h2 {
  //        margin-bottom: 10px;
  //      }
  //    }
  //  }
  //}
</style>