import { amsClient } from '@/service/ams';

const state = () => ({
  items: Object.create(null),
  videos: Object.create(null),
  loading: true,
});

const getters = {
  getListArray(state) {
    return Object.values(state.items);
  },
  getVideosListArray(state) {
    return Object.values(state.videos);
  },
  getList(state) {
    return state.items;
  },
  getVideosList(state) {
    return state.videos;
  },
  getLoading(state) {
    return state.loading;
  },
};

const mutations = {
  setWishlist(state, items) {
    state.items = items.reduce((acc, i) => {
      acc[i.id] = i;
      return acc;
    }, Object.create(null));
    state.loading = false;
  },
  setVideoWishlist(state, items) {
    state.videos = items.reduce((acc, i) => {
      acc[i.id] = i;
      return acc;
    }, Object.create(null));
  },
};

const actions = {
  async getWishlist({ commit }) {
    const resposne = await amsClient.callAms('/stk/get/wishlist/');

    commit('setWishlist', resposne.items);
  },
  async getVideoWishlist({ commit }) {
    const resposne = await amsClient.callAms('/stk/get/watch_later/');
    commit('setVideoWishlist', resposne.items);
  },
  async addToWishlist({ dispatch }, id) {
    await amsClient.callAms(`/stk/wishlist/?pk=${id}&op=start`, { method: 'post' });
    await dispatch('getWishlist');
  },
  async addToVideoWishlist({ dispatch }, id) {
    await amsClient.callAms(`/stk/watch_later/?itype=item_mov_vod_shoprz&op=start&pk=${id}`, { method: 'post' });
    await dispatch('getVideoWishlist');
  },
  async removeFromWishlist({ dispatch }, id) {
    await amsClient.callAms(`/stk/wishlist/?pk=${id}&op=stop`, { method: 'post' });
    await dispatch('getWishlist');
  },
  async removeFromVideosWishlist({ dispatch }, id) {
    await amsClient.callAms(`/stk/watch_later/?itype=item_mov_vod_shoprz&op=stop&pk=${id}`, { method: 'post' });
    await dispatch('getVideoWishlist');
  },
  clear({ commit }) {
    commit('setWishlist', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
