export const parseLink = (text) => {
  const pattern = /\[([^\]]+)\]\(([^)]+)\)/;
    const matches = text.match(pattern);

    if (matches) {
      const title = matches[1];
      const href = matches[2];

      const fullUrl = href.startsWith('http://') || href.startsWith('https://') ? href : `http://${href}`;
      return text.replace(matches[0], `<a href="${fullUrl.trim()}" target="_blank">${title}</a>`)
    }

    return text;
}