<template>
  <div class="main-wrap">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <header>
      <router-link to="/">
        <div>
          <img :src="require('src/assets/logo/splash-header.png')" alt="market">
        </div>
      </router-link>
    </header>

    <section
      class="market"
      :style="{'backgroundImage': `url('${smallBreakpoint ? imagesLinks.splashHeaderMob : imagesLinks.splashHeader}')`}"
    >
      <div class="market__content">
        <img :src="require('src/assets/logo/splash-poster-header.png')" alt="market">

        <h2>
          Where Brands Come Alive
        </h2>

        <p>
          Shoprz was built for sharing stories, and we can’t wait to share yours.
          With a growing community of unique buyers and sellers,
          Shoprz offers you the opportunity to create livestream shows that entertain and educate your audience while
          interacting with them in real-time. Our suite of robust tools for sellers allows even small businesses to produce
          TV-quality productions that will draw your audience in and keep them coming back.
        </p>

        <p class="bolder">
          Fill out our quick, 5-step application to request to join our group of live selling retailers.
        </p>

        <div class="controls">
          <button class="apply-button apply-button--desktop" @click="openPopup">
            Apply to Start Selling Now
          </button>

          <button class="apply-button apply-button--mobile" @click="openPopup">
            Become a seller
          </button>

<!--          <a class="apply-button apply-button&#45;&#45;desktop" :href="toSellerLink" target="_blank">
            Apply to Start Selling Now
          </a>

          <a class="apply-button apply-button&#45;&#45;mobile" :href="toSellerLink" target="_blank">
            Become a seller
          </a>-->

          <a href="#" @click="scrollToPricing($event)">
            View Pricing Plans
          </a>
        </div>
      </div>
    </section>

    <section class="audience">
      <div
        class="audience__picture audience__picture--1"
        :style="{'backgroundImage': `url('${imagesLinks.firstRoundImage}')`}"
      >
        <div class="little-circle little-circle--left">
          <img src="../../assets/icons/little-circle-frame.svg" alt="frame">
        </div>
      </div>

      <div class="audience__content">
        <h3>
          Expand your Audience
        </h3>

        <p>
          Selling live means your audience can connect with you from all over the world.
          Shoprz is the hub for buyers and sellers to have a social shopping experience like none other.
          Come together to share products and grow connections—it's not just a sales channel, it’s a movement!
        </p>
      </div>
    </section>

    <section class="experience">
      <div class="experience__wrap">
        <div class="experience__content">
          <h3>
            A High-Level Selling Experience
          </h3>

          <p>
            From a single phone camera to multiple cameras and remote hosting capabilities,
            Shoprz allows you to create a professional broadcast for your audience.
            Plus, our easy-to-use platform means you can go live in under four minutes from your phone or studio.
            While you present, you can share interactive buttons so your audience can engage with you and purchase your
            products effortlessly – without ever leaving your show!
          </p>
        </div>

        <div class="experience__picture">
          <div class="little-circle little-circle--right">
            <img src="../../assets/icons/little-circle-ping.svg" alt="frame">
          </div>
        </div>
      </div>
    </section>

    <section class="audience">
      <div
        class="audience__picture audience__picture--2"
        :style="{'backgroundImage': `url('${imagesLinks.secondRoundImage}')`}"
      >
        <div class="little-circle little-circle--left">
          <img src="../../assets/icons/little-circle-search.svg" alt="frame">
        </div>
      </div>

      <div class="audience__content">
        <h3>
          Powerful Analytics
        </h3>

        <p>
          Once you’ve finished streaming, you get access to in-depth analytics about your audience and their purchasing patterns.
          This gives you a chance to celebrate your successes and make data-driven plans for your next stream.
        </p>

        <button class="apply-button apply-button--desktop" @click="openPopup">
          Apply to Start Selling Now
        </button>

        <button class="apply-button apply-button--mobile" @click="openPopup">
          Become a seller
        </button>

<!--        <a class="apply-button apply-button&#45;&#45;desktop" :href="toSellerLink" target="_blank">
          Apply to Start Selling Now
        </a>

        <a class="apply-button apply-button&#45;&#45;mobile" :href="toSellerLink" target="_blank">
          Become a seller
        </a>-->
      </div>
    </section>

    <section class="how-work">
      <div class="how-work__content">
        <h3>
          How It Works
        </h3>

        <ul class="how-work__content--ul">
          <li>
            <div class="li-content">
              <h4>
                subscription fee
              </h4>

              <div class="icon icon--open"/>

              <p>
                <!-- For a limited time, our Starter Package can be purchased for just $1/month -->
                Select a subscription package that fits your needs. Or jump-start with the 30-day free trial of our Starter package first and figure it out later!
              </p>
            </div>
          </li>

          <li>
            <div class="li-content">
              <h4>
                sales commissions
              </h4>

              <div class="icon icon--commission"/>

              <p>
                Shoprz receives commissions on your sales ranging from 8-15%
              </p>
            </div>
          </li>

          <li>
            <div class="li-content">
              <h4>
                payout schedule
              </h4>

              <div class="icon icon--payout"/>

              <p>
                Payouts are initiated on the 1st, 11th and 21st
                day of each month for products delivered and not returned
                during the 10-day return period.
              </p>
            </div>
          </li>

          <li>
            <div class="li-content">
              <h4>
                credit card fee
              </h4>

              <div class="icon icon--credit"/>

              <p>
                2.6% of total purchase amount, plus 30-cents
                per item (non-refundable).
              </p>
            </div>
          </li>

          <li>
            <div class="li-content">
              <h4>
                sales tax
              </h4>

              <div class="icon icon--tax"/>

              <p>
                Shoprz will collect and remit sales tax on seller's behalf
              </p>
            </div>
          </li>

          <li>
            <div class="li-content">
              <h4>
                return policy
              </h4>

              <div class="icon icon--policy"/>

              <ul>
                <li>
                  10-day return period on most items,
                  starting on delivery date or no return.
                  Your choice as seller.
                </li>

                <li>
                  Items returned at customer's expense
                </li>

                <li>
                  Initial shipping charge is non-refundable
                  to the customer
                </li>
              </ul>
            </div>
          </li>

          <li class="wide">
            <h4>
              minimum service standards
            </h4>

            <div class="wide__content">
              <div>
                <span class="icon icon--fulfillment"/>

                <div>
                  <h5>
                    fulfillment
                  </h5>

                  <p>
                    within 2 business days of order placement
                  </p>
                </div>
              </div>

              <div>
                <span class="icon icon--ticket"/>

                <div>
                  <h5>
                    ticket/inquiry response
                  </h5>

                  <p>
                    within 1 business day of receipt
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section
      id="pricing"
      class="pricing"
      :style="{'backgroundImage': `url('${imagesLinks.pricingBackground}')`}"
    >
      <div class="pricing__content">
        <div class="description">
          <h3>
            Pricing Packages
          </h3>

          <h4>
            Features
          </h4>

          <ul class="description__features">
            <li>
              Product Sales Commission
            </li>
            <li>
              Commission Rate Discount
            </li>
            <li>
              Max Products in Active Inventory
            </li>
            <li>
              Max Events per Month
            </li>
            <li>
              Max Attendees per Event
            </li>
            <li>
              Max Event Duration (min)
            </li>
            <li>
              Co-presenters Support
            </li>
            <li>
              Max Number of Videos
            </li>
            <li>
              Chats & Comments During Event
            </li>
            <li>
              Custom Storefront
            </li>
            <li>
              Access to Help Desk
            </li>
          </ul>
        </div>

        <div class="table">
          <div class="table__column table__column--early">
            <h5>
              Starter
            </h5>

            <span>
              $10
            </span>
            <span>
              USD/mo
            </span>

            <p>
              Best for ecommerce businesses that are just getting started.
            </p>

            <ul>
              <li>
                15%
              </li>
              <li>
                0%
              </li>
              <li>
                5
              </li>
              <li>
                2
              </li>
              <li>
                100
              </li>
              <li>
                15
              </li>
              <li class="cancel">
<!--                15%-->
              </li>
              <li>
                5
              </li>
              <li class="cancel">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
            </ul>
          </div>

          <div class="table__column table__column--starter">
            <h5>
              Advanced
            </h5>

            <span>
              $20
            </span>
            <span>
              USD/mo
            </span>

            <p>
              Best for established brands who plan to host large-scale live events.
            </p>

            <ul>
              <li>
                8-15%
              </li>
              <li>
                5%
              </li>
              <li>
                20
              </li>
              <li>
                4
              </li>
              <li>
                500
              </li>
              <li>
                30
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li>
                15
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
            </ul>
          </div>

          <div class="table__column table__column--pro">
            <h5>
              Pro
            </h5>

            <span>
              $50
            </span>
            <span>
              USD/mo
            </span>

            <p>
              Best for companies with large
              product catalogs and an established audience
            </p>

            <ul>
              <li>
                8-15%
              </li>
              <li>
                10%
              </li>
              <li>
                100
              </li>
              <li>
                8
              </li>
              <li>
                5,000
              </li>
              <li>
                45
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li>
                50
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
            </ul>
          </div>

          <div class="table__column table__column--enterprise">
            <h5>
              Enterprise
            </h5>

            <span>
              $100
            </span>
            <span>
              USD/mo
            </span>

            <p>
              Best for large-scale organizations
              with several co-presenters
            </p>

            <ul>
              <li>
                8-15%
              </li>
              <li>
                15%
              </li>
              <li>
                250
              </li>
              <li>
                35
              </li>
              <li>
                10,000
              </li>
              <li>
                120
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li>
                125
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
              <li class="check">
<!--                15%-->
              </li>
            </ul>
          </div>
        </div>

        <div
          class="swiper"
          :centeredSlides="true"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide table__column table__column--early">
              <h5>
                Starter
              </h5>

              <span>
                $10
              </span>
              <span>
                USD/mo
              </span>

              <p>
                Best for ecommerce businesses that are just getting started.
              </p>

              <ul>
                <li>
                  15%
                </li>
                <li>
                  0%
                </li>
                <li>
                  5
                </li>
                <li>
                  2
                </li>
                <li>
                  100
                </li>
                <li>
                  15
                </li>
                <li class="cancel">
                  15%
                </li>
                <li>
                  5
                </li>
                <li class="cancel">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
              </ul>
            </div>

            <div class="swiper-slide table__column table__column--starter">
              <h5>
                Advanced
              </h5>

              <span>
                $20
              </span>
              <span>
                USD/mo
              </span>

              <p>
                Best for established brands who plan to host large-scale live events.
              </p>

              <ul>
                <li>
                  8-15%
                </li>
                <li>
                  5%
                </li>
                <li>
                  20
                </li>
                <li>
                  4
                </li>
                <li>
                  500
                </li>
                <li>
                  30
                </li>
                <li class="check">
                  15%
                </li>
                <li>
                  15
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
              </ul>
            </div>

            <div class="swiper-slide table__column table__column--pro">
              <h5>
                Pro
              </h5>

              <span>
                $50
              </span>
              <span>
                USD/mo
              </span>

              <p>
                Best for companies with large
                product catalogs and an established audience
              </p>

              <ul>
                <li>
                  8-15%
                </li>
                <li>
                  10%
                </li>
                <li>
                  100
                </li>
                <li>
                  8
                </li>
                <li>
                  5,000
                </li>
                <li>
                  45
                </li>
                <li class="check">
                  15%
                </li>
                <li>
                  50
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
              </ul>
            </div>

            <div class="swiper-slide table__column table__column--enterprise">
              <h5>
                Enterprise
              </h5>

              <span>
                $100
              </span>
              <span>
                USD/mo
              </span>

              <p>
                Best for large-scale organizations
                with several co-presenters
              </p>

              <ul>
                <li>
                  8-15%
                </li>
                <li>
                  15%
                </li>
                <li>
                  250
                </li>
                <li>
                  35
                </li>
                <li>
                  10,000
                </li>
                <li>
                  120
                </li>
                <li class="check">
                  15%
                </li>
                <li>
                  125
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
                <li class="check">
                  15%
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="questions">
      <div class="questions__content">
        <h3>
          More Questions?
        </h3>

        <ul ref="accordion">
          <li :class="{active: accordionState.accordion1}">
            <p>
              Do I need special equipment to do a live event?
            </p>
            <p>
              All you need is an internet connection and a mobile device or computer with a camera and microphone.
            </p>
            <div
              class="arrow"
              data-accordion="accordion1"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion2}">
            <p>
              Can I customize my storefront and product pages?
            </p>
            <p>
              Of course! You choose your unique store name and your store description.
              Your brand logo as well as images of your products will proudly be displayed on your storefront.
              A banner also appears at the top of your store,
              which you can use to display images to define your store or show off products.
            </p>
            <div
              class="arrow"
              data-accordion="accordion2"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion3}">
            <p>
              What kind of analytics will I be able to access?
            </p>
            <p>
              During your live events, you can view real-time statistics like your viewers,
              clicks, and purchases in both graphical and numerical formats.
              You can also review these statistics when your event is finished.
              On the eCommerce side, you’ll be able to view your total sales,
              total orders, and the total products sold during any period.
              Several reports are also available on the platform,
              including Sales by SKU, Sales by Order, and Sales by Customer.
            </p>
            <div
              class="arrow"
              data-accordion="accordion3"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion4}">
            <p>
              What are the commissions and fees at Shoprz?
            </p>
            <p>
              Sellers pay a Category Fee (sales commission) for each product sold at Shoprz.
              The Category Fee Percentage ranges from 8-15%, depending on the product category.
              Sellers also pay a Merchant Processing Fee equal to 2.6% of the total purchase amount, plus 30-cents per item.
            </p>
            <div
              class="arrow"
              data-accordion="accordion4"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion5}">
            <p>
              What is the return and exchange policy at Shoprz?
            </p>
            <p>
              Generally, buyers may return an item purchased from Shoprz within 10 days of delivery,
              as determined by the item’s tracking number.
              All items must be returned in new condition (as originally shipped),
              and are subject to the Seller’s inspection upon receipt.  Original shipping costs are non-refundable,
              and the buyer is responsible for paying the shipping costs to return an item to the seller.
              Sellers may designate certain items as Non-Returnable (final),
              and these items may not be returned.
            </p>
            <div
              class="arrow"
              data-accordion="accordion5"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion6}">
            <p>
              How are sales taxes collected at Shoprz?
            </p>
            <p>
              Shoprz uses a third-party application to calculate the sales tax for each product
              sold within the United States and these taxes are collected from the buyer at checkout.
              In accordance with jurisdictional laws, Shoprz will remit the sales taxes to the appropriate tax authorities,
              and there is no additional processing fee charged to you for this service.
            </p>
            <div
              class="arrow"
              data-accordion="accordion6"
              @click="toggleAccordion($event)"
            />
          </li>
          <li :class="{active: accordionState.accordion7}">
            <p>
              Can I cancel at any time?
            </p>
            <p>
              If at any time you want to stop selling on Shoprz,
              you can cancel your membership and your account will
              automatically close at the end of your current billing period.
            </p>
            <div
              class="arrow"
              data-accordion="accordion7"
              @click="toggleAccordion($event)"
            />
          </li>
        </ul>
      </div>
    </section>

    <footer
      :style="{'backgroundImage': `url('${largeBreakpoint ? imagesLinks.splashFooterMob : imagesLinks.splashFooter}')`}"
    >
      <div class="wrap">
        <div class="footer-content">
          <h2>
            Join Shoprz’s selling community
          </h2>
          <p>
            *For a limited time, Shoprz is available starting at $1/month.
          </p>
          <p>
            Fill out our quick, 5-step application to request to join our group of live selling retailers.
          </p>

          <button class="apply-button apply-button--desktop" @click="openPopup">
            Apply to Start Selling Now
          </button>

          <button class="apply-button apply-button--mobile" @click="openPopup">
            Become a seller
          </button>

<!--          <a class="apply-button apply-button&#45;&#45;desktop" :href="toSellerLink" target="_blank">
            Apply to Start Selling Now
          </a>

          <a class="apply-button apply-button&#45;&#45;mobile" :href="toSellerLink" target="_blank">
            Become a seller
          </a>-->
        </div>
      </div>
    </footer>

    <div class="popup-backplate" v-if="isShowSellerSelectTypePopup" @click="closePopup"></div>

    <div class="seller-select-type-popup popup" v-if="isShowSellerSelectTypePopup">
      <div class="popup__title">
        Start selling on Shoprz!
      </div>

      <div class="popup__description">
        First, let us know your selling strategy.
      </div>

      <div class="popup__content">
        <a :href="toSellerLink" class="popup__item" target="_blank">
          <img :src="require('src/assets/images/brand.png')" alt="" class="popup__item-image">

          <div class="popup__item-content">
            <div class="popup__item-title">
              Brands & Vendors
            </div>

            <div class="popup__item-description">
              You own the products and offer shoppers
              a curated selection.
            </div>
          </div>
        </a>

        <a :href="toCreatorLink" class="popup__item" target="_blank">
          <img :src="require('src/assets/images/content-creator.png')" alt="" class="popup__item-image">

          <div class="popup__item-content">
            <div class="popup__item-title">
              Content Creators
            </div>

            <div class="popup__item-description">
              You find products that already exist and
              offer them on Shoprz.
            </div>
          </div>
        </a>
      </div>

      <div class="popup__close-button" @click="closePopup">
        Close
      </div>
    </div>
  </div>
</template>

<script>
  import Config from '@/service/config';
  import { amsClient } from '@/service/ams';
  import device from 'src/service/device-service';
  import Swiper from 'swiper';

  export default {
    name: 'SellerSplash',
    data() {
      return {
        model: null,
        toSellerLink: '',
        toCreatorLink: '',
        swiper: null,
        accordionState: {
          accordion1: false,
          accordion2: false,
          accordion3: false,
          accordion4: false,
          accordion5: false,
          accordion6: false,
          accordion7: false,
        },
        isShowSellerSelectTypePopup: false,
        imagesLinks: {
          splashHeader: 'https://production-verb-vod.vidnt.net/posters/verb.131581.cust.original.png',
          splashFooter: 'https://production-verb-vod.vidnt.net/posters/verb.131571.cust.original.png',
          splashHeaderMob: 'https://production-verb-vod.vidnt.net/posters/verb.132091.cust.original.png',
          splashFooterMob: 'https://production-verb-vod.vidnt.net/posters/verb.132081.cust.original.png',
          firstRoundImage: 'https://production-verb-vod.vidnt.net/posters/verb.131561.cust.original.png',
          secondRoundImage: 'https://production-verb-vod.vidnt.net/posters/verb.131551.cust.original.png',
          pricingBackground: 'https://production-verb-vod.vidnt.net/posters/verb.131591.cust.original.png',
        },
        metadata: {
          title: 'Become a Seller',
        },
      }
    },
    computed: {
      isMobile() {
        return device.isAnyMobile();
      },
      smallBreakpoint() {
        return window.innerWidth <= 600;
      },
      largeBreakpoint() {
        return window.innerWidth <= 992;
      },
    },
    created() {
      this.setImageResolution();
    },
    mounted() {
      this.$stats.send('page_opened', {
        page_type: 'seller-splash',
      });
      Config.loaded(() => {
        this.toSellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
        this.toCreatorLink = amsClient.get('conf.portal.marketplace.shopify.become_creator_url');
      });

      this.swiper = new Swiper('.swiper', {
        direction: 'horizontal',
        loop: false,
        breakpoints: {
          375: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      });

      this.$bus.$emit('toggleHeaderVisibility', {
        isHide: false,
      });

      this.$bus.$emit('removeAppMarginTop', 'offMarginTop');
    },
    destroyed() {
      this.$bus.$emit('toggleHeaderVisibility', {
        isHide: true,
      });

      this.$bus.$emit('removeAppMarginTop', '');
    },
    methods: {
      setImageResolution() {
        let resolution;

        for (const [img, url] of Object.entries(this.imagesLinks)) {
          const needsHighResolution = ['splashHeader', 'splashFooter', 'splashHeaderMob', 'splashFooterMob'].includes(img);
          if (this.isMobile) {
            resolution = 'SD';
          } else {
            resolution = needsHighResolution ? 'FHD' : 'HD';
          }

          this.imagesLinks[img] = url.replace("original", resolution);
        }
      },
      toggleAccordion(e) {
        for (let a in this.accordionState){
          if(e.target.dataset.accordion === a) {
            this.accordionState[e.target.dataset.accordion] = !this.accordionState[e.target.dataset.accordion];

            continue;
          };

          this.accordionState[a] = false;
        };
      },
      findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
          do {
            curtop += obj.offsetTop;
          } while (obj = obj.offsetParent);
          return [curtop];
        }
      },
      scrollToPricing(e) {
        e.preventDefault();

        window.scroll(0, this.findPos(document.getElementById("pricing")));
      },
      openPopup() {
        this.isShowSellerSelectTypePopup = true;
      },
      closePopup() {
        this.isShowSellerSelectTypePopup = false;
      },
    },
  }
</script>

<style scoped lang='scss'>
  @import './SellerSplash.scss';
</style>
