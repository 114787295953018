<template>
  <div class="sp-shipping-info" :class="{ 'sp-desktop': !isMobile }">
    <div class="sp-shipping-info--header">
      <div class="sp-shipping-info--header-title">Shipping Info</div>
      <div class="sp-shipping-info--header-change" @click="$emit('change')">
        Change
      </div>
    </div>
    <div class="sp-shipping-info--body">
      <div v-for="(line, index) in lines" :key="index">
        {{ line }}
      </div>
    </div>
  </div>
</template>

<script>
  import OrderDetailsComponent from './order-details.component';
  import ProductListComponent from './product-list.component';
  import FormInputComponent from 'src/shared/components/form-components/form-input.component';

  export default {
    name: 'ShippingInfo',

    components: {
      ProductListComponent,
      OrderDetailsComponent,
      FormInputComponent,
    },

    props: {
      isMobile: {
        type: Boolean,
        default: false,
      },

      // cost: {
      //   type: Object,
      //   default: () => ({}),
      // },
      //
      lines: {
        type: Array,
        default: () => [],
        require: true,
      },
    },

    data() {
      return {
        discount: '',
      };
    },
  };
</script>

<style lang="scss" scoped>
  .sp-shipping-info {
    color: #343434;
    font-size: 15px;

    &--header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: 500;
      }
      &-change {
        cursor: pointer;
        font-weight: 400;
        color: #4b89e5;
      }
    }

    &.sp-desktop {
      border: 1px solid #f0f2f7;
      border-radius: 10px;
      padding: 1rem;
      //padding: 32px 24px 24px;

      .sp-summary--details {
        order: 2;
      }

      .sp-summary--products {
        order: 0;
      }

      .sp-summary--discount {
        order: 1;
      }
    }
  }

  .sp-summary {
    display: flex;
    flex-direction: column;

    &--discount {
      margin: 24px 0px;

      &-wrapper {
        display: flex;
        align-items: center;
        margin: 16px 0px;
      }

      &-input {
        flex: 1;
        margin-right: 8px;
      }

      &-submit {
        width: 96px;
        height: 44px;
        outline: none;
        border: 1px solid #f0f2f7;
        border-radius: 5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #343434;
      }
    }

    .sp-separator {
      height: 1px;
      background-color: #f0f2f7;
      width: 100%;
      display: block;
    }
  }
</style>
