<template>
  <div class="auth-actions" :class="{ 'open': isMobileOpen }">
    <div class="auth-actions__desktop">
      <div class="cart-btn" @click="handleClick()">
        <!--        TODO Replace it to FAS PRO ICONS-->
        <img
          :width="14"
          :height="16"
          :src="require(`@/assets/icons/mobile-menu/${iconName}.png`)"
        >
        <span  :class="{ 'active-btn': isActiveNav }">
          {{ !isFullAuth ? 'Sign In' : 'Account' }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import Config from '@/service/config';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import { amsClient } from '@/service/ams';
  import Auth from '@/service/authService';
  import device from '@/service/device-service.js';

  export default {
    name: 'AuthActions',
    mixins: [AuthVariableMixin],
    props: {
      isConfLoaded: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        sellerLink: '',
        isMobileOpen: false,
        user: Auth.get('user'),
      };
    },
    computed:{
      isActiveNav() {
        return this.$route.path.startsWith('/account');
      },
      iconName() {
        return this.isActiveNav ? `account-solid` : 'account';
      },
      username(){
        return this.user.name || this.user.username
      },
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
    },
    watch: {
      user(user) {
        if (user && !user.guest) {
          this.$bus.$emit('toggleAuthPopup');
        }
      },
    },
    mounted() {
      Config.loaded(() => {
        this.sellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
      });
      this.$bus.$on('ToggleMobileAuthActions', (data) => {
        this.isMobileOpen = data;
      });
      amsClient.on('auth_state_change', () => this.user = Auth.get('user'));
    },
    destroyed() {
      this.$bus.$off('ToggleMobileAuthActions');
    },
    methods: {
      handleClick() {
        this.$bus.$emit('ChatWidgetClose');
        if (!this.isFullAuth) {
          return this.showAuthPopup()
        }
        return this.$router.push('/account')
      },
      showAuthPopup() {
        this.$emit('showAuthPopup');
      },
      logout() {
        this.isMobileOpen = false;
        this.$emit('logout');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .auth-actions {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 180px;
    height: 100%;
    //padding: 0 32px;

    @media(max-width: 767px) {
      padding: 0 0;
    }

    .dropdown-link {
      padding: 13px 15px;

      &:not(:last-child) {
        border-bottom: 1px solid #f0f4fb;
      }
    }

    /*@media (max-width: $md-breakpoint) {
      display: none;
      position: fixed;
      top: 55px;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      height: auto;
      padding: 0;

      &.open {
        display: flex;
        height: calc(100vh - 50px);
      }
    }*/

    &__btn,
    &::v-deep .dropdown-toggle {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 0;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      transition: color 0.2s ease-in-out;

      &:hover {
        //color: $main-color;
      }

      &::after {
        display: none !important;
      }

      span {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    &::v-deep .dropdown-menu {
      width: 210px;
      padding: 0;
      background-color: $background-color;
      border-radius: 10px;
      border: 1px solid #f0f4fb;
      box-shadow: none;
      outline: none;
      overflow: hidden;

      .dropdown-divider {
        margin: 0 0 10px;
        border-top-color: #DAE0EB;
      }

      a {
        position: relative;
        display: block;
        padding: 8px 0 8px 25px;
        background-color: transparent !important;
        line-height: 16px;
        font-size: 16px;
        font-weight: normal;
        color: #343434;

        .dropdown-item-icon--hover {
          display: none;
        }

        &:hover {
          color: #21a66e;

          .dropdown-item-icon {
            display: none;
          }

          .dropdown-item-icon--hover {
            display: block;
          }
        }

        &.account {
          padding: 12px 10px 12px 25px;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }

        img,
        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        i {
          left: auto;
          right: 0;
          width: 6px;
          font-size: 12px;
        }
      }
    }

    &::v-deep .dropdown-item {
      padding: 0 0 0 25px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    &__desktop {
      /*@media (max-width: $md-breakpoint) {
        display: none;
      }*/
    }

    &__mobile {
      display: none;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 50px);
      padding: 0 20px;
      background-color: #fff;

      @media (max-width: $md-breakpoint) {
        display: flex;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      min-height: 65px;
      font-size: 24px;
      font-weight: 600;
      color: $main-text-color;

      i {
        margin-right: 15px;
        font-size: 16px;
      }
    }

    &__user {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      margin-bottom: 5px;
      padding: 25px 16px 25px 74px;
      background-color: $block-background-color;
      line-height: 11px;

      .logo {
        position: absolute;
        top: 19px;
        left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #aaa;
        overflow: hidden;
      }

      .name {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        color: $main-text-color;
      }

      .email {
        font-size: 14px;
        font-weight: 500;
        color: #919BAA;
      }
    }

    &__links {
      flex: 1;
      overflow-y: auto;

      a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        padding: 14px 0;
        border-bottom: 1px solid #E6EBF2;
        font-size: 15px;
        line-height: 32px;
        font-weight: 500;
        color: $main-text-color !important;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__logout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-bottom: 30px;
      padding-left: 25px;
      padding-right: 20px;
      background-color: #F3F5F9;
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      color: $main-text-color !important;
    }
  }
</style>
