import isInIframe from '@/utils/isInIframe';

export default [
  {
    name: 'video-id',
    path: '/s/:store/v/:id',
    component: () => import('./video.view'),
    beforeEnter: (to, from, next) => {
      if (isInIframe()) {
        next({ name: 'FullScreenVod', params: { id: to.params.id } });
      } else {
        next();
      }
    },
  },
];
