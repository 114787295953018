<template>
  <div class="form-autocomplete">
    <vue-bootstrap-autocomplete
      :id="id"
      v-model="input"
      :data="data"
      :serializer="serializer"
      :highlight-class="''"
      :placeholder="placeholder"
      input-class="form-autocomplete-input"
      :show-all-results="true"
      @input="onInput"
      @hit="onSelect"
    >
      <template #suggestion="{ data }">
        <slot name="default" :option="data"></slot>
      </template>
    </vue-bootstrap-autocomplete>
  </div>
</template>

<script>
  import { debounce } from 'lodash';

  export default {
    name: 'FormAutoCompleteComponent',

    props: {
      value: {
        type: String,
        default: '',
      },
      serializedField: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      data: {
        type: Array,
        default: () => [],
      },
      id: {
        type: String,
        required: true,
      },
    },

    computed: {
      input: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },

      serializer() {
        return (item) => {
          if (!this.serializedField) {
            return item;
          }

          return item[this.serializedField];
        }
      },

      debouncedInput() {
        return debounce((val) => this.$emit('typing', this.value), 300);
      },
    },

    mounted() {
      // const dropdownBody = this.getDropdownBody();
      // dropdownBody?.addEventListener('scroll', this.onScroll.bind(this));
    },
    beforeDestroy() {
      // const dropdownBody = this.getDropdownBody();
      // dropdownBody?.removeEventListener('scroll', this.onScroll.bind(this));
    },

    methods: {
      getDropdownBody() {
        const el = document.getElementById(this.id);
        if (!el) {
          return;
        }
        return el.getElementsByClassName('list-group')?.[0];
      },
      onScroll(event) {
        const { scrollHeight, scrollTop, clientHeight } = event.target;

        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
          console.log('scrolled');
        }
      },
      onInput() {
        this.debouncedInput();
      },
      onSelect(option) {
        this.$emit('select', option);
      },
    },
  };
</script>

<style lang="scss">
  .form-autocomplete {
    input {
      padding: 0px 20px;
      width: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      border: 1px solid #edf1f4;
      border-radius: 7px;
      outline: none;
      height: 52px;
    }
  }

  .form-autocomplete-input {
    color: black;
    padding: 25px 20px;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    border: 1px solid #edf1f4;
    border-radius: 7px;
    outline: none;
    &:focus {
      border: 1px solid #edf1f4;
      color: black;
      box-shadow: none;
    }
  }

  .vbt-autocomplete-list {
    background-color: white;

    .list-group-item {
      border: none;
      padding: 0.5rem;
    }
  }
</style>
