export default `
  mutation draftOrderCreate($input: DraftOrderInput!) {
  draftOrderCreate(input: $input) {
    draftOrder {
        id
        name
        taxesIncluded
        taxExempt
        taxLines {
            title
            priceSet {
                shopMoney {
                    amount
                    currencyCode
                }
            }
        }
        totalTax
        totalTaxSet {
            shopMoney {
                amount
                currencyCode
            }
        }
        totalPrice
        shippingAddress {
            address1
            city
            country
            zip
            address2
            countryCodeV2
        }
        billingAddress {
            address1
            city
            country
            zip
            address2
            countryCodeV2
        }
        lineItems(first: 10) {
            edges {
                node {
                    originalTotal
                }
            }
        }
        customAttributes {
            key
            value
        }
    }
  }
}
`;
