import axios from 'axios';
import { amsClient } from '@/service/ams';

/**
 * Makes an HTTP request using Axios.
 *
 * @param {string} method - The HTTP method (e.g., 'GET', 'POST', 'PUT', 'DELETE').
 * @param {string} path - The API endpoint path.
 * @param {Object} body - The request body data (if any).
 * @param {Object} [options] - Additional request options for Axios.
 * @returns {Promise} - A promise that resolves to the Axios response object.
 */
const makeRequest = (method, path, body, options = {}) => {
  const headers = {
    authorization: `Bearer ${amsClient.get('user')?.sid}`,
    source: 'portal',
  }

  const url = [process.env.VUE_APP_API_GATEWAY_BASE_URL, path].join('/');

  return axios[method](url, body, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  })
}

export default makeRequest;