<template>
  <div 
    class="form-group auth-form-group auth-pass form-input-auth"
    :class="error && 'error'"
  >
    <input
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      autocomplete="new-password"
      class="form-control"
      :class="[error && 'is-invalid', isActiveFeature('box_shadow_error_style') && 'box-shadow-error']"
      @input="$emit('input', $event.target.value.trim()), $emit('checkFields')"
      @blur="$emit('validate'), $emit('toggleLabelDown')"
      @keypress="$emit('validate')"
      @focus="$emit('toggleLabelUp')"
    >
    <label 
      :class="{'float-label': toggleLabel}" 
      class="form-group__label" 
      :for="name"
    >
      <span 
        v-t="{path: label}" 
        :class="{'error-label-color': errorLabel, 'toggle-label-color': labelColor}"
      />
    </label>
    <slot/>
  </div>
</template>

<script>
  export default {
    name: "FormInput",
    props: {
      label: { default: '' },
      type: { default: 'text' },
      name: { required: true },
      value: { required: true },
      error: { required: true },
      toggleLabel: {default: false},
      errorLabel:{default: false},
      labelColor:{default: false},
    },

  };
</script>
<style lang="scss">
	.form-group{ 

    &__label{
      transition: ease-in 0.1s;
    }
  }

  .form-input-auth {
    border-bottom: 1px solid #979797;

    &:hover {
      border-bottom: 1px solid $main-color;
    }
  }

  .error {
    border-bottom: none;
  }

	@media screen and (max-width: 1440px) {
		.form-group {
			margin-bottom: 0.6rem !important;
		}
	}
</style>
