<template>
  <div>
    <password-component
      v-model="form.password"
      placeholder="Password"
      :error-message="passwordErrorMessage"
      style="margin-bottom: 0.75rem; margin-top: 0.5rem"
      @input="$v.form.password.$touch()"
    />
    <password-component
      v-model="form.confirmPassword"
      placeholder="Repeat password"
      :error-message="confirmPasswordErrorMessage"
      style="margin-bottom: 0.5rem"
      @input="$v.form.confirmPassword.$touch()"
    />

    <div style="display: flex; margin-top: 1rem; margin-bottom: 0.5rem">
      <checkbox-component
        v-model="form.isChecked"
        style="margin-right: 0.5rem"
      />
      <span style="font-size: 15px">
        I subscribe to special offers newsletter according to the
        <router-link class="terms-wrapper-link" :to="{ name: 'privacy-policy' }" target="_blank">Privacy Policy</router-link>
      </span>
    </div>
    <div class="flex-nowrap justify-content-center pt-1">
      <button
        type="button"
        class="btn send-button rounded"
        :disabled="isValidForm"
        @click="onSubmit"
      >
        <span class="d-inline-block text-truncate align-middle w-100">
          Set Password
        </span>
      </button>
    </div>
  </div>
</template>

<script>
  import PasswordComponent from '@/shared/components/password.component.vue';
  import CheckboxComponent from '@/shared/features/checkout/components/checkbox.component.vue';
  import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';

  export default {
    name: 'NewPasswordPopup',
    components: { CheckboxComponent, PasswordComponent },
    props: {},

    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
          isChecked: false,
        },
      };
    },

    computed: {
      passwordErrorMessage() {
        if (!this.$v.form.password.$dirty || !this.$v.form.password.$invalid) {
          return undefined;
        }

        if (!this.$v.form.password.required) {
          return 'Field is required';
        }

        if (!this.$v.form.password.minLength) {
          return 'Should contain at least 6 symbols';
        }

        return '';
      },
      confirmPasswordErrorMessage() {
        if (!this.$v.form.confirmPassword.$dirty || !this.$v.form.confirmPassword.$invalid) {
          return undefined;
        }

        if (!this.$v.form.confirmPassword.required) {
          return 'Field is required';
        }

        if (!this.$v.form.confirmPassword.sameAs) {
          return 'Should be the same as password';
        }


        return '';
      },
      isValidForm() {
        return this.$v.form.$invalid;
      },
    },

    methods: {
      onSubmit() {
        this.$emit('submit:form', this.form);
      },
    },

    validations: {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .send-button {
    width: 100%;
    height: 50px;
    background-color: $main-color;
    font-weight: 500;
    color: white;
  }
</style>
