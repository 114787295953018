<template>
  <div class="auth-actions" :class="{ 'open': isMobileOpen }">
    <div class="auth-actions__desktop">
      <button
        v-if="!isFullAuth"
        type="button"
        class="btn auth-actions__btn"
        @click.stop="showAuthPopup()"
      >
        {{ $t('HEADER.LOGIN') }}
      </button>
      <b-dropdown
        v-else-if="user && !isMobile"
        ref="authDropdown"
        right no-caret
        class="auth-dropdown-element"
        @click.stop
      >
        <template #button-content>
          <div
            @mouseover="onHoverAuthDropdown"
            @mouseleave="hideDropdown"
          >
            <img :src="require(`@/assets/icons/account/account${isDark ? '-black' : ''}.svg`)" alt="">
          </div>
        </template>
        <b-dropdown-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.link"
          class="dropdown-link"
          @click="() => item.handler ? item.handler() : {}"
          @mouseover.native="onHoverAuthDropdown"
          @mouseleave.native="hideDropdown"
        >
          <img :src="require(`@/assets/icons/account/${item.icon}-black.svg`)" alt="" class="dropdown-item-icon">
          <img :src="require(`@/assets/icons/account/${item.icon}-green.svg`)" alt="" class="dropdown-item-icon--hover">
          {{ item.title }}
        </b-dropdown-item>
        <!--        <b-dropdown-item :to="isMobile ? '/account' : '/account/profile'" link-class="account text-overflow">
          <img :src="require('src/assets/icons/profile-actions/account.svg')" alt="Profile settings">
&lt;!&ndash;          {{ username }}
          <i class="far fa-chevron-right"/>&ndash;&gt;
          My account
        </b-dropdown-item>
        <b-dropdown-divider/>-->
        <!--        <b-dropdown-item to="/my-profile/orders">
          <img :src="require('src/assets/icons/profile-actions/bookmark.svg')" alt="My orders">
          My orders
        </b-dropdown-item>
        <b-dropdown-item to="/my-profile/billing-address">
          <img :src="require('src/assets/icons/profile-actions/billing.svg')" alt="Billing-address">
          Billing Address
        </b-dropdown-item>
        <b-dropdown-item v-if="!$isStandaloneStore" :to="'/seller-splash'">
          <img :src="require('src/assets/icons/profile-actions/user-plus.svg')" alt="Become seller">
          Become seller
        </b-dropdown-item>-->
        <!--        <b-dropdown-item v-if="isConfLoaded" @click="logout()">
          <img :src="require('src/assets/icons/profile-actions/sign-out.svg')" alt="Logout">
          Logout
        </b-dropdown-item>-->
      </b-dropdown>

      <router-link v-else to="/account">
        <img :src="require(`@/assets/icons/account/account${isDark ? '-black' : ''}.svg`)" alt="">
      </router-link>
    </div>
    <!--    <div
      v-if="isFullAuth && user"
      class="auth-actions__mobile"
    >
      <div class="auth-actions__header" @click="isMobileOpen = false">
        <i class="fas fa-chevron-left"/>
        <span>My Account</span>
      </div>
      <router-link class="auth-actions__user" to="/my-profile">
        <div class="logo">
          &lt;!&ndash;<img src="">&ndash;&gt;
        </div>
        <div class="name text-overflow">
          {{ user.username }}
        </div>
        <div class="email text-overflow">
          {{ user.username }}
        </div>
      </router-link>
      <div class="auth-actions__links">
        <router-link to="/my-profile/settings" @click.native="isMobileOpen = false">
          Account settings
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link to="/my-profile/orders" @click.native="isMobileOpen = false">
          My orders
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link to="/my-profile/billing-address" @click.native="isMobileOpen = false">
          Billing address
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link v-if="!$isStandaloneStore" to="/seller-splash" @click.native="isMobileOpen = false">
          Become seller
          <i class="fas fa-chevron-right"/>
        </router-link>
      </div>
      <a class="auth-actions__logout" @click="logout()">
        Logout
        <img :src="require(`@/assets/icons/icon-login-out.svg`)" alt="">
      </a>
    </div>-->
  </div>
</template>
<script>
  import Config from '@/service/config';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import { amsClient } from '@/service/ams';
  import Auth from '@/service/authService';
  import device from '@/service/device-service.js';
  import { debounce } from 'lodash';

  export default {
    name: 'AuthActions',
    mixins: [AuthVariableMixin],
    props: {
      isConfLoaded: {
        type: Boolean,
        default: () => false,
      },
      isDark: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        sellerLink: '',
        isMobileOpen: false,
        user: Auth.get('user'),
        isVisibleDropdown: false,
        debounceHideAuthMenu: null,
        menuItems: [
          { title: 'Profile', link: '/account/profile', icon: 'profile' },
          { title: 'My Orders', link: '/account/orders', icon: 'orders' },
          { title: 'Wishlist', link: '/account/favorites', icon: 'favorites' },
          { title: 'Messaging Center', link: '/account/message-center', icon: 'message-center' },
          // { title: 'Notifications', link: '/account/notifications', icon: 'notification' },
          { title: 'Sign out', icon: 'logout', handler: () => this.logout() },
        ],
      };
    },
    computed:{
      username(){
        return this.user.name || this.user.username
      },
      // isMobile() {
      //   return device.isAnyMobile() || device.isMobileSize();
      // },
      isMobile() {
        return window.innerWidth <= 800
      },
    },
    watch: {
      user(user) {
        if (user && !user.guest) {
          this.$bus.$emit('toggleAuthPopup');
        }
      },
    },
    mounted() {
      Config.loaded(() => {
        this.sellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
      });
      this.$bus.$on('ToggleMobileAuthActions', (data) => {
        this.isMobileOpen = data;
      });
      this.debounceHideAuthMenu = debounce(() => {
        if (this.isVisibleDropdown) {
          return;
        }
        this.$refs.authDropdown.hide();
      }, 750);
      amsClient.on('auth_state_change', () => this.user = Auth.get('user'));
    },
    destroyed() {
      this.$bus.$off('ToggleMobileAuthActions');
    },
    methods: {
      hideDropdown() {
        this.isVisibleDropdown = false;
        this.debounceHideAuthMenu();
      },
      onHoverAuthDropdown() {
        this.isVisibleDropdown = true;
        this.$refs.authDropdown.show();
      },
      showAuthPopup() {
        this.$emit('showAuthPopup');
      },
      logout() {
        this.isMobileOpen = false;
        this.$emit('logout');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .auth-actions {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 180px;
    height: 100%;
    margin-left: .75rem;
    //padding: 0 32px;

    @media(max-width: 767px) {
      padding: 0 0;
    }

    .dropdown-link {
      padding: 13px 15px;

      &:not(:last-child) {
        border-bottom: 1px solid #f0f4fb;
      }
    }

    /*@media (max-width: $md-breakpoint) {
      display: none;
      position: fixed;
      top: 55px;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      height: auto;
      padding: 0;

      &.open {
        display: flex;
        height: calc(100vh - 50px);
      }
    }*/

    &__btn,
    &::v-deep .dropdown-toggle {
      white-space: nowrap;
      color: black;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 0;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: black;
      }

      &::after {
        display: none !important;
      }

      span {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    &::v-deep .dropdown-menu {
      width: 210px;
      padding: 0;
      background-color: $background-color;
      border-radius: 10px;
      border: 1px solid #f0f4fb;
      box-shadow: 0px 8px 16px 0px #32324714;
      outline: none;
      overflow: hidden;

      .dropdown-divider {
        margin: 0 0 10px;
        border-top-color: #DAE0EB;
      }

      a {
        position: relative;
        display: block;
        padding: 8px 0 8px 25px;
        background-color: transparent !important;
        line-height: 16px;
        font-size: 16px;
        font-weight: normal;
        color: #343434;

        .dropdown-item-icon--hover {
          display: none;
        }

        &:hover {
          color: #21a66e;

          .dropdown-item-icon {
            display: none;
          }

          .dropdown-item-icon--hover {
            display: block;
          }
        }

        &.account {
          padding: 12px 10px 12px 25px;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }

        img,
        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        i {
          left: auto;
          right: 0;
          width: 6px;
          font-size: 12px;
        }
      }
    }

    &::v-deep .dropdown-item {
      padding: 0 0 0 25px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    &__desktop {
      /*@media (max-width: $md-breakpoint) {
        display: none;
      }*/
    }

    &__mobile {
      display: none;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 50px);
      padding: 0 20px;
      background-color: #fff;

      @media (max-width: $md-breakpoint) {
        display: flex;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      min-height: 65px;
      font-size: 24px;
      font-weight: 600;
      color: $main-text-color;

      i {
        margin-right: 15px;
        font-size: 16px;
      }
    }

    &__user {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      margin-bottom: 5px;
      padding: 25px 16px 25px 74px;
      background-color: $block-background-color;
      line-height: 11px;

      .logo {
        position: absolute;
        top: 19px;
        left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #aaa;
        overflow: hidden;
      }

      .name {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        color: $main-text-color;
      }

      .email {
        font-size: 14px;
        font-weight: 500;
        color: #919BAA;
      }
    }

    &__links {
      flex: 1;
      overflow-y: auto;

      a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        padding: 14px 0;
        border-bottom: 1px solid #E6EBF2;
        font-size: 15px;
        line-height: 32px;
        font-weight: 500;
        color: $main-text-color !important;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__logout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-bottom: 30px;
      padding-left: 25px;
      padding-right: 20px;
      background-color: #F3F5F9;
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      color: $main-text-color !important;
    }
  }
</style>
