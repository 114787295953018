var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'sp-chat-widget-message': true
  }, _obj[_vm.msgClass] = true, _obj )},[(_vm.img)?_c('img',{class:{
      'is-clickable': !_vm.img.isLocal,
      'sp-chat-widget-message-image-loading': _vm.img._inProgress,
    },attrs:{"src":_vm.img.src},on:{"click":function () { return _vm.openImg(_vm.img); }}}):_vm._e(),(_vm.img && _vm.img._inProgress)?_c('loader',{attrs:{"color":"rgb(93 93 93)","position":"absolute","spinner-style":"font-size: 300%","is-full-height":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.showPreview)?_c('image-preview-component',{attrs:{"src":_vm.img.src},on:{"close":_vm.onClosePreview}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }