export default `
  query getCart($id: ID!, $count: Int!) {
    cart(id: $id) {
        id
        checkoutUrl
        buyerIdentity {
            customer {
                id
                email
            }
            countryCode
            email
        }
        cost {
            totalAmount {
                amount
                currencyCode
            }
            subtotalAmount {
                amount
                currencyCode
            }
            totalTaxAmount {
                amount
                currencyCode
            }
            totalDutyAmount {
                amount
                currencyCode
            }
        }
        lines(first: $count) {
            edges {
                node {
                    id
                    quantity
                    attributes {
                        key
                        value
                    }
                    merchandise {
                        ... on ProductVariant {
                            id
                            quantityAvailable
                            price {
                                amount
                                currencyCode
                            }
                            title
                            image {
                                url
                            }
                            product {
                                title
                                id
                                handle
                            }
                        }
                    }
                }
            }
        }
    }
  }
`;
