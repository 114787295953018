<template>
  <div
    id="app"
    class="app-container"
    :class="{
      'overflow-hidden': canHideFooter,
      'hided-header': !showHeader,
    }"
  >
    <vue-headful :head="head" :title="currentTitle" />

    <app-header
      v-if="isVisibleHeader"
      :header-links="headerLinks"
      :show-mobile-menu="showMobileMenu"
      :is-tablet="isTablet"
      :is-mobile="isMobile"
      :logo="logo"
      :active-popup="activePopup"
      @closeProductPopup="closeProductPopup"
    />

    <app-mobile-menu v-if="!isInFrame &&showMobileMenu" :show-channels="store.channels !== 0" />

    <main id="app-content" class="content" :class="[extraClass, offMarginTop]">
      <router-view v-if="isMenuFinished" />
      <vod-preview />

      <product-popup
        v-if="productPopup && productItem"
        :item="productItem"
        :options="productPopupOptions"
        @closeProductPopup="closeProductPopup"
      />

      <footer-component
        v-if="!canHideFooter"
        :store-logo="logo"
        :store-name="storeName"
        :is-logged-in="!isGuest"
      />
    </main>

    <auth-block />

    <!--    <event-streem-->
    <!--      v-if="currentLive"-->
    <!--      :area="currentLive"-->
    <!--      first-event-component-->
    <!--      live-player-->
    <!--      class="event-streem"-->
    <!--      @closePopupVOD="closePopupVOD"-->
    <!--      @closeProductPopup="closeProductPopup"-->
    <!--    />-->


    <vod-popup @close="closePopupVOD" />
    <float-player-container
      @close="onCloseFloatPlayer"
      @back="onBackFloatPlayer"
    />

    <iframe-popup
      v-if="fullScreenIframe && fullScreenItem"
      :item="fullScreenItem"
      @closeIframePopup="closeIframePopup"
    />

    <message-popup
      v-if="messageDialog"
      :store-name="itemEvent.creator.name"
      :store-link="itemEvent.creator.slug"
    />

    <app-modals @change:modal="changeModalState" />

    <chat-widget-container v-if="!isInFrame && showWidgetContainer" />
    <!--    <div-->
    <!--      v-show="showFloatingWrapper"-->
    <!--      ref="floatingWrapper"-->
    <!--      class="floating-wrapper"-->
    <!--    />-->
    <div
      v-if="showCookiePolicy"
      class="app-cookie-policy"
    >
      <div class="app-cookie-policy-text">
        We use cookies to personalize your experience and for measurement and analytics purposes. </br>
        By using our website and services, you agree to our use of cookies as described in our
        <router-link
          :to="{ name: 'cookies-policy' }"
          @click.native="onAcceptCookies"
        >
          Cookie Policy
        </router-link>
        .
        <i class="far fa-times" @click="onAcceptCookies" />
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import AuthBlock from '@/components/auth-block/auth-block';
  import AppHeader from '@/components/header/AppHeader';
  import AppMobileMenu from '@/entities/app-mobile-menu/components/app-mobile-menu.vue';
  import ProductPopup from '@/components/product-popup/product-popup';
  import IframePopup from '@/components/iframe-popup/iframe-popup';
  import VodPopup from '@/shared/components/popup-player/popup-player.component.vue';
  import VodPreview from '@/components/common/vod-preview/vod-preview';
  import EventStreem from './components/channel-profile/event-streem';
  import SellerSplash from '@/views/SellerSplashPage/SellerSplash';
  import Config from '@/service/config';
  import { debounce, cloneDeep } from 'lodash';
  import JsonLD from '@/service/jsonld';
  import MessagePopup from './components/message-popup/message-popup';
  import rtm from '@/service/realTimeMessages';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import Auth from '@/service/authService';
  import AppModals from '@/components/common/modals/app-modals';
  import FooterMain from '@/components/footerMain/footerMain';
  import SharedCartDataService from './shared/services/cart/cart-data.service';
  import SharedAmsDataService from './shared/services/ams/ams-data.service';

  import WishlistService from '@/store/wishlist';
  import ProtectedApi from '@/shared/api/protected.api';

  import FloatPlayerContainer from '@/shared/components/float-player/float-player.component.vue';
  import smoothScroll from '@/shared/helpers/smooth-scroll';
  import ChatWidgetContainer from '@/shared/features/chat-widget/chat-widget.container.vue';
  import termsService from '@/shared/services/terms.service';
  import CheckoutApi from '@/shared/features/checkout/api/index';
  import FooterComponent from '@/core/components/footer.component.vue';
  import * as Sentry from '@sentry/vue';
  import isInIframe from '@/utils/isInIframe';

  const CartService = new SharedCartDataService();
  const AmsService = new SharedAmsDataService();

  export default {
    name: 'App',
    components: {
      FooterComponent,
      ChatWidgetContainer,
      AppMobileMenu,
      FloatPlayerContainer,
      AuthBlock,
      AppHeader,
      ProductPopup,
      VodPopup,
      MessagePopup,
      IframePopup,
      VodPreview,
      AppModals,
      FooterMain,
      SellerSplash,
      EventStreem,
    },
    data() {
      return {
        currentTitle: Config.get('title'),
        resizeId: 0,
        headerLinks: null,
        isMobile: false,
        isTablet: false,
        RESIZE_DELAY: 300,
        prevSize: 0,
        extraClass: '',
        fullScreenIframe: false,
        fullScreenItem: null,
        messageDialog: false,
        itemEvent: null,
        head: {},
        productPopup: false,
        productItem: null,
        prevPage: [],
        productPopupOptions: {},
        offMarginTop: '',
        isReadyLive: true,
        isReadySecondLive: true,
        appModalsPopup: false,
        historyData: {},
        showHeader: true,
        cartId: null,
        floatFrom: null,
        logo: '',
        isFooterVisible: true,
        showWidgetContainer: false,
        showCookiePolicy: false,
        isMenuFinished: false,
        isInFrame: isInIframe(),
      };
    },
    provide() {
      return {
        toggleReadyLive: this.toggleReadyLive,
      };
    },

    computed: {
      ...mapState('floatPlayer', {
        floatItem: 'floatItem',
        floatPlaybackState: 'playbackState',
        floatPagePosition: 'pagePosition',
        floatOptions: 'options',
      }),
      ...mapState('popupPlayer', {
        popupPlayerItem: 'item',
      }),
      ...mapState('popup', [
        'showAccountPopup',
        // 'floatingMode',
        // 'showFloatingWrapper',
      ]),
      // ...mapGetters('popup', ['screenType'/*, 'isVodFloating', 'isLiveFloating'*/]),
      ...mapGetters('checkout', ['getCartItems', 'getCompleteOrder']),
      // currentLive() {
      //   return this.$store.getters['dataAreasLive/currentLive'];
      // },
      // currenSecondLive() {
      //   return this.$store.getters['dataAreasLive/currenSecondLive'];
      // },
      isGuest() {
        return Auth.isGuest();
      },
      store() {
        return amsClient.get('conf.store');
      },
      storeName() {
        return this.store?.title;
      },

      handleResize() {
        return debounce(this.emitEvent, this.RESIZE_DELAY, {
          leading: false,
          trailing: true,
        });
      },
      showMobileMenu() {
        return (
          !this.isHiddenHeader &&
          this.isMobile &&
          this.$route.name !== 'product-id'
        );
      },
      // isMiniatureIframe() {
      //   return this.screenType === 'miniature';
      // },
      activePopup() {
        return (
          this.productPopup ||
          this.fullScreenIframe ||
          !!this.popupPlayerItem ||
          this.messageDialog ||
          this.appModalsPopup
        );
      },
      canHideFooter() {
        return this.isInFrame || !!this.$route.meta?.hideFooter || !this.isFooterVisible;
      },
      isVisibleHeader() {
        return !this.isInFrame && !this.isHiddenHeader && this.showHeader;
      },
      isHiddenHeader() {
        return this.$route.meta?.hideHeader;
      },
    },
    watch: {
      $route(to, from) {
        this.showWidgetContainer = to.name !== 'message-center';

        // if (to.name === 'Home') {
        //   this.$bus.$emit('updateTitle', this.$standaloneStoreName);
        // }
      },

      async getCompleteOrder(value) {
        if (value) {
          const notMargeCart = value;

          await this.onMountedDataCart(notMargeCart);

          this.$store.commit('checkout/setCompleteOrder', false);
        }
      },
    },
    created() {
      this.onCookiePolicy();
      this.$bus.$on('loggedIn', () => {
        this.onCookiePolicy();
      });
      this.$bus.$on('amsLogged', async () => {
        this.setTemplate();

        WishlistService.getWishlist();
      });

      this.fetchItemsMenu();

      if (!rtm.isActive()) {
        rtm.init('lobby').catch((e) => console.error(e));
      }

      Config.set('temp.context', 'deeplink');
      this.prevSize = window.innerWidth;
      this.checkWidth();

      // this.$stats.send('app_open');

      this.extraClass = this.isInFrame ? 'content-no-padding' : '';
      this.$bus.$on('handleSpecificClass', (classList) => {
        this.extraClass = classList;
      });

      this.$bus.$on('toggleHeaderVisibility', ({ isHide }) => {
        this.showHeader = isHide;
      });

      this.$bus.$on('updateTitle', this.setTitle);

      Config.loaded(() => {
        this.head = {
          'meta[name="google-site-verification"]': {
            content: Config.get('google-site-verification_parameter'),
          },
          'meta[property="fb:app_id"]': {
            content: Config.get('auth.facebook.app_id'),
          },
          'meta[name="prerender-status-code"]': { content: '200' },
        };
      });

      window.addEventListener('resize', this.handleResize);

      JsonLD.add();

      // const storeSettings = amsClient.get('conf.store');

      this.logo = this.store?.logo;

      const favicon =
        this.store?.favicon || 'static/favicon/verb/favicon.ico';
      this.changeFavicon(favicon);

      if (this.store?.standalone && this.store?.is_protected) {
        setInterval(() => {
          if (this.$route.path !== '/protected') {
            this.handleCheckPassword();
          }
        }, 15 * 60 * 1000);
      }
    },

    destroyed() {
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);

      window.removeEventListener('resize', this.handleResize);
      JsonLD.pop();
      JsonLD.remove();

      rtm.disconnect();
    },
    async mounted() {
      this.$bus.$on(
        'toggleFooter',
        () => (this.isFooterVisible = !this.isFooterVisible),
      );

      this.$bus.$on('resizeWindow', this.checkWidth);
      this.$bus.$on('removeAppMarginTop', (isMargin) => {
        this.offMarginTop = isMargin;
      });

      this.addSchemaOrg();

      this.$bus.$on('toggleFullScreenIframe', (data = {}) => {
        const { item } = data;

        if (
          (item && !this.fullScreenItem) ||
          (item && item.id && this.fullScreenItem.id !== item.id)
        ) {
          this.fullScreenIframe = true;
          this.fullScreenItem = item;
        } else {
          this.fullScreenIframe = false;
          this.fullScreenItem = null;
        }
      });

      this.$bus.$on('toggleProductPopup', (data = {}) => {
        const { item, options } = data;

        this.productPopupOptions = options || {};
        this.productPopup = !!item;
        this.productItem = item ?? item;

        if (item && !Auth.get('user').guest) {
          amsClient.callAms(`/stk/recent_shop/?d=web&pk=${item.id}`, {
            method: 'post',
          });
        }
      });

      this.$bus.$on('toggleShopCart', (data = {}) => {
        const { item } = data;

        if (item && !this.fullScreenItem) {
          this.fullScreenIframe = true;
          this.fullScreenItem = item;
        } else {
          this.fullScreenIframe = false;
          this.fullScreenItem = null;
        }
      });

      /*this.$bus.$on('togglePopupVOD', ({ item, source, options }) => {
        const { startPosition = 0 } = options || {};
        const clonedItem = cloneDeep(item);
        clonedItem.startPosition = startPosition;

        if (this.floatItem) {
          this.setFloatItem(null);
          this.setFloatPlaybackState({ muted: this.floatPlaybackState.muted });
          if (source !== 'banner' && source !== 'search') {
            this.$nextTick(() => {
              this.setFloatItem(clonedItem);
              this.$nextTick(() => {
                this.setFloatOptions({
                  page: clonedItem.share || location.pathname,
                  openerPage: location.pathname,
                  ignoreScroll: true,
                  type: 'vod-popup',
                });
                amsClient.callAms(`/stk/recent/?d=web&pk=${clonedItem.id}`, {
                  method: 'post',
                });
              });
            });
            return;
          }
          this.setFloatOptions(null);
        }

        if (
          !this.popupPlayerItem ||
          (clonedItem &&
            clonedItem.id &&
            this.popupPlayerItem.id !== clonedItem.id)
        ) {
          this.setPopupPlayerItem(clonedItem);
        } else {
          this.setPopupPlayerItem(null);
          this.setPopupPlayerPlaybackState(null);
        }
      });*/

      // this.$bus.$on('changeItem', () => {
      //   if (this.isMiniatureIframe) {
      //     this.$store.commit('popup/setLoadingIframe', true);
      //   }
      // });

      this.$bus.$on('toggleMessagePopup', (data = {}) => {
        const { item } = data;

        if (item) {
          this.messageDialog = true;
          this.itemEvent = item;
        } else {
          this.messageDialog = false;
          this.itemEvent = null;
        }
      });

      try {
        const t = localStorage;
      } catch (e) {
        this.$bus.$emit('toggleAuthPopup', {
          component: 'message',
          forceCloseBtn: true,
          closeOutside: false,
          force: true,
          message: this.$i18n.t('ACCESS.LOCALSTORAGE_ACCESS_DENIED'),
          actions: {
            close: this.$i18n.t('OK'),
          },
        });
      }

      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
      this.$bus.$on('closeProductPopup', this.closeProductPopup);

      this.$bus.$on('amsLogged', () => {
        const userData = Auth.get('user');

        const user = {
          username: userData?.username || '',
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          guest: userData ? userData.guest : true,
        };

        this.$store.commit('authenticate/setUser', user);

        Sentry.setUser({
          id: userData.id || null,
          ...user,
        });

        this.onMountedDataCart();
      });

      this.$bus.$on('logout', async () => {
        localStorage.removeItem('chat_was_used_by_guest');
        // this.$bus.$emit('reloadChatUser');
        if (this.$route.fullPath.includes('account')) {
          this.$router.push('/');
        }
        this.onCookiePolicy();
      });
      // const userData = Auth.get('user');

      // this.$store.commit('authenticate/setUser', {
      //   username: userData?.username || '',
      //   first_name: userData?.first_name || '',
      //   last_name: userData?.last_name || '',
      //   guest: userData ? userData.guest : true,
      // });

      // this.handleCheckoutSync();
      // this.handleShopifyPostMessages();
      // this.handleShopLayoutMessages();
      // await this.onMountedCreateCart();

      // Vue.prototype.$floatingWrapperRef = this.$refs.floatingWrapper;
    },
    methods: {
      ...mapActions({
        setTemplate: 'chachedPreviewMedia/setPlayTemplate',
        setFloatItem: 'floatPlayer/setFloatItem',
        setFloatPlaybackState: 'floatPlayer/setPlaybackState',
        setFloatOptions: 'floatPlayer/setFloatOptions',
        setPopupPlayerItem: 'popupPlayer/setItem',
        setPopupPlayerPlaybackState: 'popupPlayer/setPlaybackState',
      }),
      onCookiePolicy() {
        this.showCookiePolicy = !this.isInFrame && !termsService.isUserAcceptedCookies(!Auth.isGuest() ? Auth.get('user')?.id : 'guest');
      },
      onAcceptCookies() {
        termsService.acceptCookies(!Auth.isGuest() ? Auth.get('user')?.id : 'guest');
        this.showCookiePolicy = false;
      },
      async changeFavicon(favicon) {
        const links = document.querySelectorAll('link[rel~=\'icon\']');
        links.forEach((l) => (l.href = favicon));
      },
      async handleCheckPassword() {
        const password = localStorage.getItem('store-password');

        if (!password) {
          this.$router.push('/protected');

          return;
        }

        const payload = {
          sitepass: Buffer.from(password, 'base64').toString('utf-8'),
          domain: document.location.origin.split('//')[1],
        };

        try {
          const response = await ProtectedApi.checkPassword(payload);

          if (!response?.verified) {
            this.$router.push('/protected');
          }
        } catch (error) {
          this.$router.push('/protected');
        }
      },
      async onMountedDataCart(notMargeCart) {
        try {
          let cartId = localStorage.getItem('cartId');

          const user = Auth.get('user');

          const isGuest = user.guest;
          const isAuth = !user.guest;

          if (!cartId && isGuest) {
            const cart = await CartService.createCartForGuest();

            cartId = cart.id;

            localStorage.setItem('cartId', cartId);
          }

          if (isAuth) {
            cartId = user.cart;

            if (!cartId) {
              const userEmail = user.privateId;

              cartId = await this.createCartIdForAuthUser(userEmail);
            }
          }

          const cartResponse = await this.fetchCartById(cartId);

          if (!cartResponse) {
            return;
          }

          let { cart } = cartResponse;

          if (!notMargeCart && isAuth && this.getCartItems.length) {
            cart = await this.margeCartForAuthUser(cartId);
          }

          this.$store.commit('checkout/setCart', cart);
        } catch (error) {
          console.log(error);
        } finally {
          this.$store.commit('checkout/setLoading', false);
        }
      },

      async fetchCartById(cartId) {
        this.$store.commit('checkout/setLoading', true);
        try {
          const response = await CartService.fetchCartById(cartId);

          if (!response || !response.cart) {
            localStorage.removeItem('cartId');
            Auth.set('user.cart', null);

            await this.onMountedDataCart();
            return 'test';
          }

          return response;
        } catch (error) {
          console.error(error);
        }
      },

      async createCartIdForAuthUser(userEmail) {
        const user = Auth.get('user');
        const token = await CheckoutApi.getMultiPassToken(userEmail, user.first_name, user.last_name);

        const accessToken = await AmsService.getAccessTokenForAuthUser(token);

        const cart = await CartService.createCartForAuthUser(
          userEmail,
          accessToken,
        );

        await AmsService.updateAccount({
          cart: cart.id,
        });

        Auth.set('user.cart', cart.id);

        return cart.id;
      },

      async margeCartForAuthUser(cartId) {
        const items = this.getCartItems.map(({ node }) => ({
          merchandiseId: node.merchandise.id,
          quantity: node.quantity,
          attributes: node.attributes,
        }));

        const { cartLinesAdd } = await CartService.addALotOfItemsToCart({
          cartId,
          items,
        });

        localStorage.removeItem('cartId');

        return cartLinesAdd.cart;
      },

      changeModalState(value) {
        this.appModalsPopup = value;
      },
      emitEvent(event) {
        const curr = window.innerWidth;
        if (curr !== this.prevSize) {
          /* this.$logger.log(
            `Emit event resize from ${this.prevSize}px to ${curr}px `,
            event,
          ); */
          this.prevSize = curr;
          this.$bus.$emit('resizeWindow', event);
        }
      },
      addSchemaOrg() {
        // TODO: add ais info
        const data = {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          // url: 'https://protvplus.ro/portal/',
          logo: `${location.origin}/portal/static/favicon/${process.env.VUE_APP_CUSTOMER}/favicon.png`,
          contactPoint: [
            {
              // '@type': 'ContactPoint',
              // telephone: '+4021-9454',
              // contactType: 'customer service'
            },
          ],
        };
        JsonLD.push(data);
      },
      closeProductPopup() {
        this.productPopup = false;
        this.productItem = null;
      },
      closeIframePopup() {
        this.fullScreenIframe = false;
        this.fullScreenItem = null;
      },
      closePopupVOD() {
        this.setPopupPlayerItem(null);
        this.setPopupPlayerPlaybackState(null);
      },
      onCloseFloatPlayer(floatInfo) {
        // it means that player closed on another page, and it doesn't have sense to store the data, because it will not used
        if (
          floatInfo.options.openerPage !== this.$route.fullPath ||
          floatInfo.options.type === 'vod-popup'
        ) {
          this.setFloatItem(null);
          this.setFloatOptions(null);
          this.setFloatPlaybackState(null);
        }
      },
      async onBackFloatPlayer(floatInfo) {
        if (floatInfo.options.type === 'vod-popup') {
          // closed float with vod-popup
          if (floatInfo.options.openerPage !== this.$route.fullPath) {
            await this.$router.push(floatInfo.options.page);
          }
          this.setPopupPlayerPlaybackState({ ...floatInfo.playbackState });
          this.setPopupPlayerItem({ ...floatInfo.item });
          this.setFloatItem(null);
          this.setFloatOptions(null);
          this.setFloatPlaybackState(null);
        } else if (floatInfo.options.page) {
          // closed float for shop-player
          if (floatInfo.options.page !== this.$route.fullPath) {
            await smoothScroll(document.documentElement, {
              top: 0,
              duration: 100,
            });
            await this.$router.push(floatInfo.options.page);
          } else {
            await smoothScroll(document.documentElement, {
              top: 0,
              duration: 100,
            });
          }
        }
      },
      async fetchItemsMenu() {
        try {
          const response = await amsClient.callAms(`${this.store.link}`);

          if (response.head.itype === 'shop_menu') {
            this.headerLinks = response.items;

            this.$store.commit('shopMenu/setMenuList', response.items);
            this.$store.commit('shopMenu/setScreenType', response.head.itype);

            this.isMenuFinished = true;

            console.log('headerLinks: ', this.headerLinks);
          }
        } catch (error) {
          console.log('fetchItemsMenu: ', error);
        }
      },
      setTitle(title) {
        this.currentTitle = null;
        this.$nextTick(() => {
          this.currentTitle = title;
        });
      },
      event_setCurrentPage(path) {
        if (path) {
          if (!this.prevPage.length) {
            this.prevPage.push(this.$route.fullPath);
          }

          if (!this.prevPage.includes(path)) {
            this.prevPage.push(path);
          } else {
            const storeMatch = path.match(/^\/s\/(.*?)\//gi);

            if (storeMatch) {
              this.prevPage.unshift(storeMatch[0]);
            }
          }
        } else if (!path && this.prevPage.length) {
          const lastPage = this.prevPage.pop();
          const backPage = this.prevPage.pop();
          const storeMatch = lastPage.match(/^\/s\/(.*?)\//gi);

          if (backPage) {
            path = backPage;
          } else if (storeMatch) {
            path = storeMatch[0];
          } else {
            path = lastPage;
          }
        }

        const historyPath = this.$location + (path || '');

        if (this.historyData.lastPage) {
          this.historyData.beforeLastPage = this.historyData.lastPage;
        }

        this.historyData.lastPage = historyPath;

        history.pushState(this.historyData, null, historyPath);
      },
      toggleReadyLive(value = false) {
        this.isReadyLive = value;
      },
      checkWidth() {
        this.isMobile = window.innerWidth < 800; // for vertical and horizontal view
        this.isTablet = window.innerWidth < 1025;
      },
    },
  };
</script>

<style lang="scss">
  // NOTE: ORDER IS IMPORTANT
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  //  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    font-family: 'Roboto';
  }

  @import '~swiper/css/swiper.css';
  @import 'src/style/custom_bootstrap';
  @import './style/animate';

  @import './style/icons/fontawesome-all.css';
  @import 'src/style/scroll';
  @import 'src/style/shared';
  @import 'src/style/app';
  @import 'src/style/fix';
  @import 'src/style/modals';
  @import 'src/style/z-index';

  /* * {
    padding: 0;
    margin: 0;
  }

  * {
    background-repeat: no-repeat;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  } */

  .app-cookie-policy {
    position: fixed;
    max-width: 750px;
    width: 100%;
    left: 50%;
    bottom: 10px;
    @media (max-width: 750px) {
      //margin: 0.5rem 0;
      width: 90%;
    }
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: $cookie-policy-popup;
    box-shadow: 0px 0px 15px 0px #AFAFAF66;
    border-radius: 10px;

    &-text {
      position: relative;
      padding: 0.5rem 1rem 0.5rem 1rem;

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }

      a {
        color: #4B89E5;
      }
    }
  }

  .floating-wrapper {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
  }

  .event-streem {
    position: absolute;
    top: 250px;
    z-index: 10;
    // left: 15px;
    // width: 72vw;
    @media screen and (max-width: 1200px) {
      top: 15px;
    }
  }

  .with-shop-player {
    margin-top: 0 !important;
    overflow-y: auto;
    padding-top: 0 !important;
  }

  .with-shop-player-fullscreen {
    z-index: 1100;

    &--emulated {

    }
  }
</style>