<template>
  <!-- <transition
    name="loader-anima"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  > -->
  <span
    class="loading-container animated"
    :style="style"
  >
    <i :style="spinnerStyle" class="fas fa-5x fa-spinner fa-pulse" />
  </span>
  <!-- </transition> -->
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      color: {
        type: String,
        default: 'white',
      },

      position: {
        type: String,
        default: 'fixed',
      },

      background: {
        type: String,
        default: '',
      },
      isFullHeight: {
        type: Boolean,
        default: false,
      },
      spinnerStyle: {
        type: [Object, String],
        default: '',
      },
    },

    computed: {
      style() {
        const { color, position, background } = this;

        const style = { color, position, background };
        if (this.isFullHeight) {
          style.height = '100%';
        }

        return style;
      },
    },
  };
</script>

<style lang="scss">
  @import './loader';
</style>
