import SharedLocationsAPI from '@/shared/api/locations';

class LocationsService {
  async getUserLocations(storeId, afterCursor = null) {
    const locationsResponse = await SharedLocationsAPI.getLocations(afterCursor);

    const locations = locationsResponse.locations.edges;

    if (!locations.length) {
      return null;
    }

    const userLocations = this.#findUserLocations(locations, storeId);

    if (!userLocations.length && locationsResponse.locations.pageInfo.hasNextPage) {
      return this.getUserLocations(storeId, locationsResponse.locations.pageInfo.endCursor);
    }

    if (!userLocations.length) {
      return null;
    }

    return this.#normalizeLocations(userLocations);
  }

  async createUserLocations(storeId, payload) {
    const warehouseLocationModel = this.#prepareLocationModel(payload, 'warehouse', storeId);
    const businessLocationModel = this.#prepareLocationModel(payload, 'business', storeId);

    const newWarehouseLocation = await SharedLocationsAPI.createLocation(warehouseLocationModel);
    const newBusinessLocation = await SharedLocationsAPI.createLocation(businessLocationModel);

    const locationsIds = [
      newWarehouseLocation.locationAdd.location.id,
      newBusinessLocation.locationAdd.location.id,
    ];

    const deliveryProfile = await this.#getDeliveryProfile();

    this.#addLocationsToDeliveryProfile(deliveryProfile, locationsIds);
  }

  async updateLocation(id, type, storeId, payload) {
    const response = await SharedLocationsAPI.updateLocation({
      id,
      data: this.#prepareUpdateLocationModel(payload, type, storeId),
    });

    if (response.locationEdit.userErrors.length) {
      throw new Error(response.locationEdit.userErrors[0].message);
    }
  }

  #addLocationsToDeliveryProfile(profile, locations) {
    const data = {
      profileId: profile.id,
      profile: {
        locationGroupsToUpdate: [{
          id: profile.profileLocationGroups[0].id,
          locationsToAdd: locations,
        }],
      },
    };

    return SharedLocationsAPI.updateDeliveryProfile(data);
  }

  async #getDeliveryProfile() {
    const deliveryProfiles = await SharedLocationsAPI.getDeliveryProfiles();
    const profiles = this.#normalizeDeliveryProfiles(deliveryProfiles);

    return profiles[0];
  }

  #normalizeDeliveryProfiles(profiles) {
    return profiles.deliveryProfiles.edges.map((profile) => ({
      id: profile.node.id,
      isDefault: profile.node.default,
      name: profile.node.name,
      profileLocationGroups: profile.node.profileLocationGroups.map((g) => ({
        id: g.locationGroup.id,
      })),
    }));
  }

  #findUserLocations(locations, storeId) {
    return locations.filter((location) => location.node.metafields.edges.some((metafield) => metafield.node.key === 'seller' && metafield.node.value === storeId));
  }

  #normalizeLocations(locations) {
    return locations.reduce((acc, location) => {
      const locationTypeMetafield = location.node.metafields.edges.find((metafield) => metafield.node.key === 'type');
      const locationStateMetafield = location.node.metafields.edges.find((metafield) => metafield.node.key === 'state');

      const locationType = locationTypeMetafield.node.value;
      const locationState = locationStateMetafield?.node.value;

      acc[locationType] = [{
        id: location.node.id,
        name: location.node.name.split('|')[0],
        address: {
          ...location.node.address,
          state: locationState || 'NY',
        },
        type: locationType,
      }];

      return acc;
    }, {});
  }

  #prepareLocationModel(payload, type, storeId) {
    return {
      name: `${payload.name} ${type}|${storeId}`,
      address: {
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        countryCode: payload.country,
        zip: payload.zip,
      },
      fulfillsOnlineOrders: true,
      metafields: [
        {
          key: 'type',
          namespace: 'shoprz',
          value: type,
          type: 'single_line_text_field',
        },
        {
          key: 'seller',
          namespace: 'shoprz',
          value: storeId,
          type: 'single_line_text_field',
        },
      ],
    };
  }

  #prepareUpdateLocationModel(payload, type, storeId) {
    return {
      name: `${payload.name}|${storeId}`,
      address: {
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        countryCode: payload.country,
        zip: payload.zip,
      },
    };
  }
}

export default LocationsService;
