<template>
  <b-modal
    v-model="showModal"
    :size="'lg'"
    :modal-class="'scheduled-event-dialog'"
    :centered="true"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="scheduled-popup">
      <div v-if="item" class="scheduled-popup__main-container">
        <div v-show="isMobile" class="scheduled-popup__header">
          <button class="scheduled-popup__back-button" @click.stop="close">
            <img
              width="10px"
              height="16px"
              :src="require(`@/assets/icons/icon-come-back.svg`)"
            >
          </button>
          <h2 class="scheduled-popup__header-text">
            Upcoming event
          </h2>
        </div>
        <div
          class="scheduled-popup__poster"
          :style="`background-image: url(${item.thumbnail})`"
        >
          <div
            v-show="promoReady"
            class="promo-block"
          >
            <video
              ref="promoVideo"
              playsinline
              muted
              preload="none"
              class="promo-video"
            />
          </div>
          <button
            v-show="!isMobile"
            class="scheduled-popup__close-button"
            @click.stop="close"
          >
            <img
              width="12px"
              height="12px"
              :src="require(`@/assets/icons/icon-close-white.svg`)"
            >
          </button>
          <button
            v-show="promoReady"
            class="scheduled-popup__mute-button"
            @click="togglePromoSound"
          >
            <img
              class="scheduled-popup__mute-button__icon"
              :src="require(`@/assets/icons/icon-${promoMuted ? 'muted' : 'unmuted'}.svg`)"
            >
          </button>
        </div>
        <div class="scheduled-popup__info-section">
          <h3 class="scheduled-popup__event-title">
            {{ item.title }}
          </h3>
          <p class="scheduled-popup__event-start">
            {{ eventStart }}
          </p>
          <p class="scheduled-popup__event-description">
            {{ item.description }}
          </p>
          <div class="scheduled-popup__actions">
            <div class="scheduled-popup__buttons">
              <button
                class="scheduled-popup__notify-button"
                :class="
                  isNotificationSent && 'scheduled-popup__notify-button--grayed'
                "
                @click="notifyMe"
              >
                {{ isNotificationSent ? 'Reminder is set' : 'Notify me' }}
              </button>
              <div
                class="scheduled-popup__share-button"
                @click="openSharePopup"
              >
                <div class="scheduled-popup__share-icon" />
              </div>
              <button
                v-if="!isMobile"
                class="scheduled-popup__share-button"
                @click="openConfirmPopup"
              >
                +
              </button>
            </div>
            <div class="scheduled-popup__store-info">
              <div class="scheduled-popup__store-logo">
                <img
                  :src="creator.picture"
                  :alt="creator.name"
                  @click="goToChannel"
                >
              </div>
              <div class="scheduled-popup__store-profile">
                <div class="scheduled-popup__store-title" @click="goToChannel">
                  {{ creator.name }}
                </div>
                <div class="scheduled-popup__store-followers">
                  {{ creator.followers }} Followers
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="products.length" class="scheduled-popup__separator" />
        <div v-if="products.length" class="scheduled-popup__products-section">
          <h3 class="scheduled-popup__products-header">
            Product mentioned
          </h3>
          <div class="scheduled-popup__products-container">
            <div
              v-for="product in products"
              :key="product.id"
              class="scheduled-popup__product"
              @click="openProduct(product)"
            >
              <div class="scheduled-popup__product-poster__wrapper">
                <img
                  class="scheduled-popup__product-poster"
                  :src="product.poster"
                >
              </div>
              <p class="scheduled-popup__product-title">
                {{ product.title }}
              </p>
              <span class="item-price-value">
                {{ getFormattedCurrency(product.currency) || '$' }}{{ product.price }}
              </span>
              <span v-if="product.original_price" class="item-price-value original">
                {{ getFormattedCurrency(product.currency) || '$' }}{{ product.original_price }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import dayjs from 'dayjs';
  import { amsClient } from 'src/service/ams';
  import formatDate from 'src/filters/formatDate.js';
  import generateIcsFile from 'src/utils/generateIcsFIle';
  import { downloadFile } from 'src/utils/downloadFile';
  import Auth from '@/service/authService';
  import { getFormattedCurrency } from 'vimmi-web-utils/cjs/formatCurrency';
  import CarouselItem from 'src/components/swiper-carousel/carousel-item/carousel-item.vue';
  import { mapActions } from 'vuex';
  import HlsVideoService from 'src/service/hls-video';
  import { formatDateTime } from '@/shared/helpers/date';

  export default {
    name: 'ScheduledEventDialog',
    components: { CarouselItem },
    data() {
      return {
        showModal: false,
        item: null,
        products: [],
        isMobile: false,
        isNotificationSent: false,
        cachedPreviewMedia: Object.create(null),
        promoReady: false,
        promoMuted: true,
        promoVideo: null,
        promoLink: null,
        prevTitle: null,
      };
    },
    computed: {
      eventStart() {
        return formatDateTime(new Date(this.item?.start.replace(/ /g, 'T') + 'Z'));
      },
      creator() {
        return this.item?.creator;
      },
      livePromoObjLink() {
        return !!this.item?.promo_vod ? `/play/${this.item?.promo_vod}/` : null;
      },
      storePath() {
        return `/s/${this.item?.creator.slug}`;
      },
      eventPath() {
        return this.item?.seo_link;
      },
    },
    watch: {
      item(val) {
        this.prevTitle = document.title;
        this.$bus.$emit('updateTitle', val.title);
      },
      async showModal(val) {
        if (!val) {
          this.$bus.$emit('updateTitle', this.prevTitle);
          this.$refs.promoVideo.removeEventListener('ended', this.handlePromoEnd);
          // ToDo WEB2-4195 remove if its not breaks something
          const indexOfLiveLink = this.$route.fullPath.indexOf('/l/');
          const channelLink = indexOfLiveLink !== -1 ? this.$route.fullPath.slice(0, indexOfLiveLink) + '/' : this.$route.fullPath;
          this.$bus.$emit('setCurrentPage', channelLink);
          this.hlsVideoService.clear();

          this.$refs.promoVideo.muted = true;
          this.promoReady = false;
          this.promoMuted = true;
          this.promoVideo = null;
        } else {
          this.$emit('change:modal', 'scheduledEventModal', val);

          await this.preparePromoLink();

          const isPromoReady = !!(this.item.promo_vod && this.showModal && this.promoLink && this.promoVideo?.head?.duration);

          if (isPromoReady) {
            await this.runPromo();
            this.promoReady = isPromoReady;
          }
        }
      },
    },
    async created() {
      this.hlsVideoService = new HlsVideoService();
    },
    mounted() {
      this.$bus.$on('resizeWindow', this.checkWidth);
      this.$bus.$on('ToggleScheduleEventDialog', async (item = null) => {
        console.log('item', item);

        if (!item) {
          this.item = null;
          this.showModal = false;
          return;
        }

        if (typeof item.creator === 'string' && item.storefront) {
          this.item = {
            ...item,
            creator: {
              ...item.storefront,
            },
          };
        } else {
          this.item = item;
        }

        // ToDo WEB2-4195 remove if its not breaks something
        this.$bus.$emit('setCurrentPage', `${this.storePath}/l/${this.item.slug}`);
        this.showModal = true;
        this.checkWidth();

        const currentUser = Auth.get('user');

        amsClient
          .callAms(`/shoprzapi/remind_me/${this.item.id}/`, {
            method: 'get',
            headers: { sid: currentUser.sid },
          })
          .then((data) => {
            this.isNotificationSent = data.info.reminder;
          });

        this.products = [];

        if (this.item?.shopoffer) {
          const res = await amsClient.callAms(
            `/shop/offer/${this.item.shopoffer}/`,
            { cache: false },
          );

          if (res.items.length) {
            this.getProductLink(res.items[0].purchase);
            this.products = res.items;
          }
        }
      });
    },
    unmounted() {
      this.$bus.$off('resizeWindow');
      this.$bus.$off('ToggleScheduleEventDialog');
    },
    methods: {
      ...mapActions({
        getProductLink: 'upcomingModule/getProductLink',
      }),
      async openProduct(product) {
        this.showModal = false;
        this.$router.push(`/product/${product.slug}`);
      },
      getFormattedCurrency: getFormattedCurrency,
      close() {
        this.showModal = false;
        this.isNotificationSent = false;
      },
      notifyMe() {
        try {
          const currentUser = Auth.get('user');

          if (currentUser.guest) {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'login',
              force: true,
              outsideClose: false,
              message: '',
              formId: 'fromPlayer',
            });
            return;
          }

          amsClient
            .callAms(`/shoprzapi/remind_me/${this.item.id}/`, {
              method: this.isNotificationSent ? 'delete' : 'post',
              headers: { sid: currentUser.sid },
            })
            .then(() => {
              this.$stats.send('notify_on', {}, { item: this.item });
              this.isNotificationSent = !this.isNotificationSent;
            });
        } catch (e) {
          console.error(e);
        }
      },
      async runPromo() {
        this.cachedPreviewMedia = {
          link: this.promoLink,
          isVertical: this.promoVideo.head.media_is_vertical,
        };

        if (this.cachedPreviewMedia) {
          this.hlsVideoService.bindVideo(
            this.$refs.promoVideo,
            this.cachedPreviewMedia?.link || this.cachedPreviewMedia,
          );
        }

        if (this.hlsVideoService.canPlay()) {
          await this.hlsVideoService.play(this.isMobile ? 1 : undefined);
          this.$refs.promoVideo.addEventListener('ended', this.handlePromoEnd);
        }
      },
      async preparePromoLink() {
        if (this.livePromoObjLink) {
          this.promoVideo = await amsClient.callAms(this.livePromoObjLink, { cache: false });
          const template = await amsClient.callAms(`/playtemplate/`);

          if (this.promoVideo?.head?.mid && this.promoVideo?.head?.media_state !== 'upload') {
            this.promoLink = template.info.promo.replaceAll('{MID}', this.promoVideo.head.mid);
          } else {
            this.promoReady = false;
          }
        } else {
          this.promoReady = false;
        }
      },
      togglePromoSound() {
        if (this.$refs.promoVideo) {
          this.promoMuted = !this.promoMuted;
          this.$refs.promoVideo.muted = !this.$refs.promoVideo.muted;
        }
      },
      handlePromoEnd() {
        this.promoReady = false;
      },
      openConfirmPopup() {
        const CONFIRM_CONFIG = {
          centered: true,
          size: 'confirm',
          cancelTitle: 'No',
          okTitle: 'Yes',
        };

        this.$bvModal
          .msgBoxConfirm(
            'This show is not started yet. Do you want to add the show to your calendar so you will be reminded?',
            CONFIRM_CONFIG,
          )
          .then((confirm) => {
            if (confirm) {
              this.generateCalendarFile();
            }
          });
      },
      openSharePopup() {
        // const eventLink = this.item.seo_link;
        const eventLink = location.pathname;

        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${eventLink}?q=${this.item.creator?.owner_id}`,
          currentStoreName: this.item.creator.name,
          item: this.item,
        });
      },
      goToChannel() {
        this.$router.push(this.storePath);
        this.close();
      },
      generateCalendarFile() {
        const description = this.item.description
          ?.split('\n')
          .map((el) => `${el}`)
          .join('');

        const eventLinkRaw = this.item.seo_link;
        const ownerId = this.item.creator.owner_id;
        const eventLink = `${location.origin}/${eventLinkRaw.slice(1)}?q=${ownerId}`;
        const startDate = formatDate(
          new Date(this.item.start.replace(/ /g, 'T') + 'Z'),
          'yyyyMMddTHHmmss',
        );
        const endDate = formatDate(
          new Date(this.item.end.replace(/ /g, 'T') + 'Z'),
          'yyyyMMddTHHmmss',
        );
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let summerTime = null;
        const timeOffset = new Date().getTimezoneOffset() / 60;
        if (new Date().isDstObserved()) {
          summerTime = {
            tzFrom: timeOffset < 0 ? `+0${-(timeOffset + 1)}00` : `-0${timeOffset + 1}00`,
            tzTo: timeOffset < 0 ? `+0${-(timeOffset)}00` : `-0${timeOffset}00`,
            byMonth: '3',
          };
        }
        const eventData = {
          title: this.item.title,
          description,
          eventLink,
          startDate,
          endDate,
          timezone,
          summerTime,
        };

        const calendarData = generateIcsFile(eventData);
        downloadFile('calendar', 'ics', calendarData);
      },
      checkWidth() {
        this.isMobile = window.innerWidth < 1024;
      },
    },
  };
</script>

<style scoped lang="scss" src="./scheduled-event-dialog.scss" />