class AnalyticsUtils {
  constructor() {

  }

  normaliseScreenType(link) {
    if (!link) {
      return 'unknow';
    }
    link = Array.isArray(link) ? link[0] : link;
    const [empty, type, id] = link.split('/');
    if (type === 'screen') {
      return `${type}/${id}`;
    }
    return type;
  }
}

export default new AnalyticsUtils();
