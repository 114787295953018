import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import popup from './popup/popup.store';
import player from './player/player.store';
import checkout from './checkout/checkout.store';
import authenticate from './auth/auth.store';
import allStoreCategories from './allStoreCategories/allStoreCategories';
import catalogProducts from './catalogProducts/catalogProducts';
import vodPreview from './vodPreview/vodPreview';
import shopMenu from './shopMenu/index';
import searchPage from './searchPage/searchPage';
import chachedPreviewMedia from './chachedPreviewMedia/chachedPreviewMedia';
import dataAreasLive from './dataAreasLive/dataAreasLive';
import termsAgreement from './terms-of-use/termsOfUse';
import upcomingModule from './upcomingModule/upcomingModule';
import searchInStore from './searchInStore/searchInStore';
import floatPlayer from '@/shared/store/float-player/float-player.store';
import popupPlayer from '@/shared/store/popup-player/popup-player.store';

Vue.use(Vuex);

const debug = (process.env.NODE_ENV !== 'production');

export default new Vuex.Store({
  strict: debug,
  modules: {
    popup,
    authenticate,
    checkout,
    allStoreCategories,
    catalogProducts,
    vodPreview,
    searchPage,
    chachedPreviewMedia,
    dataAreasLive,
    termsAgreement,
    upcomingModule,
    searchInStore,
    player,
    floatPlayer,
    popupPlayer,
    shopMenu,
  },
  plugins: (debug) ? [createLogger] : [],
});
