<template>
  <b-modal 
    v-model="showModal"
    centered 
    :size="'lg'"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="badges-modal">
      <button class="close-btn" @click="simpleCloseModal">
        <img :src="require(`@/assets/icons/icon-close.svg`)">
      </button>
      <h5>
        Sertified Stores
      </h5>

      <div class="badges-modal__divider"/>

      <div class="badges-modal__description">
        <h5>
          What is a certified store?
        </h5>

        <p>
          The Certified Shop label guarantees verified customer reviews,
          verified, reliable web shops, secure payment and refund methods,
          appropriate delivery times
        </p>
      </div>

      <div class="badges-modal__badges-block">
        <h5>
          Badges
        </h5>

        <div
          class="badges-modal__badges-block--content"
        >
          <div
            v-for="(badge, name) in badges"
            :key="badge.id"
            class="badge-section"
          >
            <badge
              :badge="badge"
              :title="name"
              :wide-mode="true"
            />
            <div 
              class="badge-section__description"
              v-html="badge.description"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import Badge from "@/components/badge/badge";

  export default {
    name: 'BadgesModal',
    components: {
      Badge,
    },
    data() {
      return {
        showModal: false,
        badges: null,
      };
    },
    watch: {
      showModal(val) {
        this.$emit('change:modal', 'badgesModal', val);
      },
    },
    created() {
      this.$bus.$on('openBadgesModal', badges => {
        this.showModal = true;

        this.badges = badges;
      });
    },
    beforeDestroy() {
      this.$bus.$off('openBadgesModal');
    },
    methods: {
      simpleCloseModal() {
        this.showModal = false;
      },
    },
  }
</script>

<style>
  .badge-section__description * {
    font-family: 'Roboto' !important;
    color: #000 !important;
  }

  .badge-section__description a {
    color: #333953 !important;
  }
</style>

<style lang="scss" scoped>
  .badges-modal {
    position: relative;
    max-width: 625px;
    max-height: 700px;
    margin: auto;
    padding: 15px 20px;
    background-color: $button-white-txt-color;
    z-index: 1051;
    overflow-y: scroll;

    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
    }

    .close-btn {
      right: 10px;
      top: 10px;
      width: 10px;
      height: 10px;
      position: absolute;
      border: none;
      outline: none;
    }

    &__badges-block {
      .badge-section {
        width: 100%;
        border: 1px solid $main-border-color;
        padding: 20px 20px 5px;
        margin: 0px 0px 20px 0px;
      }
    }

    &__divider {
      background-color: $main-border-color;
      width: 100%;
      height: 1px;
      margin: 0px 0px 25px 0px;
    }
  }
</style>