<template>
  <div class="sp-checkout--header">
    <div class="sp-checkout--header-title">
      {{ title }}
    </div>
<!--    <div class="sp-checkout&#45;&#45;header-subtitle">-->
<!--      -->
<!--    </div>-->
    <slot name="subtitle"/>
  </div>
</template>

<script>
  export default {
    name: 'CheckoutHeader',
    props: {
      title: {
        type: String,
        default: 'Checkout',
      }
    },
  };
</script>

<style lang="scss" scoped>
  .sp-checkout--header {
    color: #343434;
    margin-bottom: 1rem;
    &-title {
      font-size: 20px;
      font-weight: 500;
    }
    //&-subtitle {
    //  font-size: 15px;
    //}
  }
</style>
