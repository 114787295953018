<template>
  <div class="sp-payment">
    <checkout-header-component>
      <template #subtitle>
        <span class="sp-active-breadcrump" @click="onShippingInfoChange">Shipping</span>
        <span> > </span>
        <span>Payment</span>
      </template>
    </checkout-header-component>

    <form @submit="handleSubmitForm">
      <shipping-info-component
        class="sp-payment-shipping-info"
        :lines="shippingInfo"
        @change="onShippingInfoChange"
      />
      <h3 class="sp-payment--subhead">
        Payment method
      </h3>
      <form-accordion-component
        :fields="paymentMethods"
        class="sp-payment--card-form"
        @select:filed="handleSelectPaymentMethod"
      >
        <template #body(card)>
          <!--            card-->
          <slot name="paymentForm"/>

          <h3 class="sp-payment--subhead">
            Billing Address
          </h3>

          <form-accordion-component
            :fields="billingAddressTypes"
            class="sp-payment--billing_address-form"
            @select:filed="handleSelectBillingAddress"
          >
            <template #body(same-as-shipping)>
              Same as shipping
            </template>
            <template #body(other)>
              <address-form-container @input="handleSetAddress"/>
            </template>
          </form-accordion-component>
        </template>
        <template #body(cash)>
          You will have to pay for your order upon delivery
        </template>
      </form-accordion-component>

      <button-component
        class="sp-payment-form-btn"
        label="Complete Order"
        height="50px"
        :width="isMobile ? '100%' : '220px'"
        :disabled="loading"
        @click.prevent="handleOrderComplete"
      />
    </form>
  </div>
</template>

<script>
  import CheckoutHeaderComponent from '../components/checkout-header.component';
  import ShippingInfoComponent from '../components/shipping-info.component';
  import FormInputComponent from '@/shared/components/form-components/form-input.component';
  import FormAccordionComponent from '../components/form-accordion.component';
  import ButtonComponent from '@/shared/components/button.component';
  import AddressFormContainer from '@/shared/features/address-autocomplete-form/address-form.container';

  export default {
    name: 'PaymentComponent',
    components: {
      AddressFormContainer,
      ShippingInfoComponent,
      ButtonComponent,
      FormInputComponent,
      FormAccordionComponent,
      CheckoutHeaderComponent,
    },
    props: {
      shippingInfo: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isMobile: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        stripe: null,
        stripeElements: null,
        paymentMethods: [
          {
            name: 'card',
            title: 'Credit Card',
            selected: true,
            icon: 'credit-card',
            id: 1,
          },
          {
            name: 'cash',
            title: 'Cash on Delivery',
            selected: false,
            icon: 'money-bill',
            id: 2,
          },
          {
            name: 'paypal',
            title: 'Paypal',
            selected: false,
            disabled: true,
            icon: 'not-avail-tag',
            id: 3,
          },
          {
            name: 'amazon',
            title: 'Amazon Pay',
            selected: false,
            disabled: true,
            icon: 'not-avail-tag',
            id: 4,
          },
        ],
        billingAddressTypes: [
          {
            name: 'same-as-shipping',
            title: 'Same as Shipping Address',
            selected: true,
            hideBody: true,
            id: 1,
          },

          {
            name: 'other',
            title: 'Use a Different billing address',
            selected: false,
            id: 2,
          },
        ],
        form: {
          billingAddress: null,
        },
      };
    },
    mounted() {},
    methods: {
      handleSetAddress(address) {
        this.form = {
          billingAddress: address,
        };
      },
      // handleChangeSelectedStatus(isSelectedAddress) {
      //   this.isSelectedAddress = isSelectedAddress;
      // },
      onShippingInfoChange() {
        this.$emit('step:back');
      },
      handleOrderComplete() {
        const selected = this.paymentMethods.find((method) => method.selected);

        return this.$emit('order:complete', {
          billingAddress: this.form.billingAddress,
          isPayNow: selected.name === 'card',
        });
      },
      handleSelectBillingAddress(field) {
        this.billingAddressTypes = this.billingAddressTypes.map((type) => {
          if (type.id === field.id) {
            return { ...field, selected: true };
          }

          return { ...type, selected: false };
        });
      },
      handleSelectPaymentMethod(field) {
        this.paymentMethods = this.paymentMethods.map((method) => {
          if (field.name === 'card') {
            this.$emit('payment-form:create');
          }

          if (method.id === field.id) {
            return { ...field, selected: true };
          }

          return { ...method, selected: false };
        });
      },
      handleSubmitForm() {
        this.$emit('submit', this.form);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-payment {
    &-shipping-info {
      margin-bottom: 1rem;
    }
    &--subhead {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #343434;
      margin-bottom: 16px;
    }

    &--user-form {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 8px;
      margin-bottom: 48px;
    }

    &--card-form,
    #paymentForm,
    &--shipping-form,
    &-form-btn {
      margin-bottom: 1.5rem;
    }
  }
</style>
