Date.prototype.stdTimezoneOffset = function () {
  const jan = new Date(this.getFullYear(), 0, 1);
  const jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

export default function (date, format, utc) {
  if (!date || !date.getDate) {
    if (Number.isInteger(date)) {
      date = new Date(date);
    } else {
      return '';
    }
  }
  const fullMonthNames = [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ];

  const shortMonthNames = [
    'Ian',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const leftpadDate = (number) => (number < 10 ? `0${number}` : number);

  const day = utc ? date.getUTCDate() : date.getDate();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const hours = utc ? date.getUTCHours() : date.getHours();
  const minutes = utc ? date.getUTCMinutes() : date.getMinutes();
  const seconds = utc ? date.getUTCSeconds() : date.getSeconds();

  if (format) {
    /*
 from https://docs.angularjs.org/api/ng/filter/date
'yyyy': 4 digit representation of year (e.g. AD 1 => 0001, AD 2010 => 2010)
'yy': 2 digit representation of year, padded (00-99). (e.g. AD 2001 => 01, AD 2010 => 10)
'MMMM': Month in year (January-December)
'MMM': Month in year (Jan-Dec)
'MM': Month in year, padded (01-12)
'M': Month in year (1-12)
'dd': Day in month, padded (01-31)
'd': Day in month (1-31)
'HH': Hour in day, padded (00-23)
'H': Hour in day (0-23)
'hh': Hour in AM/PM, padded (01-12)
'h': Hour in AM/PM, (1-12)
'mm': Minute in hour, padded (00-59)
'm': Minute in hour (0-59)
'ss': Second in minute, padded (00-59)
's': Second in minute (0-59)
 */
    let res = format;
    res = res.replace(/yyyy/g, year);
    res = res.replace(/yy/g, year % 100);
    res = res.replace(/dd/g, leftpadDate(day));
    res = res.replace(/d/g, day);
    res = res.replace(/HH/g, leftpadDate(hours));
    res = res.replace(/H/g, hours);
    res = res.replace(/hh/g, leftpadDate(hours % 12));
    res = res.replace(/h/g, hours % 12);
    res = res.replace(/mm/g, leftpadDate(minutes));
    res = res.replace(/m/g, minutes);
    res = res.replace(/ss/g, leftpadDate(seconds));
    res = res.replace(/s/g, seconds);
    res = /MMMM/.test(res)
      ? res.replace(/MMMM/g, fullMonthNames[month])
      : /MMM/.test(res)
        ? res.replace(/MMM/g, shortMonthNames[month])
        : /MM/.test(res)
          ? res.replace(/MM/g, leftpadDate(month + 1))
          : /M/.test(res) ? res.replace(/M/g, month + 1) : res;
    return res;
  }
  return `${year}-${leftpadDate(month)}-${leftpadDate(day)}T${leftpadDate(hours)}:${leftpadDate(minutes)}:${leftpadDate(seconds)}`;
}
