import { render, staticRenderFns } from "./iframe-popup.vue?vue&type=template&id=263d6d12"
import script from "./iframe-popup.vue?vue&type=script&lang=js"
export * from "./iframe-popup.vue?vue&type=script&lang=js"
import style0 from "./iframe-popup.vue?vue&type=style&index=0&id=263d6d12&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports