<template>
  <div
    class="lock-icon-component"
    title="Subscribe to watch the video"
  >
    <i class="fa fa-lock"></i>
  </div>
</template>
<script>
  export default {
    name: 'LockIconComponent',
    props: {},
  };
</script>

<style scoped lang="scss">
  .lock-icon-component {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    width: 25px;
    height: 25px;
    padding: 8px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.70);

    &:hover {
      cursor: pointer;
    }

    i {
      color: white;
      font-size: 0.8rem;
    }
  }
</style>