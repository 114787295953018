<template>
  <div class="preview">
    <svg
      width="108"
      height="118"
      viewBox="0 0 108 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.7264 8.91577C74.5484 7.84869 74.2103 6.88761 73.7312 6.05767C73.074 4.91946 72.1458 4.0236 71.0001 3.44746C67.0725 1.45584 61.7754 3.97662 59.1966 9.06983L58.5339 10.3784L72.7938 17.5925L73.4565 16.284C74.6675 13.891 75.1168 11.2739 74.7264 8.91577ZM71.8337 9.39796C72.0535 10.7254 71.9043 12.1801 71.4119 13.6116L62.5647 9.13066C64.5066 6.36089 67.5323 4.97534 69.6758 6.05938C70.8054 6.63122 71.5724 7.81891 71.8337 9.39796Z"
        fill="#E3E5FC"
      />
      <path
        d="M61.1615 7.92107C58.9349 6.7947 56.3924 7.29605 55.5118 9.03302L40.4614 29.3483C39.5808 31.0853 40.6819 33.4306 42.9105 34.5603L59.3955 42.9062L69.432 12.1072L61.1615 7.92107Z"
        fill="#A7ADFF"
      />
      <path
        d="M67.017 42.7976L75.0277 18.9181C75.9083 17.1811 74.8072 14.8358 72.5786 13.7061L69.43 12.1093L59.3935 42.9083L61.3653 43.9062C63.5939 45.036 66.1364 44.5346 67.017 42.7976Z"
        fill="#978CFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.9789 49.7254L4.53172 51.0669L23.2658 43.3428L22.713 42.0052C19.9218 35.2399 13.4503 31.4668 8.28937 33.593C5.70696 34.66 3.85906 36.9757 3.07815 40.1264C2.33976 43.1147 2.66063 46.5244 3.9789 49.7254ZM9.39459 36.2758C10.0325 36.0091 10.7051 35.8853 11.3894 35.8853C14.3159 35.8853 17.4936 38.1778 19.415 41.8002L6.17043 47.2588C5.5055 45.0475 5.40112 42.8053 5.89209 40.826C6.44491 38.5953 7.68973 36.9794 9.39459 36.2758Z"
        fill="#E3E5FC"
      />
      <path
        d="M4.06618 46.9435C0.996664 48.2076 -0.708195 51.2076 0.281474 53.6044L7.68853 86.422C8.67819 88.8188 11.999 89.7467 15.0685 88.4825L37.8 79.1154L15.4706 42.2426L4.06618 46.9435Z"
        fill="#2FC485"
      />
      <path
        d="M44.3064 71.3333L27.1921 42.513C26.2024 40.1162 22.8816 39.1884 19.8121 40.4525L15.4668 42.2424L37.7962 79.1153L40.5178 77.9942C43.5912 76.7301 45.296 73.7301 44.3064 71.3333Z"
        fill="#21A66E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.3686 60.6213L76.9701 62.3382L101.015 67.9108L101.414 66.1939C102.367 62.0838 102.079 57.9202 100.602 54.4786C99.0391 50.8462 96.3528 48.4251 93.0439 47.6568C89.7326 46.8925 86.2546 47.8865 83.258 50.4582C80.4127 52.8997 78.3222 56.5111 77.3686 60.6213ZM92.2481 51.097C92.8434 51.234 93.4143 51.4603 93.9486 51.7688C95.3721 52.5907 96.5535 54.0015 97.3572 55.8686C98.3384 58.1536 98.6587 60.8674 98.2928 63.6582L81.2902 59.7152C82.1906 57.0536 83.6706 54.7538 85.5572 53.1347C87.6794 51.3145 90.0568 50.5878 92.2481 51.097Z"
        fill="#E3E5FC"
      />
      <path
        d="M79.4259 57.9152C75.4844 56.9992 71.677 58.771 70.9605 61.8488L55.1215 99.6537C54.4091 102.734 57.0491 105.998 60.9906 106.914L90.1696 113.684L94.0621 61.3131L79.4259 57.9152Z"
        fill="#FAE026"
      />
      <path
        d="M102.129 110.559L105.508 69.8622C106.221 66.782 103.581 63.5175 99.639 62.6015L94.0618 61.3067L90.1693 113.677L93.6614 114.486C97.607 115.404 101.414 113.633 102.129 110.559Z"
        fill="#FDC510"
      />
    </svg>

    <h3 class="title">Your Shoprz Cart is empty</h3>
    <router-link to="/catalog/" class="link"> Go to Catalog </router-link>
  </div>
</template>

<script>
  export default {
    name: 'CartPreview',
  };
</script>

<style lang="scss" scoped>
  .preview {
    border: 1px solid #edf1f4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    max-height: 380px;

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #343434;
      margin: 20px 0px;
    }

    .link {
      padding: 12px 20px;
      height: 43px;
      background: #ffffff;
      border: 1px solid #ecf0f7;
      border-radius: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #343434;
    }
  }
</style>
