export default [
  {
    name: 'cart',
    path: '/cart',
    component: () => import('./cart.view.vue'),
    meta: {
      title: 'Cart',
    },
  },
];
