const isClosedTicket = (message) => {
  if (!message) {
    return null;
  }

  const description = JSON.parse(message?.attachments?.[0]?.description || '{}');
  return description.status === 'closed';
};

export default isClosedTicket;
