import { amsClient } from '@/service/ams';

const makeRequest = async (query, variables = {}) => {
  const response = await fetch(`${process.env.VUE_APP_API_GATEWAY_BASE_URL}/ecommerce/2023-04/graphql.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      source: 'portal',
      authorization: `Bearer ${amsClient.get('user')?.sid}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await response.json();

  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data;
};

export default makeRequest;
