function easeInOutCubic(t) {
  return t < 0.5
    ? 4 * t * t * t
    : 1 - (-2 * t + 2) ** 3 / 2;
}

async function smoothScroll(element, options) {
  const currentPosition = element.scrollTop;
  const targetPosition = options.top;
  const direction = currentPosition < targetPosition ? 'down' : 'up';
  const distance = Math.abs(targetPosition - currentPosition);

  let animationFrameId;
  const startTime = Date.now();
  return new Promise((resolve) => {
    function animateScroll() {
      const elapsed = Date.now() - startTime;
      const easing = easeInOutCubic(elapsed / options.duration);
      const newPosition = direction === 'down'
        ? currentPosition + (distance * easing)
        : currentPosition - (distance * easing);
      if ((direction === 'down' && newPosition >= targetPosition) || (direction === 'up' && newPosition <= targetPosition)) {
        element.scrollTop = targetPosition;
        cancelAnimationFrame(animationFrameId);
        resolve();
        return;
      }
      element.scrollTop = newPosition;
      animationFrameId = requestAnimationFrame(animateScroll);
    }

    animationFrameId = requestAnimationFrame(animateScroll);
  });
}

export default smoothScroll;
