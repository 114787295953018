const baseApiUrl = 'https://api.geoapify.com/v1';

const apiKey = process.env.VUE_APP_GEOAPIFY_API_KEY;

export default {
  async makeRequest(url, method, options) {
    const fullUrl = new URL(`${baseApiUrl}${url}`);
    fullUrl.searchParams.set('apiKey', apiKey);
    fullUrl.searchParams.set('format', 'json');

    const queryParams = options.query || {};
    Object.keys(options.query || {}).forEach((key) => {
      if (queryParams[key]) {
        fullUrl.searchParams.set(key, queryParams[key]);
      }
    });

    const response = await fetch(fullUrl.toString(), {
      method,
      ...(options?.body && { body: JSON.stringify(options) }),
    });

    if (!response.ok) {
      const err = await response.text();
      throw new Error(err);
    }

    return response.json();
  },
};
