<template>
  <div v-click-outside="close" class="select-with-search">
    <div
      class="select-with-search__select"
      :style="{ height }"
      @click="toggle()"
    >
      <span
        class="select-with-search__value"
        :class="{
          'select-with-search__value--selected': isSelectedValue,
        }"
      >
        {{ isSetValue }}
      </span>
    </div>

    <div
      class="select-with-search__options"
      :class="{ hidden: !visible, visible }"
    >
      <div class="select-with-search__search">
        <input
          v-model="searchString"
          type="text"
          :placeholder="`Search ${placeholder}`"
          class="select-with-search__search-input"
        />

        <img
          :src="require('@/assets/icons/account/search.svg')"
          alt=""
          class="select-with-search__search-icon"
        />
      </div>

      <ul v-if="filterBySearch.length" class="select-with-search__list">
        <li
          v-for="(item, index) in filterBySearch"
          :key="index"
          class="select-with-search__list-item"
          @click="select(item.label)"
        >
          {{ item.label }}
        </li>
      </ul>

      <div v-else class="select-with-search__empty-list">
        {{ placeholder }} not found
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomSelectWithSearch',
    props: {
      placeholder: {
        type: String,
        default: 'Select option',
      },
      selectedValue: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: [],
      },
      height: {
        type: String,
        default: '51px',
      },
    },
    data() {
      return {
        value: '',
        visible: false,
        searchString: '',
      };
    },
    computed: {
      filterBySearch() {
        return this.options.filter((item) =>
          item.label.toLowerCase().includes(this.searchString.toLowerCase()),
        );
      },

      isSelectedValue() {
        return this.value || this.selectedValue;
      },

      isSetValue() {
        return this.value
          ? this.value
          : this.selectedValue
          ? this.selectedValue
          : this.placeholder;
      },
    },
    methods: {
      close() {
        this.visible = false;
      },
      toggle() {
        this.visible = !this.visible;
      },
      select(option) {
        this.value = option;

        const item = this.options.find(o => o.label === option)

        this.toggle();

        this.$emit('handleSelectOption', option, item);
      },
    },
  };
</script>

<style scoped lang="scss">
  .select-with-search {
    position: relative;

    &__select {
      display: flex;
      align-items: center;
      padding: 0px 15px;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      border: 1px solid #edf1f4;
      border-radius: 7px;
      height: 51px;
    }

    &__value {
      color: #757575;

      &--selected {
        color: #343434;
      }
    }

    &__search {
      padding: 12px 15px;
      display: flex;
      border-bottom: 1px solid #e7ebf1;
    }

    &__search-input {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #343434;
      outline: none;

      &::placeholder {
        color: #b3bfcb;
      }
    }

    &__options {
      padding-right: 15px;
      display: none;
      width: 100%;
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      border: 1px solid #e7ebf1;
      border-radius: 5px;
      background: #fff;
      overflow: hidden;
      z-index: 100;

      &.visible {
        display: block;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__list-item {
      padding: 15px;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: #343434;
      transition: 0.3s ease all;
      cursor: pointer;

      &:hover {
        background: #f2f4f9;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e7ebf1;
      }
    }

    &__empty-list {
      padding: 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #343434;
    }
  }
</style>
