import device from '../../service/device-service.js';

export default [
  {
    name: 'account',
    path: '/account',
    component: () => import('./account.view'),
    meta: {
      page: 'account',
      title: 'My account',
      requiresAuth: true,
    },
    beforeEnter(to, _, next) {
      if (
        to.name === 'account' && !(device.isAnyMobile() || device.isMobileSize())
      ) {
        next('/account/profile');
      } else {
        next();
      }
    },
    children: [
      {
        name: 'profile',
        path: '/account/profile',
        component: () => import('./features/profile/profile.view'),
        meta: {
          page: 'profile',
          title: 'Profile',
        },
      },

      {
        name: 'orders',
        path: '/account/orders',
        component: () => import('./features/orders/orders.view'),
        meta: {
          page: 'orders',
          title: 'Orders',
        },
      },

      {
        name: 'order-id',
        path: '/account/orders/:id',
        component: () => import('./features/order/order.view'),
        meta: {
          page: 'order',
          title: 'Order',
          hideSideMenu: true,
        },
      },
      {
        name: 'request-return',
        path: '/account/orders/:id/request-return',
        component: () => import('./features/request-return/request-return.view'),
        meta: {
          page: 'request-return',
          title: 'Request return',
          hideSideMenu: true,
        },
      },
      {
        name: 'wishlist',
        path: '/account/favorites',
        component: () => import('./features/favorites/favorites.view'),
        meta: {
          page: 'wishlist',
          title: 'Wishlist',
        },
      },
      {
        name: 'notifications',
        path: '/account/notifications',
        component: () => import('./features/notification/notification.view'),
        meta: {
          page: 'notifications',
          title: 'Notifications',
        },
      },
      {
        name: 'message-center',
        path: '/account/message-center',
        component: () => import('@/entities/account/features/message-center/message-center.view'),
        meta: {
          page: 'message-center',
          title: 'Messaging Center',
        },
      },
    ],
  },
];
