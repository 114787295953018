<template>
  <div>
    <button
      :class="{ 'follow-btn-followed': isFollowed(), [variant]: variant }"
      :disabled="!creatorId"
      @click="toggleFollow()"
    >
      {{ isFollowToggle }}
    </button>
  </div>
</template>
<script>
  import Auth from 'src/service/authService';
  import ShoprzStore from 'src/store/shoprz';

  export default {
    name: 'FollowButton',
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      variant: {
        type: String,
        default: () => '',
      },
    },
    computed: {
      isFollowToggle() {
        return this.isFollowed() ? 'Following' : 'Follow';
      },
      creatorId() {
        return this.item?.creator?.id || this.item?.storefront?.id;
      },
    },
    methods: {
      isFollowed() {
        return ShoprzStore.isChannelFollowed(this.creatorId);
      },

      toggleFollow() {
        if (!Auth.isAuth() || Auth.isGuest()) {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            forceCloseBtn: true,
          });
          return;
        }

        let item = null;

        if (this.item.storefront) {
          item = {
            ...this.item,
            creator: {
              ...this.item.creator,
              id: this.item.storefront.id,
            },
          };
        } else {
          item = this.item;
        }


        if (ShoprzStore.isChannelFollowed(this.creatorId)) {
          this.$stats.send('channel_unfollow', {}, { item });
        } else {
          this.$stats.send('channel_follow', {}, { item });
        }
        ShoprzStore.toggleFollow(this.creatorId);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .follow-btn {
    width: 132px;
    height: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: $main-color;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 100px;

    &-followed {
      background-color: transparent;
      border: 1px solid $main-color;
      color: $main-color;
    }

    &:hover {
      background-color: $main-color;
      color: $seller-white;
    }
  }
</style>
