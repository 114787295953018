<template>
  <div>
    <scheduled-event-dialog @change:modal="changeModalState"/>
    <sharing-dialog @change:modal="changeModalState"/>
    <term-of-use-dialog @change:modal="changeModalState"/>
    <term-of-use-error @change:modal="changeModalState"/>
    <badges-modal @change:modal="changeModalState"/>
    <sign-out-dialog @change:modal="changeModalState"/>
    <live-finished-popup @change:modal="changeModalState"/>
  </div>
</template>
<script>
  import ScheduledEventDialog from './scheduled-event-dialog/scheduled-event-dialog';
  import SharingDialog from './sharing-dialog/sharing-dialog';
  import TermOfUseDialog from './term-of-use-dialog/term-of-use-dialog';
  import TermOfUseError from './term-of-use-error/term-of-use-error';
  import BadgesModal from './badges-modal/badges-modal';
  import SignOutDialog from './sign-out-dialog/sign-out-dialog';
  import LiveFinishedPopup from './live-finished-popup/live-finished-popup';

  export default {
    name: 'AppModals',
    components: {
      ScheduledEventDialog,
      SharingDialog,
      TermOfUseDialog,
      TermOfUseError,
      BadgesModal,
      SignOutDialog,
      LiveFinishedPopup,
    },
    data() {
      return {
        scheduledEventModal: false,
        sharingModal: false,
        termOfUseModal: false,
        termOfUseErrorModal: false,
        badgesModal: false,
        signOutDialog: false,
        liveFinishedPopup: false,
      };
    },
    computed: {
      modalState() {
        return this.scheduledEventModal || this.sharingModal || this.termOfUseModal || this.termOfUseErrorModal || this.badgesModal || this.signOutDialog || this.liveFinishedPopup;
      },
    },
    watch: {
      modalState(newState) {
        this.$emit('change:modal', newState);
      },
    },
    methods: {
      changeModalState(name, state) {
        this[name] = state;
      },
    },
  }
</script>
<style></style>
