<template>
  <div class="sp-order">
    <div class="sp-order--header">
      <i class="far fa-check-circle fa-lg" />
      <checkout-header-component title="Your order has been placed">
        <template #subtitle>
          <span
            >We sent an email to <strong>{{ customerEmail }}</strong> with your
            order confirmation and bill.</span
          >
        </template>
      </checkout-header-component>
    </div>
    <span class="sp-separator" />
    <div class="sp-order--info">
      <div class="sp-order--info_block">
        <div class="sp-order--info_block-left">Order number</div>
        <div class="sp-order--info_block-right">
          <div>{{ orderNum }}</div>
          <i
            class="far fa-clone fa-xs sp-cursor-pointer"
            @click="copyToClipboard"
          />
        </div>
      </div>
      <div class="sp-order--info_block">
        <div class="sp-order--info_block-left">Time Placed</div>
        <div class="sp-order--info_block-right">
          <div>{{ orderPlacedAt || 'unknown' }}</div>
        </div>
      </div>
    </div>
    <h3 class="sp-order--subhead">Order information</h3>
    <order-info-component class="sp-order--info" :data="orderInfo" />
    <button-component
      v-if="!hideGoToStoreButton"
      class="sp-order-form-btn"
      label="Go to Store"
      :width="isMobile ? '100%' : '220px'"
      height="50px"
      @click.prevent="$router.push('/')"
    />
  </div>
</template>

<script>
  import CheckoutLayout from '../layout/checkout.layout';
  import CheckoutHeaderComponent from '../components/checkout-header.component';
  import OrderSummaryComponent from '../components/order-summary.component';
  import OrderInfoComponent from '../components/order-info.component.vue';
  import FormInputComponent from 'src/shared/components/form-components/form-input.component';

  import FormAccordionComponent from '../components/form-accordion.component';
  import ButtonComponent from 'src/shared/components/button.component';

  export default {
    name: 'OrderComponent',
    components: {
      OrderInfoComponent,
      ButtonComponent,
      CheckoutLayout,
      FormInputComponent,
      FormAccordionComponent,
      CheckoutHeaderComponent,
      OrderSummaryComponent,
    },
    props: {
      customerEmail: {
        type: String,
        default: '',
        require: true,
      },
      orderNum: {
        type: String,
        default: '',
      },
      orderPlacedAt: {
        type: String,
        default: null,
      },
      orderInfo: {
        type: Array,
        default: () => [],
        require: true,
      },
      // products: {
      //   type: Array,
      //   default: () => [],
      // },
      hideGoToStoreButton: {
        type: Boolean,
        default: false,
      },
      isPopup: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        stripe: null,
        stripeElements: null,
        paymentMethods: [
          {
            name: 'card',
            title: 'Credit Card',
            selected: true,
            icon: 'credit-card',
            id: 1,
          },
          {
            name: 'cash',
            title: 'Cash on Delivery',
            selected: false,
            icon: 'money-bill',
            id: 2,
          },
          {
            name: 'paypal',
            title: 'Paypal',
            selected: false,
            disabled: true,
            icon: 'not-avail-tag',
            id: 3,
          },
          {
            name: 'amazon',
            title: 'Amazon Pay',
            selected: false,
            disabled: true,
            icon: 'not-avail-tag',
            id: 4,
          },
        ],
        billingAddressTypes: [
          {
            name: 'same-as-shipping',
            title: 'Same as Shipping Address',
            selected: true,
            hideBody: true,
            id: 1,
          },

          {
            name: 'other',
            title: 'Use a Different billing address',
            selected: false,
            id: 2,
          },
        ],
        form: {
          email: '',
          firstName: '',
          lastName: '',
        },
      };
    },
    computed: {
      isMobile() {
        return this.isPopup || window.innerWidth <= 990; //will real variable for checking mobile
      },
    },
    mounted() {},
    methods: {
      copyToClipboard() {
        navigator.clipboard.writeText(this.orderNum);
        alert('copied');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-order {
    .sp-separator {
      height: 1px;
      background-color: #f0f2f7;
      width: 100%;
      display: block;
    }
    &--header {
      display: flex;
    }
    &--info {
      margin: 1rem 0;
    }
    &--info_block {
      color: #343434;
      font-weight: 400;
      font-size: 15px;
      border: #f0f2f6 1px solid;
      border-radius: 5px;
      background-color: #f8f9fc;
      display: flex;
      margin: 0.25rem 0;
      justify-content: space-between;
      padding: 0.75rem;
      &-right {
        display: flex;
        align-items: center;
        i {
          margin: 0 0.5rem;
          color: #96a0b6;
        }
      }
    }
    .fa-check-circle {
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      color: #21a66e;
    }
    &--header-title {
      font-size: 20px;
    }
    &--header-subtitle {
      font-size: 15px;
    }

    &-shipping-info {
      margin-bottom: 1rem;
    }
    &--subhead {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #343434;
      margin-bottom: 16px;
    }

    &--user-form {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 8px;
      margin-bottom: 48px;
    }

    &--card-form,
    #paymentForm,
    &--shipping-form,
    &-form-btn {
      margin-bottom: 1.5rem;
    }
  }
</style>
