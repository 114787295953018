export default `

mutation returnRequest($input: ReturnRequestInput!) {
  returnRequest(input: $input) {
    return {
      id
      status
    }
    userErrors {
      field
      message
    }
  }
}
`;
