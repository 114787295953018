import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App';
import axios from 'axios';
import 'intersection-observer';
import VueAxios from 'vue-axios';
import vueHeadful from 'vue-headful';
import vueScrollBehavior from 'vue-scroll-behavior';
import Vue2TouchEvents from 'vue2-touch-events';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import { ShopPlayer } from 'shop-player-v2/dist/shop-player-v2.cjs';
import Config from '@/service/config';

import {
  AlertPlugin,
  CollapsePlugin,
  DropdownPlugin,
  ModalPlugin,
  NavbarPlugin,
  SpinnerPlugin,
  FormSelectPlugin,
  VBVisiblePlugin,
  FormCheckboxPlugin, ToastPlugin, ImagePlugin,
} from 'bootstrap-vue';
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';

import ObserveVisibility from 'vue-observe-visibility';
import { LoggerVuePlugin } from 'logger-js';
import router from './router';
import store from './store';
import './directives';
import './filters';
import i18n from './lang/index';
import VueErrorHandler from './service/vue-error-handler';
import ErrorPlugin from './plugins/errorPlugin';
import BusPlugin from './plugins/event-bus';
import BodyPlugin from './plugins/body-style';
import { default as FeatureTogglePlugin } from 'src/plugins/feature-toggle';
import VueAnalyticsPlugin from 'src/plugins/analytics/vue-analytics-plugin';
import VueClipboard from 'vue-clipboard2';
import { amsClient } from '@/service/ams';
import VueRouter from 'vue-router';
import '@/core/directives/click-outside';
import * as Sentry from "@sentry/vue";

console.info(
  `PORTAL version: ${process.env.VUE_APP_PROJECT_VERSION}, build date: ${process.env.VUE_APP_PROJECT_DATE}`,
);

// const isProduction = process.env.NODE_ENV === 'production';
const isProduction = true;

const DEV_STORE = {
  'is_live': false,
  'itype': 'event_host_admin',
  'slug': 'versunie',
  'is_active': true,
  'is_published': true,
  'id': '64ec60cde8cf62554a99335f',
  'followers': 0,
  'apptk': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6IjY0ZWM2MGNkZThjZjYyNTU0YTk5MzM1ZiIsIm9pZCI6ImlVVGNQSUl2Q0E1NFhVZXdkaW4raHBKQk1ZbS9iM09hWGZQR0F2V2h6YkE9Iiwic2l0ZXMiOltdLCJ0aW1lIjoxNjk4NjU3MjQ0fQ.Q0k1aamtzB4C9XyJOig2BKEotJ6m29BAchPUC2yym7s',
  'howner_id': 'iUTcPIIvCA54XUewdin+hpJBMYm/b3OaXfPGAvWhzbA=',
  'state': 'active',
  'name': 'Versunie',
  'picture': 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.52631.cust.original.png',
  'description': 'Versunie BV is de grootste gespecialiseerde groothandel in verse producten van Nederland. Vanuit Heerhugowaard leveren wij dagelijks aan meer dan zevenhonderd',
  'logo': 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.52571.cust.original.png',
  'favicon': 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.52741.cust.original.png',
  'store_id': '5742711000004333043',
  'title': 'Versunie',
  'poster': 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.52631.cust.original.png',
  'standalone': true,
  'audience': 'public',
  'store_type': 'vendor',
  'provider_id': 'tp9w7fogmxn5mmkd',
  'payout_commission': '11.00',
  'payout_srv_id': 'acct_1NlSq44gCkBgL078',
  'link': '/screen/versunie/',
  'boarding': null,
  'is_ready': true,
  'is_protected': false,
};

Vue.prototype.$isProduction = isProduction;

Vue.use(LoggerVuePlugin, {
  pluginName: '$logger',
  project: {
    name: 'Portal',
    style: 'background: #1f55ff; color: #ffffff',
  },
  debug: {
    enabled: true,
    level: 0,
  },
});

Vue.use(VueClipboard);
Vue.use(VueMask);
// SEO
Vue.use(VueAnalyticsPlugin);

Vue.use(BusPlugin);
Vue.use(FeatureTogglePlugin);
Vue.use(VueAxios, axios);

Vue.use(vueScrollBehavior, { router });

Vue.use(ErrorPlugin);
Vue.use(BodyPlugin);

// Bootstrap
Vue.use(DropdownPlugin);
Vue.use(NavbarPlugin);
Vue.use(AlertPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormSelectPlugin);
Vue.use(VBVisiblePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ToastPlugin);
Vue.use(ImagePlugin);
Vue.use(Vue2TouchEvents);
Vue.use(Vuelidate);

Vue.use(ObserveVisibility);

Vue.component('vue-headful', vueHeadful);
Vue.component('shop-player', ShopPlayer);

Vue.config.errorHandler = VueErrorHandler;
Vue.config.productionTip = !isProduction;
Vue.config.devtools = true;
Vue.config.performance = true;

Vue.prototype.$isPreviewMode = _isPreviewMode();
Vue.prototype.$location = location.origin;
Vue.prototype.$floatingVodRef = null;
Vue.prototype.$floatingLiveRef = null;
// Vue.prototype.$floatingWrapperRef = null;
Vue.prototype.$accountIframeRef = null;
Vue.prototype.$shopingCartIframeRef = null;

Vue.component('vue-bootstrap-autocomplete', VueBootstrapAutocomplete);

amsClient.on('conf', () => {
  const amsStoreConf = amsClient.get('conf.store');

  const store = isProduction
    ? amsStoreConf
    : DEV_STORE;

  if (!isProduction) {
    amsClient.set('conf.store', DEV_STORE);
    Config.set('provider_id', DEV_STORE.provider_id);
  }

  Vue.prototype.$standaloneStoreName = (store?.standalone && store?.slug) || null;
  Vue.prototype.$isStandaloneStore = !!Vue.prototype.$standaloneStoreName;

  console.log('Vue.prototype.$standaloneStoreName', Vue.prototype.$standaloneStoreName);
  console.log('Vue.prototype.$isStandaloneStore', Vue.prototype.$isStandaloneStore);

  // Vue.prototype.$standaloneStoreName = 'active';
  // Vue.prototype.$isStandaloneStore = true;
  loadPortalApplication(store);

  Sentry.init({
    Vue,
    dsn: "https://27c5bc442a54576c9aebc6a2ff121951@o1277054.ingest.us.sentry.io/4507447658741760",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: amsClient.get('conf.portal.env'),
    release: process.env.VUE_APP_PROJECT_VERSION,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ['localhost'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.3,
  });
});

const nonStoreRouter = new VueRouter({
  base: process.env.BASE_URL,
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes: [
    {
      path: '*',
      name: 'Error',
      component: () => import('@/views/ErrorPage/ErrorPage'),
      meta: {
        hideHeader: true,
        hideFooter: true,
        hideGoToHomePage: true,
      },
    },
  ],
});

const loadPortalApplication = (isStorePresent) => {
  const homeRoute = {
    path: '/',
    name: 'Home',
    component: Vue.prototype.$isStandaloneStore
      ? () => import('@/views/Page/Page')
      : () => import('@/views/MainHome/Home'),
    meta: {
      ...(Vue.prototype.$isStandaloneStore && {
        creatorPage: true,
        seoPage: true,
      }),
    },
  };

  router.addRoute(homeRoute);

  new Vue({
    i18n,
    el: '#app',
    router: isStorePresent ? router : nonStoreRouter,
    store,
    components: { App },
    template: '<App/>',
  });
};

export const vapp = new Vue();