import { amsClient } from '@/service/ams';
import ShopifyStorefrontApi from '@/service/shopify-storefronts';

const GUEST_CHECKOUT_ID_KEY = 'shoprz_guest_checkout_id';

const state = () => ({
  cart: null,
  loading: false,
  checkoutId: null,
  guestCheckoutId: null,
  cartItemsCount: 0,
  cartItems: [],
  costDetails: {},
  buyNowProduct: null,
  completeOrder: false,
});

const getters = {
  loading(state) {
    return state.loading;
  },

  getCheckoutId(state) {
    return state.checkoutId;
  },
  getGuestCheckoutId(state) {
    return state.guestCheckoutId;
  },
  getAvailableCheckoutId(state) {
    return state.checkoutId || state.guestCheckoutId;
  },

  getCartItems(state) {
    return state.cart?.lines?.edges || [];
  },

  getCartItemsCount(state) {
    if (!state.cart) {
      return null;
    }
    return state.cart?.lines?.edges.reduce((acc, item) => {
      acc += item.node.quantity;

      return acc;
    }, 0);
  },

  getCostDetails(state) {
    return state.cart?.cost || {};
  },

  getCheckoutUrl(state) {
    return state.cart?.checkoutUrl || '';
  },

  getBuyNowProduct(state) {
    return state.buyNowProduct;
  },

  getCompleteOrder(state) {
    return state.completeOrder;
  },
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },

  setCheckoutId(state, id) {
    state.checkoutId = id;
  },
  setGuestCheckoutId(state, id) {
    state.guestCheckoutId = id;
  },
  setCart(state, cart) {
    const cartCost = cart.cost;

    // TODO: remove after POC
    const subtotalAmount = cart.lines.edges.reduce((acc, item) => {
      const itemCustomPriceAttribute = item.node.attributes.find((a) => a.key === '__customPrice');
      const itemPrice = itemCustomPriceAttribute?.value || item.node.merchandise.price.amount;

      acc += +itemPrice * item.node.quantity;

      return acc;
    }, 0);

    state.cart = {
      ...cart,
      cost: {
        ...cartCost,
        subtotalAmount: {
          amount: subtotalAmount,
          currencyCode: cartCost.subtotalAmount.currencyCode,
        },
      },
    };
  },
  setBuyNowProduct(state, product) {
    state.buyNowProduct = product;
  },

  setCompleteOrder(state, value) {
    state.completeOrder = value;
  },

  setShippingTotal(state, value) {
    const cartCost = state.buyNowProduct ? state.buyNowProduct.cost : state.cart.cost;

    cartCost.totalShippingAmount = {
      amount: value,
    };

    const totalAmount = +cartCost.subtotalAmount.amount + (cartCost.totalTaxAmount?.amount || 0);

    cartCost.totalAmount.amount = totalAmount + value;
  },

  setTaxTotal(state, value) {
    const cartCost = state.buyNowProduct ? state.buyNowProduct.cost : state.cart.cost;

    cartCost.totalTaxAmount = {
      amount: value,
    };

    const totalAmount = +cartCost.subtotalAmount.amount + (cartCost.totalShippingAmount?.amount || 0);

    cartCost.totalAmount.amount = totalAmount + value;
  },
};

const actions = {
  init({ commit }) {
    commit('setGuestCheckoutId', localStorage.getItem(GUEST_CHECKOUT_ID_KEY));
  },
  async createNewGuestCheckoutId({ commit }) {
    const checkout = await ShopifyStorefrontApi.createCheckout();

    commit('setGuestCheckoutId', checkout.id);

    localStorage.setItem(GUEST_CHECKOUT_ID_KEY, checkout.id);
  },
  async useGuestCheckoutId({ state, dispatch, commit }) {
    if (!state.guestCheckoutId) {
      await dispatch('createNewGuestCheckoutId');

      // console.log('CHECKOUT: newGuestCheckoutId', state.guestCheckoutId);
    } else {
      // console.log('CHECKOUT: existsGuestCheckoutId', state.guestCheckoutId);

      const checkout = await ShopifyStorefrontApi.getCheckout(
        state.guestCheckoutId,
      );

      if (!checkout || !!checkout?.completedAt) {
        const checkout = await ShopifyStorefrontApi.createCheckout();
        // console.log('CHECKOUT: updateCompletedGuestCheckoutId', checkout.id);
        commit('setGuestCheckoutId', checkout.id);
        localStorage.setItem(GUEST_CHECKOUT_ID_KEY, checkout.id);
      }
    }
  },
  async useAuthUserCheckoutId({ commit }) {
    const currentUserCheckout = await amsClient.callAms('/shopify/checkout_id/');

    let userCheckoutId = currentUserCheckout.head.checkout_id;

    if (userCheckoutId) {
      commit('setCheckoutId', userCheckoutId);

      // console.log('CHECKOUT: useUserCheckoutId', userCheckoutId);

      const checkout = await ShopifyStorefrontApi.getCheckout(userCheckoutId);

      // console.log('CHECKOUT: userCheckout', checkout);

      if (!checkout || !!checkout?.completedAt) {
        const checkout = await ShopifyStorefrontApi.createCheckout();

        // console.log('CHECKOUT: updateCompletedUserCheckoutId', checkout.id);

        commit('setCheckoutId', checkout.id);

        userCheckoutId = null;
      }
    } else {
      const checkout = await ShopifyStorefrontApi.createCheckout();

      commit('setCheckoutId', checkout.id);

      // console.log('CHECKOUT: newUserCheckoutId', checkout.id);
    }

    return !userCheckoutId;
  },
  async mergeCarts({ state, commit }) {
    if (state.guestCheckoutId) {
      const guestCheckout = await ShopifyStorefrontApi.getCheckout(
        state.guestCheckoutId,
      );

      const guestLineItems = guestCheckout.lineItems.edges.map(({ node }) => ({
        variantId: node.variant.id,
        quantity: node.quantity,
        customAttributes: node.customAttributes,
      }));

      // console.log('CHECKOUT: mergeItems', guestLineItems);

      if (guestLineItems.length) {
        await ShopifyStorefrontApi.addItemsToCheckout(
          state.checkoutId,
          guestLineItems,
        );
      }

      commit('setGuestCheckoutId', null);

      localStorage.removeItem(GUEST_CHECKOUT_ID_KEY);
    }
  },
  reset({ commit }) {
    commit('setGuestCheckoutId', null);
    commit('setCheckoutId', null);
  },
  async loadCurrentCheckoutCartItems({ commit, getters }) {
    try {
      const checkout = await ShopifyStorefrontApi.getCheckout(
        getters.getAvailableCheckoutId,
      );

      const itemsCount = checkout.lineItems.edges.reduce(
        (acc, item) => acc + item.node.quantity,
        0,
      );

      commit('setCartItemsCount', itemsCount);
    } catch {}
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
