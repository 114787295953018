import { normalizeImg } from 'src/filters';

export default (el, bindings) => {
  const imgName = bindings.value.name;
  const imgType = bindings.value.type;
  const desktop = bindings.value.desktop || null;
  const mobile = bindings.value.mobile || null;
  const img = normalizeImg(imgName, imgType, desktop, mobile);
  // console.log(bindings);
  el.style.backgroundImage = (img) ? `url(${img})` : 'none';
};
