<template>
  <div class="sp-shipping">
    <checkout-header-component>
      <template #subtitle>
        <span>Shipping</span>
        <span> > </span>
        <span :class="isOrderExists ? 'sp-active-breadcrump' : 'sp-is-disabled'" @click="$emit('step:forward')">Payment</span>
      </template>
    </checkout-header-component>

    <template v-if="isMobile && !isAuth">
      <button class="sp-sign-in--button" @click="handleOpenSignInPopup">
        Sign-in
      </button>

      <span
        class="sp-separator d-flex align-items-center justify-content-between"
      >
        <hr>
        <span>Or</span>
        <hr>
      </span>
    </template>

    <form @submit.prevent="handleSubmitForm">
      <h3 class="sp-shipping--subhead">
        Contact

        <button
          v-if="!isMobile && !isAuth"
          class="sp-shipping--subhead--sign-in"
          @click="handleOpenSignInPopup"
        >
          Sign In
        </button>
      </h3>

      <div class="sp-shipping--user-form">
        <form-input-component
          v-model="form.email"
          type="email"
          :disabled="isAuth"
          placeholder="Email"
          height="50px"
        />
        <form-input-component
          v-model="form.firstName"
          placeholder="First name"
          height="50px"
        />
        <form-input-component
          v-model="form.lastName"
          placeholder="Second name"
          height="50px"
        />

        <checkbox-component v-if="!isAuth" v-model="form.allowSoftRegistration">
          <span class="ml-2">Create new account with these information</span>
        </checkbox-component>
      </div>

      <h3 class="sp-shipping--subhead">
        Delivery method
      </h3>

      <form-accordion-component
        :fields="deliveryMethods"
        class="sp-shipping--delivery-form"
        @select:filed="handleSelectDeliveryMethod"
      >
        <template #body(to-ship)>
          <address-form-container
            @input="handleSetAddress"
            @change:selected-status="handleChangeSelectedStatus"
          />

          <form-input-component
            v-model="form.phone"
            type="tel"
            placeholder="Phone"
            class="mt-2"
            height="50px"
            :mask="'+1 ###-###-####'"
          />

          <!-- <checkbox-component v-model="setDefaultAddress" class="mt-2">
            <span class="ml-2">Make this my default address</span>
          </checkbox-component> -->
        </template>

        <template #body(pick-up)>
          <h3 class="sp-shipping--pick-up-header">
            Pick up location
          </h3>

          <form-accordion-component
            :fields="storeAddress"
            hide-body
            type="secondary"
          />
        </template>
      </form-accordion-component>

      <!-- <template v-if="!isPickUpMethod">
        <h3 class="sp-shipping--subhead">
          Shipping Method
        </h3>
        <form-accordion-component
          :fields="shippingMethods"
          hide-body
          class="sp-shipping--shipping-form"
          @select:filed="handleSelectShippingMethod"
        />
      </template> -->

      <button-component
        label="Continue to Payment"
        height="50px"
        :width="isMobile ? '100%' : '220px'"
        :disabled="!canContinueToPayment || loading"
        @click.prevent="handleContinueToPayment"
      />
    </form>
  </div>
</template>

<script>
  import { email, required } from 'vuelidate/lib/validators';

  import CheckoutHeaderComponent from '../components/checkout-header.component';

  import FormInputComponent from 'src/shared/components/form-components/form-input.component';

  import FormAccordionComponent from '../components/form-accordion.component';
  import ButtonComponent from 'src/shared/components/button.component';
  import AddressFormContainer from 'src/shared/features/address-autocomplete-form/address-form.container';
  import CheckboxComponent from 'src/shared/features/checkout/components/checkbox.component';

  export default {
    name: 'ShippingComponent',

    components: {
      CheckoutHeaderComponent,
      AddressFormContainer,
      FormInputComponent,
      FormAccordionComponent,
      ButtonComponent,
      CheckboxComponent,
    },

    props: {
      products: {
        type: Array,
        default: () => [],
      },
      cost: {
        type: Object,
        default: () => ({}),
      },
      locations: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isMobile: {
        type: Boolean,
        default: false,
      },
      isOrderExists: {
        type: Boolean,
        default: false,
      },
      isRatesCalculated: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isSelectedAddress: false,
        setDefaultAddress: false,

        deliveryMethods: [
          {
            name: 'to-ship',
            title: 'To Ship',
            icon: 'truck-fast',
            selected: true,
            id: 1,
          },

          {
            name: 'pick-up',
            title: 'Pick up',
            icon: 'store',
            selected: false,
            id: 2,
          },
        ],

        shippingMethods: [
          {
            name: 'standard',
            title: 'Standard',
            selected: true,
            id: 1,
          },

          /* {
            name: 'up-to',
            title: 'Up to 3 Days',
            selected: false,
            id: 2,
          }, */
        ],

        form: {
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          allowSoftRegistration: false,

          address: {
            country: '',
            city: '',
            zip: '',
            address1: '',
            state: '',
          },
        },
      };
    },

    validations: {
      form: {
        email: {
          required,
          email,
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },

        address: {
          country: {
            required,
          },
          city: {
            required,
          },
          zip: {
            required,
          },
          address1: {
            required,
          },
        },
      },
    },

    computed: {
      isAuth() {
        return !this.$store.getters['authenticate/isGuest'];
      },
      isUser() {
        return this.$store.getters['authenticate/isUser'];
      },

      canContinueToPayment() {
        return !this.$v.$invalid && this.isRatesCalculated;
      },

      isPickUpMethod() {
        return this.deliveryMethods.some(
          (method) => method.id === 2 && method.selected,
        );
      },

      storeAddress() {
        return this.locations.map((location, index) => ({
          name: `location-${index}`,
          title: `${location.address.address1}, ${location.address.city}, ${location.address.zip}`,
          selected: index === 0,
          id: location.id,
        }));
      },
    },

    watch: {
      isAuth(value) {
        if (!value) {
          this.form = {
            ...this.form,
            email: '',
            firstName: '',
            lastName: '',
          };

          return;
        }

        this.form = {
          ...this.form,
          email: this.isUser.username,
          firstName: this.isUser.first_name,
          lastName: this.isUser.last_name,
        };
      },
    },

    mounted() {
      if (this.isAuth) {
        this.form = {
          ...this.form,
          email: this.isUser.username,
          firstName: this.isUser.first_name,
          lastName: this.isUser.last_name,
        };
      }
    },

    methods: {
      handleContinueToPayment() {
        let form = { ...this.form };

        if (this.isPickUpMethod) {
          form.address.isPickUp = true;
        }

        this.$emit('order:create', form);
      },
      handleSubmitForm() {
        this.$emit('order:create', this.form);
      },

      handleSelectDeliveryMethod(field) {
        this.deliveryMethods = this.deliveryMethods.map((method) => {
          if (method.id === field.id) {
            return {
              ...field,
              selected: true,
            };
          }

          return {
            ...method,
            selected: false,
          };
        });

        if (field.id === 2) {
          this.form.address = {
            country: this.locations[0].address.country,
            city: this.locations[0].address.city,
            zip: this.locations[0].address.zip,
            address1: this.locations[0].address.address1,
            state: this.locations[0].address.state,
          };

          this.$emit('changed:shipping-address', { type: 'pickup', address: this.form.address });

          this.isSelectedAddress = true;
        } else {
          this.form.address = {
            country: '',
            city: '',
            zip: '',
            address1: '',
            state: '',
          };
        }
      },

      handleSelectShippingMethod(field) {
        this.shippingMethods = this.shippingMethods.map((method) => {
          if (method.id === field.id) {
            return {
              ...field,
              selected: true,
            };
          }

          return {
            ...method,
            selected: false,
          };
        });
      },

      handleChangeSelectedStatus(isSelectedAddress) {
        this.isSelectedAddress = isSelectedAddress;
      },

      handleSetAddress(address, isSelectedAddress) {
        this.isSelectedAddress = isSelectedAddress;

        this.form = {
          ...this.form,
          address,
        };

        this.$emit('changed:shipping-address', { type: 'ship', address });

        // console.log('this.this.form: ', this.form);
      },

      handleOpenSignInPopup() {
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-sign-in--button {
    outline: none;
    width: 100%;
    height: 40px;
    border: 1px solid #e2e6ee;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #343434;
  }
  .sp-separator {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #b6c0d3;

    span {
      margin: 0 12px;
    }

    hr {
      flex: 1;
    }
  }
  .sp-shipping {
    &--subhead {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #343434;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--sign-in {
        outline: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #4b89e5;
      }
    }

    &--user-form {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 8px;
      margin-bottom: 48px;
    }

    &--delivery-form {
      margin-bottom: 48px;
    }

    &--pick-up-header {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #343434;
      padding-bottom: 12px;
      border-bottom: 1px solid #f0f2f6;
    }

    &--shipping-form {
      margin-bottom: 24px;
    }
  }
</style>
