<template>
  <div class="sp-order-info" :class="{ 'sp-desktop': !isMobile }">
    <div v-for="(item, ind1) in data" :key="`item_${ind1}`">
      <img :src="iconSrc(item.icon)" alt="" />
      <span class="sp-order-info--title">{{ item.title }}</span>
      <div v-for="(line, ind2) in item.lines" :key="`line_${ind2}`">
        {{ line }}
      </div>
      <span v-if="ind1 !== data.length - 1" class="sp-separator"></span>
    </div>
  </div>
</template>

<script>
  import OrderDetailsComponent from './order-details.component';
  import ProductListComponent from './product-list.component';
  import FormInputComponent from 'src/shared/components/form-components/form-input.component';

  export default {
    name: 'OrderInfo',

    components: {
      ProductListComponent,
      OrderDetailsComponent,
      FormInputComponent,
    },

    props: {
      isMobile: {
        type: Boolean,
        default: false,
      },

      data: {
        type: Array,
        default: () => [],
        require: true,
      },
    },

    data() {
      return {
        discount: '',
      };
    },
    methods: {
      iconSrc(icon) {
        try {
          return require(`@/assets/icons/icon-${icon}.svg`);
        } catch (e) {
          return '';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-order-info {
    color: #343434;
    font-size: 15px;

    &--title {
      margin-left: 0.5rem;
      font-weight: 500;
    }

    &.sp-desktop {
      border: 1px solid #f0f2f7;
      border-radius: 10px;
      padding: 1rem;
      //padding: 32px 24px 24px;

      .sp-summary--details {
        order: 2;
      }

      .sp-summary--products {
        order: 0;
      }

      .sp-summary--discount {
        order: 1;
      }
    }

    .sp-separator {
      margin: 0.5rem 0;
      height: 1px;
      background-color: #f0f2f7;
      width: 100%;
      display: block;
    }
  }
</style>
