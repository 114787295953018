import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import Config from 'src/service/config';
import { activateFeature } from '@/plugins/feature-toggle';

import lang_en from './eng';
import lang_he from './heb';

Vue.use(VueI18n);

export const messages = {
  eng: lang_en,
  heb: lang_he,
};

// ?lang=en
let langUrl = location.href.match('[\\?&]lang=([^&#s]*)');
langUrl = langUrl && langUrl.length && langUrl[1]
  ? normalizeLang(langUrl[1], 'iso-3') : null;
const activeLanguage = langUrl || Config.get('activeLang') || Config.get('languages.default');

if ((Config.get('languages.rtl') || []).indexOf(activeLanguage) !== -1) {
  activateFeature('rtl');
}
Config.set('activeLang', activeLanguage);

const i18n = new VueI18n({
  locale: activeLanguage, // set locale
  fallbackLocale: 'eng',
  messages, // set locale messages
});

export default i18n;
