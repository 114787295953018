export default `
  mutation {
    checkoutCreate(input: {}) {
      checkout {
          id
          webUrl
      }
    }
  }
`;
