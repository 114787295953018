export default {
  isUserAcceptedCookies(userId = 'guest') {
    const accepts = this.getAccepts();
    return accepts.includes(userId);
  },

  acceptCookies(userId = 'guest') {
    const accepts = this.getAccepts();
    accepts.push(userId);
    localStorage.setItem('cookies_accepted_by', JSON.stringify(accepts));
  },

  getAccepts() {
    return JSON.parse(localStorage.getItem('cookies_accepted_by') || '[]');
  },
}