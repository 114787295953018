<template>
  <div>
    <div class="animated fadeIn">
      <form
        class="auth-form need-validated"
        autocomplete="on"
        novalidate
        @submit.prevent.stop="login()"
      >
        <div>
          <h5
            v-for="(mess, index) in messages"
            :key="index"
            class="auth-message text-center mt-4 mb-4"
            :class="'message-' + mess.status"
            v-html="mess.text"
          />
        </div>
        <template>
          <div class="custom-line" />
          <div style="text-align: center">
            <span class="social-login-title">Sign in with</span>
          </div>
          <div class="social-login-wrapper">
            <button
              type="button"
              class="btn btn-login align-self-center"
              @click="loginFromGoogle"
            >
              <div class="log-button-wrapper">
                <span class="log-icon">
                  <img
                    :src="require('src/assets/icons/google-logo.svg')"
                    alt=""
                  />
                </span>
                <span v-t="{ path: 'LOGIN.BY_GOOGLE' }" class="log-title" />
              </div>
            </button>
            <button
              type="button"
              class="btn btn-login align-self-center"
              @click="fbLogin"
            >
              <div class="log-button-wrapper">
                <span class="log-icon">
                  <img
                    :src="require(`@/assets/icons/icon-fb.svg`)"
                    class="icon-fb"
                    alt=""
                  />
                </span>
                <span v-t="{ path: 'LOGIN.BY_FACEBOOK' }" class="log-title" />
              </div>
            </button>
          </div>
        </template>
        <div class="divider">
          <span>or</span>
        </div>
        <div class="form-wrap">
          <div
            class="form-input form-input-auth auth-form-group auth-form-email"
            :class="{ 'error-line': errorLine.email }"
          >
            <input
              id="email"
              v-model="accessData.login"
              :disabled="waiting"
              type="email"
              name="login"
              class="form-control"
              :class="{ 'is-invalid': validator.login }"
              @change="isResendConfirm = false"
              @focus="toggleLabelUp('email')"
              @blur="toggleLabelDown('email')"
              @input="checkFields('email')"
            />
            <label
              for="email"
              :class="{ 'float-label': toggleEmail }"
              class="form-group__label"
            >
              <span
                v-t="{ path: 'LOGIN.EMAIL' }"
                :class="{
                  'error-label-color': errorLabelEmail,
                  'toggle-label-color': LabelColorEmail,
                }"
              />
            </label>
          </div>
          <div v-if="checkField.email" class="valid-field">
            <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="" />
            Please enter a valid email
          </div>
        </div>
        <div class="form-wrap">
          <div
            class="form-input form-input-auth auth-form-group auth-form-pass"
            :class="{ 'error-line': errorLine.password }"
          >
            <input
              id="password"
              v-model="accessData.password"
              :disabled="waiting"
              :type="isShowPassword ? 'text' : 'password'"
              name="password"
              autocomplete="current-password"
              class="form-control"
              @focus="toggleLabelUp('password')"
              @blur="toggleLabelDown('password')"
              @input="checkFields('password')"
            />
            <label
              for="password"
              :class="{ 'float-label': togglePassword }"
              class="form-group__label"
            >
              <span
                v-t="{ path: 'LOGIN.PASSWORD' }"
                :class="{
                  'error-label-color': errorLabelPassword,
                  'toggle-label-color': LabelColorPassword,
                }"
              />
            </label>
            <button
              class="show-password-toggler"
              type="button"
              @click="onShowHidePassword"
            >
              <img
                v-if="!isShowPassword"
                class="icon-eye"
                :src="require(`@/assets/icons/icon-eye.svg`)"
                alt=""
              />
              <img
                v-else
                class="icon-eye"
                :src="require(`@/assets/icons/icon-eye-hide.svg`)"
                alt=""
              />
            </button>
          </div>
          <div v-if="checkField.password" class="valid-field">
            <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="" />
            Please enter a password
          </div>
        </div>
        <div
          v-if="isResendConfirm"
          class="form-group custom-control custom-checkbox auth-form-remember"
        >
          <div class="ml-auto">
            <button
              :hidden="!isResendConfirm"
              :disabled="!isResendConfirm"
              type="button"
              class="btn p-0"
              @click="resendConfirmation"
            >
              <span class="text-truncate align-middle small">
                <span v-t="{ path: 'LOGIN.RESEND_CONFIRM' }" />
              </span>
            </button>
          </div>
        </div>

        <div
          class="d-flex flex-column form-group auth-form-group justify-content-center align-items-center"
        >
          <button
            type="button"
            class="btn auth-form-link-title align-self-start p-0"
            @click="showForgotPopup"
          >
            <span v-t="{ path: 'LOGIN.FORGOT_PASS' }" class="form-link-title" />
          </button>

          <button
            :disabled="waiting || disableLogin"
            type="submit"
            class="btn auth-login"
          >
            <span class="d-inline-block text-truncate align-middle w-100">
              <span v-t="{ path: 'LOGIN.SING_IN' }" />
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import Auth from '@/service/authService';
  import { isActiveFeature } from '@/plugins/feature-toggle';
  import { amsClient } from '@/service/ams';
  import AuthService from '@/service/authService';
  import { email, required } from 'vuelidate/lib/validators';

  export default {
    name: 'LoginBlock',

    props: {
      isActive: {
        type: [Boolean, String],
        default: false,
      },
      defaultMessages: {
        type: String,
        default: '',
      },
      isShowRegister: {
        type: Boolean,
      },
    },
    data() {
      return {
        timeToShowMessage: 4 * 1000,
        accessData: {
          login: '',
          password: '',
        },
        checkField: {
          email: '',
          password: '',
        },
        errorLine: {
          email: false,
          password: false,
        },
        fix18n: {},
        messages: [],
        timeOutID: 0,
        validator: {},
        waiting: false,
        isResendConfirm: false,
        isShowPassword: false,
        toggleEmail: false,
        togglePassword: false,
        errorLabelEmail: false,
        errorLabelPassword: false,
        LabelColorPassword: false,
        LabelColorEmail: false,
      };
    },

    validations: {
      accessData: {
        email: {
          email,
        },

        password: {
          required,
        },
      },
    },

    computed: {
      disableLogin() {
        return this.$v.$invalid;
      },
    },
    watch: {
      defaultMessages: {
        immediate: true,
        handler: function (newVal) {
          this.$nextTick(() => {
            if (newVal) {
              this.messages.splice(0, 1, {
                status: 'title',
                text: newVal,
              });
            }
          });
        },
      },
    },
    mounted() {
      this.validator = {};
      this.fix18n = this.$i18n;
      this.hookTabindexParent();
    },
    methods: {
      toggleLabelUp(data) {
        if (data === 'email' && this.accessData.login === '') {
          this.toggleEmail = true;
        }

        if (data === 'password' && this.accessData.password === '') {
          this.togglePassword = true;
        }
      },
      toggleLabelDown(data) {
        if (data === 'email' && this.accessData.login === '') {
          this.errorLabelEmail = true;
          this.toggleEmail = false;
          this.LabelColorEmail = false;
          this.checkField.email = true;
          this.errorLine.email = true;
        }

        if (data === 'password' && this.accessData.password === '') {
          this.errorLabelPassword = true;
          this.togglePassword = false;
          this.LabelColorPassword = false;
          this.checkField.password = true;
          this.errorLine.password = true;
        }
      },
      checkFields(data) {
        if (data === 'email' && this.accessData.login !== '') {
          this.LabelColorEmail = true;
          this.checkField.email = false;
          this.errorLine.email = false;
        }

        if (data === 'password' && this.accessData.password !== '') {
          this.LabelColorPassword = true;
          this.checkField.password = false;
          this.errorLine.password = false;
        }
      },
      async fbLogin() {
        const path = location.pathname + location.search;

        Auth.saveCurrentPath(path);

        // localStore.set('show-account-after-sso-login', +!this.skipAccountPopupAfterLogin);

        location.href = await AuthService.getUrlForFacebookLogin();
      },
      async loginFromGoogle() {
        const path = location.pathname + location.search;

        Auth.saveCurrentPath(path);

        // localStore.set('show-account-after-sso-login', +!this.skipAccountPopupAfterLogin);

        location.href = await AuthService.getUrlForGoogleLogin();
      },
      translateVal(val) {
        return this.fix18n.t(val);
      },
      onShowHidePassword() {
        this.isShowPassword = !this.isShowPassword;
      },
      close() {
        if (this.waiting) {
          return;
        }

        if (!this.isShowRegister && this.isActive) {
          this.$emit('update:isActive', false);
        }
      },
      showRegister() {
        this.$emit('update:isShowRegister', true);
      },
      resendConfirmation() {
        this.isResendConfirm = false;

        amsClient.account
          .resendRegisterConfirm(this.accessData.login)
          .then((response) => {
            this.accessData.login = '';
            this.accessData.password = '';
            this.messages = [];

            this.showMessage(
              {
                status: 'success',
                text: this.translateVal('LOGIN.SUCCESSFULLY_SENT'),
              },
              3000,
            );
          });
      },
      async login() {
        if (!this.accessData.login) {
          this.errorLabelEmail = true;
          this.toggleEmail = false;
          this.LabelColorEmail = false;
          this.checkField.email = true;
          this.errorLine.email = true;
        }

        if (!this.accessData.password) {
          this.errorLabelPassword = true;
          this.togglePassword = false;
          this.LabelColorPassword = false;
          this.checkField.password = true;
          this.errorLine.password = true;
        }

        if (!this.accessData.login || !this.accessData.password) {
          this.showMessage({
            status: 'warning',
            text: this.translateVal('LOGIN.PLEASE_FILL_IN_FIELDS'),
          });

          return;
        }
        // else if (!this.accessData.password) {
        //   this.showMessage({
        //     status: 'warning',
        //     text: this.translateVal('LOGIN.YOUR_PASSWORD_MUST'),
        //   });

        //   return;
        // }

        this.waiting = true;

        if (isActiveFeature('guest-users')) {
        }

        try {
          await Auth.loginByAMS({
            user: this.accessData.login,
            pass: this.accessData.password,
          });

          this.validator = {};

          this.showMessage(
            {
              status: 'success',
              text: this.translateVal('LOGIN.SUCCESSFULLY_AUTHORIZED'),
            },
            3000,
          );

          this.$bus.$emit('loggedIn');
          this.$store.commit('authenticate/setUser', Auth.get('user'));

          // this.$bus.$emit('reloadChatUser');

          const timeout = setTimeout(() => {
            this.close();
            clearTimeout(timeout);
          }, 3000);
        } catch (error) {
          if (error?.message.includes('Can not create session for')) {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'concurrency',
              force: true,
              message: this.$i18n.t('CONCURRENCY_ALERT_MESS.MESSAGE'),
              actions: {
                close: this.$i18n.t('OK'),
              },
            });
          } else if (
            error.message &&
            error.message.toLowerCase().indexOf('not active') !== -1
          ) {
            this.isResendConfirm = true;
            this.showMessage(
              {
                status: 'error',
                text: this.translateVal('LOGIN.ACCOUNT_NOT_ACTIVE'),
              },
              8000,
            );
          } else if (
            error.message &&
            error.message.toLowerCase().includes('forbidden')
          ) {
            this.showMessage(
              {
                status: 'error',
                text: this.translateVal('LOGIN.ACCOUNT_DO_NOT_EXIST'),
              },
              8000,
            );
          } else {
            this.validator = {
              login: [this.translateVal('LOGIN.PLEASE_FILL_IN_LOGIN')],
            };
            setTimeout(() => {
              this.validator = {};
            }, 8000);
          }
        } finally {
          this.waiting = false;
        }
      },
      showMessage(messageData, time) {
        this.waiting = true;
        // apolieshchuk: WEB2-2980. call me if it is break something
        this.messages = [];

        this.messages.push(messageData);

        // apolieshchuk: WEB2-2980. call me if it is redundant
        // setTimeout(() => {
        //   let pos = this.messages.indexOf(messageData);
        //   this.messages.splice(pos, 1);
        //
        //   this.waiting = false;
        // }, time || this.timeToShowMessage);
      },
      showForgotPopup() {
        this.$emit('update:isShowLogin', false);
        this.$emit('update:isShowForgot', true);
      },
      hookTabindexParent() {
        const sheduledEvent = document.querySelector('.scheduled-event-dialog');

        if (!sheduledEvent) {
          return;
        }

        sheduledEvent
          .querySelector('.modal-content')
          .removeAttribute('tabindex');
      },
    },
  };
</script>
<style lang="scss">
  .auth-form-pass {
    position: relative;
  }

  .show-password-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    &:hover,
    &:focus,
    &:active {
      outline: none;
      border: none;
    }
    svg {
      color: #333953;
    }
  }

  .auth-form-link-title {
    color: $main-color !important;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 20px;

    &:hover {
      color: $auth-hover-color !important;
      border-bottom: 1px solid $main-color;
    }
  }

  .social-login {
    &-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 10px;
      padding-top: 10px;
      width: 100%;
      margin-bottom: 20px;

      .btn-login {
        width: 140px;
        border-radius: 5px;
        border: 1px solid rgba(217, 224, 238, 1);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(47, 59, 69, 1);
        font-size: 14px;

        &:hover {
          color: #000;
        }
      }

      .log-button-wrapper {
        align-items: center;
        display: flex;

        .log-icon {
          img {
            height: 20px;
            width: 20px;
          }
        }

        .log-title {
          line-height: 33px;
          padding-left: 10px;
        }
      }
    }

    &-title {
      opacity: 1;
      color: rgba(127, 128, 149, 1);
      font-size: 16px;
      line-height: 20px;
    }
  }

  .icon-fb {
    padding: 3px 6px;
    background-color: #3b5998;
  }

  .divider {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    span {
      opacity: 1;
      color: rgb(127, 128, 149);
      font-size: 12px;
      line-height: 16px;
    }

    ::before {
      content: '';
      position: absolute;
      left: 25px;
      top: 9px;
      width: 102px;
      height: 1px;
      opacity: 1;
      background-color: rgb(217, 224, 238);
    }

    ::after {
      content: '';
      position: absolute;
      right: 25px;
      top: 9px;
      width: 102px;
      height: 1px;
      opacity: 1;
      background-color: rgb(217, 224, 238);
    }
  }

  .auth-login {
    min-width: 100%;
    border-radius: 5px !important;
    padding: .95rem !important;

    span {
      & {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  .invalid-feedback {
    img {
      margin-right: 8px;
    }
  }
</style>
