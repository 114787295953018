const changePropertyFn = (el, bindings, event) => {
  const prop = bindings.value.properties.split(',');
  const color = (event.type === 'mouseenter') ? bindings.value.color : '';

  prop.forEach((item) => {
    el.style[item] = color;
  });
  // console.log(prop, color);
};

export default {
  bind: (el, bindings) => {
    // console.log('Bind changeProperty');
    // let changeProperty = ;
    el.event = changePropertyFn.bind(null, el, bindings);

    // console.log(changeProperty.bind(null, el, bindings));
    // console.log(changeProperty(el, bindings, {type:''}));
    el.addEventListener('mouseenter', el.event);
    el.addEventListener('mouseleave', el.event);
  },
  unbind: (el, bindings) => {
    // console.log('Bind unbind');
    // let changeProperty = changePropertyFn.bind(null, el, bindings);

    el.removeEventListener('mouseenter', el.event);
    el.removeEventListener('mouseleave', el.event);
  },
};
