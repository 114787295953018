// import GatewayApi from '@/shared/api/checkout/gateway';
import CheckoutApi from './api';
import ShopifyStorefrontApi from '@/service/shopify-storefronts';
import { loadStripe } from '@stripe/stripe-js/pure';
import dayjs from 'dayjs';
import Vue from 'vue';
import Config from '@/service/config';
import { amsClient } from '@/service/ams';
import { formatDateTime } from '@/shared/helpers/date';

class CheckoutService {
  static #instance = null;

  static PAYMENT_METHOD_NOW = 'Pay now';
  static PAYMENT_METHOD_COD = 'Cash on delivery';
  static SHIPPING_METHOD_PICKUP = 'Pick up';
  static SHIPPING_METHOD_STANDARD = 'Standard';
  // #draftOrder = null;

  #defaultShippingPackage = {
    id: 'SM_FLAT_RATE_BOX',
    title: 'Small Flat Rate Box',
    dimensions: {
      length: '8.625',
      width: '5.375',
      height: '1.625',
      weight: '0.13',
    },
  };

  #order = Vue.observable({
    draft: null,
    number: null,
    customerInfo: null,
    completedAt: null,
    placedAt: null,
    paymentMethod: CheckoutService.PAYMENT_METHOD_NOW,
    shippingMethod: CheckoutService.SHIPPING_METHOD_STANDARD,
  });
  // #orderCustomerInfo = null;
  // #orderCompletedAt = null;

  #stripe = null;
  #stripeElements = null;
  #stripeAcc = 'acct_1Nk1XJQHURKTmK5E'; // ToDo acct_1Nk1XJQHURKTmK5E - fallback. remove it in the future
  #stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
  #stripePaymentSecret = null;

  /**
   * @returns {CheckoutService}
   */
  static get instance() {
    if (!this.#instance) {
      this.#instance = new CheckoutService();
    }

    return this.#instance;
  }

  constructor() {
    // ToDo acct_1Nk1XJQHURKTmK5E - fallback. remove it in the future
    this.#stripeAcc = amsClient.get('conf.store.payout_srv_id') || 'acct_1Nk1XJQHURKTmK5E';

    this.init();
  }

  async init() {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });

    this.#stripe = await loadStripe(this.#stripePublicKey, {
      stripeAccount: this.#stripeAcc,
    });
  }

  get shippingAddressInfo() {
    return this.#addressInfo('shippingAddress');
  }

  get billingAddressInfo() {
    return this.#addressInfo('billingAddress');
  }

  get isOrderExists() {
    return !!this.#order.draft;
  }

  get orderNum() {
    return this.#order.number || '';
  }

  get orderPaymentMethod() {
    return this.#order.paymentMethod;
  }

  get orderShippingMethod() {
    return this.#order.shippingMethod;
  }

  get orderPlacedAt() {
    if (this.#order.placedAt) {
      return formatDateTime(this.#order.placedAt);
    }

    return null;
  }

  /**
   * Retrieves the customer information associated with the draft order.
   *
   * @async
   * @returns {Object} Customer information.
   * @property {string} email - Customer's email.
   * @property {string} firstName - Customer's first name.
   * @property {string} lastName - Customer's last name.
   */
  get customerInfo() {
    return this.#order.customerInfo;
  }

  get defaultShippingPackage() {
    const storeShippingPackage = amsClient.get('conf.store.default_shipping_package');

    return storeShippingPackage || this.#defaultShippingPackage;
  }

  async getPaymentForm() {
    if (!this.#order.draft) {
      throw new Error('draft order is not exists');
    }

    if (!this.#stripe) {
      throw new Error('stripe not initialized');
    }

    const clientSecret = await this.#createPayment();

    this.#stripeElements = this.#stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
      locale: 'auto',
      clientSecret,
    });
    const p = this.#stripeElements.create('payment');
    console.log('p', p);

    return p;
  }

  async getPaymentTerms() {
    const { paymentTermsTemplates } = await CheckoutApi.getPaymentTerms();
    return paymentTermsTemplates;
  }

  calculateShippingRate(payload) {
    return CheckoutApi.calculateShippingRate(payload);
  }

  calculateTax(payload) {
    return CheckoutApi.calculateTax({ account: this.#stripeAcc, ...payload });
  }

  /**
   * Creates a draft order with the provided customer and order details.
   *
   * @async
   * @param {Object} customerInfo - Customer information.
   * @param {string} customerInfo.email - Customer's email.
   * @param {string} customerInfo.firstName - Customer's first name.
   * @param {string} customerInfo.lastName - Customer's last name.
   * @param {Object} addressInfo - Shipping address information.
   * @param {string} addressInfo.address1 - Shipping address.
   * @param {string} addressInfo.city - City.
   * @param {string} addressInfo.country - Country.
   * @param {string} addressInfo.countryCode - Country code.
   * @param {string} addressInfo.zip - ZIP code.
   * @param {Boolean} addressInfo.isPickUp - Set flag to true if shipping method is pickup
   * @param {Array<Object>} items - Array of line items for the draft order.
   * @param {string} items[].variantId - The variant ID of the product.
   * @param {number} items[].quantity - The quantity of the product to add to the draft order.
   * @returns {Promise<Object>} A promise that resolves to the created draft order object.
   * @throws {Error} Throws an error if there is a problem creating the draft order.
   */

  async createDraftOrder(customerInfo, addressInfo, items, shippingRate = 0, taxRate = 0) {
    const { email, firstName, lastName } = customerInfo;
    const address = this.#normalizeAddressInfo(addressInfo);
    const token = await CheckoutApi.getMultiPassToken(
      email,
      firstName,
      lastName,
    );

    const customerToken = await ShopifyStorefrontApi.getCustomerAccessTokenWithMultipass(token);
    const customer = await ShopifyStorefrontApi.getCustomerByAccessToken(
      customerToken,
    );

    const draftOrderInput = {
      email,
      taxExempt: true,
      purchasingEntity: {
        customerId: customer.id,
      },
      tags: [`int:store:${amsClient.getPridId()}`],
      shippingLine: {
        title: 'Standard Shipping',
        price: shippingRate,
      },
      shippingAddress: address,
      billingAddress: address,
      lineItems: items,
      customAttributes: [
        { key: 'tax', value: taxRate.toString() },
      ],
    };

    const draftOrder = await CheckoutApi.createDraftOrder(draftOrderInput);

    if (draftOrder) {
      this.#order.draft = draftOrder;
      this.#order.customerInfo = customerInfo;
      this.#order.shippingMethod = addressInfo.isPickUp
        ? CheckoutService.SHIPPING_METHOD_PICKUP
        : CheckoutService.SHIPPING_METHOD_STANDARD;

      return this.#order.draft;
    }

    return null;
  }

  /**
   * Complete an order
   *
   * @async
   * @param {Object} [billingAddress] - Optional. Billing address information.
   * @param {string} billingAddress.address1 - Billing address.
   * @param {string} billingAddress.city - City.
   * @param {string} billingAddress.country - Country.
   * @param {string} billingAddress.zip - ZIP code.
   * @returns {Promise<Object>} A promise that resolves to the created draft order object.
   * @throws {Error} Throws an error if there is a problem creating the draft order.
   */
  async completeOrder(billingAddress, taxCalculationId) {
    if (!this.#order.draft) {
      throw new Error('draft order is not exists');
    }

    // only if billingAddress !== shippingAddress
    if (billingAddress) {
      await this.#setBillingAddress(billingAddress);
    }

    const res = await this.#confirmPayment();
    if (res.error) {
      throw new Error(res.error.message);
    }

    if (res?.paymentIntent?.status !== 'succeeded') {
      throw new Error('payment not found');
    }

    const draftOrder = await CheckoutApi.completeDraftOrder(
      this.#order.draft.id,
      res?.paymentIntent?.id,
      this.#stripeAcc,
      taxCalculationId,
    );

    const order = draftOrder?.order;

    if (order) {
      this.#order.placedAt = new Date(order.createdAt);
      this.#order.number = order.name;
      this.#order.paymentMethod = CheckoutService.PAYMENT_METHOD_NOW;

      return draftOrder;
    }
  }

  /**
   * Complete an order that will be paid on delivery
   *
   * @async
   * @returns {Promise<Object>} A promise that resolves to the created draft order object.
   * @throws {Error} Throws an error if there is a problem creating the draft order.
   */
  async termOrder(termId, taxCalculationId) {
    if (!this.#order.draft) {
      throw new Error('draft order is not exists');
    }

    const updatePayload = {
      paymentTerms: { paymentTermsTemplateId: termId },
    };

    await CheckoutApi.updateDraftOrder(this.#order.draft.id, updatePayload);

    const draftOrder = await CheckoutApi.completeUnpaidDraftOrder(
      this.#order.draft.id,
      this.#stripeAcc,
      taxCalculationId,
    );

    const order = draftOrder?.order;

    if (order) {
      this.#order.placedAt = new Date(order.createdAt);
      this.#order.number = order.name;
      this.#order.paymentMethod = CheckoutService.PAYMENT_METHOD_COD;
      this.#stripePaymentSecret = null;
      return draftOrder;
    }

    this.#stripePaymentSecret = null;
    return null;
  }

  /**
   * Soft Registration
   *
   * @async
   * @param {Object} form
   * @param {string} form.email - Form user email.
   * @param {string} form.first_name - Form user first name.
   * @param {string} form.last_name - Form user last name.
   * @param {string} form.cart - cartId.
   * @param {boolean} form.allow_soft_registration - Set salient registration.
   * @returns {Promise<Object>} A promise that resolves to the created draft order object.
   * @throws {Error} Throws an error if there is a problem creating the draft order.
   */
  async softRegistration(form) {
    const response = await amsClient.callAms(
      '/accounts/register/?d=web&mode=soft',
      {
        method: 'POST',
        body: JSON.stringify(form),
      },
    );

    return response;
  }

  /**
   * Set a billing address for draft order
   *
   * @async
   * @param {Object} addressInfo - Shipping address information.
   * @param {string} addressInfo.address1 - Shipping address.
   * @param {string} addressInfo.city - City.
   * @param {string} addressInfo.country - Country.
   * @param {string} addressInfo.zip - ZIP code.
   * @returns {Promise<Object>} A promise that resolves to the created draft order object.
   * @throws {Error} Throws an error if there is a problem creating the draft order.
   */
  async #setBillingAddress(addressInfo) {
    if (!this.#order) {
      throw new Error('draft order is not exists');
      // return console.error('draft order is not exists')
    }

    const draftOrder = await CheckoutApi.updateDraftOrder(
      this.#order.draft.id,
      {
        billingAddress: this.#normalizeAddressInfo(addressInfo),
      },
    );

    if (draftOrder) {
      this.#order.draft = draftOrder;
    }

    return this.#order.draft;
  }

  #addressInfo(addressFieldName) {
    if (!this.#order.draft?.[addressFieldName]) {
      return [];
    }

    const { address1, city, country, countryCodeV2, zip } = this.#order.draft[addressFieldName];
    const { firstName, lastName } = this.#order.customerInfo;

    return [
      `${firstName} ${lastName}`,
      [address1, city, countryCodeV2].join(', '),
      [zip, country].join(', '),
    ];
  }

  async #createPayment() {
    if (this.#stripePaymentSecret) {
      return this.#stripePaymentSecret;
    }

    if (!this.#order.draft) {
      throw new Error('draft order is not exists');
    }

    console.log('this.#order.draft', this.#order.draft);

    const customTaxAttribute = this.#order.draft.customAttributes.find(a => a.key === 'tax');
    const taxAmount = customTaxAttribute.value || 0;

    const amount = Math.floor((+this.#order.draft.totalPrice + +taxAmount) * 100);
    const applicationFee = 0;
    const metadata = { orderId: this.#order.draft.id };

    const { client_secret } = await CheckoutApi.createPayment(
      this.#stripeAcc,
      amount,
      applicationFee,
      metadata,
    );

    this.#stripePaymentSecret = client_secret;

    return client_secret;
  }

  async #confirmPayment() {
    if (!this.#stripe || !this.#stripeElements) {
      throw new Error('stripe not initialized');
    }

    const response = await this.#stripe.confirmPayment({
      elements: this.#stripeElements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://localhost:8088/cart', // temporary
      },
    });
    this.#stripePaymentSecret = null;
    return response;
  }

  #normalizeAddressInfo(addressInfo) {
    const { address1, city, country, zip, countryCode } = addressInfo;

    return {
      address1,
      city,
      country,
      zip,
      countryCode: countryCode?.toUpperCase(),
    };
  }
}

export default CheckoutService;
