<template>
  <div>
    <div v-if="label" class="sp-form-input-label">
      <label>{{ label }}</label>
    </div>
    <input
      v-if="mask"
      v-model="input"
      v-mask="mask"
      :disabled="disabled"
      :title="title"
      :placeholder="placeholder"
      :style="{ height }"
      class="sp-form-input"
    >
    <input
      v-else
      v-model="input"
      :disabled="disabled"
      :title="title"
      :placeholder="placeholder"
      :style="{ height }"
      class="sp-form-input"
    >

    <div class="sp-form-input-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormInputComponent',

    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      errorMessage: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      height: {
        type: String,
        default: '44px',
      },

      mask: {
        type: [String, Object],
        default: '',
      },
    },

    data() {
      return {
        options: {
          mask: '#-#',
          eager: true,
        },
      };
    },

    computed: {
      input: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', (val || '').trim());
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-form-input {
    border: 1px solid #ecf0f6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 16px;
    font-size: 15px;
    color: black;
    &::placeholder {
      color: #b6c0d3;
    }

    &:disabled {
      background: #f8f8fb;
    }

    &:focus {
      outline: none;
    }

    &-label {
      font-weight: 500;
    }

    &-error {
      font-size: 12px;
      color: red;
    }
  }
</style>
