<template>
  <div
    class="product-details"
    :class="{ 'product-details-in-popup': openFromVideo }"
  >
    <div class="product-details__container">
      <div class="product-details__content">
        <div
          v-if="productImages && productImages.length"
          class="product-details__main-image-container"
        >
          <img
            v-if="selectedImage"
            :src="selectedImage.url"
            alt=""
            class="product-details__main-image"
          />
          <img
            v-else
            :src="productImages[0].url"
            alt=""
            class="product-details__main-image"
          />

          <div class="product-details__buttons">
            <button
              v-if="isFullAuth"
              class="product-details__button"
              @click="addRemoveItemToWishlist"
            >
              <img
                :src="heartImg"
                class="product-details__button-icon"
                alt="Like"
              />
            </button>
            <button class="product-details__button">
              <img
                src="@/assets/icons/icon-sharing.svg"
                class="product-details__button-icon"
                alt="Sharing"
              />
            </button>
          </div>
        </div>

        <div
          v-if="productImages && productImages.length"
          class="product-details__images"
        >
          <div
            class="product-details__slide"
            :style="calculateMobileSlideWidth"
          >
            <div
              v-for="(image, index) in productImages"
              :key="index"
              class="product-details__image-container"
            >
              <img
                :src="image.url"
                :alt="image.altText || 'Product image'"
                class="product-details__image"
                @click="handleImageClick(image)"
              />
            </div>
          </div>
        </div>

        <div
          class="product-details__info"
          :class="{
            'product-details__info--full-width': !product.images.edges.length,
          }"
        >
          <div class="product-details__head">
            <div
              class="product-details__status"
              :class="{
                'product-details__status--not-active':
                  productAvailability === 'not_active',
                'product-details__status--out-of-stock':
                  productAvailability === 'out_of_stock',
              }"
            >
              {{ productAvailabilityText }}
            </div>

            <div class="product-details__buttons">
              <button
                v-if="isFullAuth"
                class="product-details__button"
                @click="addRemoveItemToWishlist"
              >
                <img
                  :src="heartImg"
                  class="product-details__button-icon"
                  alt="Like"
                />
              </button>
              <button class="product-details__button">
                <img
                  src="@/assets/icons/icon-sharing.svg"
                  class="product-details__button-icon"
                  alt="Sharing"
                />
              </button>
            </div>
          </div>

          <div class="product-details__title">
            <div
              class="product-details__title-text"
              @click="openProductInNewTab"
            >
              {{ product.title }}
            </div>

            <div
              v-if="isFullAuth"
              class="product-details__wishlist-in-title"
              @click="addRemoveItemToWishlist"
            >
              <i v-if="isInWishList" class="icon-added-to-wishlist" />
              <i v-else class="icon-add-to-wishlist" />
              <!--              <img-->
              <!--                :src="heartImg"-->
              <!--                class="product-details__wishlist-in-title-image"-->
              <!--                alt="Like"-->
              <!--              >-->
            </div>
          </div>

          <div class="product-details__price">
            <template v-if="selectedVariant">
              <div class="product-details__price-value">
                ${{ selectedVariant.price }}
              </div>

              <div
                v-if="selectedVariant.compareAtPrice"
                class="product-details__price-value product-details__price-value--discount"
              >
                ${{ selectedVariant.compareAtPrice }}
              </div>
            </template>

            <template v-else>
              <div class="product-details__price-value">
                ${{ product.priceRangeV2.minVariantPrice.amount }}0
              </div>

              <div
                v-if="maxCompareAtPrice"
                class="product-details__price-value product-details__price-value--discount"
              >
                ${{ maxCompareAtPrice }}
              </div>
            </template>

            <div
              class="product-details__status product-details__price-status"
              :class="{
                'product-details__status--not-active':
                  productAvailability === 'not_active',
                'product-details__status--out-of-stock':
                  productAvailability === 'out_of_stock',
              }"
            >
              {{ productAvailabilityText }}
            </div>
          </div>

          <template v-if="!product.hasOnlyDefaultVariant">
            <div
              v-for="(option, index) in productAvailableOptions"
              :key="index"
              class="product-details__option"
            >
              <template v-if="option.values.length">
                <div class="product-details__option-title">
                  {{ option.name }}
                </div>

                <ul class="product-details__option-list">
                  <li
                    v-for="(item, i) in option.values"
                    :key="i"
                    class="product-details__option-item"
                    :class="{
                      'product-details__option-item--active':
                        item.name === productSelectedOptions[option.name],
                      'product-details__option-item--disabled': item.disabled,
                    }"
                    @click="handleSelectProductOption(item.name, option.name)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </template>
            </div>
          </template>

          <div class="product-details__order">
            <div class="product-details__count">
              <button
                class="product-details__count-button"
                :disabled="!isEnabledCountButtons"
                @click="decreaseCount"
              >
                -
              </button>

              <div class="product-details__count-value">
                {{ productCount }}
              </div>

              <button
                class="product-details__count-button"
                :disabled="!isEnabledCountButtons"
                @click="increaseCount"
              >
                +
              </button>
            </div>

            <button
              class="sp-product--actions--button button-primary"
              :class="{
                disabled: !isCanPurchase || loading,
                'button-secondary': inCart,
              }"
              :disabled="!isCanPurchase || loading"
              @click="handleGoToCart"
            >
              {{ inCart ? 'GO TO CART' : 'ADD TO CART' }}
            </button>

            <button
              class="sp-product--actions--button button-default"
              :class="{ disabled: !isCanPurchase || loading }"
              :disabled="!isCanPurchase || loading"
              @click="buyVariant"
            >
              Buy Now
            </button>
          </div>

          <div class="product-details__description">
            <div class="product-details__description-title">
              Product Description
            </div>

            <div
              class="product-details__description-item"
              v-html="product.descriptionHtml"
            />
          </div>

          <div class="product-details__policy">
            <div v-if="productShippingFee" class="product-details__policy-item">
              <div class="product-details__policy-item-title">Shipping Fee</div>
              <div class="product-details__policy-item-value">
                {{
                  productShippingFee === '0.0'
                    ? 'Free'
                    : '$' + productShippingFee
                }}
              </div>
            </div>
            <div
              v-if="productReturnablePolicy"
              class="product-details__policy-item"
            >
              <div class="product-details__policy-item-title">
                Returnable Policy
              </div>
              <div class="product-details__policy-item-value">
                {{
                  productReturnablePolicy === 'false'
                    ? 'Not returnable'
                    : 'Within 14 days'
                }}
              </div>
            </div>
            <div v-if="productConditions" class="product-details__policy-item">
              <div class="product-details__policy-item-title">
                Product Conditions
              </div>
              <div class="product-details__policy-item-value">
                {{ productConditions }}
              </div>
            </div>
          </div>

          <div
            v-if="productDetails && productDetails.length"
            class="product-details__details"
          >
            <div class="product-details__details-title">Product details</div>

            <ul class="product-details__details-list">
              <li
                v-for="(item, index) in productDetails"
                :key="index"
                class="product-details__details-list-item"
              >
                <div class="product-details__details-item-title">
                  {{ item.name }}
                </div>
                <div
                  class="product-details__details-item"
                  v-html="parseLink(item.value)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  // import cartApi from '../api/cart/cart.api';
  import Auth from '@/service/authService';
  import normalizeImg from '@/filters/normalizeImg';
  import SharedCartDataService from '@/shared/services/cart/cart-data.service';
  import WishlistService from '@/store/wishlist';
  import { mapGetters } from 'vuex';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import device from '@/service/device-service';
  import ReferalService from '@/shared/services/referal.service';

  const CartService = new SharedCartDataService();

  const addedHeartImg = require('@/assets/icons/added-to-wishlist-icon.svg');
  const addHeartImg = require('@/assets/icons/add-to-wishlist-icon.svg');

  import { parseLink } from '@/shared/helpers/custom-link-parse.helper';

  export default {
    name: 'ProductDetailsComponent',
    mixins: [AuthVariableMixin],
    props: {
      id: {
        type: String,
        default: null,
      },
      oldId: {
        type: String,
        default: null,
      },
      product: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      amsProduct: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      openFromVideo: {
        type: Boolean,
        require: false,
        default: false,
      },
      isPopup: {
        type: Boolean,
        default: false,
      },
      mediaSource: {
        type: String,
        default: 'vod',
      },
    },

    data() {
      return {
        isMobile: device.isAnyMobile(),
        productDetails: null,
        productVariants: null,
        productAvailability: '',
        productSelectedOptions: null,
        productAvailableOptions: null,
        productShippingFee: null,
        productReturnablePolicy: null,
        productConditions: null,
        productImages: null,
        selectedVariant: null,
        selectedImage: null,
        productCount: 0,
        maxCompareAtPrice: 0,
        store: null,
        loading: false,
      };
    },

    computed: {
      ...mapGetters({
        getList: 'wishlist/getList',
      }),
      isInWishList() {
        return Object.keys(this.getList).includes(this.amsProduct.id);
      },
      heartImg() {
        return this.isInWishList ? addedHeartImg : addHeartImg;
      },
      seller() {
        const store = this.product.metafields.edges.find(
          ({ node }) => node.key === 'seller',
        );

        return store;
      },
      cartId() {
        if (Auth.get('user') && !Auth.get('user')?.guest) {
          return Auth.get('user').cart;
        }

        return localStorage.getItem('cartId');
      },
      productAvailabilityText() {
        let text = '';

        if (this.productAvailability === 'in_stock') {
          text = 'In Stock';
        }

        if (this.productAvailability === 'out_of_stock') {
          text = 'Out Of Stock';
        }

        if (this.productAvailability === 'not_active') {
          text = 'Not Active';
        }

        return text;
      },
      isCanPurchase() {
        return this.productCount && this.selectedVariant;
      },
      calculateMobileSlideWidth() {
        if (!this.isMobile) {
          return null;
        }

        if (this.openFromVideo) {
          return `width: ${113 * this.product.images.edges.length}px`;
        }
        return `width: ${46 * this.product.images.edges.length}px`;
      },
      isEnabledCountButtons() {
        return (
          this.selectedVariant &&
          (!this.selectedVariant.inventoryItem.tracked ||
            this.selectedVariant.inventoryQuantity)
        );
      },
      imageVariantMap() {
        const res = new Map();

        this.product.variants.edges.forEach((variant) => {
          if (variant.node.image) {
            res.set(variant.node.image.id, variant.node);
          }
        });

        return res;
      },

      inCart() {
        const products = this.$store.getters['checkout/getCartItems'];

        console.log('this.selectedVariant: ', this.selectedVariant);

        return products.find(
          (product) =>
            product?.node?.merchandise?.id === this.selectedVariant?.id,
        );
      },
    },

    mounted() {
      this.fetchStoreInformation();

      this.parseProductMetafields();

      this.productImages = [...this.product.images.edges].map(
        (item) => item.node,
      );

      if (this.productAvailability === 'not_active') {
        this.$plError.setError({
          text: 'Product not found',
          title: 'Not found',
          code: 404,
        });
        // todo: redirect ro error page from player
        // this.$router.replace({
        //   path: '/error',
        // });
      }

      this.productVariants = this.product.variants.edges
        .map((item) => item.node)
        .map((item) => ({ ...item, splitedTitle: item.title.split(' / ') }))
        .filter((item) => {
          const object = item.metafields.edges.find(
            (object) => object.node.key === 'enabled',
          );

          return !object ? item : object.node.value !== 'false' ? item : null;
        });

      this.productVariants.forEach((item) => {
        if (
          item.compareAtPrice &&
          parseFloat(item.compareAtPrice) > parseFloat(this.maxCompareAtPrice)
        ) {
          this.maxCompareAtPrice = item.compareAtPrice;
        }
      });

      this.productAvailableOptions = this.getProductAvailableOptions(
        this.product.options,
        this.productVariants,
      );

      this.productSelectedOptions = this.createProductSelectedOptions(
        this.productAvailableOptions,
      );

      if (this.product.hasOnlyDefaultVariant) {
        this.selectedVariant = this.product.variants.edges[0].node;

        if (this.selectedVariant.inventoryItem.tracked) {
          this.productCount =
            this.selectedVariant.inventoryQuantity === 0 ? 0 : 1;
        } else {
          this.productCount = 1;
        }
      } else {
        this.selectFirstAvailableVariant();
      }
    },
    methods: {
      normalizeImg,
      parseLink,

      handleImageClick(image) {
        if (this.imageVariantMap.has(image.id)) {
          const variant = this.imageVariantMap.get(image.id);
          const options = variant.title.split(' / ');

          options.forEach((option, i) => {
            this.handleSelectProductOption(
              option,
              this.product.options[i].name,
            );
          });
        }
      },
      selectFirstAvailableVariant() {
        const availableVariant = this.productVariants.find((variant) => {
          const enabledMetafield = variant.metafields.edges.find(
            (metafield) => metafield.node.key === 'enabled',
          );

          if (enabledMetafield.node.value === 'false') {
            return;
          }

          return (
            !variant.inventoryItem.tracked || variant.inventoryQuantity > 0
          );
        });

        availableVariant.splitedTitle.forEach((option, i) => {
          this.handleSelectProductOption(option, this.product.options[i].name);
        });
      },
      async fetchStoreInformation() {
        try {
          const response = await amsClient.callAms(
            `/ugc/creator/${this.seller.node.value}/?d=web`,
          );

          this.store = {
            slug: response.head.slug,
            id: response.head.provider_id,
            avatar: response.head.picture,
            title: response.head.title,
            cats: response.head.cats,
            vimmi_store_id: response.head.id,
            crm_store_id: response.head.store_id,
          };
        } catch (error) {
          console.log(error);
        }
      },
      getProductAvailableOptions(options, variants) {
        const availableOptions = [];

        options.forEach((item) => {
          const options = [];

          item.values.map((value) => {
            variants.forEach((variant) => {
              if (
                variant.title.includes(value) &&
                (!variant.inventoryItem.tracked || variant.inventoryQuantity) &&
                !options.find((item) => item.name === value)
              ) {
                options.push({ name: value, disabled: false });
              }
            });
          });

          availableOptions.push({
            name: item.name,
            values: options,
          });
        });

        return availableOptions;
      },
      createProductSelectedOptions(options) {
        const selectedOptions = {};

        options.forEach((item) => {
          selectedOptions[item.name] = '';
        });

        return selectedOptions;
      },
      parseProductMetafields() {
        this.product.metafields.edges.forEach((item) => {
          if (item.node.key === 'specifications') {
            this.productDetails = JSON.parse(item.node.value);
          }

          if (item.node.key === 'availability') {
            this.productAvailability = item.node.value;
          }

          if (item.node.key === 'shipping_fee') {
            this.productShippingFee = item.node.value;
          }

          if (item.node.key === 'returnable') {
            this.productReturnablePolicy = item.node.value;
          }

          if (item.node.key === 'condition') {
            this.productConditions = item.node.value;
          }
        });
      },
      handleSelectProductOption(optionValue, optionName) {
        this.productSelectedOptions[optionName] = optionValue;

        this.validateOtherOptions();

        const variantTitle = this.getVariantTitle();

        if (variantTitle) {
          this.selectedVariant = this.productVariants.filter(
            (item) => item.title === variantTitle,
          )[0];

          if (this.selectedVariant.image) {
            this.selectImage(this.selectedVariant.image);

            if (!this.isMobile) {
              this.sortImagesArray(this.selectedVariant.image.id);
            }
          }

          if (this.selectedVariant.inventoryItem.tracked) {
            this.productCount =
              this.selectedVariant.inventoryQuantity === 0 ? 0 : 1;
          } else {
            this.productCount = 1;
          }
        } else {
          this.selectedVariant = null;
          this.productCount = 0;
        }
      },
      getVariantTitle() {
        const result = [];

        for (const item in this.productSelectedOptions) {
          if (this.productSelectedOptions[item]) {
            result.push(this.productSelectedOptions[item]);
          } else {
            return null;
          }
        }

        return result.join(' / ');
      },
      handleGoToCart() {
        if (!this.inCart) {
          this.getPurchaseData();

          return;
        }

        if (this.isPopup) {
          this.$emit('open:cart');

          return;
        }
      },

      async buyVariant() {
        console.log('this.selectedVariant: ', this.selectedVariant);
        const data = {
          merchandise: {
            id: this.selectedVariant.id,
            image: {
              url: this.productImages[0].url,
            },
            price: {
              amount: this.selectedVariant.price,
              currencyCode: 'USD',
            },
            product: {
              id: '',
              title: this.product.title,
            },
            quantityAvailable: this.selectedVariant.inventoryQuantity,
            title: this.selectedVariant.title,
          },
          attributes: [
            {
              key: '__store',
              value: JSON.stringify(this.store) || 'null',
            },
            {
              key: '__ref',
              value: ReferalService.instance().encode(this.id),
            },
          ],
          quantity: this.productCount,
          cost: {
            subtotalAmount: {
              amount: `${+this.selectedVariant.price * this.productCount}`,
              currencyCode: 'USD',
            },
            totalAmount: {
              amount: `${+this.selectedVariant.price * this.productCount}`,
              currencyCode: 'USD',
            },
            totalShippingAmount: {
              amount: '0.00',
              currencyCode: 'USD',
            },
          },
        };

        if (this.isPopup) {
          this.$emit('buy:now', data);

          return;
        }
      },
      async getPurchaseData() {
        this.loading = true;

        const data = {
          id: this.selectedVariant.id,
          count: this.productCount,
          removeId: this.id,
          oldId: this.amsProduct.id,
        };

        this.$stats.send(
          'add_to_cart',
          {
            variant_id: this.selectedVariant.id, // todo:
            quantity: this.productCount,
          },
          { item: this.amsProduct },
        );

        try {
          ReferalService.instance().setSource(this.mediaSource);

          const response = await CartService.addItemToCart({
            cartId: this.cartId,
            itemId: data.id,
            quantity: data.count,
            store: JSON.stringify(this.store) || 'null',
            referal: ReferalService.instance().encode(this.id),
          });

          this.$store.commit(
            'checkout/setCart',
            response?.cartLinesAdd?.cart || null,
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }

        return data;
      },
      selectImage(url) {
        this.selectedImage = url;
      },
      increaseCount() {
        if (!this.selectedVariant) {
          return;
        }

        if (!this.selectedVariant.inventoryItem.tracked) {
          this.productCount++;
        } else if (this.selectedVariant.inventoryQuantity > this.productCount) {
          this.productCount++;
        }
      },
      decreaseCount() {
        if (this.productCount > 0) {
          this.productCount--;
        }
      },
      validateOtherOptions() {
        const selectedOptionValue = [];
        const notSelectedOption = [];

        for (const item in this.productSelectedOptions) {
          if (this.productSelectedOptions[item]) {
            selectedOptionValue.push(this.productSelectedOptions[item]);
          } else {
            notSelectedOption.push(item);
          }
        }

        let allVariantsWithItem = [...this.productVariants];

        selectedOptionValue.forEach((optionValue) => {
          allVariantsWithItem = allVariantsWithItem.filter((variant) =>
            variant.title.includes(optionValue),
          );
        });

        notSelectedOption.forEach((option) => {
          this.productAvailableOptions
            .find((o) => o.name === option)
            .values.map((value) => {
              let disabled = true;

              allVariantsWithItem.forEach((item) => {
                if (item.splitedTitle.includes(value.name)) {
                  if (!item.inventoryItem.tracked || item.inventoryQuantity) {
                    disabled = false;
                  }
                }
              });

              return (value.disabled = disabled);
            });
        });
      },
      goToStore() {
        this.$router.push({
          name: 'ChannelPage',
          params: { id: this.store.slug },
        });
      },
      openProductInNewTab() {
        if (this.openFromVideo && this.id) {
          window.open(`/product/${this.product.handle}`, '_blank');
        }
      },
      sortImagesArray(id) {
        // id of image that must be first in productImages array
        const image = this.productImages.find((item) => item.id === id);

        this.productImages = this.productImages.filter(
          (item) => item !== image,
        );
        this.productImages.unshift(image);
      },
      async addRemoveItemToWishlist() {
        if (this.isInWishList) {
          await WishlistService.removeFromWishlist(this.amsProduct.id);
          this.$stats.send('remove_wishlist', {}, { item: this.amsProduct });
        } else {
          this.$stats.send('add_wishlist', {}, { item: this.amsProduct });
          await WishlistService.addToWishlist(this.amsProduct.id);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .product-details {
    padding-top: 30px;
    padding-bottom: 30px;

    .sp-product--actions--button {
      height: 50px;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 24 / 15 * 100%;
      outline: none;

      @include lg() {
        font-size: 15px;
      }

      &.button-primary {
        background-color: #21a66e;
        color: #ffffff;
      }

      &.button-default {
        border: 1px solid #edf1f4;
        color: #343434;
      }

      &.button-secondary {
        background-color: #ffc700;
        color: #343434;
      }

      &.disabled {
        background-color: #edf1f4;
        color: #ffffff;
        pointer-events: none;
      }
    }

    // TODO need delete &__product-popup style later
    &__product-popup {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    &__product-popup-swiper {
      display: none;
      width: 100px;
      height: 130px;
      position: absolute;
      top: -140px;
      left: 50%;
      background: #fff;
      border-radius: 10px;
      transform: translateX(-50%);
    }

    &__container {
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
      max-width: 1450px;
      width: 100%;
    }

    &__content {
      display: flex;
      gap: 40px;
    }

    &__main-image-container {
      display: none;
    }

    &__images {
      width: calc(100% - 480px);

      // TODO need delete this styel later
      position: relative;
    }

    &__slide {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__image-container {
      width: calc(50% - 10px);
      height: 460px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #eaecf4;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__info {
      width: 440px;

      &--full-width {
        width: 100%;
      }
    }

    &__head {
      margin-bottom: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__status {
      padding: 10px 15px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #343434;
      background: #d5f4e1;
      border-radius: 50px;

      &--not-active {
        background: red;
      }

      &--out-of-stock {
        background: #ccc;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border: 1px solid #eff2fa;
      border-radius: 50%;
      outline: none;

      &:not(:first-child) {
        margin-left: 7px;
      }

      &-icon {
        width: 18px;
        height: 18px;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      color: #343434;
    }

    &__wishlist-in-title {
      display: none;
    }

    &__price {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }

    &__price-value {
      margin-right: 8px;
      font-weight: 400;
      font-size: 22px;
      line-height: 16px;
      color: #343434;

      &--discount {
        position: relative;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #b7c1d6;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -2px;
          width: calc(100% + 4px);
          height: 1px;
          background: #f11e1e;
        }
      }
    }

    &__price-status {
      display: none;
    }

    &__option {
      margin-bottom: 20px;

      &-title {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        color: #343434;
        word-break: break-word;
      }

      &-list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        list-style: none;
      }

      &-item {
        padding: 15px 20px;
        border: 1px solid #edf1f4;
        border-radius: 5px;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #343434;
        cursor: pointer;
        transition: 0.3s ease all;

        &:hover {
          border-color: #343434;
        }

        &--active {
          border-color: #21a66e;
        }

        &--disabled {
          pointer-events: none;
          color: #ccc;
        }
      }
    }

    &__order {
      margin-bottom: 70px;
      display: grid;
      grid-template-columns: 70px 1fr 95px;
      column-gap: 8px;
    }

    &__count {
      margin-right: 10px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 70px;
      width: 100px;
      height: 50px;
      border: 1px solid #edf1f4;
      border-radius: 5px;

      &-button {
        display: block;
        width: 21px;
        max-width: 21px;
        height: 100%;
        font-size: 20px;
        font-weight: 500;
        border-radius: 3px;
        outline: none;
        padding: 0;

        &:hover {
          background: #edf1f4;
        }
      }

      &-value {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #343434;
        flex: 1;
        text-align: center;
      }
    }

    &__buy-button {
      width: calc(100% - 220px);
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #343434;
      text-transform: uppercase;
      text-align: center;
      background: transparent;
      border: 1px solid #e2e6ee;

      border-radius: 5px;
      outline: none;

      &:disabled {
        background: #ccc;
      }
    }

    &__add-to-cart-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      background: #ffd568;
      border-radius: 100px;
      outline: none;

      &:disabled {
        background: #ccc;
      }
    }

    &__go-to-cart-button {
      outline: none;
      text-transform: uppercase;
      background: #ffc700;
      border-radius: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      align-items: center;
      justify-content: center;
      color: #343434;
    }

    &__add-to-cart-icon {
      width: 25px;
      height: 25px;
    }

    &__description {
      margin-bottom: 50px;

      &-title {
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #343434;
      }

      &-item {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #343434;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    &__policy {
      margin-bottom: 30px;

      &-item {
        margin-bottom: 15px;
        display: inline-block;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }

      &-item-title {
        margin-bottom: 3px;
      }

      &-item-value {
        font-weight: 400;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__details {
      &-title {
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #343434;
      }

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      &-list-item {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        &:not(:last-child) {
          margin-bottom: 13px;
        }
      }

      &-item,
      &-item-title {
        width: calc(50% - 5px);
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #343434;
      }
    }

    &__store {
      display: flex;
      background-color: #f8f8fb;
      padding: 12px;
      margin-top: 40px;
      border-radius: 8px;

      &__logo {
        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      &__info {
        margin-left: 15px;
        display: flex;
        flex-direction: column;

        &__name {
          color: #343434;
          font-size: 18px;
          font-weight: 500;
          cursor: pointer;
        }

        &__category {
          color: #c6c9d9;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .product {
      &__image-container {
        height: 330px;
      }
    }
  }

  @media (max-width: 991px) {
    .product {
      &__image-container {
        height: 350px;
        width: 100%;
      }

      &__images {
        max-width: 100%;
        overflow-x: auto;
      }
    }
  }

  @media (max-width: 767px) {
    .product {
      padding-top: 0;

      &__container {
        padding-left: 0;
        padding-right: 0;
      }

      &__content {
        display: block;
      }

      &__main-image-container {
        margin-bottom: 10px;
        display: block;
        width: 100%;
        height: 375px;
        position: relative;

        .product-details__buttons {
          position: absolute;
          left: 50%;
          bottom: 15px;
          transform: translateX(-50%);
        }

        .product-details__button {
          background: #fff;
          border-color: #fff;
        }
      }

      &__main-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &__images {
        margin-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        overflow-x: auto;
      }

      &__slide {
        margin-bottom: 6px;
        display: flex;
        flex-wrap: nowrap;
        gap: 6px;
        width: 100%;
        overflow: hidden;
      }

      &__image-container {
        height: 40px;
        width: 40px;
        border-radius: 5px;
      }

      &__info {
        padding: 0 20px;
        width: 100%;
      }

      &__head {
        display: none;
      }

      &__status {
        padding: 8px 10px;
        font-size: 13px;
        line-height: 15px;
      }

      &__title {
        margin-bottom: 16px;
        line-height: 26px;
      }

      &__price {
        margin-bottom: 25px;
      }

      &__price-status {
        display: block;
      }

      &__option-item {
        padding: 15px 20px;
        line-height: 16px;
      }

      &__order {
        margin-bottom: 40px;
      }

      &__description {
        margin-bottom: 40px;

        &-title {
          margin-bottom: 20px;
          font-size: 20px;
        }

        &-item {
          margin-bottom: 0 !important;
        }
      }

      &__details {
        &-title {
          margin-bottom: 20px;
        }

        &-list-item {
          display: block;
        }

        &-item,
        &-item-title {
          margin-bottom: 0;
          width: 100%;
        }

        &-item-title {
          color: #a0a6b9;
        }
      }

      &__product-popup {
        position: fixed;
        left: 8px;
        right: 8px;
        top: 50%;
        z-index: 1000;
        transition: 0.3s ease all;
      }

      &__product-popup-swiper {
        display: block;
      }
    }
  }

  .product-details-in-popup {
    padding-top: 0;
    padding-bottom: 0;

    .product-details__container {
      padding-left: 0;
      padding-right: 0;
    }

    .product-details__content {
      display: block;
    }

    .product-details__images {
      margin-bottom: 20px;
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
    }

    .product-details__info {
      width: 100%;
    }

    .product-details__slide {
      gap: 3px;
    }

    .product-details__image-container {
      width: calc(100% / 3 - 6px);
      height: 110px;
    }

    .product-details__head {
      display: none;
    }

    .product-details__title {
      margin-bottom: 15px;
      padding-right: 45px;
      position: relative;
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;
    }

    .product-details__wishlist-in-title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #e9edf4;
    }

    .product-details__price-value {
      font-size: 18px;
    }

    .product-details__price-value--discount {
      font-size: 16px;
    }

    .product-details__option-list {
      gap: 8px;
    }

    .product-details__option-item {
      padding: 10px 20px;
    }

    .product-details__order {
      margin-bottom: 35px;
    }

    .product-details__count {
      margin-right: 0;
    }

    .product-details__description {
      margin-bottom: 20px;
    }

    .product-details__description-title,
    .product-details__details-title {
      margin-bottom: 20px;
      font-size: 16px;
    }

    .product-details__details-list-item {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 767px) {
    .product-details-in-popup {
      .product-details__main-image-container {
        display: none;
      }

      .product-details__images {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
        overflow-x: auto;
      }

      .product-details__title {
        padding-right: 0;
      }

      .product-details__wishlist-in-title {
        display: none;
      }

      .product-details__info {
        padding: 0;
      }

      .product-details__price-status {
        display: none;
      }

      .product-details__image-container {
        width: 110px;
        height: 110px;
      }
    }
  }
</style>