import Vue from 'vue';
import RTM from 'vimmi-web-utils/esm/realTimeMessages';
import store from '@/store';
import PopupService from '@/service/popup';
import eventBus from './eventBus';

const rtm = new RTM();

rtm.debug();

/* rtm.on('debug', data => {
  console.log('[rtm][debug]', data);
}); */
//
// rtm.on('overlay_product', (data) => {
//   eventBus.$emit('overlay_product', data.msg || null);
// });

rtm.on(['finished', 'on_air_now', 'started', 'failed', 'scheduled'], (data) => {
  // console.log('[events changed]', data);

  if (data.op === 'on_air_now') {
    Vue.prototype.$rtmWasInited = true;
  }

  if (data.op === 'finished') {
    const firstLive = store.getters['dataAreasLive/currentLive'];
    const secondLive = store.getters['dataAreasLive/currenSecondLive'];
    const playingLive = store.getters['dataAreasLive/playingLive'];
    store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
    store.commit('popup/setMiniPlayerFromProduct', false);

    if (playingLive) {
      PopupService.setScreenType('full');
      PopupService.setFloatingMode(null);
    }

    document.querySelector('.channel-event-video--wrapper.float-event')?.removeAttribute('style');

    if (data.room === firstLive?.actualEvent?.id) {
      store.commit('dataAreasLive/setCurrenLive', null);
    }

    if (data.room === secondLive?.actualEvent?.id) {
      store.commit('dataAreasLive/setCurrenSecondLive', null);
    }
  }

  const key = 'fastcastTimestamp';
  let timestamp = +data.msg?.timestamp || null;
  const savedTimestamp = +localStorage.getItem(key);

  if (timestamp < savedTimestamp) {
    timestamp = savedTimestamp;
  }

  Vue.prototype.$eventsTimestamp = timestamp;

  localStorage.setItem(key, +timestamp);
});

if (process.env.NODE_ENV === 'development') {
  rtm.setDomain('et-ut-unde.dev-shoprz.live');
} else {
  rtm.setDomain(location.host);
}

export default rtm;
