export default [
  {
    path: '/terms',
    component: () => import('./terms.view'),
    children: [
      {
        path: 'cookies-policy',
        name: 'cookies-policy',
        component: () => import('@/entities/terms/features/store-cookies-policy/store-cookies-policy.view'),
        meta: {
          title: 'Store Cookies Policy',
        },
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/entities/terms/features/privacy-policy/privacy-policy.view'),
        meta: {
          title: 'Privacy Policy',
        },
      },
      {
        path: 'third-party-cookies',
        name: 'third-party-cookies',
        component: () => import('@/entities/terms/features/third-party-cookies/third-party-cookies.view'),
        meta: {
          title: 'Third Party Cookies',
        },
      },
      {
        path: 'terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@/entities/terms/features/terms-and-conditions/terms-and-conditions.view'),
        meta: {
          title: 'Terms and Conditions',
        },
      },
    ],
  },
];
