import { amsClient } from 'src/service/ams';

const DEFAULT_LINK = '/shoprzapi/catalog/pc/cats-menu-level-0/';
const CHECK_TERM = 'cats-menu-level-0';
const DEFAULT_QUERY_PARAMS = {
  start: 0,
  count: 16,
  total: 0,
};

const state = () => ({
  catalogConfig: null,
  isLastLevel: false,
  isItemsLoading: false,
  carousels: null,
  itemsLink: null,
  productsLoading: false,
  productLink: null,
  productLinkParams: DEFAULT_QUERY_PARAMS,
  products: [],
  canLoadMoreProducts: true,
  sortParams: null,
  isAllProductSortLvl: false,
});

const getters = {
  breadcrumbs: (state) => {
    const breadcrumbs = state?.catalogConfig?.head?.breadcrumbs?.split(',');

    return !breadcrumbs
      ? null
      : breadcrumbs
        ?.reverse()
        ?.map((item, index) => {
          const headLink = state?.catalogConfig?.head?.link?.split('/')?.filter((item) => !!item && item !== 'shoprzapi');
          const link = `/${headLink?.splice(0, headLink.length - (index + 1))?.join('/')}`;

          return {
            title: item,
            link,
          };
        })
        ?.reverse();
  },
  breadcrumbList: (state) => {
    return (state?.catalogConfig?.head?.breadcrumbs_list || []).reverse().map((item, index) => {
      const headLink = state?.catalogConfig?.head?.link?.split('/')?.filter((item) => !!item && item !== 'shoprzapi');
      const link = `/${headLink?.splice(0, headLink.length - (index + 1))?.join('/')}`;

      return {
        title: item,
        link,
      };
    }).reverse();
  },
  title: (state) => {
    const data = state.catalogConfig?.head;
    const label = !data?.link?.includes(CHECK_TERM) ? data?.title : null;
    const link = data?.link?.split('/')?.filter((item) => !!item && item !== 'shoprzapi');

    return {
      label,
      backLink: !!link && !link?.includes(CHECK_TERM) ? `/${link.slice(0, link.length - 1).join('/')}` : null,
    };
  },
  navigation: (state) => {
    const menu = state.catalogConfig?.menu;

    return !menu
      ? null
      : Object.keys(menu)
        .map((key) => ({
          ...menu[key],
          title: key,
          link: `/${menu[key]?.items?.replace('/shoprzapi/catalog/p/', 'catalog/')}`,
          isLastItem: !menu[key]?.n_direct_cats,
        }))
        .filter((item) => item?.title !== 'All' && item?.n_products);
  },
  isLastLevel: (state) => state.isLastLevel,
  carousels: (state) => state.carousels,
  itemsLink: (state) => state.itemsLink,
  isItemsLoading: (state) => state.isItemsLoading,
  productsLoading: (state) => state.productsLoading,
  products: (state) => state.products,
  canLoadMoreProducts: (state) => state.canLoadMoreProducts,
  head: (state) => state.catalogConfig?.head,
};

const actions = {
  toggleLoading({ commit }, payload) {
    commit('setIsItemsLoading', payload);
  },
  toggleProductsLoading({ commit }, payload) {
    commit('setProductsLoading', payload);
  },
  async getCatalogConfig({ commit, state, dispatch }, { url, query }) {
    const mainUrl = url.replace('view-all/', '');

    try {
      const apiUrl = !mainUrl || mainUrl === '/' ? DEFAULT_LINK : `/shoprzapi/catalog/pc${mainUrl}`;
      let config = await amsClient.callAms(apiUrl);
      const isLastLevel = !(config?.head?.n_direct_cats === undefined || config?.head?.n_direct_cats);

      if (isLastLevel) {
        const prevUrlData = mainUrl?.split('/')?.filter((item) => !!item);
        const prevUrl = `/shoprzapi/catalog/pc/${prevUrlData?.slice(0, prevUrlData.length - 1)?.join('/')}`;
        const { menu } = state.catalogConfig || await amsClient.callAms(prevUrl);
        config = {
          ...config,
          menu,
        };
      }

      if (query) {
        commit('setSortCatalog', query);
      }

      dispatch('getCatalogItems', { config, isLastLevel });
      commit('setIsLastLevel', isLastLevel);
      commit('setCatalogConfig', config);
    } catch (e) {
      commit('setIsItemsLoading', false);
    }
  },
  async getCatalogItems({ commit, state }, { config, isLastLevel }) {
    try {
      // const url = config?.head?.link?.includes(CHECK_TERM) ? config?.head?.link : config?.head?.items;
      //
      // if (!isLastLevel && config?.menu) {
      //   const { menu } = config;
      //   const rootData = await amsClient.callAms(url);
      //   const carouselsPromises = await Promise.allSettled(
      //     Object.keys(menu)
      //       .map((key) => menu[key])
      //       .filter((item) => !!item?.items)
      //       .map((item) => amsClient.callAms(item.items)),
      //   );
      //   const carouselsData = carouselsPromises
      //     .filter(({ status, value }) => status === 'fulfilled' && value.head?.n_products)
      //     .map(({ status, value }) => ({
      //       title: value.head.title,
      //       link: value.head.link.replace('/p/', '/').replace('/shoprzapi', ''),
      //       data: value.products,
      //     }));
      //
      //   const rootItem = rootData.head?.link?.includes(CHECK_TERM) ? [] : [{
      //     title: rootData.head.title,
      //     link: `${rootData.head.link.replace('/p/', '/').replace('/shoprzapi', '')}view-all/`,
      //     data: rootData.products,
      //   }];
      //
      //   const items = [
      //     ...carouselsData,
      //     ...rootItem,
      //   ];
      //   commit('setCarousels', items);
      //
      //   if (state.isAllProductSortLvl) {
      //     commit('setIsLastLevel', state.isAllProductSortLvl);
      //     commit('setProducts', rootData.products);
      //     commit('setProductLinkParams', { ...DEFAULT_QUERY_PARAMS, total: config?.head?.n_products });
      //     commit('setCanLoadMoreProducts', true);
      //     commit('setProducts', 'default');
      //     commit('setProductLink', url);
      //   }
      // } else {
      //   commit('setProductLinkParams', { ...DEFAULT_QUERY_PARAMS, total: config?.head?.n_products });
      //   commit('setCanLoadMoreProducts', true);
      //   commit('setProducts', 'default');
      //   commit('setProductLink', url);
      // }

      commit('setIsItemsLoading', false);
    } catch (e) {
      commit('setIsItemsLoading', false);
    }
  },
  async getProductItems({ state, commit, dispatch }) {
    const { productLink, productLinkParams, canLoadMoreProducts } = state;
    const { start, total, count } = productLinkParams;
    const nextStartValue = start + count;
    const canLoadMore = total >= start;

    commit('setProductLinkParams', {
      ...productLinkParams,
      start: nextStartValue,
    });

    if (canLoadMore) {
      dispatch('toggleProductsLoading', true);
      const sort = state.sortParams ? `&sort=${state.sortParams}` : '';
      const url = `${productLink}?start=${start}&count=${count}${sort}`;
      const data = await amsClient.callAms(url);

      commit('setProducts', data.products);
      commit('setCanLoadMoreProducts', total >= nextStartValue);
    }

    dispatch('toggleProductsLoading', false);
  },
};

const mutations = {
  setIsAllProductSortLvl(state, payload) {
    state.isAllProductSortLvl = payload;
  },
  setSortCatalog(state, payload) {
    state.sortParams = payload;
  },
  setCatalogConfig(state, payload) {
    state.catalogConfig = payload;
  },
  setIsLastLevel(state, payload) {
    state.isLastLevel = payload;
  },
  setCarousels(state, payload) {
    state.carousels = payload;
  },
  setItemsLink(state, payload) {
    state.itemsLink = payload;
  },
  setIsItemsLoading(state, payload) {
    state.isItemsLoading = payload;
  },
  setProductLink(state, payload) {
    state.productLink = payload;
  },
  setProductLinkParams(state, payload) {
    state.productLinkParams = payload;
  },
  setProductsLoading(state, payload) {
    state.productsLoading = payload;
  },
  setProducts(state, payload) {
    if (payload === 'default') {
      state.products = [];
      return;
    }

    state.products = [
      ...state.products,
      ...payload,
    ];
  },
  setCanLoadMoreProducts(state, payload) {
    state.canLoadMoreProducts = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
