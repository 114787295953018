var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"links",class:{
  'links-tablet': _vm.isTablet,
  'open': _vm.isOpen,
}},[_c('button',{staticClass:"nav-toggle btn mobile-visible",attrs:{"type":"button"},on:{"click":_vm.toggleNav}},[_c('i',{staticClass:"fas",class:{
        'links-item-light': !_vm.isDark,
        'fa-bars': !_vm.isOpen,
        'fa-times': _vm.isOpen,
      },style:(("color: " + (_vm.isDark ? 'black !important' : 'white !important')))})]),_c('button',{staticClass:"close-btn btn mobile-visible",attrs:{"type":"button"},on:{"click":_vm.handleClose}},[_c('i',{staticClass:"fas fa-times"})]),_c('div',{staticClass:"links-container"},[_c('div',{staticClass:"links-group mobile-visible"},[(!_vm.isFullAuth)?_c('a',{staticClass:"links-item",class:{
          'links-item-light': !_vm.isDark,
        },on:{"click":function($event){$event.stopPropagation();_vm.onLogin();
          _vm.handleClose();}}},[_vm._v(" "+_vm._s(_vm.$t('HEADER.LOGIN'))+" "),_c('img',{attrs:{"src":require("@/assets/icons/icon-login.svg"),"alt":""}}),_c('i',{staticClass:"login-icon fas fa-chevron-right mobile-visible"})]):_c('div',{staticClass:"links-item logout",on:{"click":_vm.showMobileAuthActions}},[_vm._v(" "+_vm._s(_vm.username)+" "),_c('i',{staticClass:"fas fa-chevron-right"})])]),_c('hr',{staticClass:"mobile-visible wide"}),(_vm.$isStandaloneStore)?_c('div',{staticClass:"links-group"},[(_vm.isNeededStore)?_vm._l((_vm.NavLinksConst[_vm.storeName]),function(links){return _c('router-link',{key:links.path,staticClass:"links-item",class:{
            'mobile-not-visible': !_vm.isNeededStore,
            'links-item-light': !_vm.isDark,
          },attrs:{"to":links.path},nativeOn:{"click":function($event){_vm.isOpen = false;
            _vm.handleClose();}}},[_vm._v(" "+_vm._s(links.name)+" ")])}):_vm._l((_vm.headerLinks),function(link,index){return _c('router-link',{key:link.id,staticClass:"links-item",class:{
            'links-item-light': !_vm.isDark,
          },attrs:{"to":index === 0 ? '/' : ("/s/" + (link.slug))},nativeOn:{"click":function($event){_vm.isOpen = false;
            _vm.handleClose();}}},[_vm._v(" "+_vm._s(link.name)+" ")])})],2):_vm._e(),(!_vm.$isStandaloneStore)?_c('div',{staticClass:"links-group"},[_c('div',{staticClass:"links-group__title mobile-visible"},[_vm._v("Menu")]),_c('router-link',{staticClass:"links-item mobile-not-visible",class:{
          'links-item-light': !_vm.isDark,
        },attrs:{"to":'/watch'},nativeOn:{"click":function($event){_vm.isOpen = false;
          _vm.handleClose();}}},[_vm._v(" Watch ")]),_vm._l((_vm.items),function(item){return _c('router-link',{key:item.id,staticClass:"links-item",class:{
          'links-item-light': !_vm.isDark,
          'mobile-visible': item.slug === 'home',
        },attrs:{"to":item.itype === 'item_app'
            ? item.link.replace('get_item', 'web')
            : item.link},nativeOn:{"click":function($event){_vm.isOpen = false;
          _vm.handleClose();}}},[(_vm.isShowIcon(item.slug))?_c('img',{staticClass:"links-icons mobile-visible",attrs:{"src":require(("@/assets/icons/icon-" + (item.slug) + ".svg")),"alt":_vm._f("contentLanguage")(item,'title')}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("contentLanguage")(item,'title'))+" ")])})],2):_vm._e(),[_c('hr',{staticClass:"mobile-visible"}),_c('catalog')]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }