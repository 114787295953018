export default new (class {
  constructor() {
    this.script = null;
    this.data = [];
    this.base = {};
  }

  add() {
    // <script type="application/ld+json" id="videoschema"></script>
    if (this.script) {
      return false;
    }
    this.script = document.createElement('script');
    this.script.type = 'application/ld+json';
    this.script.id = 'jsonld';

    document.head.appendChild(this.script);
    return true;
  }

  remove() {
    // this.data = [];
    if (this.script) {
      this.script.parentNode.removeChild(this.script);
      this.script = null;
      return true;
    }
    return false;
  }

  update(data = null) {
    if (data) {
      this.script.innerHTML = this.createMarkup(data);
    } else {
      this.script.innerHTML = this.createMarkup(this.data);
    }
  }

  push(data) {
    // console.trace(data['@type']);
    this.data.push(this.deleteEmptyFiedls(data));
  }

  pop(index = -1) {
    // console.trace(index);

    if (index > 0 && index < this.data.length) {
      this.data.splice(index, 1);
    } else {
      this.data.pop();
    }
  }

  deleteEmptyFiedls(obj) {
    const res = {};
    for (const key in obj) {
      const val = obj[key];
      if (obj.hasOwnProperty(key) && val !== null && val !== undefined) {
        if (this.isObject(val)) {
          res[key] = this.deleteEmptyFiedls(val);
        } else if (this.isArray(val)) {
          res[key] = this.arrayToJsonStr(val);
        } else if (!this.isString(val)) {
          res[key] = JSON.stringify(val);
        } else {
          res[key] = val;
        }
      }
    }
    return res;
  }

  arrayToJsonStr(arr) {
    return arr.map((el) => (this.isString(el) ? el : (this.isObject(el)) ? this.deleteEmptyFiedls(el) : JSON.stringify(el)));
  }

  isObject(obj) {
    return obj ? obj.constructor.name.toLowerCase() === 'object' : false;
  }

  isString(str) {
    return str || str === '' ? typeof str === 'string' : false;
  }

  isArray(obj) {
    return obj ? obj.constructor.name.toLowerCase() === 'array' : false;
  }

  createMarkup(data) {
    // console.log(data);
    if (!data || !(this.isObject(data) || this.isArray(data))) {
      console.error('No set any data');
      return '';
    }
    // this.data = [];
    // this.data.push(this.base);
    // this.data.push(this.deleteEmptyFiedls(data));
    return JSON.stringify(data);
  }
})();
