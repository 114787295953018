<template>
  <b-modal
    v-model="showModal"
    centered
    scrollable
    :size="'lg'"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="term-of-use-popup modal-rounded">
      <button class="close-btn" @click="simpleCloseModal">
        <img :src="require(`@/assets/icons/icon-close.svg`)">
      </button>
      <div class="content-wrap">
        <div class="term-of-use-popup__title">
          <span>Term of Use</span>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            Buyer Policy
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              Buyer Policy Shoprz is a livestream social
              shopping platform. We want you to have a positive experience shopping on Shoprz.
              Please read on to find out more about your rights, as well as what is expected of you, as a buyer.
              This policy was published on February 7, 2022.
              This policy is a part of our Terms of Use and outlines our expectations for buyer behavior.
              By shopping on Shoprz, you’re agreeing to this policy and our Terms of Use.
            </p>

            <ul>
              <li>
                1. Understanding Shoprz’s Limitations as a Platform
              </li>
              <li>
                2. Communicating with Other Shoprz Members
              </li>
              <li>
                3. Purchasing an Item on Shoprz
              </li>
              <li>
                4. Leaving a Review, Comment, Communication, and Other Content
              </li>
              <li>
                5. Creating and Uploading Content
              </li>
              <li>
                6. Reporting a Problem with an Order or Returning an Item
              </li>
            </ul>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            1. Understanding Shoprz’s Limitations as a Platform
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              Shoprz provides a livestream social shopping platform.
              By shopping on Shoprz, you understand that:
            </p>

            <ul>
              <li>
                1. You are not buying from one of the sellers on Shoprz;
              </li>
              <li>
                2. Shoprz does not warrant that product descriptions or other content is accurate,
                complete, or error free;
              </li>
              <li>
                3. Shoprz does not pre-screen items sold on Shoprz and therefore does
                not endorse or guarantee any items sold on
                Shoprz or any content posted by sellers
                (such as photographs or language used in listings or shop policies);
              </li>
              <li>
                4. Prices and promotions are subject to change;
              </li>
              <li>
                5. Shoprz cannot confirm the availability or price of an item until you place your order;
              </li>
              <li>
                6. Each seller on Shoprz has their own processing times, shipping methods, and shop policies;
              </li>
            </ul>

            <p>
              As a member of the community, you have the opportunity to flag an item or a
              shop that violates any of Shoprz’s policies.
            </p>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            2. Communicating with Other Shoprz Members
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              You can use Shoprz’s Messages (“Messages”) tool to communicate directly with sellers or other Shoprz members.
              Messages are a great way to ask sellers any questions you have about an item or an order.
              Messages may not be used for the following activities:
            </p>

            <ul>
              <li>
                1. Sending unsolicited advertising or promotions, requests for donations, or spam;
              </li>
              <li>
                2. Interfering with a transaction or the business of another member.
              </li>
              <li>
                3. Threating, harassing, or abusing another member or otherwise violating our Anti-Discrimination Policy;
              </li>
              <li>
                4. Contacting someone after they have explicitly asked you not to;
              </li>
              <li>
                5. Exchanging personal contact, financial or other information for the purposes
                of evading the checkout process on Shoprz, including phone number, address, email, social media handles,
                external URLs, instructions for money transfer, etc.
              </li>
              <li>
                6. Send viruses, malicious code, or software through our messaging service
              </li>
            </ul>

            <p>
              Interference <br>
              Interference occurs when a member intentionally interferes with another member’s
              shop in order to drive away their business. Interference is strictly prohibited on Shoprz.
              Examples of interference include:
            </p>

            <ul>
              <li>
                1. Posting in public areas to demonstrate or discuss a dispute with another member;
              </li>
              <li>
                2. Contacting another member via Messages to warn them away from a particular member, shop, or item;
              </li>
              <li>
                3. Purchasing from a seller for the sole purpose of leaving a negative review;
              </li>
            </ul>

            <p>
              Harassment and Discrimination <br>
              Any use of Messages to harass other members is strictly prohibited.
              Similarly, Messages may not be used to support or glorify hatred or otherwise violate our Anti-Discrimination Policy. If you receive a Message that violates this policy, please let us know right away.
            </p>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            3. Purchasing an Item on Shoprz
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              By making a purchase from a seller on Shoprz, you agree that you have:
            </p>

            <ul>
              <li>
                1. Read the item description and shop policies before making a purchase;
              </li>
              <li>
                2. Submitted appropriate payment for item(s) purchased;
              </li>
              <li>
                3. Provided accurate shipping information to the seller.
              </li>
            </ul>

            <p>
              By using a third-party service, you may also be subject to an agreement with the third party.
              For example, PayPal is a third-party service provided by PayPal Holdings, Inc.
              and is subject to
              the <a href="https://www.paypal.com/us/
              webapps/mpp/ua/useragreement-full" target="_blank"
              >PayPal User Agreement</a>.
              Apple Pay is a third-party service provided by Apple Inc. and is subject to the Apple Pay Terms and Conditions.
              Google Pay is a third-party service provided by Google Payment Corp.
              and is subject to the
              <a href="https://payments.google.com/payments/apis-secure/u/0/
              get_legal_document?ldo=0&ldt=googlepaytos&ldl=und#SafeHtmlFilter_US" target="_blank"
              >Google Pay Terms of Service for US users</a>
              , <a href="https://payments.google.com/
              payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=googlepaytos" target="_blank"
              >non-US users</a>,
              and <a href="https://pay.google.com/intl/en_in/about/terms/" target="_blank">India residents</a>.
            </p>

            <p>
              Shoprz may share your personal or transactional information with those
              third-party service providers for purposes related to payments processing.
              The provider and processing time for your payment will vary based on the country in which you're located.
              If there are insufficient
              funds in your bank account when our provider processes the payment for your purchase,
              Shoprz reserves the right to contact you directly and to seek payment.
            </p>

            <p>
              For the safety of our community, and to avoid scams and fraud,
              all transactions must take place through the Shoprz checkout system,
              and only transactions that take place through the
              Shoprz checkout system are eligible for our case system.
            </p>

            <p>
              It is prohibited to share contact information for the purposes of evading the checkout system on Shoprz.
              If you need to discuss the details of your order with your seller,
              please keep your communication on the Shoprz platform (via Messages).
            </p>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            4. Leaving a Review, Comment, Communication, and Other Content
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              Reviews are a great way to learn about a seller’s items,
              help good sellers build a strong reputation,
              or help warn other buyers about a poor experience.
            </p>

            <p>
              You can leave a review of your purchase,
              for 100 days after the latter of your item’s estimated delivery date.
              If an estimated delivery date is not available, the review window opens after the order’s
              processing time and shipping time have elapsed. You can edit your review any number of times during that
              100 day period. Your review and profile information will be publicly displayed on the seller's
              listing and review pages.
            </p>

            <p>
              By leaving a review or photograph,
              you acknowledge that your content may not violate our rules for
              Creating and Uploading Content in Section 5, in addition to the following:
            </p>

            <ul>
              <li>
                1. Contain graphic, mature, or obscene language or imagery,
                or any content that is subject to our mature content policy;
              </li>
              <li>
                2. Be about things outside the seller’s control,
                such as a shipping carrier, Shoprz, or a third party;
              </li>
              <li>
                3. Include shilling or otherwise falsely inflate a shop’s review score;
              </li>
              <li>
                4. Undermine the integrity of the reviews system.
              </li>
            </ul>

            <p>
              By uploading a photograph to one of Shoprz’s
              websites or Shoprz's mobile app, you warrant that:
            </p>

            <ul>
              <li>
                1. You own the photograph or you have the rights or permission to use the photograph;
              </li>
              <li>
                2. You understand that, as stated in Shoprz’s Terms of Use,
                Shoprz has license to use any content you provide to Shoprz.
              </li>
            </ul>

            <p>
              Sellers may respond to reviews and the responses must also comply with this policy.
              Sellers may report reviews that violate our Terms of Use.We reserve the right to remove reviews or
              photographs that violate our policies or Terms of Use.
            </p>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            5. Creating and Uploading Content
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <p>
              As a member of Shoprz, you have the opportunity to create and upload a variety of content,
              like Messages, text, photos, and videos. In order to keep our community safe and respectful,
              you agree that you will not upload content that:
            </p>

            <ul>
              <li>
                1. Contains hateful or derogatory language or imagery,
                or any content that is subject to our Anti-Discrimination and Hate Speech Policy;
              </li>
              <li>
                2. Contains threats, harassment, extortion, or violates our rules about interference;
              </li>
              <li>
                3. Violates someone else’s intellectual property rights;
              </li>
              <li>
                4. Is false, deceptive, or misleading;
              </li>
              <li>
                5. Contains unsolicited advertising or promotions, requests for donations, or spam;
              </li>
              <li>
                6. Contains private information, whether it is your own, or someone else’s;
              </li>
              <li>
                7. Encourages or facilitates a transaction that evades the Shoprz checkout process;
              </li>
              <li>
                8. Contains prohibited medical drug claims;
              </li>
              <li>
                9. Violates any of the rules described in our Prohibited Items Policy.
              </li>
            </ul>

            <p>
              Some content on Shoprz is subject to additional requirements. Please see the related sections above.
            </p>
          </div>
        </div>

        <div class="term-of-use-popup__section">
          <h2>
            6. Reporting a Problem with an Order or Returning an Item
          </h2>

          <div class="term-of-use-popup__section__content-block">
            <h2>
              Shoprz’s Case System
            </h2>

            <p>
              Although Shoprz is not directly involved in a transaction between a buyer and a seller,
              we provide a case system in the unlikely event that your order does not go as expected and you
              are unable to reach a resolution with the seller. If you experience an order issue,
              the first thing you must do is contact the seller via the Order link and give them 48 hours to resolve the issue.
              After this period, you can use Shoprz’s case system to alert Shoprz of an unresolved order issue
              in the event of a non-delivery or if an item you receive is not as described in the listing.
              By using Shoprz’s case system, you understand that Shoprz may use your personal information
              for the purpose of resolving disputes with other members. If you choose a refund as your preferred resolution,
              keep in mind that Shoprz only allows on-platform refunds for a period of 180 days post-transaction.
              After this 180 window has elapsed, Shoprz cannot support on-platform refunds for your order.
              However, you may still communicate directly with the seller to come to an off-platform resolution.
              Refunds issued by Shoprz will be in your original form of payment.
            </p>

            <p>
              Non-Delivery <br>
              A non-delivery occurs when a buyer places an order but does not receive the item.
              The following are examples of non-delivery cases:
            </p>

            <ul>
              <li>
                1. There is no valid proof that the item was shipped to the buyer.
              </li>
              <li>
                2. An item was not sent to the address provided on Shoprz.
              </li>
            </ul>

            <p>
              Not as Described <br>
              An item is not as described if the buyer can demonstrate that it is significantly
              different from the seller’s listing description or photos.
              Here are a few examples of not as described cases:
            </p>

            <ul>
              <li>
                1. The item received is a different color, model, version, or size.
              </li>
              <li>
                2. The item has a different design or material.
              </li>
              <li>
                3. The seller failed to disclose that an item is damaged or is missing parts.
              </li>
              <li>
                4. The buyer received the incorrect quantity of items
                (e.g., the buyer purchased three items but only received two).
              </li>
              <li>
                5. The item was advertised as authentic but is not authentic.
              </li>
              <li>
                6. The condition of the item is misrepresented
                (e.g., the item is described as new but is used).
              </li>
            </ul>

            <p>
              Not as described cases can also be filed for late delivery.
              In order to qualify as late delivery, the buyer must provide proof
              that all of these conditions have been met:
            </p>

            <ul>
              <li>
                1. The item(s) were ordered for a specific date or event.
              </li>
              <li>
                2. The item(s) are rendered useless after that date.
              </li>
              <li>
                3. The seller did not ship the item(s) according to
                their processing time or the date agreed upon in Messages.
              </li>
            </ul>

            <p>
              If Shoprz determines that an item is not as described,
              the seller will be required to refund the order,
              including original shipping and return shipping, if applicable.
            </p>

            <p>
              Ineligible Transactions<br>
              Some disputes don’t qualify for Shoprz’s case system. These include:
            </p>

            <ul>
              <li>
                1. Items that are damaged by shipping carrier (if properly packaged by the seller).
              </li>
              <li>
                2. Items that have been altered, used, worn, washed, or discarded after receipt.
              </li>
              <li>
                3. Items that are received after the agreed-upon delivery date due to shipping delays.
              </li>
              <li>
                4. Items that are returned without a return agreement.
              </li>
              <li>
                5. Items that are accurately described but don’t meet a buyer's expectations.
              </li>
              <li>
                6. Cost of shipping disputes.
              </li>
              <li>
                7. Items that are purchased in person.
              </li>
              <li>
                8. Items prohibited from sale on Shoprz, including services and intangible goods.
              </li>
              <li>
                9. Transactions where payment is not made via Shoprz’s checkout system.
              </li>
            </ul>

            <h2>
              Requesting a Cancellation
            </h2>

            <p>
              Only sellers may cancel transactions. Buyers may request that a seller cancel an order via Messages.
              Note that all cancellations must comply with our Anti-Discrimination Policy.
            </p>

            <h2>
              Returning an Item
            </h2>

            <p>
              Each seller has his or her own return policies,
              which should be outlined in their Shop Policies. Not all sellers accept returns.
            </p>
          </div>
        </div>
      </div>
      <div class="divider"/>
      <div class="btn-wrap">
        <button
          class="g-btn btn-wrap__button"
          @click="closeModal"
        >
          Cancel
        </button>

        <button
          v-if="showAcceptButton"
          class="g-btn btn-wrap__button-accept"
          :disabled="isTermsAccepted"
          @click="acceptHandlaer"
        >
          Accept
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'TermOfUseDialog',
    data() {
      return {
        showModal: false,
        showAcceptButton: false,
        callFromFooter: false,
      };
    },
    computed: {
      ...mapState({
        isTermsAccepted: state => state.termsAgreement.isTermsAccepted,
      }),
    },
    watch: {
      showModal(val) {
        this.$emit('change:modal', 'termOfUseModal', val);
      },
    },
    mounted() {
      this.$bus.$on('openTermsOfUseDialog', ({isAcceptHidden, callFromFooter}) => {
        this.showAcceptButton = false;
        this.callFromFooter = false;

        if (!isAcceptHidden){
          this.showAcceptButton = true;
        };

        if (callFromFooter){
          this.callFromFooter = true;
        };

        this.showModal = true;
      });
    },
    destroyed() {
      this.$bus.$off('openTermsOfUseDialog');
    },
    methods: {
      ...mapMutations({
        toggleTemsStatus: 'termsAgreement/toggleTermsStatus',
      }),
      openTermsErrorModal() {
        this.$bus.$emit('openTermsErrorModal');
      },
      simpleCloseModal() {
        this.showModal = false;
      },
      closeModal() {
        this.showModal = false;

        if (!this.callFromFooter && !this.isTermsAccepted){
          this.openTermsErrorModal();
        };
      },
      acceptHandlaer() {
        this.toggleTemsStatus();

        this.$bus.$emit('toggleRegisterVisibility', {
          hide: false,
        });

        this.$bus.$emit('termsOfUseAccepted');

        this.showModal = false;
      },
    },
  }
</script>

<style lang='scss' scoped>
  ::v-deep {
    .modal-body {
      height: calc(100vh - 80px);
    }

    .modal-dialog {
      margin-top: 30px;
    }
  }

  .term-of-use-popup {
    position: relative;
    max-width: 790px;
    margin: auto;
    padding: 50px 40px 40px;
    background-color: $button-white-txt-color;
    z-index: 1051;

    a {
      color: $modal-link-color;
      text-decoration: underline;
    }

    .close-btn {
      right: 10px;
      top: 2px;
      width: 10px;
      height: 10px;
      position: absolute;
      border: none;
      outline: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .divider {
      max-width: 790px;
      height: 1px;
      background-color: $sharing-divider-color;
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
    }

    &__section {
      margin-top: 45px;
      margin-bottom: 50px;
      padding-right: 50px;

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
      }

      &__content-block {
        ul {
          list-style-type: none;
        }
      }
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      &__button {
        padding: 15px 50px;
        font-weight: 600;
        background-color: $cancel-button-bg-color;
        outline: none;
        border: none;
        margin-right: 10px;
      }

      &__button-accept {
        padding: 15px 50px;
        font-weight: 600;
        background-color: $accept-button-bg-color;
        outline: none;
        border: none;
        color: $button-white-txt-color;

        &:disabled {
          filter: opacity(0.5);
        }
      }
    }
  }

  @media (max-width: 550px) {
    ::v-deep {
      .modal-body {
        height: calc(100vh - 40px);
      }

      .modal-dialog {
        margin: 0px 15px;
        margin-top: 20px;
      }
    }

    .term-of-use-popup {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 25px;

      &__section {
        padding-right: 0px;
        margin-bottom: 0px;
        margin-top: 20px;

        h2 {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>