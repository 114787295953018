<template>
  <div class="sp-accordion" :class="type">
    <div
      v-for="field of fields"
      :key="field.id"
      :style="{ 'pointer-events': field.disabled ? 'none' : 'auto' }"
      class="sp-accordion--item"
    >
      <div class="sp-accordion--item-head" @click="handleSelectFiled(field)">
        <slot :name="`head(${field.name})`">
          <div style="width: 100%">
            <div
              class="sp-selected"
              :class="{
                selected: field.selected,
                'sp-is-disabled': !!field.disabled,
              }"
            />
            <span
              class="sp-title"
              :class="{ 'sp-is-disabled': !!field.disabled }"
              >{{ field.title }}</span
            >
            <span v-if="field.icon" class="sp-accordion--item-icon">
              <img :src="iconSrc(field)" alt="" />
            </span>
          </div>
        </slot>
      </div>
      <div
        v-if="field.selected && !hideBody && !field.hideBody"
        class="sp-accordion--item-body"
      >
        <slot :name="`body(${field.name})`" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormAccordionComponent',

    props: {
      type: {
        type: String,
        default: '',
      },
      fields: {
        type: Array,
        default: () => [
          {
            title: 'Title',
            selected: false,
            id: 1,
          },
        ],
        require: true,
      },

      hideBody: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      iconSrc(field) {
        try {
          return require(`@/assets/icons/icon-${
            field.icon + (field.selected ? '-green' : '')
          }.svg`);
        } catch (e) {
          return '';
        }
      },
      handleSelectFiled(filed) {
        this.$emit('select:filed', filed);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-accordion {
    border: 1px solid #f0f2f6;
    border-radius: 5px;

    &.secondary {
      border: none;

      .sp-accordion--item-head {
        padding: 0;
      }
    }

    &--item {
      &:not(:last-child) {
        border-bottom: 1px solid #f0f2f6;
      }

      &-head {
        padding: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .sp-selected {
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          flex: 0 0 20px;
          border: 1px solid #e2e6ee;
          border-radius: 50%;
          margin-right: 8px;
          transition: 0.2s ease;

          &.selected {
            box-shadow: inset 0px 0px 0px 5px #21a66e;
            border: none;
          }
        }

        .sp-title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #343434;
        }
      }

      &-body {
        // background-color: #f8f9fc;
        padding: 16px;
        border-top: 1px solid #f0f2f6;
      }

      &-icon {
        float: right;
      }
    }
  }
</style>
