<template>
  <div class="password-popup__container" @click.prevent.stop="closeModal">
    <div class="password-popup__body">
      <button class="password-popup__return-button" @click="returnToPrevRoute">
        <i class="fas fa-chevron-left"/>
      </button>
      <div class="password-popup__password-icon">
        <img :src="doneImage">
      </div>
      <p class="password-popup__message">
        Your password has been changed
      </p>
      <div class="password-popup__actions">
        <button class="password-popup__button password-popup__button--close" @click="closeModal">
          Close
        </button>
        <button class="password-popup__button password-popup__button--sign" @click="openSignIn">
          Sign in
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PasswordChangedPopup',
    data() {
      return {
        doneImage: require('src/assets/icons/done.svg'),
      };
    },
    methods: {
      closeModal() {
        this.$emit('closePasswordPopup');
      },
      returnToPrevRoute() {
        this.$router.back();
      },
      openSignIn(){
        this.$emit('openSignInPopup');
      },
    },
  };
</script>
<style lang="scss" scoped>
@import './password-changed-popup.scss';
</style>
