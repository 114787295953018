<template>
  <div>
    <div class="sp-password-wrapper">
      <input
        v-model="val"
        :placeholder="placeholder"
        :type="isVisible ? 'text' : 'password'"
      />
      <i
        :class="`fas ${isVisible ? 'fa-eye-slash' : 'fa-eye'}`"
        @click="toggleVisible"
      />
    </div>
    <div
      v-if="errorMessage"
      class="sp-password-error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PasswordComponent',
    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      errorMessage: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        isVisible: false,
      };
    },

    computed: {
      val: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },

    methods: {
      toggleVisible() {
        this.isVisible = !this.isVisible;
      },
    },

  };
</script>

<style lang="scss" scoped>
  .sp-password-error {
    font-size: 12px;
    color: red;
  }
  .sp-password-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #E2E6EE;
    border-radius: 5px;
    padding: 0.5rem;
    input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 15px;
      &::placeholder {
        color: #B6C0D3;
      }
    }

    i {
      color: #B6C0D3;
      cursor: pointer;
    }
  }
</style>
