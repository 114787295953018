<template>
  <div v-if="item" ref="iframePopupWrapper" class="full-page-iframe">
    <div ref="iframePopup" class="product-popup__container">
      <iframe
        ref="iframePopupRef"
        width="100%"
        height="100%"
        class="product-popup__frame"
        :src="item.link"
        frameborder="0"
      />
    </div>
    <div class="product-popup">
      <button
        class="product-popup__button"
        @click.stop="closeModal"
      >
        <img
          :src="require('/src/assets/icons/close_btn_dark.png')"
          alt="Close button image"
        >
      </button>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { PM } from 'vimmi-web-utils/cjs/postMessage';
  import WishlistService from '@/store/wishlist';
  import { amsClient } from '@/service/ams';
  import Auth from '@/service/authService';

  export default {
    name: 'IframePopup',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        prevTitle: document.title,
      };
    },
    async mounted() {
      this.updateTitle();
      Vue.prototype.$shopingCartIframeRef = this.$refs.iframePopupRef;

      this.$refs.iframePopup.addEventListener('click', (event) => event.stopPropagation());
      this.$refs.iframePopupWrapper.addEventListener('click', this.closeModal);

      PM.inst().on('shopify', async (data) => {
        console.log('shopify', data);

        if (data.event === 'addToWishlist') {
          try {
            const { head } = await amsClient.callAms(`/shoprzapi/product/${data.productId}/?by=rid`);

            console.log('head', head, Auth.isGuest());
            this.$stats.send('add_wishlist', {}, { item: head });
            if (Auth.isGuest()) {
              localStorage.setItem('addToWishlist', head.id);
              return;
            }

            this.$stats.send('add_wishlist', {}, { item: head });

            WishlistService.addToWishlist(head.id);
          } catch {
          }
        }
      });
    },
    unmounted() {
      this.$refs.iframePopup.removeEventListener('click');
      this.$refs.iframePopupWrapper.removeEventListener('click');
      PM.inst().off('shopify');
    },
    methods: {
      updateTitle() {
        if (this.item.title) {
          this.$bus.$emit('updateTitle', this.item.title);
        }
      },
      closeModal() {
        this.$emit('closeIframePopup');
        this.$bus.$emit('updateTitle', this.prevTitle);
      },
    },
  };
</script>
<style lang="scss">
  .full-page-iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    left: 0;
    z-index: 1488;
    padding-top: 0;
    background: #000000a8;

    iframe {
      border: 0;
    }

    & + .vod-popup-container {
      z-index: 1100;
    }
  }

  .product-popup__container {
    background-color: #fff;
  }

  .product-popup {
    position: relative;

    &__container {
      margin-top: 72px;
      max-height: 88%;
      width: 1200px;
    }

    &__button {
      background-color: transparent;
      border: 0;
      position: absolute;
      right: 20px;
      top: 85px;
    }

    &__frame {
      border-radius: 5px;
    }
  }
</style>
