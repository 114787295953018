import { Logger } from 'logger-js';

import VimmiAI from 'vimmi-analytics-integration/dist/vimmi-analytics-integration.cjs';
import { isActiveFeature } from 'src/plugins/feature-toggle';
import AnalyticsUtils from './utils';
import { amsClient } from '@/service/ams';
import Auth from '../../service/authService';
import { ConfigService } from 'vimmi-web-utils/esm/configService';
import Config from '../../service/config';

export default class Analytics extends ConfigService {
  constructor() {
    super();
    this.collectData = {};
    this.logger = new Logger('Analytics');
    this.utils = AnalyticsUtils;

    const drivers = [];

    if (isActiveFeature('shoprz-analytics')) {
      drivers.push(VimmiAI.DRIVERS.SHOPRZ);
    }

    if (isActiveFeature('gtm-analytics')) {
      drivers.push(VimmiAI.DRIVERS.GTM);
    }

    if (drivers.length) {
      this.analyticModule = amsClient.vimmiAIInit(VimmiAI, { drivers });

      if (isActiveFeature('shoprz-analytics')) {
        this.analyticModule.conf({
          customPath: '/batch',
        }, null, VimmiAI.DRIVERS.SHOPRZ);
      }

      amsClient.attachAnalytics(this);
    }
  }

  _getEventData(event, options, event_extra_data) {
    /** todo: separate event data by driver */
    let data = {
      // app_info: Config.get('app_info'),
      // app_version: Config.get('version'),
      // customer: process.env.VUE_APP_CUSTOMER.toLowerCase(),
      // session_id: Auth.get('user.sid'),
      // server: location.host,
      app_language: 'eng',
      user_id: Auth.get('user.id'),
      refferal_url: document.referrer || undefined,
      classification: amsClient.get('user.guest') ? 'guest' : 'registered',
      store_id: event_extra_data.store_id || amsClient.get('conf.store.id'),
    };

    const screen = this.get('current.screen') || {};
    const itype = options?.item?.itype;
    const itemId = event_extra_data.item_id || event_extra_data.media_id;
    switch (event) {
      case 'is_alive ':
      case 'session_start':
        data = Object.assign(data, {
          session_id: Auth.get('user.sid'),
        });
        break;
      case 'remove_from_cart': // todo:
        break;
      case 'add_to_cart':
        data = Object.assign(data, {
          product_id: options.item.id,
          channel_id: this.getChannelId(options.item),
          department_id: this.getDepartmentId(options.item),
        });
        break;
      case 'checkout_clicked':
      case 'checkout_finished': // todo:
        break;
      case 'channel_unfollow':
      case 'channel_follow':
        data = Object.assign(data, {
          channel_id: this.getChannelId(options.item),
          // store_id: this.getStoreId(options.item),
        });
        break;
      case 'remove_wishlist':
      case 'add_wishlist':
        data = Object.assign(data, {
          product_id: options.item.id,
          channel_id: this.getChannelId(options.item),
        });
        break;
      case 'commented':
        data = Object.assign(data, {
          media_id: options.item.id,
          media_type: this.getMediaType(options.item),
        });
        break;
      case 'share':
        data = Object.assign(data, {
          channel_id: this.getChannelId(options.item),
          department_id: this.getDepartmentId(options.item),
          ...this.formatEventDate(options.item),
        });
        break;
      case 'notify_on':
        data.media_id = options.item.id;
        break;
      case 'page_opened':
        /// / todo:channel_id, department_id, product_id, media_id, banner_id, media_type
        Config.set('temp.page_type', event_extra_data.page_type);
        if (options.item) {
          data = Object.assign(data, {
            channel_id: this.getChannelId(options.item),
            department_id: this.getDepartmentId(options.item),
          });
        }
        break;
      case 'registration':
      case 'login':
        let authType = Auth.get('user.login_type');
        if (authType === 'silent') {
          authType = 'guest';
        } else if (authType === 'default') {
          authType = 'standard';
        }
        data = Object.assign(data, {
          auth_type: authType,
        });
        break;
      case 'object_click':
        data = Object.assign(data, {
          page_type: Config.get('temp.page_type'),
          object_type: this.getItemType(options.item),
          channel_id: this.getChannelId(options.item),
          department_id: this.getDepartmentId(options.item),
          ...this.formatEventDate(options.item),
        });
        break;
      case 'search_chosen':
        data = Object.assign(data, {
          department_id: this.getDepartmentId(options.item),
          ...this.formatEventDate(options.item),
        });
        break;

      case 'watch':
        data = Object.assign(data, {
          media_type: this.getMediaType(options.item),
          media_id: options.item.id,
          channel_id: this.getChannelId(options.item),
          department_id: this.getDepartmentId(options.item),
          watch_payload: this.getCollectedData(options.item.id, event)?.watch_payload || [],
        });
        this.clearCollectedData(itemId, event);
        break;
      case 'heartbeat':
        data = Object.assign(data, {
          media_type: this.getMediaType(options.item),
          media_id: options.item.id,
          // channel_id: this.getChannelId(options.item),
          // department_id: this.getDepartmentId(options.item),

        });
        break;
    }

    return data;
  }

  getCollectedData(itemId, eventName) {
    return (this.collectData[itemId] && this.collectData[itemId][eventName]) || {};
  }

  clearCollectedData(itemId, eventName) {
    if (this.collectData[itemId]) {
      delete this.collectData[itemId][eventName];
    }
  }

  /**
   * @param itemId {String}
   * @param eventName {String}
   * @param playerEvent {String}
   * @param eventData {Object}
   * @param playerType {'main'|'float'|'popup'}
   * */
  collect(itemId, eventName, playerEvent, eventData, playerType = 'main') {
    this.collectData[itemId] = this.collectData[itemId] || {};
    if (eventName === 'watch') {
      this.collectData[itemId][eventName] = this.collectData[itemId][eventName] || {
        watch_payload: [],
        seeded: false,
      };

      let lastSegment = this.collectData[itemId][eventName].watch_payload.length
        ? this.collectData[itemId][eventName].watch_payload[this.collectData[itemId][eventName].watch_payload.length - 1]
        : null;
      if (eventData.seeking) {
        return;
      }

      if (!lastSegment) {
        lastSegment = [eventData.prevPosition, eventData.position];
        this.collectData[itemId][eventName].watch_payload.push(lastSegment);
      } else if (playerEvent === 'seeked' && eventData.watchTime !== 0) {
        // lastSegment = [eventData.position, eventData.position];
        this.collectData[itemId][eventName].seeded = true;
        // this.collectData[itemId][eventName].watch_payload.push(lastSegment);
      } else if (this.collectData[itemId][eventName].seeded) {
        this.collectData[itemId][eventName].seeded = false;
        lastSegment = [eventData.position, eventData.position];
        this.collectData[itemId][eventName].watch_payload.push(lastSegment);
      } else {
        lastSegment[1] = eventData.position;
      }
      // console.log(`[watch][${itemId}]`, playerEvent, {
      //   ...eventData,
      // }, ...this.collectData[itemId][eventName].watch_payload);
    } else if (eventName === 'heartbeat') {
      if (!eventData.position) {
        return;
      }
      if (playerEvent === 'ended' && !this.collectData[itemId][eventName]) {
        return;
      }
      this.collectData[itemId][eventName] = this.collectData[itemId][eventName] || {
        watchTime: {},
        playerType: null,
        prevTime: 0,
      };

      if (!this.collectData[itemId][eventName].playerType) {
        this.collectData[itemId][eventName].playerType = playerType;
        this.collectData[itemId][eventName].watchTime[playerType] = [];
        this.collectData[itemId][eventName].watchTime[playerType].push(eventData.watchTime);
      } else if (this.collectData[itemId][eventName].playerType !== playerType) {
        const prevPlayerType = this.collectData[itemId][eventName].playerType;
        this.collectData[itemId][eventName].playerType = playerType;
        if (!this.collectData[itemId][eventName].watchTime[playerType]) {
          this.collectData[itemId][eventName].watchTime[playerType] = [];
        }
        if (eventData.watchTime <= 0.6) {
          this.collectData[itemId][eventName].watchTime[playerType].push(eventData.watchTime);
        } else {
          this.collectData[itemId][eventName].watchTime[playerType][this.collectData[itemId][eventName].watchTime[playerType].length - 1] = eventData.watchTime;
        }
      } else {
        this.collectData[itemId][eventName].watchTime[playerType][this.collectData[itemId][eventName].watchTime[playerType].length - 1] = eventData.watchTime;
      }
      const realWatchTime = Object.values(this.collectData[itemId][eventName].watchTime).reduce((total, b) => total + b.reduce((acc, item) => acc + item, 0), 0);
      if (realWatchTime > 0) {
        if ((playerEvent === 'ended' && realWatchTime >= this.collectData[itemId][eventName].prevTime)
          || (realWatchTime >= this.collectData[itemId][eventName].prevTime + 59)) {
          const sendTime = realWatchTime - this.collectData[itemId][eventName].prevTime;
          this.collectData[itemId][eventName].prevTime = realWatchTime;
          return { stream_time: sendTime };
        }
      }
    }
  }

  getStoreId(item) {
    return amsClient.get('conf.store.id') || item?.store_id || item?.store?.id || item?.storefront?.id || item?.creator?.store_id;
  }

  getStoreTitle(item) {
    return amsClient.get('conf.store.name') || item?.store?.name;
  }

  getChannelId(item) {
    if (item?.storefront) {
      if (item?.storefront.storefront_type === 'channel') {
        return item?.storefront?.id;
      }
    }
    return undefined;
  }

  getChannelTitle(item) {
    if (item?.storefront) {
      if (item?.storefront.storefront_type === 'channel') {
        return item?.storefront?.name;
      }
    }
    return undefined;
  }

  getDepartmentId(item) {
    if (item?.storefront) {
      if (item?.storefront.storefront_type === 'department') {
        return item?.storefront?.id;
      }
    }
    return undefined;
  }

  getMediaType(item) {
    if (item.itype === 'item_epg_shop_event' && item.is_live) {
      return 'live';
    }
    switch (item.itype) {
      case 'item_mov_episode':
      case 'item_mov_vod':
      case 'item_mov_vod_shoprz':
      case 'item_epg_shop_event':
        return 'vod';
      case 'item_live':
      case 'custom_channel_event':
        return 'live';
    }
  }

  formatEventDate(item) {
    if (!item) {
      return {};
    }
    if (item?.itype === 'custom_channel_event') {
      item = item.actualEvent;
    }
    const { itype } = item;
    const options = {};
    if (itype.includes('live') || itype.includes('vod') || itype.includes('epg_shop_event')) {
      options.media_type = this.getMediaType(item);
      options.media_id = item.id;
    } else if (itype === 'shop_product') {
      options.product_id = item.id;
    } else if (this.getItemType(item) === 'banner') {
      options.banner_id = item.id;
    }/* else { // todo: segment_id, screen_id
      options.item_id = item.id;
    } */
    return options;
  }

  getItemType(item) {
    switch (item.itype) {
      case 'item_mov_episode':
      case 'item_mov_vod':
      case 'item_mov_vod_shoprz':
      case 'item_live':
      case 'item_epg_shop_event':
        return 'media';
      case 'shop_product':
        return 'product';
      case 'screen_shoprz':
      case 'event_host_admin':
        return 'screen';
      case 'section_filters_shoprz':
      case 'section_season':
      case 'category_series_head':
        return 'section'; // ?
      case 'banner_group':
        return 'banner'; // ?
    }
  }

  send(event, event_extra_data = {}, options = {}) {
    if (!isActiveFeature('shoprz-analytics')) {
      return;
    }
    const computedExtraData = { ...this._getEventData(event, options, event_extra_data), ...event_extra_data };

    this.analyticModule.send(event, computedExtraData, {
      beacon: !!options.beacon,
      only: options.only || null,
      buffer_timestamp: options.buffer_timestamp || null,
    });
  }
}