<template>
  <b-modal
    v-model="showModal"
    centered
    scrollable
    :size="isMobile ? 'sm' : 'lg'"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="sp-popup modal-rounded">
      <button class="close-btn" @click="simpleCloseModal">
        <img :src="require(`@/assets/icons/icon-close.svg`)">
      </button>
      <div class="content-wrap">
        <span class="sp-popup-title">Do you really want to sign out of Shoprz</span>
      </div>
      <div class="btn-wrap">
        <button
          class="btn-wrap__button"
          @click="closeModal"
        >
          No
        </button>

        <button
          v-if="showAcceptButton"
          class="btn-wrap__button-accept"
          @click="acceptHandler"
        >
          Yes
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Auth from '@/service/authService';
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'TermOfUseDialog',
    data() {
      return {
        showModal: false,
        showAcceptButton: false,
        callFromFooter: false,
        isMobile: false,
      };
    },
    computed: {
      ...mapState({
        isTermsAccepted: state => state.termsAgreement.isTermsAccepted,
      }),
    },
    watch: {
      showModal(val) {
        // this.$emit('change:modal', 'termOfUseModal', val);
      },
    },
    mounted() {
      this.checkWidth();
      this.$bus.$on('resizeWindow', this.checkWidth);
      this.$bus.$on('openSignOutDialog', ({isAcceptHidden, callFromFooter}) => {
        this.showAcceptButton = false;
        this.callFromFooter = false;

        if (!isAcceptHidden){
          this.showAcceptButton = true;
        };

        if (callFromFooter){
          this.callFromFooter = true;
        };

        this.showModal = true;
      });
    },
    destroyed() {
      this.$bus.$off('openSignOutDialog');
      this.$bus.$off('resizeWindow');
    },
    methods: {
      ...mapMutations({
        toggleTemsStatus: 'termsAgreement/toggleTermsStatus',
      }),
      checkWidth() {
        this.isMobile = window.innerWidth < 576;
      },
      openTermsErrorModal() {
        this.$bus.$emit('openTermsErrorModal');
      },
      simpleCloseModal() {
        this.showModal = false;
      },
      closeModal() {
        this.showModal = false;
      },
      async acceptHandler() {
        await Auth.logOut();
        WishlistService.clear();

        this.$bus.$emit('logout');
        this.showModal = false;

        if (this.$route.path.includes('wishlist') || this.$route.path.includes('checkout')) {
          this.$router.push('/');
        }
      },
    },
  }
</script>

<style lang='scss' scoped>
  ::v-deep {
    .modal-body {
      //width: 400px;
      //height: calc(100vh - 80px);
    }

    .modal-dialog {
      overflow: hidden;
      margin-top: 0;
      width: 500px;
    }

    .modal-content {
      //width: 400px;
    }
  }

  .sp-popup {
    position: relative;
    max-width: 790px;
    margin: auto;
    padding: 50px 40px 40px;
    background-color: $button-white-txt-color;
    z-index: 1051;

    a {
      color: $modal-link-color;
      text-decoration: underline;
    }

    .close-btn {
      right: 10px;
      top: 2px;
      width: 10px;
      height: 10px;
      position: absolute;
      border: none;
      outline: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-size: 20px;
      text-align: center;
    }

    .content-wrap {
      text-align: center;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      &__button {
        border-radius: 5px;
        width: 200px;
        height: 56px;
        padding: 15px 50px;
        background-color: $cancel-button-bg-color;
        outline: none;
        border: none;
        margin-right: 10px;
      }

      &__button-accept {
        width: 200px;
        height: 56px;
        border-radius: 5px;
        padding: 15px 50px;
        background-color: #21A66E;
        outline: none;
        border: none;
        color: $button-white-txt-color;

        &:disabled {
          filter: opacity(0.5);
        }
      }
    }
  }

  @media (max-width: 550px) {
    ::v-deep {
      .modal-body {
        //height: calc(100vh - 40px);
      }

      .modal-dialog {
        //margin: 0px 15px;
        //margin-top: 20px;
      }
    }

    .term-of-use-popup {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 25px;

      &__section {
        padding-right: 0px;
        margin-bottom: 0px;
        margin-top: 20px;

        h2 {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>