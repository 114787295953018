import Vue from 'vue';
import AMSClient from 'ams-client-js/dist/ams-client.esm';
import Config from './config';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import { isActiveFeature } from '@/plugins/feature-toggle';
import { visitorId } from 'vimmi-web-utils/esm/visitorId';
import { makeHash } from 'vimmi-web-utils/cjs/makeHash';
import { deepMerge } from 'vimmi-web-utils/cjs/deepMerge';
// import Auth from 'src/service/authService';

class PortalAmsClient extends AMSClient {
  callAms(path, options) {
    const prid = this.getPridId();

    const requestOptions = {
      ...options,
      headers: {
        ...(options?.headers && options.headers),
        ...(Vue.prototype.$isStandaloneStore && {
          standalone: Vue.prototype.$standaloneStoreName,
        }),
        ...(prid ? { prid } : {}),
        'cache-control': 'no-store',
        pragma: 'no-cache',
        source: 'portal',
        ...(amsClient.get('user')?.sid
          ? { authorization: `Bearer ${amsClient.get('user').sid}` }
          : {}),
      },
    };

    return super.callAms(path, {
      ...requestOptions,
    });
  }

  getPridId() {
    return Config.get('provider_id') || 'gjukfabvy32xq3hk';
  }
}

export const amsClient = new PortalAmsClient({
  backend: location.host,
  platform: 'web',
  defaultAmsCache: false,
  appInfo: {
    customer: process.env.VUE_APP_CUSTOMER.toLowerCase(),
    app_id: Config.get('app_id'),
    app_info: Config.get('app_info'),
    app_version: Config.get('version'),
    language: Config.get('activeLang'), // todo: handle as  2 symbol, and switch
    device_key: Config.get('device_key'),
  },
  authInfo: {
    public_app_key: Config.get('auth.ams.public_app_key'),
    device_id: localStore.get('udid') || null,
  },
  conf: {
    pathPrefix: '/api',
  },
});

/** whe AMS conf loaded */
amsClient.on('conf', (amsConf) => {
  const conf = deepMerge(
    amsConf.portal || {},
    {
      languages: { list: amsConf.languages || {} },
    },
    { buh: amsClient.get('buh') },
  );

  // console.log('amsConf: ', amsConf);
  Config.load(conf, amsClient);

  Config.set('provider_id', amsConf?.store?.provider_id || null);
});

Promise.all([visitorId(), amsClient.init()]).then((res) => {
  const udid = res[0];

  amsClient.conf({
    authInfo: {
      device_id: udid,
    },
  });

  if (!amsClient.get('extraData.temp_id')) {
    const randomString = makeHash(16);
    /** Temporal user ID. Mainly to use on events before user created. Should be sent with all events.  */
    amsClient.setExtraData('temp_id', `temp_${udid}_${randomString}`);
  }

  if (isActiveFeature('guest-users')) {
    /** to login as quest user */
    if (!amsClient.isAuth()) {
      amsClient.account.guestLogin(
        {
          path: '/api/accounts/login/',
        },
        { prid: amsClient.getPridId() },
      );
    }
  }
});
