const state = () => ({
  isPurchaseShown: false,
  isAuthShown: true,
  isSharing: false,
  currentStoreLink: '',
  currentStoreName: '',
  floatingMode: null,
  showFloatingWrapper: false,
  screenType: 'full',
  isLoadingIframe: false,
  miniPlayerFromProduct: false,
  showAccountPopup: false,
});

const getters = {
  isAnotherPopupShown: (state) => state.isPurchaseShown || state.isAuthShown,
  screenType: (state) => state.screenType,
  floatingMode: (state) => state.floatingMode,
  isVodFloating: (state) => state.floatingMode === 'vod',
  isLiveFloating: (state) => state.floatingMode === 'live',
  getLoadingIframe: (state) => state.isLoadingIframe,
  miniPlayerFromProduct: (state) => state.miniPlayerFromProduct,
  isAnotherPopupShown(state) {
    return state.isPurchaseShown || state.isAuthShown;
  },
  accountPopupState: (state) => state.showAccountPopup,
};
const mutations = {
  setPurchaseState(state, status) {
    state.isPurchaseShown = status ?? state.isPurchaseShown;
  },
  setAuthState(state, status) {
    state.isAuthShown = status ?? state.isAuthShown;
  },
  /**
  * @param {Object} data
  * @param {Boolean} data.status  status of a popup
  * @param {String} data.name  name of a store
  * @param {String} data.link  link to the store page
  */
  setSharing(state, data) {
    const { status, name, link } = data;
    state.isSharing = status;
    state.currentStoreName = name;
    state.currentStoreLink = link;
  },
  setFloatingMode(state, value) {
    state.floatingMode = value;
  },
  setMiniPlayerFromProduct(state, value) {
    state.miniPlayerFromProduct = value;
  },
  setFloatingWrapperState(state, value) {
    state.showFloatingWrapper = value;
  },
  setScreenType(state, value) {
    state.screenType = value;
  },
  setLoadingIframe(state, value) {
    state.isLoadingIframe = value;
  },
  setAccountPopupState(state, value) {
    state.showAccountPopup = value;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  // actions,
  mutations,
};
