export default `query getLocations($after: String) {
  locations(first: 100, after: $after) {
    edges {
      node {
        id
        name
        address {
          address1
          address2
          city
          country
          countryCode
          zip
          formatted
        }
        metafields(first: 3, namespace: "shoprz") {
          edges {
            node {
              key
              value
              id
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`;
