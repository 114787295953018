export default function (price) {
  if (!price) {
    return '';
  }

  const _price = +price % 1 ? price : parseInt(price, 10);
  const currency = '₪';
  // const currency = words[1] === 'ILS' ? '₪' : '$'
  return `${_price} ${currency}`;
}
