export default {
  // Home Page
  HEADER: {
    LOGIN: 'היכנס',
    PROFILE: 'פרופיל',
    ACCOUNT: 'חשבון',
    LOGOUT: 'התנתק',
    SEARCH: 'חיפוש',
    HOME_BTN: 'חזרה לעמוד הבית',
  },

  // Show Page/ Video Page
  VIDEO_COMPONENT: {
    RELATED_LIST: 'הצעות נוספות',
    NO_RELATED_LIST: 'אין תכנים קשורים',
  },

  // Live Page
  EPG: {
    TV_SCHEDULE: 'מדריכי תוכניות טלוויזיה',
    TODAY: 'היום',
    TOMORROW: 'מחר',
  },
  // Error Page
  ERROR: {
    BACK_HOME: 'חזור לעמוד הבית',
  },

  // Search Page
  SEARCH_PAGE: {
    RESULTS_FOR: 'תוצאות עבור:',
    NOT_SELECTED: 'לא נבחרו קטגוריות לצפייה',
    NOT_FOUND: 'לא נמצא {name} עם מילות מפתח:',
    LOAD_MORE: 'טען עוד',
  },

  // Dashboard
  BLOCK: {
    SETTINGS: 'הגדרות',
    DIRECTORS: 'מרצה',
    BLOCK: {
      MY_ACCOUNT: 'החשבון שלי',
      MY_PREFERENCE: 'העדפות שלי',
      CONTACT_US: {
        TITLE: 'צור קשר',
        PHONE: {
          NAME: 'טלפון',
          VALUE: '1700-50-41-41',
        },
        EMAIL: {
          NAME: 'אימייל',
          VALUE: 'zmaneshkol@NLC.co.il',
        },
      },
      PRICES: {
        TITLE: 'מחירון',
        COST: {
          NAME: 'עלות רכישת כל אחת מההרצאות',
          VALUE: '39 ש"ח',
        },
      },
      PURCHASES: 'היסטוריית רכישות',
    },
    LANGUAGE: 'שפה',
    AUDIO_LANGUAGE: 'שפת שמע מועדפת',
    SAVE: 'שמור',
    LENGTH: 'משך:',
    YEAR: 'שנה:',
    VIEWS: 'צפיות:',
    SEASONS: 'עונות:',
    USER: {
      MY_ACCOUNT: 'החשבון שלי',
      INTERNET_NUMBER: 'מספר פנימי',
      CONTACT_NUMBER: 'מספר טלפון',
      EMAIL: 'אימייל',
      PASSWORD: {
        TITLE: 'סיסמא',
        CHANGE_PASSWORD: 'שנה סיסמא',
      },
    },
  },

  // Change password popup
  CHANGE_PASSWORD: {
    TITLE: 'שנה סיסמא',
    OLD_PAS: 'סיסמא ישנה',
    NEW_PAS: 'סיסמא חדשה',
    REPEAT_PAS: 'חזור על הסיסמא',
    BUTTONS: {
      CONFIRM: 'אשר',
      CANCEL: 'בטל',
    },
    SUCCESS: 'שינית את סיסמתך בהצלחה',
    NEW_PASS_DIFF: 'הסיסמא החוזרת אינה נכונה',
    OLD_PASS_INCORRECT: 'סיסמא ישנה אינה נכונה',
  },

  // Autorization form
  LOGIN: {
    LOGIN_TITLE: 'היכנס',
    EMAIL: 'אימייל',
    SING_IN: 'היכנס',
    REGISTER: 'צור חשבון',
    CANCEL: 'בטל',
    FORGOT_PASS: 'שכחת סיסמא?',
    WAIT: 'אנא המתן!',
    FUNGUS: 'Fungus',
    PASSWORD: 'סיסמה',
    REMEMBER_ME: 'זכור אותי',
    RESEND_CONFIRM: 'שלח שוב הודעת אישור',
    SUCCESSFULLY_SENT: 'הודעת האישור נשלחה בהצלחה',
    PLEASE_FILL_IN_LOGIN: 'אנא מלא את שם המשתמש הנכון!',
    PLEASE_FILL_IN_PASSWORD: 'אנא מלא את הסיסמה הנכונה!',
    ACCOUNT_NOT_ACTIVE: 'חשבון זה הושבת.',
    CORRECT_LOGIN: 'אנא בדוק דוא"ל',
    YOUR_PASSWORD_MUST:
      'על הסיסמה שלך להיות באורך של 6-20 תווים, להכיל אותיות ומספרים, וללא רווחים, תווים מיוחדים או דוא"ל.',
    SUCCESSFULLY_AUTHORIZED: 'אתה מורשה להשתמש בשירות',
    AUTHENTICATION_DATA:
      'אם כבר יש לך חשבון, אתה יכול להשתמש באותם נתוני אימות.',
    CLICK_LOGIN: {
      ZMAN: 'במידה וכבר נרשמת בעבר - לחץ "היכנס".',
      NINE: 'If you have an existing account, click Sign in,',
    },
  },
  REGISTER_FORM: {
    REGISTER_TITLE: 'צור חשבון',
    USERNAME: 'שם משתמש',
    EMAIL: 'אימייל',
    PASSWORD: 'סיסמה',
    CONF_PASSWORD: 'חזור על הסיסמא',
    PRIVACY_LINK: 'מאשר את תנאי השימוש והפרטיות',
    PRIVACY_INVALID: 'בבקשה אשר את תנאי השמוש ומדיניות פרטיות',
    REGISTER: 'צור חשבון',
    COUPON: 'הזן קוד קופון במידה וקיים ברשותך',
    BACK_TO_LOGIN: 'היכנס',
    CORRECT_MAIL_ADDRESS: 'אנא הזן כתובת דוא"ל נכונה',
    PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS: 'על הסיסמה שלך להיות באורך של 6-20 תווים, להכיל אותיות ומספרים, והיא לא צריכה להכיל רווחים, תווים מיוחדים או דוא"ל.',
    PASSWORD_NOT_EQUAL: 'השדות סיסמה וסיסמה חוזרת צריכים להיות שווים',
    SUCCESSFULLY_REGISTERED: 'תודה על הרשמתך וצפייה נעימה.<br>'
      + 'להשלמת ההרשמה אנא לחץ על הקישור שנשלח לאימייל {email}.',
    SUCCESSFULLY_REGISTER_CONFIRM: 'חשבונך הופעל בהצלחה, אנא היכנס',
    SUCCESSFULLY_P_REGISTER_CONFIRM: ' להשלמת הרכישה אנא היכנס עם כתובת האימייל והסיסמא ולאחר מכן לחץ על כפתור הרכישה',
    INVALID_EMAIL: 'אימייל לא תקין',
    CLICK_REGISTER: {
      ZMAN: 'לצורך הקמת חשבון חדש - לחץ "צור חשבון".',
      NINE: 'If you do not have an account - click Sign up.',
    },
  },
  FORGOT_PASS: {
    CONTINUE_BTN: 'המשך',
    NEW_PASSWORD: 'סיסמא חדשה',
    REPEAT_NEW_PASS: 'חזור על הסיסמא החדשה',
    FORGOT_PASS_TITLE: 'שחזר את הסיסמה',
    TEXT: 'אם שכחת את הסיסמה שלך, הזן את כתובת הדוא"ל הרשומה שלך. אנו נשלח אליך קישור לאיפוס הסיסמה שלך.',
    RESTORE: 'לשחזר',
    SUCCESS: 'שלחנו קישור לאיפוס הסיסמה שלך לדוא"ל שציינת',
    SUCCESS_CHANGED_PASS: 'שינוי הסיסמה בוצע בהצלחה, אנא היכנס באמצעות הסיסמה החדשה שלך',
    CREATE_NEW_PASS: 'תודה שחזרת - נא להקליד את פרטי הסיסמה החדשה שלך למטה',
    CREATE_NEW_PASS_NAV: 'צור סיסמא חדשה',
    NOT_CORRECT_TOKEN: 'קישור זה אינו תקף',
  },
  // Coupons purchase popup
  COUPONS_PURCHASE: {
    TITLE: 'בחר את המוצר',
    SUCCESS_PURCHASE: 'תודה על רכישתך, ההרצאה תיהיה זמינה לצפייה ל 7 ימים, צפייה מהנה.',
    INVALID_COUPON: 'הקופון שברשותך אינו תקף',
    LABEL: 'הזן את קוד הקופון',
    APPLY: 'השתמש בקופון',
    BUY: 'לרכישה',
    INCORRECT_COUPON: 'קוד קופון שגוי',
    SUCCESS: 'קופון בשימוש בהצלחה',
    FULL_NAME_TITLE: 'לביצוע רכישה אנא הכנס שם פרטי ושם משפחה',
    CONTINUE_BTN: 'לְהַמשִׁיך',
  },
  // Concurrency alert message popup
  CONCURRENCY_ALERT_MESS: {
    MESSAGE: 'אתה כבר מחובר למכשיר אחר, אנא התנתק מהמכשיר האחר והתחבר למכשיר זה.',
  },

  VIDEO: {
    CONTENT_IS_RESTRICTED:
      'אנא התחבר כדי לראות את התוכן.',
    GUEST_PREVIEW_TIME_LEFT: 'באפשרותכם לצפות ב- 5 הדקות הראשונות של ההרצאה ללא צורך בתשלום. בסיום 5 הדקות \n'
      + 'ולאחר שהחלטתם שאתם מעוניינים להמשיך לצפות בהרצאה, אנא הרשמו ותמשיכו בצפייה.\n'
      + 'עלות הצפייה בהרצאה- 39 ₪.',
    CLOSE_MESSAGE_TEXT: 'לצפייה ב 5 דקות ראשונות של ההרצאה',
    /**/
  },
  MODAL: {
    PAGE: {
      TITLE: 'תוכן לא נצמא',
      CONTENT:
        'התוכן שנבחר הוסר מהאתר. אנא נסו למצוא תוכן מעניין בעמוד אחר.',
    },
  },

  SUBSRIPTION: {
    LABEL: 'לרכישה',
  },
  SECTION_PAGE: {
    REFRESH_BTN: 'תוכן המפגש הקרוב יהיה זמין לצפייה החל משעת האירוע המתוכנן. '
      + 'לחץ כאן ולאחר מכן, לצורך צפייה בהרצאה, לחץ על שקופית ההרצאה שתופיע על המסך. ',
  },
  ACCESS: {
    UNSUPPORTED_BROWSER: 'גרסת הדפדפן שברשותך אינה נתמכת, אנא שדרג גרסה.',
    LOCALSTORAGE_ACCESS_DENIED: 'בכדי לצפות בוידאו אנא אפשר קבלת קבצי עוגיות',
    // LOCALSTORAGE_ACCESS_DENIED: 'לא ניתן לגשת לאחסון מקומי, בבקשה לאפשר גישה או להשתמש בדפדפן אחר אחרת האתר יכול לעבוד בצורה לא נכונה!'
  },
  OK: 'Ok',
};
