import { localStore } from 'vimmi-web-utils/esm/localStore';
import { vapp } from '@/main';
import { isActiveFeature } from 'src/plugins/feature-toggle';
import { logger } from 'src/service/logger';
import { amsClient } from '../ams';
import { $stats } from '@/plugins/analytics/vue-analytics-plugin';
import Config from '@/service/config';

class AuthService {
  constructor() {
    this.$logger = logger();

    amsClient.on('auth_state_change', (event, eventData) => {
      // this.$logger.log('[amsClient][auth_state_change]', eventData);

      console.log('eventData: ', eventData);

      switch (eventData.type) {
        case 'logged':

          // not used anymore because it triggered in the logout request (logOut method)
          // if (amsClient.get('user.guest')) {
          //   vapp.$bus.$emit('changedUserState', eventData);
          // }

          vapp.$bus.$emit('amsLogged', eventData);

          setTimeout(() => {
            $stats.send('session_start');
          }, 100);
          // the application should displays user as logged
          break;
        case 'inactive':
          // that means that user comes from AMS as inactive, so need to logout
          break;
        case 'logout':
          // location.reload();// refresh a page
          // the user log out
          this.afterLogOut();
          break;
      }
    });

    amsClient.on('before_auth_state_change', (event, eventData) => {
      // this.$logger.log('[amsClient][before_auth_state_change]', eventData);
      switch (eventData.type) {
        case 'logged':
          vapp.$bus.$emit('amsBeforeLogged', eventData);
          break;
        case 'inactive':
          break;
        case 'logout':
          vapp.$bus.$emit('amsBeforeLogout', eventData);
          break;
      }
    });

    /** when AMS sync complete */
    amsClient.on('sync', (event, eventData) => {
      vapp.$bus.$emit('sync', eventData);
    });
  }

  getPridId() {
    return Config.get('provider_id') || 'gjukfabvy32xq3hk';
  }

  isGuest() {
    return !!amsClient.get('user.guest');
  }

  isAuth() {
    return amsClient.isAuth();
  }

  getDeviceRestriction() {
    return amsClient.get('deviceRestrictions');
  }

  saveCurrentPath(initPath = '') {
    const path = initPath || vapp.$router.currentRoute.fullPath;
    localStore.set('auth-redirect-url', path);
  }

  getPortalPrevPath() {
    return localStore.get('auth-redirect-url');
  }

  loginByAMS(credentials, rememberMe = false) {
    return new Promise((resolve, reject) => {
      const handler = function () {
        vapp.$nextTick(() => {
          vapp.$bus.$emit('changedUserState', { type: 'logged' });
          amsClient.off('sync', handler);

          /* if (vapp.$route.path.indexOf('home_web') !== -1) {
            vapp.$router.push({
              path: '/',
            });
          } */
          resolve(amsClient.get('user'));
        });
      };
      amsClient.on('sync', handler);
      amsClient.account
        .login(
          credentials.user,
          credentials.pass,
          {
            path: '/api/accounts/login/',
          },
          { prid: this.getPridId() },
        )
        .catch((error) => {
          console.log('debug loginAMS catch');
          reject(error);
        });
    });
  }
  async getUrlForGoogleLogin() {
    return Promise.resolve(
      `https://${location.host}/api/google/login/?prid=${this.getPridId()}`,
    );
  }

  async getUrlForFacebookLogin() {
    return Promise.resolve(
      `https://${location.host}/api/facebook/login/?prid=${this.getPridId()}`,
    );
  }

  async loginByGoogle(code) {
    const handler = function () {
      vapp.$nextTick(() => {
        vapp.$bus.$emit('changedUserState', { type: 'logged' });

        amsClient.off('sync', handler);
      });
    };
    amsClient.on('sync', handler);

    return amsClient.account.googleLogin(
      code,
      { path: '/api/google/login/' },
      { prid: this.getPridId() },
    );
  }

  async loginByFacebook(code) {
    const handler = function () {
      vapp.$nextTick(() => {
        vapp.$bus.$emit('changedUserState', { type: 'logged' });

        amsClient.off('sync', handler);
      });
    };
    amsClient.on('sync', handler);

    return amsClient.account.facebookLogin(
      code,
      { path: '/api/facebook/login/' },
      { prid: this.getPridId() },
    );
  }

  confirmRegister(pk, code) {
    return amsClient.account.registerConfirm(pk, code);
  }

  register(authData) {
    return amsClient.account.register(authData, { prid: this.getPridId() });
  }

  logOut(beforeLogin = false) {
    return amsClient.account.logout().then(() => {
      localStorage.removeItem('chat_user_id');

      if (isActiveFeature('guest-users') && !beforeLogin) {
        const handler = function () {
          vapp.$nextTick(() => {
            vapp.$bus.$emit('changedUserState', {
              type: 'logged',
              afterLogout: true,
            });

            amsClient.off('sync', handler);
          });
        };

        amsClient.on('sync', handler);
        amsClient.account.guestLogin(
          { path: '/api/accounts/login/' },
          { prid: this.getPridId() },
        );
      }
    });
  }

  afterLogOut() {
    vapp.$nextTick(() => {
      vapp.$bus.$emit('changedUserState', { type: 'logout' });
    });
  }

  get(key) {
    return amsClient.get(key);
  }

  set(key, value) {
    amsClient.set(key, value);
  }
}

export default new AuthService();
