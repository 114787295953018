var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activeItem)?_c('div',{ref:"floatPlayer",staticClass:"float-player-container float-player",class:{
       'float-player--vertical': _vm.isVertical,
       'float-player--horizontal': !_vm.isVertical,
       'float-player--drags': _vm.isDrag,
       'float-player--mobile': _vm.isAnyMobile
     },style:({ 'top': _vm.pagePosition.y + 'px', 'left': _vm.pagePosition.x + 'px' }),on:{"mousedown":_vm.onMouseDown,"touchstart":_vm.onMouseDown}},[_c('player-component',{ref:"player",staticClass:"w-100 h-100",attrs:{"autoplay":!_vm.playbackState.paused,"focused":true,"force-autoplay-muted":false,"controls":false,"muted":_vm.initialMuted,"item":_vm.activeItem,"live":_vm.activeItem.is_live,"loop":!_vm.activeItem.is_live,"start-position":_vm.activeItem.is_live ? null : _vm.startPosition,"vertical":_vm.activeItem.media_is_vertical || _vm.activeItem.orientation === 'vertical',"vjs-options":_vm.vjsOptions,"get-playback-url":_vm.getPlaybackUrl},on:{"player:play":function($event){return _vm.onPlay($event)},"player:pause":function($event){return _vm.onPause($event)},"player:duration-change":function($event){return _vm.onDurationChange($event)},"player:volume-change":function($event){return _vm.onVolumeChange($event)},"player:time-update":function($event){return _vm.onTimeUpdate($event)},"player:stats":_vm.playerStats,"player:bitrate-changed":_vm.onChangedBitrate,"player:loaded-data":function($event){return _vm.onLoadedData($event)},"player:loaded-metadata":function($event){return _vm.onLoadedMetaData($event)}},scopedSlots:_vm._u([{key:"layouts",fn:function(ref){
     var methods = ref.methods;
     var state = ref.state;
return [_c('div',{staticClass:"float-player--wrapper"},[_c('div',{staticClass:"float-player--controls header-bar "},[_c('div',{staticClass:"left-controls"},[_c('div',{staticClass:"d-inline-block"},[_c('button',{staticClass:"header-bar--exit-btn header-bar--btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.back()}}},[_c('img',{attrs:{"src":require('@/assets/icons/float-player/icon-pip.svg'),"alt":""}})])]),_c('div',{staticClass:"d-inline-block",staticStyle:{"margin-right":"auto"}},[_c('button',{staticClass:"header-bar--volume-btn header-bar--btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleMute()}}},[(_vm.playbackState.muted)?_c('img',{attrs:{"src":require('@/assets/icons/float-player/icon-mute.svg'),"alt":""}}):_c('img',{attrs:{"src":require('@/assets/icons/float-player/icon-unmute.svg'),"alt":""}})])])]),_c('div',{staticClass:"right-controls"},[_c('div',{staticClass:"d-inline-block"},[_c('button',{staticClass:"header-bar--exit-btn header-bar--btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeFloatPlayer()}}},[_c('img',{attrs:{"src":require('@/assets/icons/float-player/close.svg'),"alt":""}})])])])]),_c('play-button',{directives:[{name:"show",rawName:"v-show",value:(state.paused),expression:"state.paused"}],on:{"btn-click":_vm.togglePlayback}}),_c('div',{staticClass:"float-player--click-area",on:{"click":function($event){return _vm.wrapperClick($event)}}})],1)]}}],null,false,2832082229)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }