import { formatDate } from '@/shared/helpers/date';

const FULFILLMENT_STATUSES = {
  FULFILLED: 'Confirm',
  IN_TRANSIT: 'On its way',
  OUT_FOR_DELIVERY: 'Out for delivery',
  DELIVERED: 'Delivered',
};

const normalizeFulfillment = ({
  order, fulfillment, returns, lineItems,
}) => {
  const fLineItems = fulfillment.fulfillmentLineItems.edges.map(({ node: fli }) => {
    // impossible to have null here but anyway
    const lineItem = lineItems.find((li) => li.id === fli.lineItem.id) || {};
    const returnLineItems = returns
      .map((r) => r.lineItems)
      .flat()
      .filter((li) => li.fulfillmentLineItemId === fli.id);

    return {
      lineItem: {
        ...lineItem,
        quantity: fli.quantity,
        originalTotalSet: fli.lineItem.originalUnitPriceSet.shopMoney.amount,
      },
      fulfillmentLineItemId: fli.id,
      lineItemId: lineItem.id,
      allReturnLineItems: returnLineItems,
      ...lineItem,
    };
  });

  return {
    ...fulfillment,
    fulfillmentLineItems: fLineItems,
    lastStatus: FULFILLMENT_STATUSES[fulfillment.displayStatus],
    statuses: [
      { name: 'Submitted', date: formatDate(new Date(order.createdAt)) },
      ...getStatuses(fulfillment),
    ],
  };
};

const normalizeFulfillments = ({
  order, fulfillments, returns, lineItems,
}) => fulfillments
  .map((fulfillment) => normalizeFulfillment({
    order, fulfillment, returns, lineItems,
  }));

const normalizeReturn = ({ node, lineItems }) => ({
  status: node.status,
  name: node.name,
  lineItems: node.returnLineItems.edges.map(({ node: n }) => ({
    id: n.id,
    fulfillmentLineItemId: n.fulfillmentLineItem.id,
    quantity: n.quantity,
    refundableQuantity: n.refundableQuantity,
    returnReason: n.returnReason,
    customerNote: n.customerNote,
    returnReasonNote: n.returnReasonNote,
    item: lineItems.find((fi) => fi.id === n.fulfillmentLineItem.lineItem.id) || {},
  })),
});

const normalizeLineItems = ({ edges }) => edges.map((lineItem) => ({
  id: lineItem.node.id,
  name: lineItem.node.name,
  image: lineItem.node.image.url,
  quantity: lineItem.node.quantity,
  product: lineItem.node.product,
  variantTitle: lineItem.node.variantTitle || '-',
  originalTotalSet: lineItem.node.originalTotalSet.shopMoney.amount,
  originalUnitPriceSet: lineItem.node.originalUnitPriceSet.shopMoney.amount,
}));

export const normalizeUnfulfilledItems = ({ order }) => {
  const res = [];

  order.fulfillmentOrders.edges.forEach(({ node: _node }) => {
    _node.lineItems.edges.forEach(({ node }) => {
      if (node.remainingQuantity > 0) {
        res.push({
          ...node.lineItem,
          quantity: node.remainingQuantity,
        });
      }
    });
  });

  return res;
}

export const normalizeOrder = ({ node }) => {
  const lineItems = normalizeLineItems(node.lineItems);
  const returns = node.returns.edges.map(({ node }) => normalizeReturn({ node, lineItems }));

  const fulfillments = normalizeFulfillments({
    lineItems,
    order: node,
    fulfillments: node.fulfillments,
    returns,
  });

  const customTaxAttribute = (node.customAttributes || []).find(a => a.key === 'tax');
  const taxAmount = +(customTaxAttribute?.value || 0);

  return {
    name: node.name,
    processedAt: formatDate(node.createdAt),

    customer: node.customer,
    paymentGatewayName: node.paymentGatewayNames[0].toUpperCase(),
    billingAddress: node.billingAddress.formatted.join(', '),
    shippingAddress: node.shippingAddress.formatted.join(', '),
    shippingLine: node.shippingLine.title,

    subtotalPriceSet: +node.subtotalPriceSet.shopMoney.amount + +node.totalDiscountsSet.shopMoney.amount,
    totalShippingPriceSet: node.totalShippingPriceSet.shopMoney.amount,
    totalDiscountsSet: node.totalDiscountsSet.shopMoney.amount,
    totalTaxSet: taxAmount,
    totalPriceSet: +node.totalPriceSet.shopMoney.amount + taxAmount,

    financialStatus: {
      text: node.displayFinancialStatus
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()),
      key: node.displayFinancialStatus.toLowerCase().replace(/_/g, '-'),
    },
    lineItems,
    fulfillments,
    returns,
  };
};

function getStatuses(fulfillment) {
  const statuses = [];

  [
    { field: 'createdAt', name: 'Confirm' },
    { field: 'inTransitAt', name: 'On its way' },
    { field: 'deliveredAt', name: 'Delivered' },
  ].forEach(({ field, name }) => {
    if (fulfillment[field]) {
      statuses.push({
        name,
        date: formatDate(new Date(fulfillment[field])),
      });
    }
  });

  return statuses;
}