import RecommendationsApi from '@/shared/api/recommendations';
import ShopPlayerApi from '@/shared/api/shop-player.api';
import { memoryStore } from 'vimmi-web-utils/esm/memoryStore';

export default {
  generatePlaybackUrl(item, playTemplate) {
    let playback_url = item.playback_url;

    if (!playback_url) {
      const alphaId = item.mid || (item.media_id && !item.media_id.includes('http') ? item.media_id : null);
      if (item.media_url) {
        playback_url = item.media_url;
      } else if (playTemplate?.info && alphaId && !item.recorded) {
        const type = item.is_live ? 'live' : 'vod';
        playback_url = playTemplate.info[type].replaceAll(
          '{MID}',
          alphaId,
        );
      } else if (item.media_id && item.media_id.includes('http') && item.etype !== 'wrapper') {
        playback_url = item.media_id;
      } else if (item.media && item.media.includes('http') && item.etype !== 'wrapper') {
        playback_url = item.media;
      } else if (memoryStore.get(`${item.id}_playback_url`)) {
        playback_url = memoryStore.get(`${item.id}_playback_url`);
      } else {
        // todo: in this case the player call /play/{{item.id}}/ by itself, need to rewrite to call the container method
        if (item.recorded && item.mid) {
          delete item.mid;
        }
      }
    }
    return playback_url;
  },
  async loadItem(item) {
    if (!item) {
      return item;
    }

    let fullItem = { ...item };

    if (fullItem?.event?.chat_id) {
      fullItem.chat_id = fullItem.event.chat_id;
    }

    if (!item?.chat_id && !item?.event) {
      let loadedItem;

      try {
        if (item.recommendation_ || item.is_live) {
          loadedItem = await RecommendationsApi.getItem(item.id);
        } else if (item.slug) {
          loadedItem = await ShopPlayerApi.getItemBySlug(item.slug);
        } else {
          loadedItem = await ShopPlayerApi.getItem(item.id);
        }
      } catch (e) {
        console.warn(e);
      }

      if (loadedItem.id === fullItem.id || !fullItem.id) {
        fullItem = { ...fullItem, ...loadedItem };
      }
    }

    fullItem.chat_id = fullItem.chat_id || fullItem.event?.chat_id;
    fullItem.slug = fullItem.movie_slug || fullItem.slug;
    return fullItem;
  },
};