const state = () => ({
  user: null,
});

const getters = {
  isUser(state) {
    return state.user;
  },
  isGuest(state) {
    return state.user?.guest;
  },
};

const mutations = {
  setUser(state, user) {
    console.log('users: ', user);
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
