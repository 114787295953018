import FollowChannelsStore from './followChannel.store';
import StoreWrapperService from '@/store/store-wrapper.js';
import { amsClient } from '@/service/ams';
import Auth from '@/service/authService';

export const ShoprzStore = new StoreWrapperService({
  name: 'shoprz',
  localStore: FollowChannelsStore,
  options: {
    mutations: true,
    getters: true,
    actions: true,
  },
  ignore: {
    mutations: [],
    getters: [],
    actions: [],
  },
  init: ({ store, name }) => {
    amsClient.on('auth_state_change', (event, data) => {
      setTimeout(() => {
        const currentUser = Auth.get('user');

        if (data.type === 'logged' && !currentUser?.guest) {
          store.dispatch(`${name}/onSync`, data);
        } else if (data.type === 'logout') {
          store.dispatch(`${name}/clean`, data);
        }
      });
    });
  },
});

export default ShoprzStore;
