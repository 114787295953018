<template>
  <div
    :class="{
      'sp-chat-widget-wrapper': true,
      'sp-chat-widget-wrapper-opened': isChatVisible,
    }"
  >
    <div
      v-if="!isChatVisible"
      class="sp-chat-icon"
      @click="() => onToggleChat(true)"
    >
      <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9997 21.75C20.1802 21.75 25.9997 17.0223 25.9997 11.1875C25.9997 5.35273 20.1802 0.625 12.9997 0.625C5.81924 0.625 -0.00029141 5.35273 -0.00029141 11.1875C-0.00029141 13.4777 0.898537 15.5953 2.42197 17.327C2.32549 18.5711 1.84307 19.6781 1.33526 20.5211C1.05596 20.9883 0.771584 21.3641 0.56338 21.618C0.45674 21.7449 0.37549 21.8414 0.314552 21.9074C0.284084 21.9379 0.263771 21.9633 0.248537 21.9785L0.233302 21.9937C-0.000291409 22.2273 -0.066307 22.5727 0.0606461 22.8773C0.187599 23.182 0.48213 23.3801 0.812209 23.3801C2.26963 23.3801 3.73721 22.9281 4.95596 22.4C6.11885 21.8922 7.10908 21.2879 7.71338 20.8461C9.32822 21.4301 11.1157 21.7551 12.9997 21.7551V21.75ZM8.62236 6.58164C9.02354 5.44922 10.1001 4.6875 11.3036 4.6875H14.2642C16.0364 4.6875 17.4685 6.12461 17.4685 7.8918C17.4685 9.03945 16.854 10.1008 15.8587 10.6746L14.2185 11.6141C14.2083 12.2742 13.6649 12.8125 12.9997 12.8125C12.3243 12.8125 11.781 12.2691 11.781 11.5938V10.9082C11.781 10.4715 12.0146 10.0703 12.3954 9.85195L14.645 8.56211C14.8837 8.425 15.031 8.17109 15.031 7.89687C15.031 7.47031 14.6856 7.13008 14.2642 7.13008H11.3036C11.131 7.13008 10.9786 7.23672 10.9228 7.39922L10.9024 7.46016C10.679 8.09492 9.97822 8.425 9.34854 8.20156C8.71885 7.97812 8.38369 7.27734 8.60713 6.64766L8.62744 6.58672L8.62236 6.58164ZM11.3747 16.0625C11.3747 15.6315 11.5459 15.2182 11.8507 14.9135C12.1554 14.6087 12.5687 14.4375 12.9997 14.4375C13.4307 14.4375 13.844 14.6087 14.1488 14.9135C14.4535 15.2182 14.6247 15.6315 14.6247 16.0625C14.6247 16.4935 14.4535 16.9068 14.1488 17.2115C13.844 17.5163 13.4307 17.6875 12.9997 17.6875C12.5687 17.6875 12.1554 17.5163 11.8507 17.2115C11.5459 16.9068 11.3747 16.4935 11.3747 16.0625Z" fill="white"/>
      </svg>
    </div>
    <chat-widget-component
      v-show="isChatVisible"
      ref="widget"
      :selected-ticket="selectedTicket"
      :chat-user="chatUser"
      :messages="messages"
      :disabled-new-messages="isNewTicketCreating"
      @close="() => onToggleChat(false)"
      @ticket:new="onNewTicket"
      @ticket:reset="onResetTicket"
      @message:new="onNewMessage"
      @attachment:new="onNewAttachment"
    />
  </div>
</template>

<script>
  import ChatWidgetComponent from '@/shared/features/chat-widget/components/chat-widget.component';
  import ChatSupportService from '@/shared/services/chat-support.service';

  const chatSupportService = new ChatSupportService();
  export default {
    name: 'ChatWidgetContainer',
    components: { ChatWidgetComponent },
    props: {},

    data() {
      return {
        isChatVisible: false,
        messages: [],
        selectedTicket: null,
        chatUser: null,
        isNewTicketCreating: false,

        chatWidgetCloseCallback: null,
        resizeCallback: null,
        isMobile: false,
      };
    },

    computed: {},

    beforeMount() {
      this.initChat();

      this.isMobile = window.innerWidth < 800;

      this.chatWidgetCloseCallback = this.closeChat.bind(this);
      this.resizeCallback = this.onResize.bind(this);
      window.addEventListener('resize',  this.resizeCallback);
      this.$bus.$on('ChatWidgetClose', this.chatWidgetCloseCallback);
    },
    beforeDestroy() {
      chatSupportService.destroy();
      window.removeEventListener('resize', this.resizeCallback);
      this.$bus.$off('ChatWidgetClose', this.chatWidgetCloseCallback);
    },
    methods: {
      onResize() {
        this.isMobile = window.innerWidth < 800;
        if (!this.isMobile) {
          document.body.style.overflow = 'auto';
        }

        if (this.isMobile && this.isChatVisible) {
          document.body.style.overflow = 'hidden';
        }
      },
      async initChat() {
        try {
          await chatSupportService.init();
          this.chatUser = chatSupportService.getUser();
          chatSupportService.subscribeOnNewMessages((arg) => {
            this.scrollChatToBottom();
            this.handleNewMessage(arg.body.args[0]);
          })
        } catch (err) {
          console.log('ERR', err);
        }
      },
      scrollChatToBottom() {
        this.$refs.widget.scrollToBottom();
      },
      onToggleChat(val) {
        this.isChatVisible = val;
        const scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (val) {
          if (!scroll) {
            document.documentElement.scrollTop = 1;
          }
          if (this.isMobile) {
            document.body.style.overflow = 'hidden';
          }
          this.onResize();
        } else {
          this.closeChat();
        }
        this.$bus.$emit('VisibleSupportChat', val);
      },
      closeChat() {
        this.isChatVisible = false;
        document.body.style.overflow = 'auto';
        this.$bus.$emit('VisibleSupportChat', false);
      },
      async onNewAttachment(file) {
        if (!file) {
          return;
        }

        if (!this.selectedTicket) {
          return;
        }

        try {
          const ticketId = this.selectedTicket?._id;
          const tempId = (Math.random() + 1).toString(36).substring(7);

          const reader = new FileReader();
          // TODO: handle error signal
          reader.onload = async (e) => {
            const draftMessage = {
              tempId,
              ts: new Date(),
              u: {
                _id: this.chatUser.id,
              },
              tmid: ticketId,
              msg: '',
              _inProgress: true,
              _isFailed: false,
              _file: file,
              attachments: [{
                base64src: e.target.result,
              }],
            };

            try {
              this.addMessage(draftMessage);
              this.scrollChatToBottom();
              const { message } = await chatSupportService.uploadFileToThread(ticketId, file);
              this.replaceMessage(draftMessage, message);
              this.scrollChatToBottom();
            } catch (err) {
              if (err.response?.status === 413) {
                this.removeMessage(draftMessage.tempId);
                // this.$showNotify('error', 'File is too large.');
                return;
              }

              this.replaceMessage(draftMessage, {
                ...draftMessage,
                _inProgress: false,
                _isFailed: true,
              });
              // this.$showNotify('error', 'Cannot send attachment due to error.');
              console.log('ERR:', err);
            }
          };
          reader.readAsDataURL(file);
        } catch (err) {
          console.log('Cannot upload file', err);
          // this.$showNotify('error', 'Cannot upload file due to error.');
        }
      },
      async onNewTicket({ msg, ticket }) {
        if (this.isNewTicketCreating) {
          return;
        }

        try {
          this.isNewTicketCreating = true;
          const response = await chatSupportService.openTicket({
            ...ticket,
            msg,
          });
          this.selectedTicket = response.ticket;
          this.addMessage(response.issue);
          this.$refs.widget.scrollToBottom();
        } catch (err) {
          console.log('err', err);
        } finally {
          this.isNewTicketCreating = false;
        }
      },
      onResetTicket() {
        this.selectedTicket = null;
      },
      async onNewMessage(msg) {
        const { message: res } = await chatSupportService.sendMessageToThread(this.selectedTicket._id, msg);
        this.addMessage(res);
      },
      handleNewMessage(message) {
        if (message._id === this.selectedTicket?._id) {
          return this.selectedTicket = message;
        }
        if (message.u.username === this.chatUser.username) {
          return;
        }
        this.messages = [...this.messages, message];
      },
      addMessage(message) {
        this.messages = [
          ...this.messages,
          message,
        ];
      },
      replaceMessage(oldMessage, newMessage) {
        const ticketMessages = this.messages.filter((msg) => !msg.tempId || msg.tempId !== oldMessage.tempId);
        ticketMessages.push(newMessage);
        this.messages = ticketMessages;
      },
      removeMessage(tempId) {
        this.messages = this.messages.filter((msg) => msg.tempId !== tempId);
      },
    },

  };
</script>

<style lang="scss">
  @import './chat-widget-colors';

  .sp-chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1129;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $sp-chat-widget-primary;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    @media (max-width: 800px) {
      bottom: 60px;
    }
  }
</style>
