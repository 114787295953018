import geoapifyClient from '@/core/http/geoapify.client';

export default {
  searchAddress({ countryCode, text }) {
    const query = {
      text,
    };

    if (countryCode) {
      query.filter = `countrycode:${countryCode.toLowerCase()}`;
    }

    return geoapifyClient.makeRequest('/geocode/search', 'GET', { query });
  },

  searchByPostCode(postCode, countryCode) {
    const query = {
      text: postCode,
      type: 'postcode',
    };
    if (countryCode) {
      query.filter = `countrycode:${countryCode.toLowerCase()}`;
    }
    return geoapifyClient.makeRequest('/geocode/search', 'GET', { query });
  },
  searchByCity(city, countryCode) {
    const query = {
      text: city,
      type: 'city',
    };
    if (countryCode) {
      query.filter = `countrycode:${countryCode.toLowerCase()}`;
    }
    return geoapifyClient.makeRequest('/geocode/search', 'GET', { query });
  },
};
