export default `query {
  deliveryProfiles(first: 1) {
    edges {
      node {
        id
        default
        name
        originLocationCount
        profileLocationGroups {
          locationGroup {
            id
          }
        }
      }
    }
  }
}`;
