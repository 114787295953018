import { RocketChat } from 'vimmi-web-utils/cjs/rocketChat';
import { amsClient } from '@/service/ams';

export default class RocketChatClient extends RocketChat {
  constructor() {
    super();
  }

  getIdForChatUser(isAuth) {
    if (isAuth) {
      return amsClient.get('user').id;
    }

    return amsClient.get('authInfo.device_id');
  }

  async init(itemChatUrl = null) {
    if (!itemChatUrl) {
      itemChatUrl = amsClient.get('conf.chat.chat_backend_url');
    }
    this.setDomain(itemChatUrl);
    const chatUser = await this.getChatUser();
    return this.connect(chatUser.username, chatUser.password);
  }

  /**
   * find exist chat user or register a new one
   * */
  async getChatUser(uid) {
    const localChatUser = amsClient.get('user.chat_user');

    if (localChatUser && localChatUser.itype === 'chat_user') {
      // console.log('debug shop-layout localstore', localChatUser);
      return localChatUser;
    }

    uid = uid || amsClient.get('authInfo.device_id');
    const chatUser = await this.getChatUserAccess({
      uid: uid,
      guest: !!amsClient.get('user.guest'),
      backend: location.host,
      creator: null,
      prefix: '/api',
    });

    amsClient.set('user.chat_user', chatUser);

    return chatUser;
  }
}
