const state = () => ({
  item: null,
  playbackState: {},
});

const getters = {
  item: (state) => state.item,
};
const mutations = {
  setItem(state, item) {
    state.item = item;
  },
  setPlaybackState(state, playbackState) {
    state.playbackState = playbackState || {};
  },
};

const actions = {
  setItem({ commit }, item) {
    commit('setItem', item);
  },
  setPlaybackState({ commit }, playbackState) {
    commit('setPlaybackState', playbackState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
