// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
import device from '@/service/device-service';

const keys = {
  37: 1, 38: 1, 39: 1, 40: 1,
};
let scrollWhiteList = [];
let isDisabled = false;
let scrollBlockers = [];

function isIgnoreElement(target) {
  if (target) {
    for (let i = 0; i < scrollWhiteList.length; i++) {
      const elementClass = scrollWhiteList[i];
      if (((target.classList && target.classList.contains(elementClass)) || (target.closest && target.closest(`.${elementClass}`)))) {
        return true;
      }
    }
  }
  return false;
}

function preventDefault(e) {
  if (!isIgnoreElement(e.target)) {
    e.preventDefault();
    e.stopPropagation();
    if (device.isIOS() && scrollBlockers[scrollBlockers.length - 1] !== 'float') {
      document.documentElement.scrollTop = 0;
    }
    return false;
  }
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode] && !isIgnoreElement(e.target)) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
    get() {
      supportsPassive = true;
    },
  }));
} catch (e) {
}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export function disablePageScroll(component, whiteList) {
  scrollWhiteList = whiteList || [];
  scrollBlockers.push(component);
  console.log('[PageScroll][disablePageScroll]', {
    component, scrollWhiteList, scrollBlockers,
  });
  document.body.style.overflow = 'hidden';
  if (isDisabled) {
    return;
  }
  isDisabled = true;
  window.addEventListener('scroll', preventDefault, false);
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enablePageScroll(component) {
  if (scrollBlockers.indexOf(component) !== -1) {
    scrollBlockers.splice(scrollBlockers.indexOf(component), 1);
  }
  console.log('[PageScroll][enablePageScroll]', {
    component, scrollBlockers,
  });
  if (!scrollBlockers.length) {
    isDisabled = false;
    scrollWhiteList = [];
    window.removeEventListener('scroll', preventDefault, false);
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    document.body.style.overflow = 'auto';
  }
}

export function isPageScrollDisabled() {
  return isDisabled;
}
