import ams from 'src/shared/api/ams';

class AmsService {
  /**
   * @param {Object} payload:
   */
  async updateAccount(payload) {
    const response = await ams.updateAccount(payload);

    return response;
  }

  async getAccount() {
    const response = await ams.getAccount();

    return response;
  }


  // drop it. It is deprecated
  async fetchShopifyToken() {
    const data = await ams.fetchShopifyToken();

    const token = data.head.multify_token;

    return token;
  }

  /**
   * @deprecated use @/service/shopify-storefronts api instead
   * @param {String} token:
   */
  async getAccessTokenForAuthUser(token) {
    const response = await ams.getAccessTokenForAuthUser(token);

    const { accessToken } = response.customerAccessTokenCreateWithMultipass.customerAccessToken;

    return accessToken;
  }
}

export default AmsService;
