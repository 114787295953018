var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'sp-chat-widget-content': true,
    'sp-chat-widget-content-fixed': _vm.isFixed,
    'sp-chat-widget-content-mobile': _vm.isMobile,
    'sp-chat-widget-content-mobile-full': _vm.isMobile && _vm.mobileFullHeight,
    'sp-chat-widget-content-ios-safari': _vm.isMobile && _vm.isIOSSafari,
  }},[(_vm.isMobile)?_c('div',{staticStyle:{"height":"54px","width":"100vw"}}):_vm._e(),(!_vm.isMobile)?_c('div',{class:( _obj = {
      'sp-chat-widget-header': true
    }, _obj[_vm.headerClass] = true, _obj )},[_c('div',{staticClass:"sp-chat-widget-header-title"},[_vm._v(" Contact Store ")]),_c('i',{staticClass:"far fa-times sp-chat-widget-header-close",on:{"click":_vm.onClose}})]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"sp-chat-widget-header-mobile"},[_c('div',{staticClass:"sp-chat-widget-header-mobile-button",on:{"click":_vm.onClose}},[_c('i',{staticClass:"far fa-chevron-left"})]),_c('div',[(_vm.showMobileNewIssueButton)?_c('button',{staticClass:"sp-chat-widget-new-issue-button",on:{"click":_vm.onNewIssue}},[_vm._v(" New Issue ")]):_vm._e()])]):_vm._e(),_c('div',{class:{
      'sp-chat-widget-messages-wrapper': true,
      'sp-chat-widget-messages-wrapper-full': _vm.mobileFullHeight
    }},[_c('div',{ref:"chat",staticClass:"sp-chat-widget-messages-list",on:{"scroll":_vm.onScrollTop}},[_c('div',{staticClass:"sp-chat-widget-message-predefined"},[_vm._v(" Hello 👋 ")]),_c('div',{staticClass:"sp-chat-widget-message-predefined"},[_vm._v(" Please choose your topic ")]),_c('div',{staticClass:"sp-chat-widget-message-predefined-list"},_vm._l((_vm.topics),function(topic){return _c('div',{staticClass:"sp-chat-widget-message-predefined sp-chat-widget-message-predefined-button",class:{
            'sp-chat-widget-message-predefined sp-chat-widget-message-predefined-button': true,
            'sp-chat-widget-message-predefined-button-selected': _vm.selectedTopic === topic,
            'sp-chat-widget-message-predefined-button-disabled': _vm.selectedTopic && _vm.selectedTopic !== topic,
          },on:{"click":function () { return _vm.onSelectTopic(topic); }}},[_vm._v(" "+_vm._s(topic)+" ")])}),0),_vm._l((_vm.predefinedMessages),function(msg){return _c('div',{class:msg.class},[_vm._v(" "+_vm._s(msg.text)+" ")])}),_vm._l((_vm.messages),function(message){return _c('chat-widget-message-component',{key:message._id,attrs:{"message":message,"chat-user":_vm.chatUser}})})],2)]),_c('div',{staticClass:"sp-chat-widget-divider"}),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg","hidden":""},on:{"change":_vm.onNewFile}}),(_vm.isIssueClosed)?_c('div',{staticClass:"sp-chat-widget-closed-issue"},[_vm._v(" Issue is closed ")]):_vm._e(),(!_vm.isIssueClosed)?_c('div',{staticClass:"sp-chat-widget-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newTextMessage),expression:"newTextMessage",modifiers:{"trim":true}}],ref:"input",staticClass:"sp-chat-widget-input",attrs:{"placeholder":_vm.inputPlaceholder,"disabled":_vm.isDisabledInput},domProps:{"value":(_vm.newTextMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSendMessage($event)},"input":function($event){if($event.target.composing){ return; }_vm.newTextMessage=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.isAttachmentsDisabled)?_c('i',{staticClass:"far fa-paperclip sp-chat-widget-input-action",class:{
        'far fa-paperclip sp-chat-widget-input-action': true,
        'sp-chat-widget-input-action-disabled': _vm.isAttachmentsDisabled,
      },on:{"click":_vm.onUploadAttachment}}):_vm._e(),_c('div',{staticClass:"sp-chat-widget-input-action sp-chat-widget-input-action-send",on:{"click":_vm.onSendMessage}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.18394 7.25002L2.05581 2.9969L11.9808 7.25002H4.18394ZM4.18394 8.75002H11.9808L2.05581 13.0031L4.18081 8.75002H4.18394ZM1.39331 1.08127C1.00894 0.915647 0.558936 1.0094 0.271436 1.31252C-0.0160636 1.61565 -0.0816886 2.0719 0.105811 2.4469L2.88081 8.00002L0.105811 13.5531C-0.0816886 13.9281 -0.0160636 14.3813 0.271436 14.6875C0.558936 14.9938 1.00581 15.0844 1.39331 14.9219L15.3933 8.9219C15.7621 8.76565 15.9996 8.40315 15.9996 8.00315C15.9996 7.60315 15.7621 7.24065 15.3933 7.0844L1.39331 1.0844V1.08127Z","fill":"#B6C0D3"}})])])]):_vm._e(),(_vm.isMobile)?_c('div',{staticStyle:{"height":"56px","width":"100vw"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }