export default {
  bind: (el, bindings) => {
    // console.log(el);
    // let changeProperty = ;
    const scrollLeft = (bindings.value.scrollLeft && typeof (bindings.value.scrollLeft) === 'function') ? bindings.value.scrollLeft : null;
    const scrollRight = (bindings.value.scrollRight && typeof (bindings.value.scrollRight) === 'function') ? bindings.value.scrollRight : null;

    el.evData = {
      startX: 0,
      startY: 0,
      diffX: 0,
      diffY: 0,
      drag: false,
      // isTouch: (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch)
    };
    el.eventFn = {
      start(e) {
        if (!e) {
          e = window.event;
        }

        el.classList.add('no-select');
        el.evData.diffX = 0;
        el.evData.diffY = 0;

        el.evData.startX = (e.type === 'touchstart') ? e.touches[0].clientX : e.clientX;
        el.evData.startY = (e.type === 'touchstart') ? e.touches[0].pageY : e.pageY;
        el.evData.drag = true;
      },
      move(e) {
        if (el.evData.drag) {
          const en = bindings.value.enabled ? bindings.value.enabled : true;
          // console.log(bindings.value);
          if (!e) {
            e = window.event;
          }
          const cPosX = (e.type === 'touchmove') ? e.touches[0].clientX : e.clientX;
          const cPosY = (e.type === 'touchmove') ? e.touches[0].pageY : e.pageY;

          el.evData.diffX = el.evData.startX - cPosX;
          el.evData.diffY = el.evData.startY - cPosY;

          // console.log(el.evData);
          if (el.evData.diffX > 5) {
            // e.preventDefault();
            el.evData.drag = false;

            if (scrollRight && en) {
              scrollRight();
            }
            //   console.log('Right');
          } else if (el.evData.diffX < -5) {
            // e.preventDefault();
            el.evData.drag = false;

            if (scrollLeft && en) {
              scrollLeft();
            }
            //   console.log('Left');
          } else { // if (Math.abs(this.diffY) > 0 && e.type === 'touchmove') {
            el.evData.drag = false;
          }
        }
      },
      end(event) {
        el.classList.remove('no-select');
        el.evData.drag = false;
      },
    };
    // console.log(changeProperty.bind(null, el, bindings));
    // console.log(el.isTouch );

    // if (el.evData.isTouch) {
    el.addEventListener('touchstart', el.eventFn.start, { passive: true });
    el.addEventListener('touchmove', el.eventFn.move, { passive: true });
    el.addEventListener('touchend', el.eventFn.end, { passive: true });
    // } else {
    el.addEventListener('mousedown', el.eventFn.start, { passive: true });
    el.addEventListener('mousemove', el.eventFn.move, { passive: true });
    el.addEventListener('mouseup', el.eventFn.end, { passive: true });
    // }
  },
  unbind: (el, bindings) => {
    // console.log('Bind unbind');
    // let changeProperty = changePropertyFn.bind(null, el, bindings);
    // if (el.evData.isTouch) {
    el.removeEventListener('touchstart', el.eventFn.start);
    el.removeEventListener('touchmove', el.eventFn.move);
    el.removeEventListener('touchend', el.eventFn.end);
    // } else {
    el.removeEventListener('mousedown', el.eventFn.start);
    el.removeEventListener('mousemove', el.eventFn.move);
    el.removeEventListener('mouseup', el.eventFn.end);
    // }
  },
};
