import axios from 'axios';
import { amsClient } from '@/service/ams';
import RocketChatClient from '@/core/service/rocket-chat.service';

const rc = new RocketChatClient();
// const rc = new RocketChat();
export default class ChatSupportService {
  #roomId = null;
  #user = null;
  #newMessagesCallback = null;

  getUser() {
    return this.#user;
  }

  async init() {
    await rc.init();
    // TODO for NOW it is hardcoded
    const storeId =  amsClient.get('conf.store.id') || '648c10455b3c8d71fa2fe1cd';
    const { head } = await amsClient.callAms(`/ugc/chat/store/${storeId}`);
    const { room } = await rc.callApi('im.create', {
      method: 'POST',
      body: {
        username: head.username,
      },
    });
    this.#roomId = room._id;

    this.#user = rc.getUser();
    rc.getClient().subscribe('stream-room-messages', [this.#roomId, false]);
  };

  destroy() {
    rc.off(`${this.#roomId}_room-messages`);
    rc.getClient().unsubscribe('stream-room-messages', [this.#roomId, false]);
    rc.disconnect();
    this.#newMessagesCallback = null;
  }

  async openTicket({ msg, topic, email, name }) {
    const timeStamp = new Date().getTime();
    console.log('msg', msg);
    const timeZone = (new Date().getTimezoneOffset() / 60);
    const { message } = await rc.callApi('chat.sendMessage', {
      method: 'POST',
      body: {
        message: {
          rid: this.#roomId,
          msg: 'buyer_request',
          attachments: [{
            id: timeStamp,
            name,
            email,
            type: topic,
            timezone_offset: timeZone * -1,
            description: JSON.stringify({ status: 'new', priority: 'None' }),
          }],
        },
      },
    });

    const { message: issue } = await this.sendMessageToThread(message._id, msg);

    return {
      ticket: message,
      issue,
    };
  }

  sendMessageToThread(threadId, msg, attachments = []) {
    return rc.callApi('chat.sendMessage', {
      method: 'POST',
      body: {
        message: {
          rid: this.#roomId,
          tmid: threadId,
          msg,
          attachments,
        },
      },
    });
  }

  subscribeOnNewMessages(cb) {
    this.#newMessagesCallback = cb;
    rc.on(`${this.#roomId}_room-messages`, this.#newMessagesCallback.bind(this));
  }

  async loadMessagesForTicket(ticketId, offset = 0) {
    const res = await rc.callApi('chat.getThreadMessages', {
      query: {
        tmid: ticketId,
        count: 10,
        offset,
        sort: JSON.stringify({ 'ts': -1 }),
      },
    });

    return {
      total: res.total,
      messages: res.messages.reverse(),
    };
  }

  getRoomDetails() {
    return rc.callApi('subscriptions.getOne', {
      query: {
        roomId: this.#roomId,
      },
    });
  }

  async loadTickets(offset = 0) {
    return rc.callApi('chat.getThreadsList', {
      query: {
        rid: this.#roomId,
        count: 50,
        offset,
      },
    });
  }

  readTicket(ticketId) {
    const tempId = (Math.random() + 1).toString(36).substring(7);
    return rc.callApi('method.call/getThreadMessages', {
      method: 'POST',
      body: {
        message: JSON.stringify({
          msg: 'method',
          id: tempId,
          method: 'getThreadMessages',
          params: [{ tmid: ticketId }],
        }),
      },
    });
  }

  async uploadFileToThread(threadId, file) {
    const link = new URL(`${amsClient.get('conf.chat.chat_backend_url')}/api/v1/rooms.upload/${this.#roomId}`);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('tmid', threadId);
    const { data } = await axios.post(link.toString(), formData, {
      headers: {
        'X-Auth-Token': this.#user.token,
        'X-User-Id': this.#user.id,
      },
    });
    return data;
  }
}
