<template>
  <div class="links" :class="{
    'links-tablet': isTablet,
    'open': isOpen,
  }">
    <button
      type="button"
      class="nav-toggle btn mobile-visible"
      @click="toggleNav"
    >
      <i
        class="fas"
        :class="{
          'links-item-light': !isDark,
          'fa-bars': !isOpen,
          'fa-times': isOpen,
        }"
        :style="`color: ${isDark ? 'black !important' : 'white !important'}`"
      />
    </button>
    <button
      type="button"
      class="close-btn btn mobile-visible"
      @click="handleClose"
    >
      <i class="fas fa-times" />
    </button>
    <div class="links-container">
      <div class="links-group mobile-visible">
        <a
          v-if="!isFullAuth"
          class="links-item"
          :class="{
            'links-item-light': !isDark,
          }"
          @click.stop="
            onLogin();
            handleClose();
          "
        >
          {{ $t('HEADER.LOGIN') }}
          <img :src="require(`@/assets/icons/icon-login.svg`)" alt="" />
          <i class="login-icon fas fa-chevron-right mobile-visible" />
        </a>
        <div v-else class="links-item logout" @click="showMobileAuthActions">
          {{ username }}
          <i class="fas fa-chevron-right" />
        </div>
      </div>
      <hr class="mobile-visible wide" />
      <div v-if="$isStandaloneStore" class="links-group">
        <!-- <router-link
          class="links-item"
          :class="{
            'links-item-light': !isDark,
          }"
          :to="'/watch'"
          active-class="router-link-exact-active"
          @click.native="
            isOpen = false;
            handleClose();
          "
        >
          Watch
        </router-link> -->

        <template v-if="isNeededStore">
          <router-link
            v-for="links of NavLinksConst[storeName]"
            :key="links.path"
            class="links-item"
            :class="{
              'mobile-not-visible': !isNeededStore,
              'links-item-light': !isDark,
            }"
            :to="links.path"
            @click.native="
              isOpen = false;
              handleClose();
            "
          >
            {{ links.name }}
          </router-link>
        </template>
        <template v-else>
          <router-link
            v-for="(link, index) of headerLinks"
            :key="link.id"
            class="links-item"
            :class="{
              'links-item-light': !isDark,
            }"
            :to="index === 0 ? '/' : `/s/${link.slug}`"
            @click.native="
              isOpen = false;
              handleClose();
            "
          >
            {{ link.name }}
          </router-link>
        </template>
      </div>
      <div v-if="!$isStandaloneStore" class="links-group">
        <div class="links-group__title mobile-visible">Menu</div>
        <router-link
          class="links-item mobile-not-visible"
          :to="'/watch'"
          :class="{
            'links-item-light': !isDark,
          }"
          @click.native="
            isOpen = false;
            handleClose();
          "
        >
          Watch
        </router-link>
        <router-link
          v-for="item in items"
          :key="item.id"
          class="links-item"
          :class="{
            'links-item-light': !isDark,
            'mobile-visible': item.slug === 'home',
          }"
          :to="
            item.itype === 'item_app'
              ? item.link.replace('get_item', 'web')
              : item.link
          "
          @click.native="
            isOpen = false;
            handleClose();
          "
        >
          <img
            v-if="isShowIcon(item.slug)"
            :src="require(`@/assets/icons/icon-${item.slug}.svg`)"
            class="links-icons mobile-visible"
            :alt="item | contentLanguage('title')"
          />
          {{ item | contentLanguage('title') }}
        </router-link>
      </div>

      <template>
        <hr class="mobile-visible" />
        <catalog />
      </template>
    </div>
  </div>
</template>

<script>
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { amsClient } from 'src/service/ams';
  import Config from 'src/service/config';
  import Auth from 'src/service/authService';
  import Catalog from 'src/components/header/catalog/Catalog';
  import NavLinksConst from './constants/nav-links.const';

  export default {
    name: 'NavLinks',
    components: {
      Catalog,
    },
    mixins: [AuthVariableMixin],
    props: {
      isDark: {
        type: Boolean,
        default: false,
      },
      isTablet: {
        type: Boolean,
        default: true,
      },
      headerLinks: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        NavLinksConst,
        isOpen: false,
        items: [],
        user: Auth.get('user'),
      };
    },
    computed: {
      username() {
        return this.user?.name || this.user?.username;
      },

      store() {
        console.log('stire: ', amsClient.get('conf.store'));
        return amsClient.get('conf.store');
      },

      storeName() {
        return this.store?.name || '';
      },

      isNeededStore() {
        return false;
      },
    },
    watch: {
      isOpen(isOpen) {
        this.$bus.$emit('ScrollPositionToggleStatus', isOpen);
      },
    },
    mounted() {
      amsClient.on('auth_state_change', () => (this.user = Auth.get('user')));
    },
    created() {
      this.getItems();

      this.$bus.$on('ToggleNavLinks', (data) => (this.isOpen = data));
    },
    destroyed() {
      this.$bus.$off('ToggleNavLinks');
    },
    methods: {
      async getItems() {
        const data = await amsClient.callAms(Config.get('amsEntryPoint'));

        if (data?.items?.length) {
          this.items = data.items.map((item) => {
            if (item.title === 'Store') {
              return {
                ...item,
                title: 'Stores',
                link: '/stores',
              };
            }

            return item;
          });
        }
      },
      toggleNav() {
        this.$bus.$emit('ToggleNavLinks', !this.isOpen);
      },
      handleClose() {
        this.$bus.$emit('ToggleNavLinks', false);
      },
      onLogin() {
        this.isOpen = false;
        this.$emit('onLogin');
      },
      logout() {
        this.$emit('logout');
        this.$bus.$emit('ToggleNavLinks', false);
      },
      isShowIcon(slug) {
        return ['home', 'live', 'Black_Friday', 'stores_categories'].includes(
          slug,
        );
      },
      showMobileAuthActions() {
        this.toggleNav();
        this.$router.push('/account');
        // this.$bus.$emit('ToggleMobileAuthActions', true);
      },
    },
  };
</script>
<style lang="scss">
  .links-item {
    color: #343434 !important;

    &-light {
      color: white !important;
      &.router-link-exact-active::before {
        background-color: white !important;
      }
    }

    &.router-link-exact-active  {
      position: relative;
      font-weight: 500 !important;

      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: #343434;
        position: absolute;
        bottom: -12px;
        left: -6px;
        width: calc(100% + 12px)
      }
    }
  }
  .links-tablet {
    .router-link-exact-active {
      color: #21A66E !important;
      &::before {
        background-color: unset;
      }
    }
  }
</style>
<style lang="scss" scoped="true">
  @import 'NavLinks';
</style>
