import makeRequest from 'src/core/http/storefront.client';
import { amsClient } from 'src/service/ams';

import multipassTokenMutation from '../shopify_REMOVE/mutations/multipass-token.mutation';

export default {
  /**
   * @param {Object} payload:
   */
  async updateAccount(payload) {
    const response = await amsClient.callAms('/accounts/update/?d=web', {
      method: 'post',
      body: JSON.stringify(payload),
    });

    return response;
  },

  async getAccount() {
    const response = await amsClient.callAms('/accounts/sync/?d=gstweb&gst=0');

    return response;
  },

  /**
   * @deprecated use gatewayApi.getMultiPassToken instead
   * @returns {Promise<AMSResponse|T>}
   */
  async fetchShopifyToken() {
    const data = await amsClient.callAms('/shopify/token/');

    return data;
  },

  /**
   * @deprecated use @/service/shopify-storefronts api instead
   * @param {String} multipassToken:
   */
  async getAccessTokenForAuthUser(multipassToken) {
    const response = await makeRequest(multipassTokenMutation, {
      multipassToken,
    });

    return response;
  },
};
