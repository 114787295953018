export default `
  query productByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      description
      descriptionHtml
      hasOnlyDefaultVariant
      images(first: 20) {
        edges {
          node {
            id
            url
            altText
          }
        }
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      options {
        name
        values
      }
      metafields(first: 15, namespace: "shoprz") {
        edges {
          node {
            key
            value
          }
        }
      }
      variants(first: 20) {
        edges {
          node {
            id
            compareAtPrice
            displayName
            inventoryPolicy
            inventoryQuantity
            inventoryItem {
              tracked
            }
            price
            title
            image {
              id
              url
              altText
            }
            metafields(first: 10, namespace: "shoprz") {
              edges {
                node {
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
