<template>
  <div class="sp-products">
    <div class="sp-products--header" :class="{ 'sp-desktop': !isMobile }">
      <span class="sp-quantity">{{ itemsCalc }}</span>

      <template v-if="!hideEditCart">
        <div
          v-if="isPopupCheckout"
          class="sp-link"
          @click="handleClickEditCart"
        >
          Edit cart
        </div>

        <router-link v-else class="sp-link" :to="{ name: 'cart' }">
          Edit cart
        </router-link>
      </template>
    </div>

    <div class="sp-products--list" :class="{ 'sp-desktop': !isMobile }">
      <div
        v-for="product of products"
        :key="product.id"
        class="sp-products--item"
        :class="{ 'sp-desktop': !isMobile }"
      >
        <div class="sp-picture">
          <img
            v-if="product.merchandise.image"
            :src="product.merchandise.image.url"
            :alt="product.merchandise.title"
          />
        </div>

        <div class="sp-information">
          <h3 class="sp-products--item-title" :title="productTitle(product)">
            {{ productTitle(product) }}
          </h3>

          <p class="sp-products--item-subtitle">
            {{ productSubtitle(product) }}
          </p>
        </div>

        <div class="sp-price">
          <p class="sp-products--item-title">
            ${{ productTotal(product).toFixed(2) }}
          </p>

          <span class="sp-products--item-subtitle">
            {{ productQuantity(product.quantity) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductList',

    props: {
      isMobile: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
        require: true,
      },
      isPopupCheckout: {
        type: Boolean,
        default: false,
      },
      hideEditCart: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      itemsCalc() {
        const items = this.products.reduce((acc, item) => acc + item.quantity, 0);

        return items !== 1 ? `${items} items` : `${items} item`;
      },
    },

    methods: {
      productSubtitle(product) {
        const subtitle = product?.merchandise?.title
          ?.split(' / ')
          ?.join(', ');

        return subtitle !== 'Default Title' ? subtitle : '';
      },
      productTitle(product) {
        return product.merchandise.product.title;
      },
      productTotal(product) {
        return +product.merchandise.price.amount * +product.quantity;
      },
      productQuantity(quantity) {
        return quantity > 1 ? `${quantity} items` : `${quantity} item`;
      },
      handleClickEditCart() {
        this.$emit('edit:cart');
      },
    },
  };
</script>

<style lang="scss" scoped>
  $gray: #e2e6ee;
  $dark: #343434;

  .sp-products {
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      .sp-quantity {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: $dark;
      }

      .sp-link {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #4b89e5;
        cursor: pointer;
      }

      &.sp-desktop {
        .sp-quantity {
          font-size: 16px;
        }
        .sp-link {
          font-size: 14px;
        }
      }
    }

    &--list {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 8px;

      @media screen and (min-width: 768px) {
        overflow: auto;
        max-height: 846px;
      }

      .sp-price {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .sp-information {
        margin-right: 10px;
      }

      &.sp-desktop {
        row-gap: 4px;
      }
    }

    &--item {
      border: 1px solid $gray;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px 8px 8px;

      &-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: $dark;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &-subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #acb6ca;
        margin-bottom: 0;
      }

      .sp-picture {
        width: 44px;
        height: 44px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        margin-right: 12px;
        flex: 0 0 auto;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .sp-price {
        margin-left: auto;
      }

      &.sp-desktop {
        padding: 6px 24px 6px 6px;

        .sp-picture {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
</style>
