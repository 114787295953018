import { amsClient } from '@/service/ams';
// import { doFetch } from 'vimmi-web-utils/esm/doFetch';

export default {
  async getItem(id) {
    const response = await amsClient.callAms(`/get_item/${id}`, { cache: false });
    return response.head;
  },
  async getItemBySlug(slug) {
    const response = await amsClient.callAms(`/get_item/${slug}`, { cache: false });
    return response.head;
  },
  // async getStoreFrontInfo(pk) {
  //   const response = await doFetch(`/aoss/manage/(store|storefront)/?pk=${pk}`);
  //   return response.info;
  // },
  async getShopOffer(id) {
    const response = await amsClient.callAms(`/shop/offer/${id}/`, { cache: false });
    return {
      ...response.head,
      items: response.items,
    };
  },
};
