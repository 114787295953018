<template>
  <div class="sp-mobile-menu">
    <nav class="toolbar" :style="`height: ${menuHeight}; padding-bottom: ${menuPadding}`">
      <div class="toolbar-container">
        <router-link
          v-for="(nav, i) in navs" :key="i" class="cart-btn" :to="nav.to"
          @click.native="() => onClick(nav)"
        >
          <!--        TODO Replace it to FAS PRO ICONS-->
          <img
            :width="nav.width"
            :height="nav.height"
            :src="require(`@/assets/icons/mobile-menu/${iconName(nav)}.png`)"
          >
          <span :class="{ 'active-btn': isActiveNav(nav) }">{{ nav.title }}</span>
        </router-link>
        <auth-actions
          :is-conf-loaded="isConfLoaded"
          @showAuthPopup="showAuthPopup()"
          @logout="logout()"
        />
      </div>
    </nav>
  </div>
</template>
<script>
  import { amsClient } from '@/service/ams';
  import AuthActions from '@/entities/app-mobile-menu/components/auth-actions.vue';
  import Auth from '@/service/authService';
  import device from '@/service/device-service.js';
  import WishlistService from '@/store/wishlist';

  const SPECIFIC_STORES = ['608237000002298040', '5742711000004333043'];

  export default {
    name: 'AppMobileMenu',
    components: {
      AuthActions,
    },
    props: {
      showChannels: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      const storeConf = amsClient.get('conf.store');
      const isSpecificStore = SPECIFIC_STORES.includes(storeConf.store_id);

      return {
        isConfLoaded: false,

        navs_: [
          {
            title: isSpecificStore ? 'Home' : 'Explore',
            icon: 'home',
            width: 18,
            height: 16,
            to: '/',
            name: 'Home',
            show: true,
          },
          {
            title: 'Catalog',
            icon: 'grid',
            width: 16,
            height: 16,
            to: '/catalog',
            name: 'CatalogPage',
            show: true,
          },
          {
            title: 'Watch',
            icon: 'watch',
            width: 18,
            height: 16,
            to: '/watch',
            name: ['Watch', 'WatchItem'],
            show: !isSpecificStore,
          },
          {
            title: isSpecificStore ? 'Instructors' : 'Channels',
            icon: 'play',
            width: 14,
            height: 16,
            to: '/channels',
            name: 'channels',
            show: this.showChannels
          },
          // { title: 'Departments', icon: 'rect-history', width: 16, height: 16, to: '/departments', name: 'departments' },
          // { title: 'Account', icon: 'account', width: 14, height: 16 },
        ],
      };
    },
    computed: {
      navs() {
        return this.navs_.filter((nav) => !!nav.show);
      },
      menuHeight() {
        return device.isIOS() && device.isChrome() ? '60px' : '56px';
      },
      menuPadding() {
        return device.isIOS() && device.isChrome() ? '10px' : '0px';
      },
    },
    mounted() {
      document.body.classList.add('with-mobile-menu');
    },
    beforeDestroy() {
      document.body.classList.remove('with-mobile-menu');
    },
    methods: {
      onClick(nav) {
        this.$bus.$emit('ChatWidgetClose');
        if (nav.name === 'CatalogPage' && window.innerWidth < 801) {
          window.scrollTo({ top: 0 })
        }
      },
      showAuthPopup() {
        let isZman = process.env.VUE_APP_CUSTOMER === 'zman';
        this.$bus.$emit('toggleAuthPopup', {
          component: isZman ? 'register' : 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
      logout() {
        this.$bus.$emit('openSignOutDialog', {
          isAcceptHidden: false,
          callFromFooter: false,
        });
        // const CONFIRM_CONFIG = {
        //   centered: true,
        //   size: 'confirm',
        //   cancelTitle: 'No',
        //   okTitle: 'Yes',
        // };
        //
        // this
        //   .$bvModal
        //   .msgBoxConfirm('Do you really want to sign out of Shoprz', CONFIRM_CONFIG)
        //   .then(async (confirm) => {
        //     if (confirm) {
        //       await Auth.logOut();
        //       WishlistService.clear();
        //       this.$bus.$emit('logout');
        //
        //       if (this.$route.path.includes('wishlist')) {
        //         this.$router.push('/');
        //       }
        //     }
        //   });
      },
      isActiveNav(nav) {
        return Array.isArray(nav.name) ? nav.name.includes(this.$route.name) : this.$route.name === nav.name;
      },
      iconName(nav) {
        return this.isActiveNav(nav) ? `${nav.icon}-solid` : nav.icon;
      },
    },
  };
</script>
<style lang="scss">
  $sm-breakpoint: 820px;

  .sp-mobile-menu {
    width: 100%;
    display: flex;
    justify-content: center;

    .toolbar {
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $header-background-color;
      font-family: 'Roboto', sans-serif;
      color: $text-color-white;
      z-index: 1030;
      transform: translateY(0);
      transition: transform .3s;

      &-container {
        display: flex;
        width: 75%;
        max-width: 500px;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @media (max-width: $sm-breakpoint) {
          width: 95%;
        }
      }

      .spacer {
        flex: 1;
      }

      .search {
        flex: 1;
        margin-left: auto;

        @media (max-width: $md-breakpoint) {
          margin-right: 12px;
        }
      }
    }

    .cart-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      //width: 150px;
      //height: 80px;
      padding: .5rem;
      //border: none !important;
      //box-shadow: none !important;
      //text-transform: uppercase;
      font-size: 12px;
      //font-weight: 500;
      color: #71707C;

      //@media (max-width: $sm-breakpoint) {
      //  width: 65px;
      //}

      img {
        //margin-left: 9px;

        //@media (max-width: $sm-breakpoint) {
        //  margin-left: 0;
        //}
      }
    }

    .active-btn {
      color: white;
      font-weight: 500;
    }
  }
</style>