import cartRoute from '../entities/cart/routes';
import autoSegmentsRoute from '../entities/auto-segments/routes';
import productRoute from '../entities/product/routes';
import watchRoute from '../entities/watch/routes'
import videoRoute from '../entities/video/routes';
import accountRoute from '../entities/account/routes';
import protectedRoute from '../entities/protected/routes';
import checkoutRoute from '../entities/checkout/routes';
import termsRoute from '../entities/terms/routes';
import catalogRoutes from '@/entities/catalog/routes';

export default [
  ...cartRoute,
  ...autoSegmentsRoute,
  ...productRoute,
  ...accountRoute,
  ...protectedRoute,
  ...watchRoute,
  ...videoRoute,
  ...checkoutRoute,
  ...termsRoute,
  ...catalogRoutes,
];
