import UserConfig from '../config/config_user';
import CustomerConfig from '../config/config_shoprz';
import AppConfig from '../config/config_template';
import normaliseLanguageCodes from '../utils/normaliseLanguageCodes';
import normalizeUrlProtocol from 'src/utils/normalizeUrlProtocol';
import { ConfigService } from 'vimmi-web-utils/esm/configService';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import { normalizeLang } from 'vimmi-web-utils/cjs/normalizeLang';
import { activateFeature } from 'src/plugins/feature-toggle';
import { deepMerge } from 'vimmi-web-utils/cjs/deepMerge';

let DefaultConfig = deepMerge(AppConfig, CustomerConfig);

if (process.env.NODE_ENV !== 'production') {
  DefaultConfig = deepMerge(DefaultConfig, UserConfig);
}

class ConfigClass extends ConfigService {
  isLoaded = false;

  callbacks = [];

  constructor() {
    super();

    this._confData = DefaultConfig;
    const langIso3 = normalizeLang(localStore.get('lang'), 'iso-3');

    if ((this.get('languages.rtl') || []).indexOf(langIso3) !== -1) {
      activateFeature('rtl');
    }

    this._confData.activeLang = langIso3;
  }

  load(_conf, _amsClient) {
    if (_conf.dev_mode) {
      this._confData.mode = 'dev';
    }

    if (this._confData.dev_mode) {
      this._confData.mode = 'dev';
    }

    this._confData.env = _conf.env || 'prod';

    /** Player config
     * option: url
     * option: metadata
     * */
    if (_conf.player) {
      if (_conf.player.url) {
        if (process.env.NODE_ENV === 'production' || this._confData.player.replaceLink) {
          this._confData.player.url = normalizeUrlProtocol(_conf.player.url);
        }
      }

      if (_conf.player.metadata) {
        this._confData.player.metadata = _conf.player.metadata;
      }
    }

    /** Languages config
     * option: languages
     * */
    if (_conf.languages) {
      this._confData.languages = deepMerge(
        {},
        this._confData.languages,
        { list: normaliseLanguageCodes(_conf.languages.list) },
      );
    }
    if (_conf.recommendation) {
      this._confData.recommendation = _conf.recommendation;
    }

    if (_conf.banners_call_gap) {
      this._confData.banners_call_gap = _conf.banners_call_gap * 1000;
    }

    this.isLoaded = true;

    while (this.callbacks.length) {
      (this.callbacks.shift())();
    }
  }

  loaded(callback) {
    if (this.isLoaded) {
      callback();
    } else {
      this.callbacks.push(callback);
    }
  }
}

export default new ConfigClass();
