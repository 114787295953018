const state = () => ({
  productLink: '',
});

const getters = {
  productUrl(state) {
    return state.productLink;
  },
};

const actions = {
  getProductLink({ commit }, payload) {
    commit('setProductLink', payload);
  },
};

const mutations = {
  setProductLink(state, payload) {
    state.productLink = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
