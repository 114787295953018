<template>
  <div
    v-if="shopPlayerActiveItem"
    ref="vodContainer"
    class="vod-popup"
    @click.prevent.stop="closeModal({event:$event})"
  >
    <div ref="popup" class="vod-popup--container">
      <shop-player
        ref="shopPlayer"
        :items="[shopPlayerActiveItem]"
        :active-item="shopPlayerActiveItem"
        :is-loading="false"
        :scroll-navigate="false"
        :active-content-type="'vod'"
        :rewind="true"
        :blocked-page-scroll="true"
        :shop-controls="{
          upDownToggler: false,
          tabs: false,
          volume: isMobile,
          fullscreen: isMobile,
          productsDrawer: shopPlayerActiveItem && !shopPlayerActiveItem.vod_short
        }"
        :items-products="itemsProducts"
        :items-stats="itemsStats"
        :items-overlay-state="itemsOverlaysState"
        :items-channel-follow-state="itemsChannelsFollowState"
        :chat-config="chatConfig"
        :loop="true"
        :background="'purple'"
        :logo-link="logoLink || require('src/assets/logo/logo-vimmi-white.svg')"
        :is-store-name-clickable="false"
        :viewers-limit-reached="viewersLimitReached"
        :get-playback-url="getPlaybackUrl"
        :can-play="canPlay"
        @playback-state="shopPlayerPlaybackStateUpdated"
        @fs-state="shopPlayerFullScreenChanged"
        @open-pip="openFloatPlayer($event)"
        @toggle-follow="toggleFollow($event)"
        @block-scroll="blockPageScroll($event)"
        @unblock-scroll="unBlockPageScroll($event)"
        @chat-user-updated="chatUserUpdated($event)"
        @product-click="onProductClick($event)"
        @close-product="closeProductsDetailsPopup($event)"
        @send-reaction="sendReaction($event)"
        @title-click="closeModal($event, true, true)"
        @player:stats="shopPlayerStats($event, 'popup')"
        @ready="onShopPlayerReady($event)"
        @share="openSharePopup($event)"
      >
        <template v-if="isMobile" #header-bar-before>
          <button
            class="vod-popup-back mr-3"
            type="button"
            @click="closeModal({event:$event}, true)"
          >
            <i class="fas fa-chevron-left" />
          </button>
        </template>
        <template #products-details-popup>
          <product-details-popup-component
            v-if="productPopupDetails"
            :product="productPopupDetails"
            @close="closeProductsDetailsPopup"
          />
        </template>
      </shop-player>
    </div>
  </div>
</template>
<script>
  import device from '@/service/device-service.js';
  // import GtagService from '@/service/gtag';
  import { mapActions, mapState } from 'vuex';
  import ProductDetailsPopupComponent from '@/shared/components/shop-player/product-details-popup.component.vue';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import Config from '@/service/config';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'VodPopup',
    components: {
      ProductDetailsPopupComponent,
      // ShopPlayerContainer,
    },
    mixins: [ShopPlayerMixin],
    data() {
      return {
        prevTitle: document.title,
        initialPlaybackState: null,
        shopPlayerActiveItem: null,
        logoLink: '',
      };
    },
    computed: {
      ...mapState('popupPlayer', {
        item: 'item',
        playbackState: 'playbackState',
      }),
      isMobile() {
        return device.isAnyMobile();
      },
      isUser() {
        return this.$store.getters['authenticate/isUser'];
      },
    },
    watch: {
      item: {
        handler(newItem, prevItem) {
          if (newItem) {
            const { startPosition = 0 } = newItem;
            this.startPosition = startPosition;
            this.initialPlaybackState = { ...this.playbackState };
            if (startPosition > 0) {
              this.initialPlaybackState = {
                ...this.initialPlaybackState,
                position: startPosition,
                firstPlay: true,
              };
            }
            if (newItem.id !== prevItem?.id) {
              this.productPopupDetails = null;
              this.setItemActiveItem(newItem);
            }
          } else {
            this.unBlockPageScroll({ component: 'popup-player' });
            if (this.inFullScreen) {
              this.shopPlayerFullScreenChanged({ active: false, emulated: false });
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      Config.loaded(() => {
        this.logoLink = amsClient.get('conf.store.logo');
        // this.logo = 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.40751.cust.original.jpg'
      });
    },
    methods: {
      ...mapActions({
        setItem: 'popupPlayer/setItem',
        setPlaybackState: 'popupPlayer/setPlaybackState',
        setFloatItem: 'floatPlayer/setFloatItem',
        setFloatPlaybackState: 'floatPlayer/setPlaybackState',
        setFloatOptions: 'floatPlayer/setFloatOptions',
      }),
      onShopPlayerReady(event) {
        if (!this.isUser.guest)
          amsClient.callAms(`/stk/recent/?d=web&pk=${this.shopPlayerActiveItem.id}`, {
            method: 'post',
          });

        this.blockPageScroll({ component: 'popup-player', whiteList: event?.scrollWhiteList || [] });
      },
      async setItemActiveItem(item) {
        this.prevTitle = document.title;
        const fullItem = await this.loadFullItem(item);
        this.shopPlayerActiveItem = fullItem;

        if (
          fullItem.itype === 'item_mov_vod_shoprz' &&
          fullItem.share
        ) {
          console.log('setCurrentPage fullItem.share', fullItem.share);
          // this.$bus.$emit('setCurrentPage', fullItem.share);
        } else {
          if (item.share && this.$route.fullPath !== item.share) {
            // this.$bus.$emit('setCurrentPage', item.share);
          }
        }

        this.$bus.$emit(
          'updateTitle',
          this.shopPlayerActiveItem.seo_metadata.title,
        );
        this.$stats.send('srz_vod_view', {
          store_id: this.shopPlayerActiveItem.creator.id,
          store_slug: this.shopPlayerActiveItem.creator.slug,
          store_name: this.shopPlayerActiveItem.creator.name,
          vod_id: this.shopPlayerActiveItem.id,
          vod_name: this.shopPlayerActiveItem.title,
        }, {
          only: ['gtm'],
        });
        await this.handleShopOffer(this.shopPlayerActiveItem);
      },
      async closeModal({ event, item }, force, clickByName = false) {
        const target = event?.target || event?.currentTarget;
        if (
          !force &&
          target &&
          (target.tagName === 'BUTTON' ||
            target.tagName === 'A' ||
            target.classList.contains('vod-popup--container') ||
            target.closest('.vod-popup--container'))
        ) {
          return;
        }
        item = item || { ...this.shopPlayerActiveItem };
        this.productPopupDetails = null;
        this.shopPlayerActiveItem = null;
        this.setItem(null);
        this.setPlaybackState(null);
        this.unBlockPageScroll({ component: 'popup-player' });
        this.$bus.$emit('updateTitle', this.prevTitle);
        this.$emit('close');
        if (clickByName) {
          const slug = (item?.storefront || item?.store || item?.creator || {}).slug;
          this.$router.push({ path: `/s/${slug}` });
        }

        if (!clickByName) {
          // ToDo WEB2-4327 need to discuss it separately with @s.tretiak
          // await this.$bus.$emit('setCurrentPage', null);
        }
      },
      shopPlayerPlaybackStateUpdated({ itemId, state }) {
        if (
          itemId === this.shopPlayerActiveItem?.id &&
          state.duration &&
          !isNaN(state.duration) &&
          this.initialPlaybackState
        ) {
          if (
            (this.initialPlaybackState.position,
              this.initialPlaybackState.firstPlay)
          ) {
            this.$refs.shopPlayer.seekTo(
              this.shopPlayerActiveItem,
              this.initialPlaybackState.position,
            );
          }
          if (this.initialPlaybackState.muted) {
            this.$refs.shopPlayer.mute(this.shopPlayerActiveItem, true);
          } else {
            this.$refs.shopPlayer.unmute(this.shopPlayerActiveItem, true);
          }
          if (this.initialPlaybackState.paused && !state.paused) {
            this.$refs.shopPlayer.pause(this.shopPlayerActiveItem, true);
          } else {
            this.$refs.shopPlayer.play(this.shopPlayerActiveItem, true);
          }
          this.initialPlaybackState = null;

          this.shopPlayerPlaybackState = state;
        }
        this.setPlaybackState(state);
      },
      async openFloatPlayer({ item, playbackState }) {
        this.setFloatPlaybackState({ ...this.playbackState });
        this.setFloatItem({ ...item });
        this.setFloatOptions({
          page: item.share || location.pathname,
          openerPage: location.pathname,
          ignoreScroll: true,
          type: 'vod-popup',
        });
        await this.$bus.$emit('setCurrentPage', null);

        this.productPopupDetails = null;
        this.shopPlayerActiveItem = null;
        this.setItem(null);
        this.setPlaybackState(null);
        this.$bus.$emit('updateTitle', this.prevTitle);
      },
    },
  };
</script>
<style lang="scss">
  @import 'shop-player-v2/dist/style';
  @import 'popup-player.component';
</style>