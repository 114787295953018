<template>
  <div class="footer-wrapper">
    <div class="footer-content">
      <div
        class="footer-content-left"
        :class="{
          'footer-content-left-no-border': isFullAuth,
        }"
      >
        <div
          class="footer-content-left-logo"
        >
          <img
            :style="`height: ${storeLogo ? '36' : '23'}px; border-radius: ${storeLogo ? '5px' : 0}`"
            :src="logoSrc"
            @click="onClickLogo"
          />
        </div>
        <div class="footer-content-left-menu">
          <div
            v-for="(item, index) in menu"
            :key="index"
            class="footer-content-left-menu-section"
          >
            <div class="footer-content-left-menu-section-title">
              {{ item.title }}
            </div>
            <div
              v-for="(item, i) in item.items"
              :key="i"
              :class="{
                'footer-content-left-menu-section-item': true,
                'footer-content-left-menu-section-item-disabled': !item.isNotLink && item.link === '#',
              }"
            >
              <router-link
                v-if="!item.isNotLink"
                :to="item.link"
                :class="{
                  'footer-content-left-menu-section-item-link-disabled': item.link === '#',
                }"
              >
                {{ item.title }}
              </router-link>
              <span
                v-else
                class="footer-bottom-content-left-menu-section-item-link"
                @click="() => item.action()"
              >
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="footer-content-left-menu-mobile">
          <div
            v-for="(column, i) in mobileMenuColumns"
            :key="i"
          >
            <div
              v-for="(menu, index) in column"
              :key="index"
              :class="{
                'footer-content-left-menu-section': index !== column.length - 1
              }"
            >
              <div class="footer-content-left-menu-section-title">
                {{ menu.title }}
              </div>
              <div
                v-for="(item, i) in menu.items"
                :key="i"
                :class="{
                  'footer-content-left-menu-section-item': true,
                  'footer-content-left-menu-section-item-disabled': !item.isNotLink && item.link === '#',
                }"
              >
                <router-link
                  v-if="!item.isNotLink"
                  :to="item.link"
                  :class="{
                    'footer-content-left-menu-section-item-link-disabled': item.link === '#',
                  }"
                >
                  {{ item.title }}
                </router-link>
                <span
                  v-else
                  class="footer-bottom-content-left-menu-section-item-link"
                  @click="() => item.action()"
                >
                  {{ item.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-content-right">
        <div v-if="!isFullAuth">
          <div class="footer-content-right-auth">
            <div class="footer-content-right-auth-title">
              Get the latest deals and more
            </div>
            <div>
              <button
                class="footer-content-right-auth-button"
                @click="onClickSignIn"
              >
                Sign in or Create account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom-wrapper">
      <div class="footer-content footer-bottom-content">
        <div>
          © {{ currentYear }} — {{ storeName }}. All right reserved &#9679;
          Powered by Vimmi
        </div>
        <div>
          <router-link
            :to="{ name: 'terms-and-conditions' }"
          >
            Terms and Conditions
          </router-link>
          &#9679;
          <router-link
            :to="{ name: 'privacy-policy' }"
          >
            Privacy Policy
          </router-link>
        </div>
      </div>
      <div class="footer-content footer-bottom-content-mobile">
        <div>
          © {{ currentYear }} — {{ storeName }}. All right reserved
        </div>
        <div>
          <router-link
            :to="{ name: 'terms-and-conditions' }"
          >
            Terms and Conditions
          </router-link>
          &#9679;
          <router-link
            :to="{ name: 'privacy-policy' }"
          >
            Privacy Policy
          </router-link>
        </div>
        <div>
          Powered by Vimmi
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { AuthVariableMixin } from '@/plugins/mixin';

  export default {
    name: 'FooterComponent',
    mixins: [AuthVariableMixin],
    props: {
      storeLogo: {
        type: String,
        default: '',
      },
      storeName: {
        type: String,
        default: 'Store name',
      },
      // isLoggedIn: {
      //   type: Boolean,
      //   default: false,
      // },
    },

    data() {
      return {
        currentYear: new Date().getFullYear(),
        menu: [
          {
            title: 'About store',
            items: [
              { title: 'About us', link: '#' },
              { title: 'Contact us', link: '#' },
            ],
          },
          {
            title: 'Customer Service',
            items: [
              { title: 'FAQ', link: '#' },
              { title: 'Shipping & Pickup', link: '#' },
              { title: 'Return Policy', link: '#' },
              { title: 'Contact Seller', link: '#', isNotLink: true, action: () => this.openContactSeller() },
            ],
          },
          {
            title: 'My Account',
            items: [
              { title: 'Profile', link: '/account/profile', isNotLink: true, action: () => this.openAuthRoute('/account/profile') },
              { title: 'Wishlist', link: '/account/favorites', isNotLink: true, action: () => this.openAuthRoute('/account/favorites') },
              { title: 'My Orders', link: '/account/orders', isNotLink: true, action: () => this.openAuthRoute('/account/orders') },
            ],
          },
        ],
      };
    },

    computed: {
      mobileMenuColumns() {
        return [
          [this.menu[0], this.menu[2]],
          [this.menu[1]],
        ]
      },
      logoSrc() {
        if (this.storeLogo) {
          return this.storeLogo;
        }
        return require('src/assets/logo/logo-store-name-white.svg')
      },
    },

    methods: {
      onClickLogo() {
        this.$router.push({ path: '/' });
        document.documentElement.scrollTop = 0;
      },
      onClickSignIn() {
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
      openAuthRoute(routeName) {
        if (!this.isFullAuth) {
          return this.onClickSignIn();
        }

        this.$router.push(routeName);
      },
      openContactSeller() {
        const el = document.getElementsByClassName('sp-chat-icon')?.[0];
        el?.click();
      },
    },

  };
</script>

<style lang="scss" scoped>
  .footer-wrapper {
    background-color: #16171A;
    z-index: 0;
    margin-top: 20px;
  }

  .footer-content {
    margin: 0 auto;
    //max-width: calc(1420px + 3rem);
    padding: 1.5rem 1.5rem;
    display: flex;
    justify-content: space-between;

    &-left-menu-mobile {
      display: none;
    }

    &-left {
      display: flex;
      flex: 1;
      &-logo {
        margin-bottom: 1rem;
        img {
          cursor: pointer;
        }
      }
      &-menu {
        margin-left: 3rem;
        display: flex;
        justify-content: space-between;
        max-width: 425px;
        width: 100%;
        //width: 425px;
        &-section {
          &-title {
            font-size: 16px;
            font-weight: 600;
            color: white;
            margin-bottom: 0.4rem;
          }

          &-item {
            margin-top: 0.25rem;
            &-disabled {
              cursor: not-allowed;
            }
            span, a {
              font-size: 15px;
              font-weight: 400;
              color: #7B8089;
              cursor: pointer;
              &:hover {
                color: white;
              }
            }
            &-link-disabled {
              pointer-events: none;
            }
          }
        }
      }
    }
    &-right {
      margin-left: 1rem;
      &-auth {
        &-title {
          font-size: 15px;
          font-weight: 600;
          color: white;
          margin-bottom: 0.5rem;
        }

        &-button {
          background-color: #16171a;
          border-radius: 5px;
          padding: 9px 20px;
          border: 1px solid #31323E;
          color: white;
          font-size: 15px;
          font-weight: 400;
          &:hover {
            background-color: #21A66E;
          }
        }
      }
    }

    @media (max-width: 800px) {
      display: block;
      &-left {
        border-bottom: 1px solid #212326;
        margin: 0 -1rem 1rem -1rem;
        padding: 0 1rem 1rem 1rem;
        &-no-border {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        &-menu {
          flex: 1;
          flex-wrap: wrap;
          max-width: 100%;
        }
      }

      &-right {
        margin-left: 0;
      }
    }

    @media (max-width: 600px) {
      &-left {
        display: block;
        &-menu {
          margin-left: 0;
        }
      }
    }

    @media(max-width: 500px) {
      &-left-menu {
        display: none;
      }

      &-left-menu-section {
        margin-bottom: 2rem;
      }

      &-left-menu-mobile {
        display: flex;
        justify-content: space-between;
        > * {
          width: 50%;
        }
      }
      &-left-menu-section {
        width: 50%;
      }
    }
  }

  .footer-bottom-wrapper {
    border-top: 1px solid #212326;
    @media (max-width: 800px) {
      padding-bottom: 54px;
    }
    color: #5D6168;
    font-size: 13px;
    font-weight: 400;
    a {
      color: #5D6168;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        color: white;
      }
    }
  }


  .footer-bottom-content {
    align-items: center;
    padding: 1rem 1.5rem;
    &-mobile {
      display: none;
    }
    @media (max-width: 800px) {
      display: none;
      &-mobile {
        display: block;
        padding: 1rem 1.5rem;
      }
    }
  }
</style>