export default function (value) {
  if (value === undefined || value === null) {
    return '0:00:00';
  }

  const hours = Math.floor(value / 3600);
  const x = value % 3600;
  const minute = Math.floor(x / 60);
  const second = value % 60;

  if (hours === 0) {
    return `${minute}:${(second < 10) ? `0${second}` : second}`;
  } return `${hours}:${(minute < 10) ? `0${minute}` : minute}:${(second < 10) ? `0${second}` : second}`;
}
