export default `
  mutation cartCreate($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        id
        attributes {
          key
          value
        }
        buyerIdentity {
          customer {
              id
              email
          }
          countryCode
          email
        }
      }
    }
  }
`;
