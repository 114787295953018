<template>
  <div
    :class="{
      'sp-chat-widget-message': true,
      [msgClass]: true,
    }"
  >
    <img
      v-if="img"
      :src="img.src"
      :class="{
        'is-clickable': !img.isLocal,
        'sp-chat-widget-message-image-loading': img._inProgress,
      }"
      @click="() => openImg(img)"
    />
    <loader
      v-if="img && img._inProgress"
      color="rgb(93 93 93)"
      position="absolute"
      spinner-style="font-size: 300%"
      is-full-height
    />
    {{ text }}
    <image-preview-component
      v-if="showPreview"
      :src="img.src"
      @close="onClosePreview"
    />
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import Loader from '@/components/loader/loader.vue';
  import ImagePreviewComponent from '@/shared/components/image-preview.component.vue';

  export default {
    name: 'ChatWidgetMessageComponent',
    components: { ImagePreviewComponent, Loader },
    props: {
      message: {
        type: Object,
        default: () => ({}),
      },
      chatUser: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showPreview: false,
      };
    },

    computed: {
      domain() {
        return amsClient.get('conf.chat.chat_backend_url').slice(0, -1);
      },
      img() {
        const img = this.message.attachments?.[0];
        if (img && img.image_url) {
          return {
            src: `${this.domain}${img.image_url}`,
            download_src: `${this.domain}${img.title_link}`,
          };
        }

        if (img && (this.message._inProgress || this.message._isFailed)) {
          return {
            src: img.base64src,
            isLocal: true,
            ...this.message,
          };
        }

        return null;
      },
      text() {
        return this.message.msg;
      },
      isSenderBuyer() {
        return this.message.u._id === this.chatUser.id
      },
      msgClass() {
        return this.isSenderBuyer ? 'sp-chat-widget-message-buyer' : 'sp-chat-widget-message-seller';
      },
    },

    methods: {
      openImg(img) {
        if (img.isLocal) {
          return;
        }

        this.showPreview = true;
      },
      onClosePreview() {
        this.showPreview = false;
      },
    },

  };
</script>

<style lang="scss" scoped>
  @import '../chat-widget-colors';

  .sp-chat-widget-message {
    padding: 0.75rem;
    border-radius: 15px;
    border: 1px solid #E2E6EE;
    margin-bottom: 0.5rem;
    width: max-content;
    max-width: 100%;
    word-break: break-word;
    position: relative;
    font-size: 15px;

    img {
      width: 100%;
      height: auto;
      &.is-clickable {
        cursor: pointer;
      }
    }

    &-seller {
      color: $sp-chat-widget-primary;
      background-color: white;
    }

    &-image-loading {
      opacity: 0.5;
    }

    &-buyer {
      color: black;
      background-color: #F0F2F6;
      margin-left: auto;
    }
  }
</style>
