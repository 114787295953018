<template>
  <div class="sp-checkout-layout" :class="{ 'sp-desktop': !isMobile }">
    <div>
      <slot name="actions" />
    </div>

    <div class="sp-details">
      <slot name="details" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CheckoutLayout',

    props: {
      isMobile: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      if (!this.isMobile) {
        const container = document.getElementById('app-content');

        container.style.overflow = 'visible';
      }
    },

    destroyed() {
      if (!this.isMobile) {
        const container = document.getElementById('app-content');

        container.removeAttribute('style');
      }
    },
  };
</script>

<style lang="scss" scoped>
  .sp-checkout-layout {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    row-gap: 32px;

    .sp-details {
      position: sticky;
      top: 70px;
    }

    &.sp-desktop {
      grid-template-columns: 1fr 1fr;
      column-gap: 6%;
    }
  }
</style>
