<template>
  <nav :class="finalHeaderClass">
    <div
      class="toolbar-container"
      :class="{
        'is-type-secondary': !isTablet,
      }"
      :style="`display: ${extendedSearch ? 'flex' : 'grid'}`"
    >
      <div v-if="!extendedSearch" class="toolbar-container-left">
        <router-link
          v-if="!isTablet"
          to="/"
          class="logo"
          @click.native="closeAuthPopup()"
        >
          <img
            :style="`border-radius: ${logo ? '5px' : 0}`"
            :src="logoSrc"
            :alt="appTitle()"
          />
        </router-link>

        <button id="catalog_btn" class="catalog-btn" @click="toggleCatalog">
          <img
            :src="require(`src/assets/icons/${catalogIcon}`)"
            alt="Catalog"
          />
          Catalog
        </button>

        <nav-links
          :is-dark="!isShopPlayerHeader"
          :is-tablet="isTablet"
          :header-links="headerLinks"
          @onLogin="onLogin"
          @logout="logout"
        />
      </div>

      <div v-if="!extendedSearch" class="toolbar-container-center">
        <router-link
          v-if="isTablet"
          to="/"
          class="logo"
          @click.native="closeAuthPopup()"
        >
          <img
            :style="`border-radius: ${logo ? '5px' : 0}`"
            :src="logoSrc"
            :alt="appTitle()"
          />
        </router-link>
      </div>

      <div v-if="!extendedSearch" class="toolbar-container-right">
        <header-search-block
          v-if="!isTablet && !extendedSearch"
          @closeSearchProductPopup="closeSearchProductPopup"
          @icon-click="extendedSearch = false"
        />
        <!--      <div v-else class="spacer" />-->
        <div class="toolbar-container-center">
          <button
            v-if="isTablet && !extendedSearch"
            ref="afterClick"
            type="button"
            class="btn"
            @click="extendedSearch = true"
          >
            <i
              :style="`color: ${
                isShopPlayerHeader ? 'white' : 'black'
              }; font-weight: 500`"
              class="fas fa-search search-icon icon fa-lg"
              aria-hidden="true"
            />
          </button>
          <auth-actions
            v-if="!showMobileMenu && !isProductMobilePage"
            :is-dark="!isShopPlayerHeader"
            style="margin-right: 1rem"
            :is-conf-loaded="isConfLoaded"
            @showAuthPopup="showAuthPopup()"
            @logout="logout()"
          />
          <cart :is-tablet="isTablet" :is-dark="!isShopPlayerHeader" />
        </div>
      </div>

      <div v-if="extendedSearch" class="sp-extended-search">
        <div class="backButton" @click="extendedSearch = false">
          <i
            class="fas fa-chevron-left"
            :style="`color: ${isShopPlayerHeader ? 'white' : 'black'}`"
          />
        </div>
        <header-search-block
          extended
          @closeSearchProductPopup="closeSearchProductPopup"
          @icon-click="extendedSearch = false"
        />
      </div>
    </div>
  </nav>
</template>

<script>
  import HeaderSearchBlock from 'src/components/search-block/SearchComponent.vue';
  import Auth from 'src/service/authService';
  import Config from 'src/service/config';
  import AuthBlock from 'src/components/auth-block/auth-block';
  import Cart from 'src/components/header/cart/Cart';
  import NavLinks from 'src/components/header/nav-links/NavLinks';
  import AuthActions from 'src/components/header/auth-actions/auth-actions';
  import device from 'src/service/device-service';
  import { formatDate } from 'src/filters';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { amsClient } from 'src/service/ams';
  import { activateFeature, isActiveFeature } from '@/plugins/feature-toggle';
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'AppHeader',
    components: {
      AuthBlock,
      HeaderSearchBlock,
      Cart,
      NavLinks,
      AuthActions,
    },
    mixins: [AuthVariableMixin],
    props: {
      activePopup: {
        type: Boolean,
        default: false,
      },
      showMobileMenu: {
        type: Boolean,
        default: false,
      },
      isMobile: {
        type: Boolean,
        default: false,
      },
      isTablet: {
        type: Boolean,
        default: false,
      },
      logo: {
        type: String,
        default: '',
      },
      headerLinks: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        isActiveSidebar: false,
        isConfLoaded: false,
        isDesktop: true,
        isNewInboxMessages: false,
        extendedSearch: false,
        isMainPage: false,
        showCatalog: false,
        showNavLinks: false,
        username: Auth.get('user.username'),
        lastScroll: 0,
        hide: '',
        extraClass: '',
        toggleStatus: false,
        visibleSupportChat: false,
        hideHeader: true,
      };
    },
    computed: {
      finalHeaderClass() {
        let classes = {
          toolbar: true,
        };
        if (this.visibleSupportChat && this.isMobile) {
          return classes;
        }

        classes = {
          ...classes,
          [this.hide]: this.hideHeader,
          [this.headerClass]:
            !this.activePopup || this.extraClass.includes('shop-player-header'),
        };
        return classes;
      },
      logoSrc() {
        if (this.logo) {
          return this.logo;
        }
        return this.isShopPlayerHeader
          ? require('src/assets/logo/logo-store-name-white.svg')
          : require('src/assets/logo/logo-store-name-dark.svg');
      },
      isProductMobilePage() {
        return this.isMobile && this.$route.name === 'product-id';
      },
      isShopPlayerHeader() {
        return (
          !(this.visibleSupportChat && this.isMobile) &&
          this.headerClass === 'shop-player-header'
        );
      },
      catalogIcon() {
        if (this.showCatalog) {
          return this.isShopPlayerHeader
            ? 'catalog_close_light.svg'
            : 'catalog_close.svg';
        }
        return this.isShopPlayerHeader
          ? 'categories_icon_light.svg'
          : 'categories_icon.svg';
      },
      profileTitle() {
        return this.$i18n.t('HEADER.PROFILE');
      },
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isMiniatureFrame() {
        return this.screenType === 'miniature';
      },
      headerClass() {
        return this.showNavLinks ? '' : this.extraClass;
      },
      store() {
        return amsClient.get('conf.store');
      },

      storeName() {
        return this.store?.name || '';
      },

      isNeededStore() {
        return this.storeName === 'poc' || this.storeName === 'Imtech' || this.storeName === 'ZYMOX';
      },
    },
    watch: {
      $route(to, from) {
        this.isMainPage = to.fullPath === '/';
        if (to.query.onlyregister && !isActiveFeature('webview_feature')) {
          this.webviewRegister();
        } else if (
          !!to.query.onlyrestore &&
          !isActiveFeature('webview_feature')
        ) {
          this.webviewRestorePass();
        }
        this.$bus.$emit('ToggleMobileAuthActions', false);
        this.$bus.$emit('ToggleNavLinks', false);
        this.hide = '';
      },
      isFullAuth: function () {
        this.username = Auth.get('user.username');
      },
      activePopup() {
        if (this.activePopup) {
          this.hide = '';
        }
      },
    },
    created() {
      this.isMainPage = this.$route.fullPath === '/';

      Config.loaded(() => {
        this.isConfLoaded = true;
        this.isDesktop = !device.isAnyMobile();
      });
    },
    mounted() {
      document.addEventListener('scroll', this.newScrollPosition);
      this.$bus.$on('ToggleCatalog', (data) => {
        this.showCatalog = data;
      });
      this.$bus.$on('VisibleSupportChat', (val) => {
        this.visibleSupportChat = val;
      });
      this.$bus.$on('ToggleNavLinks', (data) => {
        this.showNavLinks = data;
      });
      this.$bus.$on(
        'ScrollPositionToggleStatus',
        (toggleStatus) => (this.toggleStatus = toggleStatus),
      );

      if (
        this.$route.query.onlyregister &&
        !isActiveFeature('webview_feature')
      ) {
        this.$nextTick(() => {
          this.webviewRegister();
        });
      } else if (
        this.$route.query.onlyrestore &&
        !isActiveFeature('webview_feature')
      ) {
        this.$nextTick(() => {
          this.webviewRestorePass();
        });
      }
      this.$bus.$on('headerHide', () => {
        this.hide = '';
      });

      this.$bus.$on('HideHeader', (val) => {
        this.hideHeader = val;
      });
      this.$bus.$on('handleHeaderSpecificClass', (classList) => {
        // ignore set transparent header if side links enabled
        if (this.showNavLinks && classList === 'shop-player-header') {
          return;
        }
        this.extraClass = classList;
      });
    },
    unmounted() {
      document.removeEventListener('scroll', this.newScrollPosition);
    },
    destroyed() {
      this.$bus.$off('ToggleCatalog');

      this.$bus.$off('handleHeaderSpecificClass');
    },
    methods: {
      newScrollPosition() {
        if (this.toggleStatus || window.pageYOffset < 0) {
          return;
        }
        const scrollPosition = document.documentElement.scrollTop;
        if (!this.activePopup) {
          this.hide =
            scrollPosition > this.lastScroll &&
            device.isMobileSize() &&
            !this.alwaysShow
              ? 'hide'
              : '';
        }
        this.lastScroll = scrollPosition;
      },
      formatDate,
      webviewRegister() {
        activateFeature('webview_feature');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'register',
          center: false,
          force: true,
        });
      },
      webviewRestorePass() {
        activateFeature('webview_feature');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'forgot',
          center: false,
          force: true,
        });
      },
      closeSearchProductPopup() {
        this.$emit('closeProductPopup');
      },
      toggleSideBar(event) {
        event.stopPropagation();
        this.isActiveSidebar = !this.isActiveSidebar;
        if (this.isActiveSidebar) {
          this.$body.openSideBar();
        } else {
          // // this.$body.closeModal();
        }
      },
      logout() {
        this.$bus.$emit('openSignOutDialog', {
          isAcceptHidden: false,
          callFromFooter: false,
        });
        //
        // const CONFIRM_CONFIG = {
        //   centered: true,
        //   size: 'confirm',
        //   cancelTitle: 'No',
        //   okTitle: 'Yes',
        // };
        //
        // this
        //   .$bvModal
        //   .msgBoxConfirm('Do you really want to sign out of Shoprz', CONFIRM_CONFIG)
        //   .then(async (confirm) => {
        //     if (confirm) {
        //       await Auth.logOut();
        //       WishlistService.clear();
        //
        //       this.$bus.$emit('logout');
        //
        //       if (this.$route.path.includes('wishlist') || this.$route.path.includes('checkout')) {
        //         this.$router.push('/');
        //       }
        //     }
        //   });
      },

      showAuthPopup() {
        let isZman = process.env.VUE_APP_CUSTOMER === 'zman';
        this.$bus.$emit('toggleAuthPopup', {
          component: isZman ? 'register' : 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
      closeAuthPopup() {
        if (!this.isMiniatureFrame) {
          // this.$bus.$emit('togglePopupVOD');
          this.$bus.$emit('setMiniplayer');
          this.$bus.$emit('toggleFullScreenIframe');
        }
        this.$bus.$emit('toggleProductPopup');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          force: false,
        });
      },
      // appLogo() {
      //   return device.isMobileScreen()
      //     ? require('src/assets/logo/logo-market-shoprz.png')
      //     : require('src/assets/logo/logo-market-shoprz.png');
      // },
      appTitle() {
        return Config.get('app_title') || 'Portal';
      },
      onLogin() {
        this.showAuthPopup();
      },
      toggleCatalog() {
        // this.$router.push('/catalog');
        this.$bus.$emit('ToggleCatalog', !this.showCatalog);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './AppHeader';
</style>
