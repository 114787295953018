import { amsClient } from '@/service/ams';

let accessToken = null;
let endpoint = null;

const makeRequest = async (query, variables = {}) => {
  if (!accessToken || !endpoint) {
    accessToken = amsClient.get('conf.portal.marketplace.shopify.storefront_token');
    endpoint = `${amsClient.get('conf.portal.marketplace.shopify.shopify_api_endpoint')}/api/2022-10/graphql.json`;
  }

  // TODO: remove after POC
  if (window.location.hostname === 'poc.demo-shoprz.live') {
    accessToken = '970175c26c3365aa13d5332c49419316';
    endpoint = 'https://poc-vstore.myshopify.com/api/2022-10/graphql.json';
  }

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await response.json();

  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data;
};

export default makeRequest;
