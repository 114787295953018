export default `
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
        checkoutUrl
        cost {
          totalAmount {
              amount
              currencyCode
          }
          subtotalAmount {
              amount
              currencyCode
          }
          totalTaxAmount {
              amount
              currencyCode
          }
          totalDutyAmount {
              amount
              currencyCode
          }
        }
        lines(first: 100) {
          edges {
              node {
                  id
                  quantity
                  attributes {
                    key
                    value
                  }
                  merchandise {
                      ... on ProductVariant {
                          id
                          quantityAvailable
                          price {
                              amount
                              currencyCode
                          }
                          title
                          image {
                              url
                          }
                          product {
                            title
                            id
                          }
                      }
                  }
              }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
