export default [
  {
    name: 'protected',
    path: '/protected',
    component: () => import('./protected.view'),
    meta: {
      page: 'protected',
      title: 'Protected',
    },
  },
];
