<template>
  <transition name="vod-preview" @after-enter="startVideo">
    <div
      v-if="visible"
      :style="{ top, left, width }"
      class="vod-preview"
      :class="{ [size]: size }"
      @mouseleave="hidePreview"
    >
      <carousel-item
        ref="itemPreview"
        :item="item"
        :item-type="'horizontal'"
        :is-vod-preview="true"
        :position="0"
        @slide-click="onClick"
      />
    </div>
  </transition>
</template>
<script>
  import { mapState } from 'vuex';
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';

  export default {
    name: 'VodPreview',
    components: {
      CarouselItem,
    },
    data() {
      return {
        topOffset: 26,
        leftOffset: 40,
      };
    },
    computed: {
      ...mapState('vodPreview', {
        visible: 'visible',
        coords: 'coords',
        item: 'item',
      }),
      top() {
        return `${this.coords.top - this.topOffset}px`;
      },
      left() {
        return `${this.coords.left - this.leftOffset}px`;
      },
      width() {
        return `${
          2 * Math.round((this.leftOffset * 2 + this.coords.width) / 2)
        }px`;
      },

      // height() {
      //   return `${
      //     2 * Math.round((this.leftOffset * 2 + this.coords.width) / 3.3)
      //   }px`;
      // },

      size() {
        return this.item.size;
      },
    },
    mounted() {
      this.checkWidth();
      this.$bus.$on('resizeWindow', this.checkWidth);
    },
    methods: {
      checkWidth() {
        this.viewWidth = window.innerWidth;
      },
      onClick(product) {
        // if (!product) {
        //   return;
        // }

        if (this.item?.itype?.includes('vod')) {
          // const path = this.$route.path !== '/' ? this.$route.path : '';
          // this.$bus.$emit('togglePopupVOD', {
          //   item: {
          //     ...this.item,
          //     // ...(!newItem.share && {
          //     //   share: `${path}/v/${newItem.slug}/`,
          //     // }),
          //   },
          //   options: {
          //     startPosition: (product?.time_in || 0) / 1000,
          //   },
          // });
          // this.$bus.$emit('headerHide');

          this.$router.push(`/s/${this.item.storefront?.slug}/v/${this.item.slug || this.item.id}`);
          // this.$router.push(`/v/${this.item.slug || this.item.id}`);
        }
      },
      hidePreview() {
        this.$refs.itemPreview?.stopPreview();
        this.$store.dispatch('vodPreview/hide');
      },
      startVideo() {
        if (this.$refs.itemPreview.canPlay()) {
          this.$refs.itemPreview.runPreview();
          this.$refs.itemPreview.getFeaturedProducts();
        }
      },
    },
  };
</script>

<style lang="scss">
  .vod-preview {
    &.large {
      .item {
        &.horizontal {
          .item-image {
            height: 355px !important;
            @media(max-width: 1400px) {
              height: 400px !important;
            }
          }
        }
      }
    }

    &.medium {
      .item {
        &.horizontal {
          .item-image {
            height: 250px !important;
            @media(max-width: 1450px) {
              height: 260px !important;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .vod-preview {
    position: absolute;
    min-height: 196px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.25);
    z-index: 301;
    border-radius: 10px;

    &::v-deep .item {
      max-width: 100%;

      &.horizontal {
        .item-image {
          height: 196px;
          background-color: black;
          border-radius: 10px 10px 0 0;
        }

        video {
          display: block;
          width: auto;
        }

        .item-title {
          padding: 0 15px;
          background-color: #fff;
        }

        .featured-products {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  .vod-preview-enter-active {
    animation: preview-show 0.5s;
  }

  .vod-preview-leave-active {
    animation: preview-show 0.2s reverse;
  }

  @keyframes preview-show {
    0% {
      opacity: 0;
      transform: scale(0.85);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
