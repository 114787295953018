<template>
  <form class="auth-form pt-2">
    <div v-if="messages.length" class="row justify-content-center">
      <h5
        v-for="(mess, index) in messages"
        :key="index"
        class="auth-message text-center mb-4"
        :class="'message-' + mess.status"
        v-html="mess.text"
      />
    </div>
    <template v-if="!messObj">
      <div class="form-group auth-form-group auth-email">
        <input
          id="email"
          v-model="data.email"
          required
          type="email"
          :placeholder="'Your email'"
          class="form-control"
          :class="{ 'is-invalid': validator.email }"
        >
        <span v-if="validator.email" class="invalid-feedback">
          {{ validator.email[0] }}
        </span>
      </div>
      <div class="flex-nowrap justify-content-center pt-1">
        <button
          type="button"
          class="btn send-button rounded"
          :disabled="wait"
          @click="submitForm()"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{ path: 'FORGOT_PASS.SEND_BTN' }"/>
          </span>
        </button>
      </div>
    </template>
    <template v-else-if="!isInvalidToken">
      <div class="form-wrap">
        <div class="form-group auth-form-group auth-form-pass form-input form-input-auth">
          <input
            id="new_password"
            v-model="data.password"
            type="password"
            name="new_password"
            :disabled="wait"
            autocomplete="new-password"
            class="form-control"
            required="required"
            minlength="6"
            @focus="toggleLabelUp('new_password')"
            @blur="toggleLabelDown('new_password')"
          >
          <label for="new_password" :class="{'float-label': togglePassword}" class="form-group__label">
            <span v-t="{ path: 'FORGOT_PASS.NEW_PASSWORD' }"/>
          </label>
        </div>
      </div>
      <div class="form-wrap">
        <div class="form-group auth-form-group auth-form-pass form-input form-input-auth">
          <label for="confirm_password" :class="{'float-label': toggleConfirmPassword}" class="form-group__label">
            <span v-t="{ path: 'FORGOT_PASS.REPEAT_NEW_PASS' }"/>
          </label>
          <input
            id="confirm_password"
            v-model="data.confirm_password"
            type="password"
            name="confirm_password"
            :disabled="wait"
            autocomplete="new-password"
            class="form-control"
            :class="{ 'is-invalid': validator.confirm_password }"
            required="required"
            minlength="6"
            @focus="toggleLabelUp('confirm_password')"
            @blur="toggleLabelDown('confirm_password')"
          >
          <span v-if="validator.password" class="invalid-feedback">{{
            validator.password[0]
          }}</span>
        </div>
      </div>
      <div class="flex-nowrap justify-content-center pt-1">
        <button
          type="button"
          class="btn send-button rounded"
          :disabled="wait"
          @click="changePass"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{ path: 'FORGOT_PASS.CREATE_PASS' }"/>
          </span>
        </button>
      </div>
    </template>
  </form>
</template>
<script>
  import Auth from 'src/service/authService';
  import vClickOutside from 'v-click-outside';
  import { amsClient } from '@/service/ams';
  import { doFetch } from 'vimmi-web-utils/esm/doFetch';
  import { isActiveFeature } from '@/plugins/feature-toggle';

  export default {
    name: 'ForgotBlock',
    components: {},
    props: {
      messObj: {
        type: Object,
        default: () => ({}),
      },
      accessData: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        wait: false,
        timeToShowMessage: 4 * 1000,
        data: {
          email: '',
          password: '',
          confirm_password: '',
        },
        messages: [],
        fix18n: 0,
        isInvalidToken: false,
        validator: {},
        toggleConfirmPassword: false,
        togglePassword: false,
      };
    },
    watch: {
      messObj: {
        deep: true,
        immediate: true,
        handler: function (newVal, oldVal) {
          this.$nextTick(() => {
            if (newVal) {
              this.messages.splice(0, 1, {
                status: 'title',
                text: newVal?.text ? newVal.text : '',
              });
              if (newVal?.status === 'error') this.isInvalidToken = true;
            }
          });
        },
      },
    },
    mounted() {
      this.fix18n = this.$i18n;
      this.validator = {};
    },
    methods: {
      toggleLabelUp(data) {
        if(data === 'new_password' && (this.data.password === '')) this.togglePassword = true
        if(data === 'confirm_password' && (this.data.confirm_password  === '')) this.toggleConfirmPassword = true
      },
      toggleLabelDown(data){
        if (data === 'new_password' && (this.data.password === '')) {
          this.togglePassword = false
          this.toggleConfirmPassword = false
        }

        if (data === 'confirm_password' && (this.data.confirm_password === '')) {
          this.toggleConfirmPassword = false
          this.togglePassword = false
        }
      },
      changePass() {
        if (
          !this.data.password ||
          this.data.password.length < 6 ||
          !this.data.confirm_password ||
          this.data.confirm_password.length < 6
        ) {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t(
              'REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS',
            ),
          });
          return;
        } else if (this.data.confirm_password !== this.data.password) {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t('REGISTER_FORM.PASSWORD_NOT_EQUAL'),
          });
          return;
        }
        if (this.accessData) {
          this.wait = true;
          amsClient.account
            .verifyToken(
              this.accessData.pk,
              this.accessData.token,
              true,
              this.data.password,
              this.data.confirm_password,
            )
            .then((res) => {
              // this.$bus.$emit('toggleAuthPopup', {
              //   component: 'login',
              //   force: true,
              //   message: this.translateVal('FORGOT_PASS.SUCCESS_CHANGED_PASS'),
              // });
              this.wait = false;
              this.close();
              this.$emit('togglePasswordPopup');
            })
            .catch((error) => {
              this.showMessage({
                status: 'error',
                text: error.message,
              });
            });
        }
      },
      translateVal(val) {
        return this.fix18n.t(val);
      },
      close() {
        if (this.waiting) {
          return;
        }
        if (isActiveFeature('webview_feature')) {
          location.href = 'https://callback_closeme.html/';
        } else {
          this.$emit('update:isActive', false);
          // this.$body.closeModal();
        }
      },
      submitForm: function () {
        if (!this.data.email) {
          this.showMessage({
            status: 'warning',
            text: this.translateVal('REGISTER_FORM.CORRECT_MAIL_ADDRESS'),
          });
          return;
        }
        this.wait = true;
        amsClient.account
          .resendRegisterConfirm(this.data.email, true)
          .then((response) => {
            this.close();
            this.$emit('toggleEmailPopup');
          })
          .catch((error) => {
            console.dir(error);
            this.showMessage(
              {
                status: 'error',
                text: error.message,
              },
              5000,
            );
            this.wait = false;
          });
      },
      showMessage(messageData, time, callback = null) {
        this.messages = this.messages.filter(
          (m) => m.status !== 'error' && m.status !== 'warning',
        );
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          if (this.messages.length > 1) {
            this.messages.splice(pos, 1);
            if (callback) callback();
          }
        }, time || this.timeToShowMessage);
      },
    },
  };
</script>

<style lang="scss" scoped>
.send-button {
  width: 100%;
  height: 50px;
  background-color: $main-color;
  font-weight: 500;
  color: white;
}
.forgot-popup {
  color: #333953;
}
</style>
