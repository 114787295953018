export default `mutation locationEdit($id: ID!, $input: LocationEditInput!) {
  locationEdit(id: $id, input: $input) {
    location {
      id
      name
      address {
        address1
        address2
        city
        country
        countryCode
        zip
        formatted
      }
      metafields(first: 2, namespace: "shoprz") {
        edges {
          node {
            key
            value
            id
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}`;
