export default (item, link = null) => {
  if (!item) {
    return '/';
  }
  if (item.itype === 'screen_settings') {
    return '/set';
  }
  if (item.itype === 'item_epg_shop_event' && item?.is_live) {
    return `/s/${item.storefront?.slug}`;
  }
  if (item.itype === 'item_epg_shop_event') {
    // return `/s/${item.creator?.slug || item.slug || ''}`;
    return item.seo_link || `/s/${item.creator?.slug || item.slug || ''}`;
  }
  if (item.itype === 'item_mov_vod_shoprz') {
    // return `/s/${item.creator?.slug || item.slug || ''}`;

    // return `/v/${item.slug || item.id}`;
    return `/s/${item.storefront?.slug}/v/${item.slug || item.id}`;
  }
  if (item.itype === 'screen_tv') {
    return '/live/';
  }
  if (['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1) {
    return `/live/?vid=${item.id}`;
  }
  if (item.itype === 'item_app') {
    return item.dl_web || item.schema_web || item.link;
  }
  if (['item_mov_episode', 'section_season'].indexOf(item.itype) > -1) {
    const type = item.itype === 'item_mov_episode' ? 'series' : 'season';
    return `/serial/${type}/${item.id}`;
  }
  if (['screen_shoprz', 'event_host_admin'].indexOf(item.itype) > -1) {
    return item.slug === 'home' ? '/' : `/s/${item.slug || item.id}`;
  }
  if (item.itype === 'screen_shoprz_page') {
    return item.slug === 'home' ? '/' : `/s/${item.slug || item.id}`;
  }
  return item.link || '';
};
