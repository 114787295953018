<script>
  import isExternalLink from '@/utils/isExternalLink.js';

  export default {
    name: 'LinkWrapper',
    functional: true,
    props: {
      link: {
        type: String,
        default: null,
        required: true,
      },
      item: {
        type: Object,
        default: null,
      },
      isVod: {
        type: Boolean,
        default: false,
      },
    },
    render(createElement, context) {
      let link = context.props.link;
      const itype = context.props.item?.itype;

      if (
        !link ||
        (context.props.item &&
          (itype === 'shop_product' ||
            itype === 'item_mov_vod_shoprz' ||
            context.props.isVod))
      ) {
        return createElement(
          'div',
          Object.assign(context.data, {
            on: context.listeners,
          }),
          context.children,
        );
      } else if (isExternalLink(context.props.link)) {
        let attrs = Object.assign(
          {
            href: context.props.link,
            target: 'blank',
          },
          context.data.attrs || {},
        );
        return createElement(
          'a',
          Object.assign(context.data, {
            attrs: attrs,
            on: context.listeners,
          }),
          context.children,
        );
      } else {
        let attrs = Object.assign(
          {
            to: context.props.link,
          },
          context.data.attrs || {},
        );
        return createElement(
          'router-link',
          Object.assign(context.data, {
            attrs: attrs,
            nativeOn: context.listeners,
          }),
          context.children,
        );
      }
    },
  };
</script>
