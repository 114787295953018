<template>
  <div class="total" :class="{ 'sp-desktop': !isPopup }">
    <h3 class="total-title line-container">
      <span>Total</span>

      <span>${{ parseFloat(price.products).toFixed(2) }}</span>
    </h3>

    <hr />

    <!-- <div class="total-item products line-container">
      <span>Products</span>
      <span>${{ formatPrice(Number(price.products).toFixed(2)) }}</span>
    </div> -->

    <div class="total-item taxes line-container">
      <span>Taxes and shipping calculated at checkout</span>
    </div>

    <div
      class="total-button btn"
      :class="{ disable }"
      @click="handleGoToCheckout"
    >
      Checkout
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CartTotal',
    props: {
      price: {
        type: Object,
        default: () => ({
          products: 0,
          shipping: 0,
        }),
        required: true,
      },
      checkoutUrl: {
        type: String,
        default: '',
      },
      cartId: {
        type: String,
        default: '',
      },
      isPopup: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      disable() {
        return !this.$store.getters['checkout/getCartItemsCount'];
      },
    },

    methods: {
      handleGoToCheckout() {
        this.$emit('open:checkout');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .total {
    background-color: #f8f8fb;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    height: fit-content;
    line-height: 25/22 * 100%;
    padding: 20px;

    .line-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .taxes {
      font-size: 0.75rem;
      color: #8a8a8a;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-item {
      font-size: 15px;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    &-button {
      background-color: #21a66e;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 60px;
      line-height: 19px;
      width: 100%;

      &.disable {
        background-color: rgba($color: grey, $alpha: 0.5);
        pointer-events: none;
      }
    }

    &.sp-desktop {
      .total-title {
        @media screen and (min-width: 1024px) {
          font-size: 22px;
        }
      }
    }
  }
</style>
