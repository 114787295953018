export default [
  {
    name: 'channels',
    path: '/channels',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Channels',
    },
  },
  {
    name: 'departments',
    path: '/departments',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Departments',
    },
  },
  {
    name: 'upcoming',
    path: '/upcoming',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Upcoming',
    },
  },
  {
    name: 'uploads',
    path: '/uploads',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Uploads',
    },
  },
  {
    name: 'recently-watched-videos',
    path: '/recently-watched-videos',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Recently Watched',
    },
  },
  {
    name: 'popular-videos',
    path: '/popular-videos',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Most Popular Videos',
    },
  },
  {
    name: 'recently-watched-products',
    path: '/recently-watched-products',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'Recently Watched Products',
    },
  },
  {
    name: 'new-arrivals',
    path: '/new-arrivals',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'New Arrivals',
    },
  },
  {
    name: 'onair',
    path: '/onair',
    component: () => import('./auto-segments.view.vue'),
    meta: {
      title: 'On Air',
    },
  },
];
