<template>
  <div class="sp-details">
    <ul v-if="cost" class="sp-details--list">
      <li class="sp-details--item header">
        <p class="sp-name">Order Summary</p>
      </li>

      <li class="sp-details--item">
        <p class="sp-name">Cart subtotal</p>
        <span class="sp-value">
          ${{
            cost.subtotalAmount ? formatPrice(cost.subtotalAmount.amount) : '0.00'
          }}
        </span>
      </li>

      <li class="sp-details--item">
        <p class="sp-name">Estimated tax</p>
        <span class="sp-value">
          $
          {{
            cost.totalTaxAmount ? formatPrice(cost.totalTaxAmount.amount) : '0.00'
          }}
        </span>
      </li>

      <li class="sp-details--item">
        <p class="sp-name">Shipping</p>
        <span class="sp-value">
          ${{
            cost.totalShippingAmount ? formatPrice(cost.totalShippingAmount.amount) : '0.00'
          }}
        </span>
      </li>

      <li class="sp-details--item">
        <p class="sp-name">Discount</p>
        <span class="sp-value">- $0.00</span>
      </li>

      <li class="sp-details--item footer">
        <p class="sp-name">Order total</p>
        <span class="sp-value">
          ${{ cost.totalAmount ? formatPrice(cost.totalAmount.amount) : '0.00' }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'OrderDetails',

    props: {
      cost: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      formatPrice(price) {
        return parseFloat(price).toFixed(2);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-details {
    &--list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &--item {
      padding: 0;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sp-name {
        padding: 0;
        margin: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #626c83;
      }

      .sp-value {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #626c83;
      }

      &.header {
        margin-bottom: 20px;

        .sp-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #343434;
        }
      }

      &.footer {
        margin-bottom: 0;

        .sp-name,
        .sp-value {
          font-weight: 500;
          color: #343434;
        }
      }
    }
  }
</style>
