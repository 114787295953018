const state = () => ({
  menuList: [],
  screenType: '',
});

const getters = {
  getMenuList(state) {
    return state.menuList;
  },

  getScreenType(state) {
    return state.screenType;
  },

  isShopMenu(state) {
    return state.screenType === 'shop_menu';
  },
};

const mutations = {
  setMenuList(state, list) {
    state.menuList = list;
  },

  setScreenType(state, type) {
    state.screenType = type;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
