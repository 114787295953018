import { render, staticRenderFns } from "./order-summary.component.vue?vue&type=template&id=d89c0976&scoped=true"
import script from "./order-summary.component.vue?vue&type=script&lang=js"
export * from "./order-summary.component.vue?vue&type=script&lang=js"
import style0 from "./order-summary.component.vue?vue&type=style&index=0&id=d89c0976&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89c0976",
  null
  
)

export default component.exports