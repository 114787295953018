import cartApiShared from '../../api/cart/cart.api';

class CartService {
  /**
   * @param {String} cartId:
   */

  async fetchCartById(cartId) {
    const response = await cartApiShared.getCartById(cartId);

    return response;
  }

  async createCartForGuest() {
    const response = await cartApiShared.createCart();

    const { cart } = response.cartCreate;

    return cart;
  }

  /**
   * @param {String} userEmail:
   * @param {String} token:
   */
  async createCartForAuthUser(userEmail, token) {
    const response = await cartApiShared.createAuthCart(token, userEmail);

    const { cart } = response.cartCreate;

    return cart;
  }

  /**
   * @param {Object} payload
   * @param {String} payload.cartId
   * @param {String} payload.itemId
   * @param {String} payload.store
   */
  async addItemToCart(payload) {
    const response = await cartApiShared.addItemToCart(payload);

    return response;
  }

  /**
   * @param {Object} payload
   * @param {String} payload.cartId
   * @param {Array} payload.items
   */
  async addALotOfItemsToCart(payload) {
    const response = await cartApiShared.addALotOfItemsToCart(payload);

    return response;
  }
}

export default CartService;
