<template>
  <div class="product" :class="{ 'sp-desktop': !isPopup }">
    <div v-if="isSoldOut" class="error">SOLD OUT</div>

    <div class="product-picture">
      <img
        v-if="product.node.merchandise.image"
        :src="product.node.merchandise.image.url"
        :alt="product.node.merchandise.title"
      />
      <div class="product-picture__no-image">
        <div>Image not set</div>
      </div>
    </div>

    <div class="product-description">
      <router-link :to="`/product/${productHandle}`">
        <h3 class="title" :title="productTitle">
          {{ productTitle }}
        </h3>
      </router-link>

      <p class="subtitle" :title="productSubtitle">
        {{ productSubtitle }}
      </p>

      <span class="price">
        ${{ productPrice }}
      </span>
    </div>

    <b-dropdown
      v-if="isMobile"
      id="dropdown-left"
      variant="link"
      class="product-actions"
      no-caret
    >
      <template #button-content>
        <svg
          width="4"
          height="18"
          viewBox="0 0 4 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4C2.39556 4 2.78224 3.8827 3.11114 3.66294C3.44004 3.44318 3.69639 3.13082 3.84776 2.76537C3.99914 2.39992 4.03874 1.99778 3.96157 1.60982C3.8844 1.22186 3.69392 0.865492 3.41421 0.585787C3.13451 0.306082 2.77814 0.115601 2.39018 0.0384303C2.00222 -0.0387401 1.60009 0.000866562 1.23463 0.152242C0.869182 0.303617 0.556825 0.559962 0.337062 0.88886C0.117299 1.21776 1.07779e-06 1.60444 1.07779e-06 2C1.07779e-06 2.53043 0.210715 3.03914 0.585788 3.41421C0.960861 3.78929 1.46957 4 2 4ZM2 14C1.60444 14 1.21776 14.1173 0.888861 14.3371C0.559963 14.5568 0.303617 14.8692 0.152242 15.2346C0.000866562 15.6001 -0.0387401 16.0022 0.0384303 16.3902C0.115601 16.7781 0.306083 17.1345 0.585788 17.4142C0.865493 17.6939 1.22186 17.8844 1.60982 17.9616C1.99778 18.0387 2.39992 17.9991 2.76537 17.8478C3.13082 17.6964 3.44318 17.44 3.66294 17.1111C3.8827 16.7822 4 16.3956 4 16C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14ZM2 7C1.60444 7 1.21776 7.1173 0.888861 7.33706C0.559963 7.55682 0.303617 7.86918 0.152242 8.23463C0.000866562 8.60009 -0.0387401 9.00222 0.0384303 9.39018C0.115601 9.77814 0.306083 10.1345 0.585788 10.4142C0.865493 10.6939 1.22186 10.8844 1.60982 10.9616C1.99778 11.0387 2.39992 10.9991 2.76537 10.8478C3.13082 10.6964 3.44318 10.44 3.66294 10.1111C3.8827 9.78224 4 9.39556 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7Z"
            fill="#343434"
          />
        </svg>
      </template>
      <b-dropdown-item @click="handleWishlist">
        {{ addRemoveWishlistText }}
      </b-dropdown-item>
      <b-dropdown-item @click="handleRemove">Remove</b-dropdown-item>
    </b-dropdown>

    <div class="product-count">
      <div class="counter">
        <span
          class="counter-btn minus"
          :class="{ disable: disable || disableMinusButton }"
          @click="handleDecreaseCount"
        >
          -
        </span>

        <input
          :value="quantity"
          :disabled="isSoldOut"
          type="number"
          class="quantity-input"
          @input="handleInputChangeQuantity"
          @blur="handleBlurChangeQuantity"
        />

        <span
          class="counter-btn plus"
          :class="{ disable: disable || disablePlusButton }"
          @click="handleIncreaseCount"
        >
          +
        </span>
      </div>
      <div class="total">${{ parseFloat(total).toFixed(2) }}</div>
    </div>

    <div v-if="!isMobile" class="product-actions">
      <span @click="handleWishlist">
        <img
          :src="heartImg"
          class="product__wishlist-in-title-image"
          alt="Like"
        />
        <!--        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5413 1.58905C13.6931 0.690292 12.5686 0.141742 11.3712 0.0424872C10.1737 -0.0567676 8.98209 0.299817 8.01163 1.04783C6.9935 0.247532 5.72624 -0.115362 4.46507 0.0322272C3.20389 0.179816 2.04247 0.826925 1.21468 1.84324C0.386899 2.85956 -0.0457584 4.16959 0.0038373 5.50953C0.053433 6.84947 0.581598 8.11978 1.48197 9.06466L6.45124 14.3246C6.86736 14.7574 7.42779 15 8.01163 15C8.59547 15 9.15591 14.7574 9.57203 14.3246L14.5413 9.06466C15.4756 8.07124 16 6.72749 16 5.32686C16 3.92622 15.4756 2.58247 14.5413 1.58905ZM13.413 7.89765L8.44374 13.1492C8.38719 13.2095 8.31989 13.2574 8.24572 13.2901C8.17156 13.3228 8.09199 13.3396 8.01163 13.3396C7.93127 13.3396 7.85171 13.3228 7.77754 13.2901C7.70337 13.2574 7.63607 13.2095 7.57952 13.1492L2.61026 7.87228C1.98271 7.19436 1.6313 6.28369 1.6313 5.33531C1.6313 4.38694 1.98271 3.47627 2.61026 2.79834C3.24975 2.13111 4.11222 1.75697 5.01087 1.75697C5.90952 1.75697 6.77199 2.13111 7.41148 2.79834C7.48587 2.8776 7.57437 2.94051 7.67188 2.98345C7.7694 3.02638 7.87399 3.04848 7.97962 3.04848C8.08526 3.04848 8.18985 3.02638 8.28736 2.98345C8.38488 2.94051 8.47338 2.8776 8.54777 2.79834C9.18726 2.13111 10.0497 1.75697 10.9484 1.75697C11.847 1.75697 12.7095 2.13111 13.349 2.79834C13.9852 3.46738 14.3481 4.37312 14.3601 5.32152C14.372 6.26992 14.0321 7.18555 13.413 7.87228V7.89765Z"
            fill="#343434"
          />
        </svg>-->
      </span>
      <span @click="handleRemove">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.248322 0.248323C0.579419 -0.0827742 1.11623 -0.0827742 1.44733 0.248322L12.7517 11.5527C13.0828 11.8838 13.0828 12.4206 12.7517 12.7517C12.4206 13.0828 11.8838 13.0828 11.5527 12.7517L0.248323 1.44733C-0.0827741 1.11623 -0.0827741 0.579419 0.248322 0.248323Z"
            fill="#343434"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.7517 0.248323C13.0828 0.579419 13.0828 1.11623 12.7517 1.44733L1.44733 12.7517C1.11623 13.0828 0.579419 13.0828 0.248322 12.7517C-0.0827741 12.4206 -0.0827741 11.8838 0.248323 11.5527L11.5527 0.248322C11.8838 -0.0827742 12.4206 -0.0827742 12.7517 0.248323Z"
            fill="#343434"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import { device } from 'vimmi-web-utils/cjs/device';
  import { mapGetters } from 'vuex';
  import Auth from '@/service/authService';
  import WishlistService from '@/store/wishlist';
  import addedHeartImg from '@/assets/icons/added-to-wishlist-icon.svg';
  import addHeartImg from '@/assets/icons/add-to-wishlist-icon.svg';

  export default {
    name: 'CartItemComponent',

    props: {
      product: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      disable: {
        type: Boolean,
        default: false,
      },
      isPopup: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        // need remove oldId value after normal favorite implementation
        oldId: null,
        quantity: this.product.node.quantity,
        timeout: null,
      };
    },

    computed: {
      ...mapGetters({
        getList: 'wishlist/getList',
      }),
      isInWishList() {
        return Object.keys(this.getList).includes(this.oldId);
      },
      heartImg() {
        return this.isInWishList ? addedHeartImg : addHeartImg;
      },
      addRemoveWishlistText() {
        return this.isInWishList ? 'Remove from wishlist' : 'Add to wishlist';
      },
      total() {
        return +this.productPrice * +this.quantity;
      },
      productPrice() {
        const productAttributes = this.product.node.attributes || [];
        const customPriceAttribute = productAttributes.find((a) => a.key === '__customPrice' && !!a.value);

        if (customPriceAttribute) {
          return customPriceAttribute.value;
        }

        return parseFloat(this.product.node.merchandise.price.amount).toFixed(2);
      },
      isMobile() {
        return this.isPopup || device.isMobileSize();
      },
      productSubtitle() {
        const subtitle = this.product.node.merchandise.title
          .split(' / ')
          .join(', ');

        return subtitle !== 'Default Title' ? subtitle : '';
      },
      productTitle() {
        const title = this.product.node.merchandise.product.title;

        return title;
      },
      productId() {
        const parseId = this.product.node.merchandise.product.id.split('/');
        const id = parseId[parseId.length - 1];

        return id;
      },
      productHandle() {
        return this.product.node.merchandise.product.handle;
      },
      quantityAvailable() {
        return this.product.node.merchandise.quantityAvailable;
      },
      infinityQuantity() {
        return this.quantityAvailable <= 0;
      },
      quantityAdded() {
        return this.product.node.quantity;
      },
      isSoldOut() {
        return this.quantityAdded === 0 && this.infinityQuantity;
      },
      disablePlusButton() {
        if (this.isSoldOut) {
          return true;
        }

        if (this.infinityQuantity) {
          return false;
        }

        return this.quantity >= this.quantityAvailable;
      },
      disableMinusButton() {
        return this.quantity <= 1 || this.isSoldOut;
      },
    },

    async mounted() {
      const productId = this.parseProductId(
        this.product.node.merchandise.product.id,
      );

      const { head } = await amsClient.callAms(`/shoprzapi/product/${productId}/?by=rid`);

      this.oldId = head.id;
    },

    methods: {
      async addRemoveItemToWishlist() {},
      parseProductId(id) {
        const array = id.split('/');

        return array[array.length - 1];
      },
      handleDecreaseCount() {
        if (this.disable || this.disableMinusButton) {
          return;
        }

        clearTimeout(this.timeout);
        this.timeout = null;

        this.quantity = this.quantity - 1;

        this.timeout = setTimeout(() => {
          this.$emit('change:quantity', this.product, this.quantity);

          clearTimeout(this.timeout);
        }, 400);
      },
      handleIncreaseCount() {
        if (this.disable || this.disablePlusButton) {
          return;
        }

        clearTimeout(this.timeout);
        this.timeout = null;

        this.quantity = this.quantity + 1;

        this.timeout = setTimeout(() => {
          this.$emit('change:quantity', this.product, this.quantity);

          clearTimeout(this.timeout);
        }, 400);
      },
      handleInputChangeQuantity({ target }) {
        if (target.value === '') {
          clearTimeout(this.timeout);
          this.timeout = null;
          return;
        }

        clearTimeout(this.timeout);
        this.timeout = null;

        this.quantity = +target.value;

        if (this.infinityQuantity) {
          this.timeout = setTimeout(() => {
            this.quantity = this.quantity || 1;

            this.$emit('change:quantity', this.product, this.quantity);

            clearTimeout(this.timeout);
          }, 800);

          return;
        }

        if (this.quantity >= this.quantityAvailable) {
          this.quantity = this.quantityAvailable;
        }

        if (this.quantity < 0) {
          this.quantity = 1;
        }

        this.timeout = setTimeout(() => {
          // this.quantity = this.quantity || 1;

          this.$emit('change:quantity', this.product, this.quantity);

          clearTimeout(this.timeout);
        }, 800);
      },
      handleBlurChangeQuantity({ target }) {
        clearTimeout(this.timeout);
        this.timeout = null;

        if (target.value === '') {
          this.quantity = 1;
        } else {
          this.quantity = +target.value;
        }

        if (this.infinityQuantity) {
          this.$emit('change:quantity', this.product, this.quantity);

          return;
        }

        if (this.quantity > this.quantityAvailable) {
          this.quantity = this.quantityAvailable;
        }

        if (this.quantity < 0) {
          this.quantity = 1;
        }

        this.quantity = this.quantity || 1;

        this.$emit('change:quantity', this.product, this.quantity);
      },
      async handleWishlist() {
        if (Auth.isGuest()) {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            forceCloseBtn: true,
            center: false,
            force: true,
          });

          return;
        }

        if (this.isInWishList) {
          await WishlistService.removeFromWishlist(this.oldId);
        } else {
          // todo: send stats
          await WishlistService.addToWishlist(this.oldId);
        }
        // this.$emit('like', this.product);
      },
      handleRemove() {
        this.$emit('remove', {
          product: this.product,
          quantity: this.quantity,
        });
      },
    },
  };
</script>

<style lang="scss">
  .product-actions {
    .btn.dropdown-toggle.btn-link.dropdown-toggle-no-caret {
      padding: 5px;
    }

    .dropdown-menu.show {
      transform: translate3d(-100%, 35px, 0px) !important;
      top: 0px !important;
      left: 20px !important;
    }

    .dropdown-item {
      font-weight: 400;
    }
  }
</style>

<style lang="scss" scoped>
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  .error {
    color: #f02f30;
    min-width: 75px;
  }

  .product {
    column-gap: 14px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &-picture {
      flex: 0 0 66px;
      border-radius: 5px;
      height: 66px;
      overflow: hidden;
      position: relative;
      width: 66px;

      img {
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      &__no-image {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b7c1d6;
        font-size: small;
      }
    }

    &-description {
      font-family: 'Roboto';
      font-style: normal;
      color: #343434;
      flex: 1;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
        color: #343434;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #b7c1d6;
        // display: block;
        // height: 16px;
        margin-bottom: 15px;
      }

      .price {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      column-gap: 15px;
      font-family: 'Roboto';
      font-style: normal;
      color: #343434;
      width: 100%;

      border-top: 1px solid #f0f1f6;
      padding-top: 12px;
      margin-top: 18px;

      .quantity-input {
        text-align: center;
        outline: none;

        width: 54px;
        flex: 0 0 54px;
      }

      .counter {
        display: flex;
        background-color: #ffffff;
        border: 1px solid #f0f1f6;
        border-radius: 5px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        height: 44px;
        align-items: center;
        justify-content: space-between;

        max-width: 125px;
        flex: 0 0 115px;

        &-btn {
          display: flex;
          align-self: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          cursor: pointer;

          &.disable {
            color: grey;
            cursor: no-drop;
          }
        }
      }

      .price {
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: auto;

      span {
        cursor: pointer;
      }
    }

    &.sp-desktop {
      @media screen and (min-width: 640px) {
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 24px;
      }

      .product-picture {
        @media screen and (min-width: 640px) {
          height: 100px;
          width: 100px;
          flex: 0 0 100px;
        }
      }

      .product-description {
        @media screen and (min-width: 640px) {
          flex: initial;
        }

        .title {
          @media screen and (min-width: 640px) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        .subtitle {
          @media screen and (min-width: 640px) {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }

      .product-count {
        @media screen and (min-width: 640px) {
          flex: 0 0 170px;
          width: auto;
          margin-left: auto;

          border-top: none;
          padding-top: 0;
          margin-top: 0;
        }

        .quantity-input {
          @media screen and (min-width: 640px) {
            width: 38px;
            flex: initial;
          }
        }

        .counter {
          @media screen and (min-width: 640px) {
            max-width: 110px;
            flex: 0 0 90px;
          }
        }
      }

      .product-actions {
        @media screen and (min-width: 640px) and (max-width: 1025px) {
          order: 1;
        }
      }
    }
  }
</style>
