export default class ReferalService {
  static #instance = null;

  #platform = 'web-portal';

  #affiliate = '';

  #source = 'page';

  static instance() {
    if (!this.#instance) {
      this.#instance = new ReferalService();
    }

    return this.#instance;
  }

  collectRouteData(route) {
    console.log('[ReferalService][collectRouteData]', route);

    if (!route) {
      return;
    }

    if (route.query.platform) {
      this.#platform = route.query.platform;
    }

    if (route.query.aff) {
      this.#affiliate = route.query.aff;
    }

    if (route.query.source) {
      this.#source = route.query.source;
    }
  }

  setSource(source) {
    this.#source = source;
  }

  encode(item) {
    return btoa(JSON.stringify({
      platform: this.#platform,
      affiliate: this.#affiliate,
      source: this.#source,
      item,
    }));
  }
}
