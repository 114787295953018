import makeECommerceRequest from '@/core/http/e-commerce.client';

import getLocationsQuery from './queries/get-locations.query';
import getDeliveryProfilesQuery from './queries/get-delivery-profiles.query';
import addLocationMutation from './mutations/add-location.mutation';
import updateDeliveryProfileMutation from './mutations/update-delivery-profile.mutation';
import updateLocationMutation from './mutations/update-location.mutation';

const getLocations = (afterCursor = null) => makeECommerceRequest(getLocationsQuery, { after: afterCursor });
const createLocation = (payload) => makeECommerceRequest(addLocationMutation, { input: payload });
const getDeliveryProfiles = () => makeECommerceRequest(getDeliveryProfilesQuery);
const updateDeliveryProfile = (payload) => makeECommerceRequest(updateDeliveryProfileMutation, { id: payload.profileId, profile: payload.profile });
const updateLocation = (payload) => makeECommerceRequest(updateLocationMutation, { id: payload.id, input: payload.data });

export default {
  getLocations,
  createLocation,
  updateLocation,
  getDeliveryProfiles,
  updateDeliveryProfile,
};
