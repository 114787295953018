/**
 * Installation procedure
 *
 * @param Vue
 */
const install = function (Vue) {
  Vue.prototype.$bus = new Vue();
};
// Export module
export default { install };
