import createDraftOrderMutation from './mutations/createDraftOrder';
import updateDraftOrderMutation from './mutations/updateDraftOrder';
import paymentTermsTemplatesQuery from './queries/paymentTermsTemplates';
import makeRequestEcommerce from '@/core/http/e-commerce.client';
import makeRequestGateway from '@/core/http/gateway.client';

const getPaymentTerms = () => {
  return makeRequestEcommerce(paymentTermsTemplatesQuery)
}
/**
 * Creates a draft order.
 *
 * @param {Object} input - The input data for creating the draft order.
 * @returns {Promise} - A promise that resolves to the result of the createDraftOrderMutation.
 */
const createDraftOrder = async (input) => {
  const res = await makeRequestEcommerce(createDraftOrderMutation, { input })
  return res?.draftOrderCreate?.draftOrder;
}

/**
 * Updates a draft order.
 *
 * @param {string} id - Draft order id.
 * @param {Object} input - The input data for updating the draft order.
 * @returns {Promise} - A promise that resolves to the result of the createDraftOrderMutation.
 */
const updateDraftOrder = async (id, input) => {
  const res = await makeRequestEcommerce(updateDraftOrderMutation, { id, input })
  return res?.draftOrderUpdate?.draftOrder;
}

/**
 * Completes a draft order by its ID.
 *
 * @param {string} orderId - The ID of the draft order to complete.
 * @param {string} paymentId - The ID of succeed payment.
 * @param {string} accountId - Account's ID
 * @returns {Promise} - A promise that resolves to the result of the completeDraftOrderMutation.
 */
const completeDraftOrder = async (orderId, paymentId, accountId, taxCalculationId) => {
  const path = 'ecommerce/complete-order';
  const body = { orderId, paymentId, accountId, taxCalculationId };

  const res = await makeRequestGateway('post', path, body);

  return res.data?.draftOrderComplete?.draftOrder;
}

const completeUnpaidDraftOrder = async (orderId, accountId, taxCalculationId) => {
  const path = 'ecommerce/complete-unpaid-order';
  const body = { orderId, accountId, taxCalculationId };

  const res = await makeRequestGateway('post', path, body);

  return res.data?.draftOrderComplete?.draftOrder;
}

/**
 * Gets a Multipass token for authentication.
 *
 * @param {string} email - The email address of the user.
 * @param {string} first_name - The first name of the user.
 * @param {string} last_name - The last name of the user.
 * @returns {Promise} - A promise that resolves to the Multipass token.
 */
const getMultiPassToken = async (email, first_name, last_name) => {
  const path = 'multipass';
  const body = { email, first_name, last_name };
  const res = await makeRequestGateway('post', path, body);
  return res.data;
}

/**
 * Creates a payment.
 *
 * @param {string} accountId - The ID of the account for the payment.
 * @param {number} amount - The amount to be paid.
 * @param {number} [applicationFee] - The application fee for the payment (if any).
 * @param {Object} [metadata] - The application fee for the payment (if any).
 * @returns {Promise} - A promise that resolves to the payment result.
 */
const createPayment = async (accountId, amount, applicationFee = 0, metadata = {}) => {
  const path = 'payment/create';
  const body = { accountId, amount, applicationFee, metadata };
  const res = await makeRequestGateway('post', path, body);
  return res.data;
}

const calculateShippingRate = async (payload) => {
  const path = 'shipping/calculate';
  const res = await makeRequestGateway('post', path, payload);
  return res.data;
};

const calculateTax = async (payload) => {
  const path = 'tax/calculations';
  const res = await makeRequestGateway('post', path, payload);
  return res.data;
};

export default {
  createDraftOrder,
  updateDraftOrder,
  completeDraftOrder,
  completeUnpaidDraftOrder,
  getMultiPassToken,
  createPayment,
  getPaymentTerms,
  calculateShippingRate,
  calculateTax,
};