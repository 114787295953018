var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"footer-content"},[_c('div',{staticClass:"footer-content-left",class:{
        'footer-content-left-no-border': _vm.isFullAuth,
      }},[_c('div',{staticClass:"footer-content-left-logo"},[_c('img',{style:(("height: " + (_vm.storeLogo ? '36' : '23') + "px; border-radius: " + (_vm.storeLogo ? '5px' : 0))),attrs:{"src":_vm.logoSrc},on:{"click":_vm.onClickLogo}})]),_c('div',{staticClass:"footer-content-left-menu"},_vm._l((_vm.menu),function(item,index){return _c('div',{key:index,staticClass:"footer-content-left-menu-section"},[_c('div',{staticClass:"footer-content-left-menu-section-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.items),function(item,i){return _c('div',{key:i,class:{
              'footer-content-left-menu-section-item': true,
              'footer-content-left-menu-section-item-disabled': !item.isNotLink && item.link === '#',
            }},[(!item.isNotLink)?_c('router-link',{class:{
                'footer-content-left-menu-section-item-link-disabled': item.link === '#',
              },attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',{staticClass:"footer-bottom-content-left-menu-section-item-link",on:{"click":function () { return item.action(); }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2)}),0),_c('div',{staticClass:"footer-content-left-menu-mobile"},_vm._l((_vm.mobileMenuColumns),function(column,i){return _c('div',{key:i},_vm._l((column),function(menu,index){return _c('div',{key:index,class:{
              'footer-content-left-menu-section': index !== column.length - 1
            }},[_c('div',{staticClass:"footer-content-left-menu-section-title"},[_vm._v(" "+_vm._s(menu.title)+" ")]),_vm._l((menu.items),function(item,i){return _c('div',{key:i,class:{
                'footer-content-left-menu-section-item': true,
                'footer-content-left-menu-section-item-disabled': !item.isNotLink && item.link === '#',
              }},[(!item.isNotLink)?_c('router-link',{class:{
                  'footer-content-left-menu-section-item-link-disabled': item.link === '#',
                },attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',{staticClass:"footer-bottom-content-left-menu-section-item-link",on:{"click":function () { return item.action(); }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2)}),0)}),0)]),_c('div',{staticClass:"footer-content-right"},[(!_vm.isFullAuth)?_c('div',[_c('div',{staticClass:"footer-content-right-auth"},[_c('div',{staticClass:"footer-content-right-auth-title"},[_vm._v(" Get the latest deals and more ")]),_c('div',[_c('button',{staticClass:"footer-content-right-auth-button",on:{"click":_vm.onClickSignIn}},[_vm._v(" Sign in or Create account ")])])])]):_vm._e()])]),_c('div',{staticClass:"footer-bottom-wrapper"},[_c('div',{staticClass:"footer-content footer-bottom-content"},[_c('div',[_vm._v(" © "+_vm._s(_vm.currentYear)+" — "+_vm._s(_vm.storeName)+". All right reserved ● Powered by Vimmi ")]),_c('div',[_c('router-link',{attrs:{"to":{ name: 'terms-and-conditions' }}},[_vm._v(" Terms and Conditions ")]),_vm._v(" ● "),_c('router-link',{attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v(" Privacy Policy ")])],1)]),_c('div',{staticClass:"footer-content footer-bottom-content-mobile"},[_c('div',[_vm._v(" © "+_vm._s(_vm.currentYear)+" — "+_vm._s(_vm.storeName)+". All right reserved ")]),_c('div',[_c('router-link',{attrs:{"to":{ name: 'terms-and-conditions' }}},[_vm._v(" Terms and Conditions ")]),_vm._v(" ● "),_c('router-link',{attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v(" Privacy Policy ")])],1),_c('div',[_vm._v(" Powered by Vimmi ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }