import Config from './config';
import Cookie from '../plugins/cookie';

const logger = function () {
  const components = Config.get('components');

  function defaultHandler() {
    const type = this;
    const args = Array.prototype.slice.call(arguments, 0, arguments.length);
    const newArgs = [];
    newArgs.push('%cPORTAL');
    newArgs.push('background: #183fbe; color: #fff');
    if ((type === 'log' || type === 'info') && args.length && Object.keys(components).indexOf(args[0]) !== -1) {
      const componentName = args.shift();
      const component = components[componentName];
      if (!component.debug && location.href.indexOf('debug=true') === -1 && !Cookie.get('debug')) {
        return;
      }
      newArgs[0] = `${newArgs[0]} %c${componentName}`;
      if (component.color) {
        newArgs.push(`background: ${component.color}; color: #fff`);
      }
    }
    console[type].apply(console, newArgs.concat(args));
  }

  function logPath(component) {
    try {
      throw new Error();
    } catch (e) {
      const params = e.stack.split('\n').slice(1);
      let res = 'PATH execute\n';
      for (let i = 0; i !== params.length; i++) {
        res += `${params[i].replace('    at', '    |->')}\n`;
      }
      defaultHandler.bind(this, component, res)();
    }
  }

  return {
    log: defaultHandler.bind('log'),
    info: defaultHandler.bind('info'),
    error: defaultHandler.bind('error'),
    warn: defaultHandler.bind('warn'),
    dir: defaultHandler.bind('dir'),
    path: logPath.bind('log'),
    debug: defaultHandler.bind('debug'),
  };
};
const install = function (Vue, options) {
  Vue.prototype.$logger = logger();
};

export { install, logger };
