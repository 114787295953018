import makeRequest from 'src/core/http/storefront.client';

// mutations
import updateItemQuantityMutation from './mutations/update-item-quantity.mutation';
import removeItemMutation from './mutations/remove-item.mutation';

/**
 * @param {Object} payload
 * @param {String} payload.cartId
 * @param {String} payload.itemId
 */
const removeCheckoutItem = async (payload = {}) => {
  const response = await makeRequest(removeItemMutation, {
    cartId: payload.cartId,
    lineIds: [payload.itemId],
  });

  return response;
};

/**
 * @param {Object} payload
 * @param {String} payload.cartId
 * @param {String} payload.itemId
 * @param {Number} payload.quantity
 * @param {String} payload.store
 */
const updateQuantitiesItem = async (payload = {}) => {
  const response = await makeRequest(updateItemQuantityMutation, {
    cartId: payload.cartId,
    lines: [
      {
        id: payload.itemId,
        quantity: payload.quantity,
        attributes: [
          ...(payload.store ? [{ key: '__store', value: payload.store }] : []),
          ...(payload.referal ? [{ key: '__ref', value: payload.referal }] : []),
          ...(payload.customPrice ? [{ key: '__customPrice', value: payload.customPrice }] : []),
        ],
      },
    ],
  });

  return response;
};

export default {
  removeCheckoutItem,
  updateQuantitiesItem,
};
