// import Vue from 'vue';
// import eventBus from "src/service/eventBus";
import { vapp } from '../main';
import stripHTML from '../filters/stripHtml';

const canvas = document.createElement('canvas');
const stripTagDiv = document.createElement('div');
canvas.hidden = true;
canvas.width = '1px';
canvas.height = '1px';
const ctx = canvas.getContext('2d');
const DEFAULT_WIDTH_ELEMENT = 200;

function transformText(width, _text, rows, symbolPix) {
  const words = _text.split(' ');
  const _strings = [];
  let _currentIndex = 0;
  words.map((word) => {
    if (_currentIndex + 1 <= rows) {
      if (!_strings[_currentIndex]) {
        _strings[_currentIndex] = word;
      } else if (
        (`${_strings[_currentIndex]} ${word}`).length * symbolPix + 10
        < width - (_currentIndex + 1 === rows ? symbolPix * 4 : 0)
      ) {
        _strings[_currentIndex] = `${_strings[_currentIndex]} ${word}`;
      } else {
        _currentIndex += 1;
        if (_currentIndex + 1 <= rows) {
          _strings[_currentIndex] = word;
        }
      }
    }
  });
  // console.log(_strings);
  return _strings.join(' ');
}

function getFont(styles) {
  let { font } = styles;
  if (!font) {
    font = `${styles.fontWeight
    } ${
      styles.fontStyle
    } ${
      styles.fontVariant
    } ${
      styles.fontSize
    }/${
      styles.lineHeight
    } ${
      styles.fontFamily}`;
  }
  return font;
}

// variant with word wrap
const trimText = (el, bindings) => {
  let text = bindings.value.content;
  stripTagDiv.innerHTML = text || '';
  text = stripTagDiv.innerText.replace('\n', '').replace('\r', '');
  if (text) {
    const styles = getComputedStyle(el);
    const paddingLn = parseInt(styles.paddingLeft) + parseInt(styles.paddingRight);
    const paddingHg = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom);
    const maxHeight = parseInt(styles.maxHeight);
    const height = parseInt(styles.height);
    const isDisplay = styles.display !== 'none';
    // console.log (styles);

    const ht = maxHeight > 0 && !isNaN(maxHeight)
      ? maxHeight
      : height > 0 && isNaN(height)
        ? height
        : el.clientHeight;
    ctx.font = getFont(styles);

    const metrics = ctx.measureText(text);
    const symbolPix = metrics.width / text.length;

    // if (text === 'At the Office Lorem ipsum dolor sit amet 222222222 22222222222') {
    //   console.log(paddingLn, paddingHg, maxHeight, height, symbolPix, metrics.width, el, getFont(styles));
    // }
    let rows = Math.floor((ht - paddingHg) / parseInt(styles.lineHeight));
    rows = rows <= 1 ? 1 : rows;
    // If element is display none or not mounted set default width as DEFAULT_WIDTH_ELEMENT = 200
    const width = isDisplay && el.clientWidth > 0
      ? el.clientWidth - paddingLn
      : DEFAULT_WIDTH_ELEMENT;
    const toSlice = width * rows;

    if (toSlice > metrics.width) {
      el.textContent = transformText(width, text, rows, symbolPix);
    } else {
      const widthPer = (toSlice - rows) / (metrics.width / 100);
      const sliceLn = (text.length / 100) * widthPer;

      let _text = text.substr(0, Math.floor(sliceLn));

      if (_text[_text.length - 1] !== ' ' && text[_text.length] !== ' ') {
        _text = _text.substr(0, _text.lastIndexOf(' '));
      }
      el.textContent = `${transformText(width, _text, rows, symbolPix)}...`;
    }
    el.style.visibility = 'visible';
  }
};

function getIntValue(value, defaultValue = null) {
  const val = parseInt(value);
  return !isNaN(val) ? val : defaultValue;
}

function checkPositive(value, defaultReturn = 0) {
  return value > 0 ? value : defaultReturn;
}

function calculateHeight(styles, defaultHeight = 0) {
  const maxHeight = getIntValue(styles.maxHeight);
  const height = getIntValue(styles.height);
  const ht = maxHeight > 0 ? maxHeight : height > 0 ? height : defaultHeight;

  const paddingTop = getIntValue(styles.paddingTop, 0) + getIntValue(styles.paddingBottom, 0);
  return checkPositive(ht - paddingTop, 0);
}

function calculateWidth(styles, width) {
  const paddingLn = getIntValue(styles.paddingLeft, 0) + getIntValue(styles.paddingRight, 0);
  const wdth = Math.round(checkPositive(width - paddingLn));
  return wdth;
}

function getNormalizeText(text, nativeRow = false) {
  const replaceRow = nativeRow ? ' <br/> ' : '';
  const txtRows = text.replace('\n', replaceRow);
  return txtRows;
}

function spaceIndexOf(text = '', start = 0, end = 0, last = false) {
  if (text && text.length > 0) {
    const txt = text.slice(start, end);
    // console.log(txt, start, end, text.length);
    const regexp = last ? /[\s|-]+(?!.*[\s|-])/gi : /[\s|-]+/i;
    regexp.lastIndex = start;
    const res = txt.search(regexp);
    return res;
  }
  return -1;
}

function getWordWidth(val) {
  // this.ctx.font = this.getFont(styles);
  const metrics = ctx.measureText(val);
  return Math.round(metrics.width);
}

function findStringByWidth(text, width) {
  if (text && text.length > 0) {
    const textWidth = getWordWidth(text);
    const index = Math.min(
      Math.floor((width / textWidth) * text.length),
      text.length,
    );
    const lastSpace = index !== text.length ? spaceIndexOf(text, 0, index + 1, true) : -1;
    // console.log(text);
    // console.log(textWidth, width, index, lastSpace);

    if (lastSpace > -1) {
      const norm = Math.min(lastSpace + 1, text.length - 1);
      const res = text.slice(0, norm);
      if (getWordWidth(res) >= width) {
        return findStringByWidth(res, width);
      }
      return res;
    }
    return text;
  }
  return text;
}

function transformByTextLen(text, rows, width, nativeRow = false) {
  let txtRows = getNormalizeText(text, nativeRow);
  const endSymbolsWidth = getWordWidth(stripHTML('...'));
  const strings = [];
  let textLenght = txtRows.length;
  const rowsIndex = (width * rows) / getWordWidth(txtRows);
  if (rowsIndex < 1) {
    txtRows = txtRows.slice(0, Math.floor((rowsIndex + 0.1) * textLenght));
    textLenght = txtRows.length;
  }
  // console.group(name);
  for (
    let currentStrings = 0;
    currentStrings < rows && textLenght > 0;
    currentStrings++
  ) {
    const isLastRow = !(currentStrings + 1 < rows);
    let str = findStringByWidth(txtRows, width);
    let sliceIndex = Math.min(str.length, txtRows.length);

    // NOTE: if '\n' is first item ignore it
    if (nativeRow && str.indexOf('<br/>') > 0) {
      sliceIndex = str.indexOf('<br/>');
      str = str.slice(0, sliceIndex);
    }

    txtRows = txtRows.slice(sliceIndex);
    strings.push(str);

    if (isLastRow && txtRows.length > 0) {
      str = findStringByWidth(str, width - endSymbolsWidth);
      str += '&hellip;';
      // console.log(width, endSymbolsWidth, width - endSymbolsWidth);
      // console.log(str);
      strings.splice(currentStrings, 1, str);
    }
    textLenght = txtRows.length;
  }
  const res = strings.join('');
  return res;
}

const trimTextLen = (el, bindings) => {
  const text = stripHTML(bindings.value.content); // .replace('\n', ' ').replace('\r', '');
  if (text) {
    const styles = getComputedStyle(el);

    // get Symbol width
    ctx.font = getFont(styles);
    // let endMetrics = this.ctx.measureText('...');
    // // let metrics = this.ctx.measureText(textMeasure);
    // let currentWidth = this.getWordWidth(textMeasure); //metrics.width;
    // let symbolPix = currentWidth / textMeasure.length;

    // Get Rows
    const htElem = calculateHeight(styles, el.clientHeight);
    const lineHeight = getIntValue(styles.lineHeight, 1);
    const rows = Math.max(Math.floor(htElem / lineHeight), 1);

    // Get target Width
    const width = checkPositive(calculateWidth(styles, el.clientWidth), 200);
    // let targetWidth = width * rows;
    el.style.visibility = 'visible';

    el.innerHTML = transformByTextLen(text, rows, width);
    return true;
    // } else {
    //   return this.nativeRow ? text.replace('\n', '<br/>') : text;
    // }
  }
  el.style.visibility = 'visible';
  el.innerHTML = '';
  return true;
};

export default {
  bind: (el, bindings) => {
    if (bindings.value.resize) {
      el.event = trimTextLen.bind(null, el, bindings);
      vapp.$bus.$on('resizeWindow', el.event);
    }
  },
  inserted: (el, bindings) => {
    const text = bindings.value.content;
    // el.innerHTML = text;
    el.style.visibility = 'hidden';
    // trimText(el, bindings)
    setTimeout(trimTextLen, 0, el, bindings);
  },
  update: (el, bindings) => {
    // trimText(el, bindings);
    const text = bindings.value.content;
    // el.innerHTML = text;
    el.style.visibility = 'hidden';
    // trimText(el, bindings)
    setTimeout(trimTextLen, 0, el, bindings);
  },
  unbind: (el, bindings) => {
    if (bindings.value.resize) {
      // $(el).$off('resizeWindow', trimText);
      vapp.$bus.$off('resizeWindow', el.event);
      // console.log('unresize');
    }
  },
};
