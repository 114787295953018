import Vue from 'vue';
import Router from 'vue-router';
import Auth from '@/service/authService';
import { amsClient } from '@/service/ams';

import ProtectedApi from '@/shared/api/protected.api';
import ReferalService from '@/shared/services/referal.service';

import routes from './routes';
import isInIframe from '@/utils/isInIframe';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'Error',
      component: () => import('@/views/ErrorPage/ErrorPage'),
    },
    {
      path: '/portal/callback/:type/:status',
      name: 'Callbacks',
      component: () => import('@/views/CallbacksPage/Callbacks'), // CallbacksContainer,
    },
    {
      path: '/web/:id',
      name: 'Web',
      component: () => import('@/views/WebPage/Web'),
      meta: {
        hideFooter: true,
      },
    },
    {
      path: '/full-screen-vod/:id',
      name: 'FullScreenVod',
      component: () => import('@/views/FullScreenVod/FullScreenVod'),
    },
    {
      path: '/screen/:id',
      name: 'Page',
      component: () => import('@/views/Page/Page'), // PageContainer,
      meta: {
        seoPage: true,
      },
    },
    {
      path: '/p/:id',
      name: 'ChannelShortPage',
      component: () => import('@/views/Page/Page'), // PageContainer,
      meta: {
        creatorPage: true,
        productPage: true,
      },
    },
    {
      path: '/s/:id',
      name: 'ChannelPage',
      component: () => import('@/views/Page/Page'), // PageContainer,
      meta: {
        creatorPage: true,
        seoPage: true,
        pixelAnalitics: true,
      },
    },
    {
      path: '/set',
      name: 'Settings',
      component: () => import('@/views/SettingsPage/Settings'), // SettingsPage,
    },
    {
      path: '/get_section/:id',
      name: 'VideoPageSection',
      component: () => import('@/views/CategoryPage/Category'), // CategoryPage,
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import('@/views/SearchPage/Search'), // SearchContainer,
    },
    {
      path: '/live',
      name: 'Live',
      component: () => import('@/views/LivePage/Live'), // LiveContainer,
    },
    {
      path: '/my-profile',
      name: 'MyProfile',
      component: () => import('@/views/MyProfile/MyProfile'), // MyProfile,
    },
    {
      path: '/my-profile/:param',
      name: 'MyProfileParam',
      component: () => import('@/views/MyProfile/MyProfile'), // MyProfile,
    },
    {
      path: '/coming-soon',
      name: 'ComingSoon',
      component: () => import('@/views/ComingSoon/ComingSoon'), // ComingSoonPage,
    },
    {
      path: '/stores',
      name: 'stores',
      component: () => import('@/views/allStoresPage/allStoresPage'), // allStores,
    },
    {
      path: '/stores/:category',
      name: 'store',
      component: () => import('@/views/allStoresPage/allStoresPage'), // allStores,
    },
    // {
    //   path: '/catalog*',
    //   name: 'CatalogPage',
    //   component: () => import('@/views/CatalogPage/CatalogPage'), // CatalogPage,
    // },
    {
      path: '/wishlist',
      name: 'WishlistPage',
      component: () => import('@/views/WishlistPage/WishlistPage'), // WishlistPage,
    },
    {
      path: '/seller-splash',
      name: 'SellerSplash',
      component: () => import('@/views/SellerSplashPage/SellerSplash'), // SellerSplash,
    },
    ...routes,
    {
      path: '/s/:id/*',
      name: 'ChannelSharePage',
      component: () => import('@/views/Page/Page'), // PageContainer,
      meta: {
        creatorPage: true,
        seoPage: true,
        pixelAnalitics: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {

  ReferalService.instance().collectRouteData(to);

  window.fbq && window.fbq('track', 'PageView');

  // const playingLive = store.getters['dataAreasLive/playingLive'];
  // const isLiveFloating = store.getters['popup/isLiveFloating'];
  // const routerToLive = store.getters['player/routerToLive'];
  // const screenType = store.getters['popup/screenType'];
  //
  // if (!playingLive && !isLiveFloating) {
  //   store.commit('dataAreasLive/setCurrenLive', null);
  //   store.commit('dataAreasLive/setCurrenSecondLive', null);
  // }
  //
  // if (screenType !== 'miniature' && !routerToLive) {
  //   Vue.prototype.$bus.$emit('setMiniplayer');
  // }

  Vue.prototype.$bus.$emit('toggleFullScreenIframe');

  const requiresAuth = to.matched.some((record) =>
    // console.log(record.meta);
    record.meta.requiresAuth);

  const needAuth = requiresAuth && !(Auth.isAuth() && !Auth.isGuest());
  // const showComingSoon = window.location.hostname.includes('demo-shoprz')
  //   || window.location.hostname.includes('dev-shoprz')

  const storeSettings = amsClient.get('conf.store');

  const isFrame = isInIframe();
  const isEnabledComingSoon = !process.env.VUE_APP_DISABLE_COMING_SOON;
  const showComingSoon = !isFrame && isEnabledComingSoon && localStorage.getItem('iepp') !== 'U2gwcGVSWjIz' && !window.location.hostname.includes('poc.demo-shoprz.live');

  const showProtected = !isFrame && (!isEnabledComingSoon || localStorage.getItem('iepp') === 'U2gwcGVSWjIz') && storeSettings?.standalone && storeSettings?.is_protected;

  if (to.path !== '/coming-soon' && showComingSoon) {
    next({
      path: '/coming-soon',
    });
  } else if (showProtected) {
    const password = localStorage.getItem('store-password');

    if (to.path !== '/protected') {
      if (!password) {
        next({
          path: '/protected',
        });
      } else {
        const payload = {
          sitepass: Buffer.from(password, 'base64').toString('utf-8'),
          domain: document.location.origin.split('//')[1],
        };

        const response = await ProtectedApi.checkPassword(payload).catch((error) => ({
          verified: error.verified,
        }));

        if (!response?.verified) {
          next({
            path: '/protected',
          });
        } else if (response?.verified && needAuth) {
          next({ path: '/' });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  } else if (needAuth) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;