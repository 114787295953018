import Vue from 'vue';

import NormalizeImg from './normalizeImg';
import Duration from './duration';
import FormatDate from './formatDate';
import NormalizePrice from './normalizePrice';
// import TruncateOnWord from './truncateOnWord';
import StripHTML from './stripHtml';
import RenderBackgroundImage from './renderBackgroundImage';
import LinkNormalisation from './linkNormalisation';
import ContentLanguage from './contentLanguage';
import ViewsNormalise from './viewsNormalise';

export const normalizeImg = Vue.filter('normalizeImg', NormalizeImg);
export const renderBackgroundImage = Vue.filter('renderBackgroundImage', RenderBackgroundImage);
export const stripHTML = Vue.filter('stripHTML', StripHTML);
export const linkNormalisation = Vue.filter('linkNormalisation', LinkNormalisation);
export const viewsNormalise = Vue.filter('viewsNormalise', ViewsNormalise);
// export let truncateOnWord = Vue.filter('truncateOnWord', TruncateOnWord);
export const formatDate = Vue.filter('formatDate', FormatDate);
export const duration = Vue.filter('duration', Duration);
export const contentLanguage = Vue.filter('contentLanguage', ContentLanguage);
export const normalizePrice = Vue.filter('normalizePrice', NormalizePrice);

export default {
  duration,
  viewsNormalise,
  linkNormalisation,
  contentLanguage,
  // truncateOnWord: truncateOnWord,
  normalizeImg,
  formatDate,
  stripHTML,
  renderBackgroundImage,
  normalizePrice,
};
