import apiService from '../api';

class CartService {
  /**
   * @param {Object} payload
   * @param {String} payload.cartId
   * @param {String} payload.itemId
   */
  async removeCheckoutItem(payload = {}) {
    const response = await apiService.removeCheckoutItem(payload);

    return response;
  }

  /**
   * @param {Object} payload
   * @param {String} payload.cartId
   * @param {String} payload.itemId
   * @param {Number} payload.quantity
   * @param {String} payload.store
   */
  async updateQuantitiesItem(payload = {}) {
    const response = await apiService.updateQuantitiesItem(payload);

    return response;
  }
}

export default CartService;
