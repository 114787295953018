import { amsClient } from '@/service/ams';

/**
 * @param {Object} payload
 * @param {String} payload.sitepass
 * @param {String} payload.domain
 */
const checkPassword = async (payload) => {
  const response = await amsClient.callAms('/check_site_password/', {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return response;
};

export default {
  checkPassword,
};
