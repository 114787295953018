const state = () => ({
  playerCoords: null,
  playerState: null,
  firstLivePlaing: false,
  isFirstEventComponentFloating: false,
  isSecondEventComponentFloating: false,
  routerToLive: false,
  isLivePage: false,
  isFromVideo: false,
  pageForCurrentLive: false,
  pageForSecondLive: false,
  muted: false,
  isLiveData: null,
  isRestore: false,
});

const getters = {
  playerCoords: (state) => state.playerCoords,
  playerState: (state) => state.playerState,
  firstLivePlaing: (state) => state.firstLivePlaing,
  isFirstEventComponentFloating: (state) => state.isFirstEventComponentFloating,
  isSecondEventComponentFloating: (state) => state.isSecondEventComponentFloating,
  routerToLive: (state) => state.routerToLive,
  isLivePage: (state) => state.isLivePage,
  isFromVideo: (state) => state.isFromVideo,
  pageForCurrentLive: (state) => state.pageForCurrentLive,
  pageForSecondLive: (state) => state.pageForSecondLive,
  muted: (state) => state.muted,
  isLiveData: (state) => state.isLiveData,
  isRestore: (state) => state.isRestore,

};
const mutations = {
  /**
  * @param {Object} payload
  * @param {Number} payload.x
  * @param {Number} payload.y
  */
  SET_PLAYER_COORDS(state, payload = null) {
    state.playerCoords = payload;
  },

  /**
  * @param {Boolean} payload
  */
  SET_FIRST_LIVE_PLAING(state, payload = false) {
    state.firstLivePlaing = payload;
  },

  /**
  * @param {Object} payload
  * @param {String} payload.name
  * @param {Boolean} payload.value
  */

  SET_EVENT_COMPONENT_STATE(state, { name, value }) {
    state[name] = value;
  },

  CLEAR_EVENT_COMPONENT_STATE(state) {
    state.isFirstEventComponentFloating = false;
    state.isSecondEventComponentFloating = false;
  },

  /**
  * @param {Boolean} payload
  */
  SET_ROUTER_TO_LIVE(state, payload = false) {
    state.routerToLive = payload;
  },

  /**
   * @param {Boolean} payload
   * @param {Boolean} payload.isLivePage
   *  */
  SET_IS_LIVE_PAGE(state, payload = false) {
    state.isLivePage = payload;
  },
  /**
   * @param {Boolean} payload
   */
  SET_IS_FROM_VIDEO(state, payload = false) {
    state.isFromVideo = payload;
  },
  /**
  * @param {Boolean} payload
  */
  SET_PAGE_FOR_CURRENT_LIVE(state, payload = false) {
    state.pageForCurrentLive = payload;
  },
  /**
  * @param {Boolean} payload
  */
  SET_PAGE_FOR_SECOND_LIVE(state, payload = false) {
    state.pageForSecondLive = payload;
  },
  /**
  * @param {Boolean} payload
  */
  SET_MUTED(state, payload = true) {
    state.muted = payload;
  },

  SET_IS_LIVE_DATA(state, payload = null) {
    state.isLiveData = payload;
  },
  /**
  * @param {Boolean} payload
  */
  SET_RESTORE(state, payload = false) {
    state.isRestore = payload;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
