import { amsClient } from '@/service/ams';

const state = () => ({
  followChannels: [],
  followedStores: [],
});

const getters = {
  isChannelFollowed(state) {
    return (creator) => state.followChannels && state.followChannels.indexOf(creator) !== -1;
  },

  followedStores(state) {
    return [...state.followedStores];
  },
};

const mutations = {
  _setFollowedChannels(state, values) {
    state.followChannels = values;
  },

  _setFollowedStores(state, values) {
    state.followedStores = values;
  },
};

const actions = {
  onSync({
    state, dispatch, getters, commit,
  }) {
    amsClient.callAms('/shoprzapi/user-interaction/subscribe/', {
      cache: false,
    }).then((resp) => {
      commit('_setFollowedChannels', resp.info.creators || []);
    });
  },

  clean({
    state, dispatch, getters, commit,
  }, data) {
    commit('_setFollowedChannels', []);
  },

  toggleFollow({
    state, dispatch, getters, commit,
  }, creator) {
    const channels = [...state.followChannels];
    const pos = channels.indexOf(creator);
    const op = pos !== -1 ? 'stop' : 'start';

    return amsClient.callAms('/shoprzapi/user-interaction/subscribe/', {
      cache: false,
      query: {
        op, creator,
      },
    }).then((res) => {
      if (op === 'start') {
        channels.push(creator);
      } else {
        channels.splice(pos, 1);
      }
      commit('_setFollowedChannels', channels);
      return res;
    });
  },

  setFollowedStores({ commit }, items) {
    commit('_setFollowedStores', items);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
