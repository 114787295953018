<template>
  <div class="cart" :class="{ 'sp-desktop': !isPopup, 'sp-popup': isPopup }">
    <div class="container">
      <h2 class="default-title">Cart</h2>

      <loader v-if="loading" color="#343434" position="relative" class="mt-5" />

      <div v-else class="content content-wrapper">
        <div v-if="products.length" class="cart-list">
          <!-- <div v-if="Object.keys(stores).length" class="cart-list--main">
            <b-form-checkbox
              :checked="checkedAllProducts"
              class="checkbox"
              @change="handleSelectAllProducts"
            />
            <span>Select all Cart items</span>
          </div> -->

          <div
            v-for="(store, key) in stores"
            :key="key"
            class="cart-list--group"
          >
            <div class="cart-list--store">
              <!-- <b-form-checkbox
                :checked="checkedStore(store)"
                class="checkbox"
                @change="handleSelectAllProductsFromStore(store)"
              /> -->
              <router-link class="link" :to="`/s/${store.slug}`">
                <div class="avatar">
                  <img :src="store.avatar" :alt="store.title" />
                </div>
                {{ store.title }}
              </router-link>
            </div>

            <div
              v-for="product of store.products"
              :key="product.id"
              class="cart-list--item"
            >
              <!-- <b-form-checkbox
                :checked="
                  isCheckedProducts.includes(product.node.merchandise.id)
                "
                class="checkbox"
                @change="handleSelectProduct(product)"
              /> -->
              <cart-item
                :is-popup="isPopup"
                :product="product"
                class="item-product"
                @change:quantity="handleChangeQuantity"
                @like="handleLike($event, key)"
                @remove="handleRemove($event, key)"
              />
            </div>

            <div class="cart-list--total">
              Store Total

              <span class="price"> ${{ totalStorePrice(store) }} </span>
            </div>
          </div>
        </div>

        <cart-preview v-else />

        <cart-total
          :is-popup="isPopup"
          :price="amount"
          :checkout-url="checkoutUrl"
          :cart-id="isCartId"
          class="cart-total"
          @open:checkout="handleOpenCheckout"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CartItem from './components/cart-item.components.vue';
  import CartTotal from './components/cart-total.component.vue';
  import CartPreview from './components/cart-preview.component.vue';
  import Loader from 'src/components/loader/loader.vue';
  import CartDataService from './services/cart-data.service';
  import Auth from '@/service/authService';
  import SharedCartDataService from '@/shared/services/cart/cart-data.service';

  const CartService = new CartDataService();
  const sharedCartService = new SharedCartDataService();

  export default {
    name: 'CartContainer',
    components: {
      Loader,
      CartTotal,
      CartItem,
      CartPreview,
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        updateItemLoading: false,
        isCheckedProducts: [],
      };
    },
    computed: {
      isMobile() {
        return this.isPopup || window.innerWidth < 1024;
      },
      isTest() {
        return this.$route.query.test;
      },
      loading() {
        return this.$store.getters['checkout/loading'];
      },
      cost() {
        return this.$store.getters['checkout/getCostDetails'];
      },
      products() {
        return this.$store.getters['checkout/getCartItems'];
      },
      stores() {
        return this.products.reduce(this.replaceStores, {});
      },
      amount() {
        if (this.cost) {
          return this.updateTotalPrice(this.cost);
        }

        return {
          total: 0,
          products: 0,
        };
      },
      checkoutUrl() {
        return this.$store.getters['checkout/getCheckoutUrl'];
      },
      isUser() {
        return this.$store.getters['authenticate/isUser'];
      },
      isCartId() {
        if (!this.isUser.guest) {
          return Auth.get('user.cart');
        }

        return localStorage.getItem('cartId');
      },
      totalStorePrice() {
        return (store) => {
          const total = store.products.reduce((acc, item) => {
            const customPriceAttribute = item.node.attributes.find(
              (attr) => attr.key === '__customPrice',
            );

            const productPrice = customPriceAttribute
              ? customPriceAttribute.value
              : item.node.merchandise.price.amount;

            acc = acc + (+productPrice * item.node.quantity);

            return acc;
          }, 0);

          return parseFloat(total).toFixed(2);
        };
      },
      checkedAllProducts() {
        return this.products.length === this.isCheckedProducts.length;
      },
      checkedStore() {
        return (store) => {
          const checked = [];

          store.products.forEach((product) => {
            const isChecked = this.isCheckedProducts.includes(
              product.node.merchandise.id,
            );

            if (isChecked) {
              checked.push(product.node.merchandise.id);
            }
          });

          return checked.length === store.products.length;
        };
      },
    },

    mounted() {
      this.$stats.send('page_opened', {
        page_type: 'cart',
      });

      this.initCart();

      if(!this.isPopup) {
        this.$bus.$emit('updateTitle', 'Cart');
      }

    },

    methods: {
      async initCart() {

        if (!this.isCartId) {
          return;
        }
        const cart = (await this.fetchCartById(this.isCartId))?.cart;

        if (!cart) {
          return;
        }

        this.$store.commit('checkout/setCart', cart);
      },
      async fetchCartById(cartId) {
        this.$store.commit('checkout/setLoading', true);

        try {
          return await sharedCartService.fetchCartById(cartId);
        } catch (error) {
          console.error(error);
        } finally {
          this.$store.commit('checkout/setLoading', false);
        }
      },
      async handleChangeQuantity(value, quantity) {
        this.updateItemLoading = true;

        const attributes = value.node.attributes.reduce((acc, item) => {
          acc = {
            ...acc,
            [item.key]: item.value,
          };

          return acc;
        }, {});

        try {
          const response = await CartService.updateQuantitiesItem({
            cartId: this.isCartId,
            itemId: value.node.id,
            quantity,
            ...(attributes['__store'] && { store: attributes['__store'] }),
            ...(attributes['__ref'] && { referal: attributes['__ref'] }),
            ...(attributes['__customPrice'] && { customPrice: attributes['__customPrice'] }),
          });

          const cart = response?.cartLinesUpdate?.cart;

          this.$store.commit('checkout/setCart', cart || null);
        } catch (error) {
          console.log(error);
        } finally {
          this.updateItemLoading = false;
        }
      },
      async handleRemove({ product, quantity }) {
        try {
          this.$stats.send('remove_from_cart', {
            product_id: product.node.id, // todo:
            // channel_id: null, // todo:
            // variant_id: null, // todo:
            quantity: quantity,
          });

          const response = await CartService.removeCheckoutItem({
            cartId: this.isCartId,
            itemId: product.node.id,
          });

          this.$store.commit(
            'checkout/setCart',
            response.cartLinesRemove.cart || null,
          );
        } catch (error) {
          console.log(error);
        }
      },
      handleLike(product) {},
      handleOpenCheckout() {
        if (this.isPopup) {
          this.$emit('open:checkout');
          return;
        }

        this.$router.push({ name: 'checkout' });
      },
      handleSelectAllProducts() {
        if (this.checkedAllProducts) {
          this.isCheckedProducts = [];
        } else {
          this.isCheckedProducts = this.products.map(
            (product) => product.node.merchandise.id,
          );
        }
      },
      handleSelectAllProductsFromStore(store) {
        const isCheckedProductsStore = this.checkedStore(store);

        if (!isCheckedProductsStore) {
          store.products.forEach((product) => {
            const isChecked = this.isCheckedProducts.includes(
              product.node.merchandise.id,
            );

            if (!isChecked) {
              this.isCheckedProducts.push(product.node.merchandise.id);
            }
          });
        } else {
          store.products.forEach(this.checkProduct);
        }
      },
      handleSelectProduct(product) {
        this.checkProduct(product);
      },
      replaceStores(acc, item) {
        let store = item.node.attributes.find((attr) => attr.key === '__store');

        store = JSON.parse(store.value);

        if (!store) {
          return acc;
        }

        if (acc[store.id]) {
          return {
            ...acc,
            [store.id]: {
              ...acc[store.id],
              products: [...acc[store.id].products, item],
            },
          };
        }

        return {
          ...acc,
          [store.id]: {
            ...store,
            products: [item],
          },
        };
      },
      checkProduct(product) {
        const isChecked = this.isCheckedProducts.includes(
          product.node.merchandise.id,
        );

        if (isChecked) {
          this.isCheckedProducts = this.isCheckedProducts.filter(
            (id) => id !== product.node.merchandise.id,
          );
        } else {
          this.isCheckedProducts.push(product.node.merchandise.id);
        }
      },
      updateTotalPrice(cost) {
        return {
          total: cost?.totalAmount?.amount || 0,
          products: cost?.subtotalAmount?.amount || 0,
        };
      },
    },
  };
</script>

<style lang="scss">
  body {
    min-height: 50vh;
    height: auto;
  }

  #app-content.content {
    .cart {
      min-height: auto;
    }
  }

  .cart-list {
    .checkbox.custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::before {
      background-color: #21a66e !important;
      border-color: #21a66e !important;
    }
  }
</style>

<style lang="scss" scoped>
  .cart {
    padding-top: 25px;

    .container {
      min-height: calc(100vh - 319px);

      .default-title {
        font-size: 24px;
      }
    }

    .content {
      column-gap: 57px;
      row-gap: 50px;
      display: grid;
      grid-template-columns: 1fr;
      position: relative;
      margin-bottom: 20px;
      margin-top: 35px !important;
      padding-top: 0;

      &-wrapper {
        margin-top: 27px !important;
        min-height: calc(100vh - 400px);
      }
    }

    &-list {
      &--group {
        margin-bottom: 40px;
      }

      &--main,
      &--store,
      &--item,
      &--total {
        padding: 10px 14px;
        background-color: #ffffff;
        border: 1px solid #edf1f4;
        border-radius: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        column-gap: 8px;
      }

      &--store {
        padding: 12px;

        .link {
          display: flex;
          align-items: center;
          color: #343434;
          font-weight: bold;
        }

        .avatar {
          width: 26px;
          height: 26px;
          flex: 0 0 26px;
          border-radius: 50%;
          background-color: #343434;
          position: relative;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &--item {
        position: relative;
        padding: 14px 15px;

        .item-product {
          flex: 1;
        }

        .checkbox {
          position: absolute;
          z-index: 1;
          top: 12px;
          left: 18px;
        }
      }

      &--total {
        justify-content: space-between;
        padding: 16px 20px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #343434;
      }
    }

    &-total {
      position: sticky;
    }

    &.sp-popup {
      padding-top: 0;

      .container {
        padding: 0;
      }

      .content {
        padding-top: 0;
        margin-top: 24px;
      }
    }

    &.sp-desktop {
      @media screen and (min-width: 640px) {
        padding-top: 40px;
      }

      .content {
        @media screen and (min-width: 640px) {
          margin-top: 0;
        }

        @media screen and (min-width: 1024px) {
          grid-template-columns: 1fr 30%;
        }
      }
      .cart-list--store {
        // @media screen and (min-width: 640px) {
        //   padding: 0;
        // }

        .avatar {
          @media screen and (min-width: 640px) {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
          }
        }
      }

      .cart-list--item {
        // @media screen and (min-width: 640px) {
        //   padding: 0;
        // }

        .checkbox {
          @media screen and (min-width: 640px) {
            position: static;
          }
        }
      }

      .cart-total {
        @media screen and (min-width: 1024px) {
          min-width: 200px;
          max-width: 460px;
        }
      }
    }
  }
</style>
