import { amsClient } from '@/service/ams';
import makeRequest from 'src/core/http/e-commerce.client';

import getProductQuery from './queries/get-product.query';
import getProductByHandleQuery from '@/shared/api/product/queries/get-product-by-handle.query';

const getProductById = (productId) => makeRequest(getProductQuery, {
  id: `gid://shopify/Product/${productId}`,
});
const getProductByHandle = (slug) => {
  return makeRequest(getProductByHandleQuery, {
    handle: slug,
  });
};

const getAMSProductByRemoteId = (remoteId) => amsClient.callAms(`/shoprzapi/product/${remoteId}/?by=rid`);

const getProductVideosByIds = async (payload) => amsClient.callAms('/shoprzapi/itemvod/by_ids/', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
  body: JSON.stringify(payload),
});

export default {
  getProductById,
  getProductVideosByIds,
  getAMSProductByRemoteId,
  getProductByHandle,
};
