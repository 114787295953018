import { amsClient } from '@/service/ams';

const state = () => ({
  dataAreasLive: true,
  comingSoonItems: [],
  airItems: [],
  currentLive: null,
  currenSecondLive: null,
  playingLive: false,
});

const getters = {
  areasLive(state) {
    return state.comingSoonItems.length || state.airItems.length;
    // return state.dataAreasLive;
  },
  currentLive(state) {
    return state.currentLive;
  },
  currenSecondLive(state) {
    return state.currenSecondLive;
  },
  playingLive(state) {
    return state.playingLive;
  },
};

const mutations = {
  setDataAreasLive(state, payload) {
    state.dataAreasLive = payload;
  },
  setCurrenLive(state, payload) {
    state.currentLive = payload;
  },
  setCurrenSecondLive(state, payload) {
    state.currenSecondLive = payload;
  },
  setPlayingLive(state, value = false) {
    state.playingLive = value;
  },
  setItems(state, payload) {
    state[payload.type] = payload.items;
  },
};

const actions = {
  async getDataAreasLive({ commit }, payload) {
    try {
      const subData = await Promise.all(
        payload?.map((nav) => amsClient.callAms(nav.link)) || [],
      );
      const checkLive = subData.some((subArr) => subArr?.items.length);
      if (!checkLive) {
        commit('setDataAreasLive', false);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
