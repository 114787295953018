<template>
  <div
    :class="{
      'sp-chat-widget-content': true,
      'sp-chat-widget-content-fixed': isFixed,
      'sp-chat-widget-content-mobile': isMobile,
      'sp-chat-widget-content-mobile-full': isMobile && mobileFullHeight,
      'sp-chat-widget-content-ios-safari': isMobile && isIOSSafari,
    }"
  >
    <div
      v-if="isMobile"
      style="height: 54px; width: 100vw"
    />
    <div
      v-if="!isMobile"
      :class="{
        'sp-chat-widget-header': true,
        [headerClass]: true,
      }"
    >
      <div class="sp-chat-widget-header-title">
        Contact Store
      </div>
      <i
        class="far fa-times sp-chat-widget-header-close"
        @click="onClose"
      />
    </div>
    <div
      v-if="isMobile"
      class="sp-chat-widget-header-mobile"
    >
      <div
        class="sp-chat-widget-header-mobile-button"
        @click="onClose"
      >
        <i class="far fa-chevron-left"/>
      </div>
      <div>
        <button
          v-if="showMobileNewIssueButton"
          class="sp-chat-widget-new-issue-button"
          @click="onNewIssue"
        >
          New Issue
        </button>
      </div>
    </div>
    <div
      :class="{
        'sp-chat-widget-messages-wrapper': true,
        'sp-chat-widget-messages-wrapper-full': mobileFullHeight
      }"
    >
      <div
        ref="chat"
        class="sp-chat-widget-messages-list"
        @scroll="onScrollTop"
      >
        <div class="sp-chat-widget-message-predefined">
          Hello 👋
        </div>
        <div class="sp-chat-widget-message-predefined">
          Please choose your topic
        </div>
        <div class="sp-chat-widget-message-predefined-list">
          <div
            v-for="topic in topics"
            class="sp-chat-widget-message-predefined sp-chat-widget-message-predefined-button"
            :class="{
              'sp-chat-widget-message-predefined sp-chat-widget-message-predefined-button': true,
              'sp-chat-widget-message-predefined-button-selected': selectedTopic === topic,
              'sp-chat-widget-message-predefined-button-disabled': selectedTopic && selectedTopic !== topic,
            }"
            @click="() => onSelectTopic(topic)"
          >
            {{ topic }}
          </div>
        </div>
        <div
          v-for="msg in predefinedMessages"
          :class="msg.class"
        >
          {{ msg.text }}
        </div>
        <chat-widget-message-component
          v-for="message in messages"
          :key="message._id"
          :message="message"
          :chat-user="chatUser"
        />
      </div>

    </div>
    <div class="sp-chat-widget-divider"/>
    <input
      ref="file"
      type="file"
      accept="image/png, image/jpeg, image/jpg"
      hidden
      @change="onNewFile"
    />
    <div
      v-if="isIssueClosed"
      class="sp-chat-widget-closed-issue"
    >
      Issue is closed
    </div>
    <div
      v-if="!isIssueClosed"
      class="sp-chat-widget-input-wrapper"
    >
      <input
        v-model.trim="newTextMessage"
        class="sp-chat-widget-input"
        :placeholder="inputPlaceholder"
        :disabled="isDisabledInput"
        ref="input"
        @keyup.enter="onSendMessage"
      />
      <i
        v-if="!isAttachmentsDisabled"
        class="far fa-paperclip sp-chat-widget-input-action"
        :class="{
          'far fa-paperclip sp-chat-widget-input-action': true,
          'sp-chat-widget-input-action-disabled': isAttachmentsDisabled,
        }"
        @click="onUploadAttachment"
      />
      <div
        class="sp-chat-widget-input-action sp-chat-widget-input-action-send"
        @click="onSendMessage"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.18394 7.25002L2.05581 2.9969L11.9808 7.25002H4.18394ZM4.18394 8.75002H11.9808L2.05581 13.0031L4.18081 8.75002H4.18394ZM1.39331 1.08127C1.00894 0.915647 0.558936 1.0094 0.271436 1.31252C-0.0160636 1.61565 -0.0816886 2.0719 0.105811 2.4469L2.88081 8.00002L0.105811 13.5531C-0.0816886 13.9281 -0.0160636 14.3813 0.271436 14.6875C0.558936 14.9938 1.00581 15.0844 1.39331 14.9219L15.3933 8.9219C15.7621 8.76565 15.9996 8.40315 15.9996 8.00315C15.9996 7.60315 15.7621 7.24065 15.3933 7.0844L1.39331 1.0844V1.08127Z" fill="#B6C0D3"/>
        </svg>
      </div>
    </div>
    <div
      v-if="isMobile"
      style="height: 56px; width: 100vw"
    />
  </div>
</template>

<script>
  import ChatWidgetMessageComponent from '@/shared/features/chat-widget/components/chat-widget-message.component';
  import { isActiveFeature } from '@/plugins/feature-toggle';
  import isClosedTicket from '@/entities/account/features/message-center/helpers/is-closed-ticket.helper';

  export default {
    name: 'ChatWidgetComponent',
    components: { ChatWidgetMessageComponent },
    props: {
      selectedTicket: {
        type: Object,
        default: null,
      },
      messages: {
        type: Array,
        default: () => ([]),
      },
      chatUser: {
        type: Object,
        default: () => ({}),
      },
      isFixed: {
        type: Boolean,
        default: true,
      },
      headerClass: {
        type: String,
        default: '',
      },
      disabledNewMessages: {
        type: Boolean,
        default: false,
      },
      showMobileNewIssueButton: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        newTextMessage: '',
        enteredEmail: '',
        enteredName: '',
        topics: [
          'Product',
          'My Order',
          'Delivery',
          'Payment',
          'Returns',
          'Other',
        ],
        selectedTopic: null,
        isMobile: false,
        mobileFullHeight: false,
        isIOSSafari: false,
      };
    },

    computed: {
      isIssueClosed() {
        return isClosedTicket(this.selectedTicket);
      },
      isAttachmentsDisabled() {
        return !this.selectedTicket;
      },
      isDisabledInput() {
        return !this.selectedTopic;
      },
      inputPlaceholder() {
        if (!this.selectedTopic) {
          return 'Select a topic';
        }

        return 'Write a message';
      },
      predefinedMessages() {
        const messages = [];
        if (this.selectedTopic) {
          messages.push(
            { text: this.selectedTopic, class: 'sp-chat-widget-message-predefined sp-chat-widget-message-predefined-buyer' },
            { text: 'Enter your email', class: 'sp-chat-widget-message-predefined' },
          );
        }

        if (this.enteredEmail) {
          messages.push(
            { text: this.enteredEmail, class: 'sp-chat-widget-message-predefined sp-chat-widget-message-predefined-buyer' },
            { text: 'Enter your name', class: 'sp-chat-widget-message-predefined' },
          );
        }

        if (this.enteredName) {
          messages.push(
            { text: this.enteredName, class: 'sp-chat-widget-message-predefined sp-chat-widget-message-predefined-buyer' },
            { text: 'Thanks! Enter your issue', class: 'sp-chat-widget-message-predefined' },
          );
        }

        return messages;
      },
    },

    watch: {
      $route(to, from) {
        this.mobileFullHeight = this.isMobile && to.name === 'product-id';
      },
      selectedTicket: {
        handler(val) {
          if (!val) {
            this.newTextMessage = '';
            this.enteredEmail = '';
            this.enteredName = '';
            this.selectedTopic = '';
            return;
          }


          const { name, type, email } = val.attachments?.[0] || {};
          this.newTextMessage = '';
          this.enteredEmail = email;
          this.enteredName = name;
          this.selectedTopic = type;
        },
        immediate: true,
      },
    },
    beforeMount() {
      this.isMobile = window.innerWidth < 800;

      const ua = window.navigator.userAgent;
      const isIOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
      this.isIOSSafari = isIOS && ua.match(/WebKit/i) && !ua.match(/CriOS/i);
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth < 800;
        if (!this.isMobile) {
          document.body.style.overflow = 'auto'; // dirty
        }
      });

    },
    methods: {
      onScrollTop(e) {
        this.$emit('messages:more', e);
      },
      onNewIssue() {
        this.$emit('ticket:reset');
      },
      onNewFile(file) {
        this.$emit('attachment:new', this.$refs.file.files[0]);
        this.$refs.file.value = null;
      },
      onUploadAttachment() {
        if (!this.selectedTicket) {
          return;
        }
        this.$refs.file.click();
      },
      onSendMessage($event) {
        const msg = this.newTextMessage;
        if (this.disabledNewMessages || !this.selectedTopic || !msg) {
          return;
        }

        this.newTextMessage = '';

        this.$refs.input.focus();
        if (!this.enteredEmail) {
          this.scrollToBottom();
          return this.enteredEmail = msg;
        }

        if (!this.enteredName) {
          this.scrollToBottom();
          return this.enteredName = msg;
        }

        if (!this.selectedTicket) {
          this.$emit('ticket:new', {
            msg,
            ticket: {
              topic: this.selectedTopic,
              name: this.enteredName,
              email: this.enteredEmail,
            },
          })
        } else {
          this.$emit('message:new', msg);
        }
      },
      onClose() {
        this.$emit('close');
      },
      onSelectTopic(topic) {
        if (this.selectedTopic) {
          return;
        }
        this.selectedTopic = topic;
      },
      scrollToBottom() {
        setTimeout(() => {
          const { scrollHeight } = this.$refs.chat;

          this.$refs.chat.scrollTop = scrollHeight;
        }, 100);
      },
    },

  };
</script>

<style lang="scss">
  @import '../chat-widget-colors';



  .sp-chat-widget-content {
    width: 330px;
    background-color: white;
    border-radius: 15px;
    &-fixed {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 201;
      box-shadow: 0px 0px 15px 0px rgba(175, 175, 175, 0.40);
    }

    &-mobile {
      border-radius: 0;
      width: 100vw;
      //bottom: 50px;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      &-full {
        bottom: 0;
      }
      .sp-chat-widget-header {
        border-radius: 0;
      }

      $height: calc(100vh - 240px);
      .sp-chat-widget-messages-wrapper {
        //height: $height;
        flex: 1;
      }

      .sp-chat-widget-messages-list {
        //max-height: $height;
        max-height: initial;
      }

      $full-height: calc(100vh - 160px);
      .sp-chat-widget-messages-wrapper-full {
        height: $full-height;
        .sp-chat-widget-messages-list {
          max-height: $full-height;
        }
      }

    }
  }

  .sp-chat-widget-header {
    background-color: $sp-chat-widget-main;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    width: 100%;
    position: relative;

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &-title {
      padding: 1rem;
      font-weight: 500;
    }
  }

  .sp-chat-widget-header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 60px;
    height: 80px;
    padding: 1rem;
    padding-left: 1.5rem;
    border-top: 1px solid #E7EBF1;
    &-button {
      border: 1px solid #E7EBF1;
      padding: 0.5rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 10px;
      }
    }

  }

  .sp-chat-widget-new-issue-button {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 10px;
    border-radius: 5px;
    background-color: #21A66E;
    color: #fff;
  }

  $desktop-height: 450px;

  @media screen and (max-height: 800px) {
    $desktop-height: 325px;
    .sp-chat-widget-messages-wrapper {
      height: $desktop-height !important;
    }

    .sp-chat-widget-messages-list {
      max-height: $desktop-height !important;
    }
  }

  .sp-chat-widget-messages-wrapper {
    height: $desktop-height;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .sp-chat-widget-messages-list {
    padding: 1rem;
    max-height: $desktop-height;
    overflow-y: scroll;
  }

  .sp-chat-widget-message-predefined-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .sp-chat-widget-message-predefined {
    padding: 0.5rem;
    border-radius: 15px;
    border: 1px solid #E2E6EE;
    width: max-content;
    max-width: 100%;
    color: $sp-chat-widget-primary;
    background-color: white;
    margin-bottom: 0.5rem;
    font-size: 15px;

    &-buyer {
      color: $sp-chat-widget-main;
      background-color: #F0F2F6;
      margin-left: auto;
    }

    &-button {
      border-color: $sp-chat-widget-primary;
      cursor: pointer;
      &:hover:not(&-disabled), &-selected {
        color: white;
        background-color: $sp-chat-widget-primary;
      }
      &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .sp-chat-widget-divider {
    border-top: 1px solid #F0F2F6;
  }

  .sp-chat-widget-input-wrapper {
    display: flex;
    align-items: center;
  }

  .sp-chat-widget-input {
    flex: 1;
    padding: 0.75rem 1rem;
    color: $sp-chat-widget-main;
    font-size: 14px;
    border-bottom-left-radius: 10px;
    &:focus {
      outline: none;
    }
    &:disabled {
      //opacity: 0.7;
      background-color: rgba(0, 0, 0, 0.04);
      cursor: not-allowed;
    }
  }

  .sp-chat-widget-input-action {
    padding: 0.5rem 0.25rem;
    cursor: pointer;
    color: #B6C0D3;
    &:hover {
      color: $sp-chat-widget-main;
    }
    &-send {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      &:hover {
        path {
          fill: $sp-chat-widget-main;
        }
      }
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .sp-chat-widget-closed-issue {
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B6C0D3;
  }
</style>
