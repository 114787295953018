import { screenMode } from 'vimmi-web-utils/cjs/screenMode';

const state = () => ({
  floatItem: null,
  playbackState: {},
  options: {},
  pagePosition: {},
});

const getters = {
  floatItem: (state) => state.floatItem,
};
const mutations = {
  setFloatItem(state, floatItem) {
    state.floatItem = floatItem;
  },
  setPlaybackState(state, playbackState) {
    state.playbackState = playbackState || {};
  },
  setFloatOptions(state, options) {
    state.options = options || {};
  },
  setFloatPagePosition(state, position) {
    state.pagePosition = position || {};
  },
};

const actions = {
  setFloatItem({ commit }, floatItem) {
    commit('setFloatItem', floatItem);
  },
  setPlaybackState({ commit }, playbackState) {
    commit('setPlaybackState', playbackState);
  },
  setFloatOptions({ commit }, options) {
    commit('setFloatOptions', options);
  },
  setFloatPagePosition({ commit }, position) {
    commit('setFloatPagePosition', position);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
