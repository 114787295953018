export default [
  {
    name: 'checkout',
    path: '/checkout',
    component: () => import('./checkout.view'),
    meta: {
      page: 'Checkout',
      title: 'Checkout',
    },

    children: [
      {
        name: 'checkout-shipping',
        path: '/checkout/shipping',
        meta: {
          page: 'Checkout shipping',
          title: 'Checkout | Shipping',
        },
      },

      {
        name: 'checkout-payment',
        path: '/checkout/payment',
        meta: {
          page: 'Checkout payment',
          title: 'Checkout | Payment',
        },
      },

      {
        name: 'checkout-order',
        path: '/checkout/:id',
        meta: {
          page: 'Checkout Order',
          title: 'Checkout | Order',
        },
      },
    ],
  },
];
